import React, { useEffect, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import ClipLoader from 'react-spinners/ClipLoader';

import { BRAND } from '../constants/color';

interface IProps {
  color?: string;
  size?: string;
  show?: boolean;
  bar?: boolean;
  space?: boolean;
}

export default (props: IProps) => {
  const { color = BRAND, size, bar = false, space = false, show = false } = props;
  const [isLoading, setIsLoading] = useState(show);
  let timeoutTracker: any;

  useEffect(() => {
    // do not show loader for a second
    timeoutTracker = setTimeout(() => setIsLoading(true), 1000);

    return () => {
      if (timeoutTracker) clearTimeout(timeoutTracker);
    };
  }, []);

  return (
    <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${space ? 'mt-12' : ''}`}>
      {bar ? (
        <BarLoader loading={isLoading} color={color} />
      ) : (
        <ClipLoader loading={isLoading} color={color} size={size === 'tiny' ? 15 : size === 'small' ? 25 : 45} />
      )}
    </div>
  );
};
