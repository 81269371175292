import React, { useState } from 'react';
import { FaAngleRight, FaArrowCircleRight } from 'react-icons/fa';
import { TUserTransaction, TUserInvoice } from 'types';
import Modal from 'fragments/Modal';
import TransactionBillRow from './TransactionRowBill';
import InvoiceBillRow from './InvoiceRowBilling';
import ReactTooltip from 'react-tooltip';

type TProps = {
  transaction?: TUserTransaction[];
  invoice?:TUserInvoice[];
};

export default function BillingHistory({ transaction=[],invoice=[] }: TProps) {
  const [showModal,setShowModal] = useState<boolean>(false);
  
  const closeShowModal = () => setShowModal(false);
  const openShowModal = () => setShowModal(true);

  return (
    <div className="border-solid border-l border-r lg:border-r-0 border-t lg:border-t-0 border-indigo-300">
      <div className="text-base font-medium text-text-blue pl-4 py-2 border-b border-indigo-300"> Billing History</div>
      <div className="overflow-y-auto h-52 xsm:h-56 max-h-56">
        {invoice?.length === 0 && <p className="pl-4 py-2 text-text-grey">No Billing History Available .</p>}
        {invoice?.length > 0 && invoice?.map(row => <InvoiceBillRow key={`Invoice-Bill-Row-${row.id}`} data={row} />)}
      </div>

        <div className="linked cursor-pointer pl-4 xsm:text-base flex flex-row items-center " onClick={openShowModal}>
          <h2 className="pointer linked font-switzer text-base xsm:text-xl flex flex-row items-center">Show all Transactions</h2>
          <FaArrowCircleRight className="self-center ml-2 text-base xsm:text-xl"></FaArrowCircleRight>
        </div>

      <Modal 
        isOpen={showModal} 
        close={closeShowModal}
        size = 'lg'
      >
        <div className='max-h-70vh overflow-y-auto overflow-x-hidden p-3 sm:p-4 md:p-6 relative'>
          <h1 className='text-center border-b border-indigo-300 bg-gray-50 pb-4 text-text-dark font-switzer '>Billing History</h1>
          {transaction?.length ===0 && <h1 className="py-8 text-center text-text-grey text-xl font-switzer ">No Transaction History Available </h1>}
          {transaction?.length > 0 && transaction?.map((row,idx:number) => <TransactionBillRow key={`Billing-History-Row-${idx}`} data={row} />)}
        </div>
      </Modal>
    </div>
  );
}
