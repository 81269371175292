import moment from 'moment';
import React from 'react';
import { Chrono } from 'react-chrono';
import { TimelineItemModel } from 'react-chrono/dist/models/TimelineItemModel';
import { TSchedule } from 'types';
import { isMobile } from 'react-device-detect';
import { LEARN_UNIV_PATH } from 'constants/app';

type TProps = {
  schedules: TSchedule[];
};

export declare type MediaType = 'VIDEO' | 'IMAGE';
const t: MediaType = 'IMAGE';
const getDuration = (from: string, to: string) => {
  const fromMoment = moment(from);
  const toMoment = moment(to);

  if (toMoment.diff(fromMoment, 'days') > 1)
    return ` from ${fromMoment.format('DD-MMM') + ' to ' + toMoment.format('DD-MMM')}`;
  else return ` from ${fromMoment.format('HH:mm') + ' to ' + toMoment.format('HH:mm')}`;
};
export default function ScheduleTimeline(props: TProps) {
  const { schedules } = props;
  const scheduleItems = schedules.map(schedule => {
    const item: TimelineItemModel = {
      title: moment(schedule.date).format('DD-MMM-YY'),
      cardTitle: schedule.title,
      url: schedule.url ? schedule.url : LEARN_UNIV_PATH,
      cardSubtitle: schedule.subTitle + getDuration(schedule.from, schedule.to),
      cardDetailedText: schedule.description
        ? schedule.description
        : `This class is schedulued from ${
            moment(schedule.from).format('HH:mm') + ' to ' + moment(schedule.to).format('HH:mm')
          }. `,
    };
    if (schedule.image)
      item.media = {
        type: t,
        source: {
          url: schedule.image,
        },
      };
    return item;
  });

  const getTitleSize = () => {
    return isMobile ? '1rem' : '4rem';
  };
  const getSubTitleSize = () => {
    return isMobile ? '1rem' : '1rem';
  };
  return (
    // grid md:grid-flow-col grid-flow-row grid-col-1 md:grid-rows-4

    <div className="">
      <Chrono
        items={scheduleItems}
        mode={isMobile ? 'VERTICAL' : 'VERTICAL_ALTERNATING'}
        hideControls
        disableNavOnKey
        enableOutline
        fontSizes={{
          cardSubtitle: getSubTitleSize(),
          cardText: '1rem',
          cardTitle: '2rem',
          title: getTitleSize(),
        }}
        theme={{ primary: 'blue', secondary: '#3b82f68' }}
      />
    </div>
  );
}
