import React, { useState } from 'react';
import MultiSelect, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';

type Props = {
  defaultValue?: any;
  fieldProps: any;
  info?: string;
  options: TOption[];
  remainingOptions: TOption[];
  placeholder?: string;
  error?: string;
  getValues: Function;
  setValue: Function;
  maxConstraints?: number;
};

type TOption = {
  label: string;
  value: string;
};

const animatedComponent = makeAnimated();

export function FormMultiSearchSelect(props: Props) {
  const {
    defaultValue,
    error,
    options,
    fieldProps,
    placeholder,
    getValues,
    setValue,
    maxConstraints,
    remainingOptions,
  } = props;

  const defaultValueArray = defaultValue?.split(',');
  let updateDefault = options?.filter((item: TOption) => defaultValueArray?.includes(item.value?.toLowerCase()));

  if (maxConstraints) updateDefault = updateDefault.slice(0, maxConstraints);

  const [multiselectValue, setMultiselectValue] = useState<TOption[]>(updateDefault || '');

  const colourStyles: StylesConfig = {
    placeholder: styles => ({ ...styles, color: 'rgba(156, 163, 175)' }),
    control: styles => ({ ...styles, backgroundColor: 'rgba(243, 244, 246)' }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#5d9ce030',
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: '#5D9DE0',
      fontSize: '1rem',
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: 'blue',
      ':hover': {
        backgroundColor: '#5D9DE0',
        color: 'white',
      },
    }),
  };

  if (!options) return null;

  return (
    <React.Fragment>
      <MultiSelect
        isMulti
        styles={colourStyles}
        options={remainingOptions ? remainingOptions : options}
        isSearchable={true}
        isClearable={true}
        isDisabled={false}
        isLoading={false}
        isRtl={false}
        components={animatedComponent}
        closeMenuOnSelect={false}
        {...fieldProps}
        placeholder={placeholder}
        // defaultValue={updateDefault || ""}
        value={multiselectValue}
        className={`text-sm sm:text-base relative w-full border border-gray-300 placeholder-gray-400
                ${fieldProps.disabled ? 'bg-white border-none' : ''}
                ${error ? 'border border-red-500 focus:ring-red-300' : 'focus:border-blue-300'}
            `}
        key={fieldProps.name}
        onChange={(items: TOption[]) => {
          let updatedItems;
          if (maxConstraints && items.length > maxConstraints) {
            updatedItems = items
              .slice(0, maxConstraints)
              .map((item: TOption) => item.value)
              ?.join(',');
            setMultiselectValue(items.slice(0, maxConstraints));
          } else {
            updatedItems = items.map((item: TOption) => item.value)?.join(',');
            setMultiselectValue(items);
          }
          setValue(fieldProps.name, updatedItems);
        }}
      />
    </React.Fragment>
  );
}
