import React from 'react';
import { useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';

import { FormComponentHolder, FormHolder, FormInput, FormSelect } from 'components/Form';
import { TProcessedFormField, TProcessedFormFieldList } from 'types';

interface IProps {
  onSubmit: (values: any) => void;
  defaultValues: { [key: string]: any };
  submitting: boolean;
  schoolEducationFields: TProcessedFormFieldList;
  ugEducationFields: TProcessedFormFieldList;
  pgEducationFields: TProcessedFormFieldList;
  phdEducationFields: TProcessedFormFieldList;
  highestEducationParams: TProcessedFormField;
  error: any;
}

export default function EducationProfile(props: IProps) {
  const {
    onSubmit,
    defaultValues,
    schoolEducationFields,
    ugEducationFields,
    pgEducationFields,
    phdEducationFields,
    highestEducationParams,
    error: formError,
  } = props;

  const { control, register, handleSubmit, getValues, setValue, watch, formState, reset } = useForm({ defaultValues });
  const { isDirty } = formState;
  const formValues = watch();

  const highestEducationField = {
    ...highestEducationParams,
    errors: formError,
    error: formError[`${highestEducationParams.name}`]?.length > 0,
    defaultValue: defaultValues[highestEducationParams.name],
    fieldProps: register(highestEducationParams.name, highestEducationParams.props),
    refGenerator: register,
    setValue,
    rules: highestEducationParams.props,
    control,
    getValues,
    formValues,
    required: highestEducationParams.props?.constraints.required,
  };

  const processForm = (processedData: { [key: string]: any }) => {
    const ugData: any = {};
    const pgData: any = {};
    const doctorateData: any = {};
    const schoolData: any = {};

    for (const dataKey in processedData) {
      if (dataKey.startsWith('undergraduate-')) {
        const key = dataKey.replace('undergraduate-', '');
        ugData[key] = processedData[dataKey];
      }

      if (dataKey.startsWith('postgraduate-')) {
        const key = dataKey.replace('postgraduate-', '');
        pgData[key] = processedData[dataKey];
      }

      if (dataKey.startsWith('doctorate-')) {
        const key = dataKey.replace('doctorate-', '');
        doctorateData[key] = processedData[dataKey];
      }
      if (dataKey.startsWith('school-')) {
        const key = dataKey.replace('school-', '');
        schoolData[key] = processedData[dataKey];
      }
    }

    const data = {
      highestEducation: processedData.highestEducation,
      undergraduate: ugData,
      postgraduate: pgData,
      doctorate: doctorateData,
      school: schoolData,
    };
    onSubmit(data);
    reset(processedData);
    return true;
  };

  const ugParams: any = {};
  for (const field in ugEducationFields) {
    const f = ugEducationFields[field];
    ugParams[field] = {
      ...f,
      errors: formError,
      error: formError[`${f.name}`]?.length > 0,
      defaultValue: defaultValues[f.name],
      fieldProps: register(f.name, f.props),
      refGenerator: register,
      setValue,
      rules: f.props,
      control,
      getValues,
      formValues,
      required: f.props?.required,
    };
  }

  const pgParams: any = {};
  for (const field in pgEducationFields) {
    const f = pgEducationFields[field];
    pgParams[field] = {
      ...f,
      errors: formError,
      error: formError[`${f.name}`]?.length > 0,
      defaultValue: defaultValues[f.name],
      fieldProps: register(f.name, f.props),
      refGenerator: register,
      setValue,
      rules: f.props,
      control,
      getValues,
      formValues,
      required: f.props?.required,
    };
  }

  const phdParams: any = {};
  for (const field in phdEducationFields) {
    const f = phdEducationFields[field];
    phdParams[field] = {
      ...f,
      errors: formError,
      error: formError[`${f.name}`]?.length > 0,
      defaultValue: defaultValues[f.name],
      fieldProps: register(f.name, f.props),
      refGenerator: register,
      setValue,
      rules: f.props,
      control,
      getValues,
      formValues,
      required: f.props?.required,
    };
  }

  const schoolParams: any = {};
  for (const field in schoolEducationFields) {
    const f = schoolEducationFields[field];
    schoolParams[field] = {
      ...f,
      errors: formError,
      error: formError[`${f.name}`]?.length > 0,
      defaultValue: defaultValues[f.name],
      fieldProps: register(f.name, f.props),
      refGenerator: register,
      setValue,
      rules: f.props,
      control,
      getValues,
      formValues,
      required: f.props?.required,
    };
  }

  const uguniversityField = ugParams['undergraduate-university'];
  const ugcollegeField = ugParams['undergraduate-college'];
  const ugmajorField = ugParams['undergraduate-major'];
  const ugcgpaField = ugParams['undergraduate-cgpa'];
  const ugedustatusField = ugParams['undergraduate-eduStatus'];
  const ugstartyearField = ugParams['undergraduate-startYear'];
  const ugendyearField = ugParams['undergraduate-endYear'];
  const ugsubjectField = ugParams['undergraduate-subject'];

  const pguniversityField = pgParams['postgraduate-university'];
  const pgcollegeField = pgParams['postgraduate-college'];
  const pgmajorField = pgParams['postgraduate-major'];
  const pgcgpaField = pgParams['postgraduate-cgpa'];
  const pgedustatusField = pgParams['postgraduate-eduStatus'];
  const pgstartyearField = pgParams['postgraduate-startYear'];
  const pgendyearField = pgParams['postgraduate-endYear'];
  const pgsubjectField = pgParams['postgraduate-subject'];

  const schoolNameField = schoolParams['school-name'];
  const schoolGraduationField = schoolParams['school-graduation'];
  const schoolGradeField = schoolParams['school-grade'];

  const phduniversityField = phdParams['doctorate-university'];
  const phdcgpaField = phdParams['doctorate-cgpa'];
  const phdedustatusField = phdParams['doctorate-eduStatus'];
  const phdstartyearField = phdParams['doctorate-startYear'];
  const phdsubjectField = phdParams['doctorate-subject'];
  const phdtopicField = phdParams['doctorate-topic'];

  const { 'undergraduate-eduStatus': ugEduStatusValue, 'postgraduate-eduStatus': pgEduStatusValue } = getValues();

  const ugFields = (
    <div className="w-full">
      <h2>Undergraduate</h2>
      <FormComponentHolder FormComponent={FormSelect} {...ugedustatusField} />
      <FormComponentHolder FormComponent={FormInput} {...uguniversityField} />
      <FormComponentHolder FormComponent={FormInput} {...ugcollegeField} />
      <FormComponentHolder FormComponent={FormInput} {...ugmajorField} />
      <FormComponentHolder FormComponent={FormInput} {...ugsubjectField} />
      <FormComponentHolder FormComponent={FormInput} {...ugcgpaField} />
      <FormComponentHolder FormComponent={FormInput} {...ugstartyearField} />
      {ugEduStatusValue?.toLowerCase() !== 'ongoing' && (
        <FormComponentHolder FormComponent={FormInput} {...ugendyearField} />
      )}
    </div>
  );

  const pgFields = (
    <div className="w-full">
      <h2>Postgraduate/Masters</h2>
      <FormComponentHolder FormComponent={FormSelect} {...pgedustatusField} />
      <FormComponentHolder FormComponent={FormInput} {...pguniversityField} />
      <FormComponentHolder FormComponent={FormInput} {...pgcollegeField} />
      <FormComponentHolder FormComponent={FormInput} {...pgmajorField} />
      <FormComponentHolder FormComponent={FormInput} {...pgsubjectField} />
      <FormComponentHolder FormComponent={FormInput} {...pgcgpaField} />
      <FormComponentHolder FormComponent={FormInput} {...pgstartyearField} />
      {pgEduStatusValue?.toLowerCase() !== 'ongoing' && (
        <FormComponentHolder FormComponent={FormInput} {...pgendyearField} />
      )}
    </div>
  );

  const phdFields = (
    <div className="w-full">
      <h2>Doctorate</h2>
      <FormComponentHolder FormComponent={FormSelect} {...phdedustatusField} />
      <FormComponentHolder FormComponent={FormInput} {...phduniversityField} />
      <FormComponentHolder FormComponent={FormInput} {...phdtopicField} />
      <FormComponentHolder FormComponent={FormInput} {...phdsubjectField} />
      <FormComponentHolder FormComponent={FormInput} {...phdcgpaField} />
      <FormComponentHolder FormComponent={FormInput} {...phdstartyearField} />
    </div>
  );

  const schoolFields = (
    <div className="w-full">
      <h2>School</h2>
      <FormComponentHolder FormComponent={FormInput} {...schoolNameField} />
      <FormComponentHolder FormComponent={FormInput} {...schoolGraduationField} />
      <FormComponentHolder FormComponent={FormSelect} {...schoolGradeField} />
    </div>
  );

  const fields = [phdFields, pgFields, ugFields, schoolFields];
  const fieldMap = ['doctorate', 'postgraduate', 'undergraduate', 'school'];
  const highestEducation = formValues.highestEducation;
  const educationFieldIndex = fieldMap.findIndex(f => f === highestEducation);
  const releventFields = fields.slice(educationFieldIndex);

  return (
    <FormHolder
      isDirty={isDirty}
      onSubmit={handleSubmit(processForm)}
      className={`mx-auto mt-4 sm:mt-8 2xl:px-12 ${
        typeof formError === 'object' && Object?.keys(formError)?.length > 0
          ? 'p-4 pb-12 md:p-8 md:pb-14 border-2 border-red-500'
          : ''
      }`}
    >
      <div>
        <FormComponentHolder FormComponent={FormSelect} {...highestEducationField} />
      </div>
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-8 mt-4">
        {releventFields[0]}
        {releventFields[1]}
      </div>
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-8 mt-4">
        {releventFields[2]}
        {releventFields[3]}
      </div>

      <button className="button dark filled float-right mt-2" type="submit">
        <FaSave className="inline mr-2" /> Save
      </button>
    </FormHolder>
  );
}
