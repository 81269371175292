import React from 'react';
import { useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';

import {
  FormPhone,
  FormHolder,
  FormComponentHolder,
  FormInput,
  FormUpload,
  FormSwitch,
  FormSelect,
  FormTextArea,
  FormMultiSearchSelect,
} from 'components/Form';
import { FormEmailVerify } from 'components/Form/EmailVerify';
import { TProcessedFormFieldList } from 'types';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { DEFAULT_PROFILE_AVATAR_IMG } from 'constants/app';

interface IProps {
  onSubmit: (values: any) => void;
  defaultValues: { [key: string]: any };
  submitting: boolean;
  error?: any;
  backgroundFields: TProcessedFormFieldList;
}

export default function BackgroundProfile(props: IProps) {
  const { onSubmit, defaultValues, error: formError, backgroundFields } = props;
  const { control, register, handleSubmit, getValues, setValue, watch, formState, reset } = useForm({ defaultValues });
  const { isDirty } = formState;
  const formValues = watch();

  const processForm = (processedData: { [key: string]: any }) => {
    const backgroundData = {
      picture: processedData.picture,
      username: processedData.username,
      sname: {
        firstname: processedData['sname-firstname'],
        lastname: processedData['sname-lastname'],
      },
      email: processedData.email,
      iemail: processedData.iemail,
      idcard: processedData.idcard,
      phone: processedData.phone,
      gender: processedData.gender,
      birthYear: processedData.birthYear,
      linkedinUrl: processedData.linkedinUrl,
      blurb: processedData.blurb,
      topskills: processedData.topskills,
      cCity: processedData.cCity,
      isWorking: processedData.isWorking,
      highestEducation: processedData.highestEducation,
      isLookingPlacement: processedData.isLookingPlacement,
      hasValidInstitutionalMail: processedData.hasValidInstitutionalMail,
    };
    onSubmit(backgroundData);
    reset(processedData);
    return true;
  };

  const inputParams: any = {};
  for (const field in backgroundFields) {
    const f = backgroundFields[field];
    inputParams[field] = {
      ...f,
      errors: formError,
      error: formError[`${f.name}`]?.length > 0,
      defaultValue: defaultValues[f.name],
      fieldProps: register(f.name, f.props),
      refGenerator: register,
      setValue,
      rules: f.props,
      control,
      getValues,
      formValues,
      required: f.props?.constraints?.required,
    };
  }

  const pictureField = inputParams.picture;
  const firstnameField = inputParams['sname-firstname'];
  const lastnameField = inputParams['sname-lastname'];
  const usernameField = inputParams.username;
  const emailField = inputParams.email;
  const iemailField = inputParams.iemail;
  const idcardField = inputParams.idcard;
  const phoneField = inputParams.phone;
  const genderField = inputParams.gender;
  const birthYearField = inputParams.birthYear;
  const linkedinUrlField = inputParams.linkedinUrl;
  const profileBlurbField = inputParams.profileBlurb;
  const profileTopSkillField = inputParams.profileTopSkill;
  const currentCityField = inputParams.currentCity;
  const isWorkingField = inputParams.isWorking;
  const highestEducationField = inputParams.highestEducation;
  const isLookingPlacementField = inputParams.isLookingPlacement;
  const hasValidInstitutionalMailField = inputParams.hasValidInstitutionalMail;

  const { isWorking, is_idcard_verified, hasValidInstitutionalMail } = getValues();

  return (
    <FormHolder
      isDirty={isDirty}
      onSubmit={handleSubmit(processForm)}
      className={`mx-auto max-w-2xl mt-4 sm:mt-8 ${
        typeof formError === 'object' && Object?.keys(formError)?.length > 0
          ? 'p-4 pb-12 md:p-8 md:pb-14 border-2 border-red-500'
          : ''
      }`}
    >
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <div className="mr-4 order-1 sm:order-0 w-full">
          <FormComponentHolder FormComponent={FormInput} {...usernameField} />
          <FormComponentHolder FormComponent={FormInput} {...emailField} />
        </div>
        <div className="flex flex-col items-center order-0 sm:order-1">
          <FormComponentHolder
            FormComponent={FormUpload}
            {...pictureField}
            render={
              <div className="border border-gray-300 rounded-sm h-32 w-32 flex items-center justify-center">
                <img src={formValues.picture ? formValues.picture : DEFAULT_PROFILE_AVATAR_IMG} alt="profile" />
              </div>
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-0 mt-0 xsm:flex-row xsm:gap-4 xsm:mt-2">
        <FormComponentHolder
          FormComponent={FormInput}
          {...firstnameField}
          defaultValue={formValues['sname-firstname']}
        />
        <FormComponentHolder FormComponent={FormInput} {...lastnameField} defaultValue={formValues['sname-lastname']} />
      </div>
      <FormComponentHolder FormComponent={FormSelect} {...genderField} />
      <FormComponentHolder FormComponent={FormInput} {...birthYearField} />
      <FormComponentHolder FormComponent={FormInput} {...currentCityField} />
      <FormComponentHolder FormComponent={FormPhone} {...phoneField} />
      <FormComponentHolder FormComponent={FormSelect} {...highestEducationField} />
      <FormComponentHolder FormComponent={FormTextArea} {...linkedinUrlField} rows={1} />
      <FormComponentHolder FormComponent={FormTextArea} {...profileBlurbField} rows={10} />
      <FormComponentHolder
        FormComponent={FormMultiSearchSelect}
        {...profileTopSkillField}
        maxConstraints={profileTopSkillField.constraints.maxItem.value}
        defaultValue={formValues[profileTopSkillField.name]}
      />
      <FormComponentHolder FormComponent={FormSwitch} {...isLookingPlacementField} />
      <FormComponentHolder FormComponent={FormSwitch} {...isWorkingField} />
      {!isWorking && (
        <React.Fragment>
          <FormComponentHolder FormComponent={FormSwitch} {...hasValidInstitutionalMailField} />
          {hasValidInstitutionalMail ? (
            <FormComponentHolder FormComponent={FormEmailVerify} {...iemailField} />
          ) : (
            <div className="flex w-full gap-4">
              <FormComponentHolder
                FormComponent={FormUpload}
                {...idcardField}
                render={
                  formValues?.idcard && (
                    <div className="flex-grow border border-gray-300 rounded-sm flex items-center justify-center">
                      <img className="max-h-80 max-w-xs object-contain" src={formValues.idcard} />
                    </div>
                  )
                }
              />
              {formValues?.idcard && (
                <div className="flex items-start justify-center gap-4 mt-4">
                  {is_idcard_verified ? (
                    <React.Fragment>
                      <FaCheckCircle className="text-green-500 text-3xl" data-tip="Verified" />
                      <span className="text-base xsm:text-lg text-text-dark cursor-default">Verify</span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <FaExclamationTriangle className="text-yellow-500 text-3xl" />
                      <span className="text-base xsm:text-lg text-text-dark cursor-default">Verify</span>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      )}

      <button className="button dark float-right mt-2" type="submit">
        <FaSave className="inline mr-2" /> Save
      </button>
    </FormHolder>
  );
}
