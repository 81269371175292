import React from 'react';

type Props = {
  Icon?: any;
  fieldProps: any;
  label: string;
  info?: string;
  placeholder?: string;
  defaultValue?: string;
  error?: string;
  rows?: number;
};

export function FormTextArea(props: Props) {
  const { defaultValue, error, placeholder, fieldProps, rows = 5 } = props;

  return (
    <textarea
      className={`text-sm sm:text-base relative w-full placeholder-gray-400 p-2
        ${fieldProps.disabled ? 'bg-white border-none' : ''}
        ${error ? 'border-2 border-red-500 focus:ring-red-300' : 'border border-gray-300 focus:border-blue-300'}
      `}
      placeholder={placeholder}
      rows={rows}
      defaultValue={defaultValue}
      {...fieldProps}
    />
  );
}
