import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ReactModal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from 'fragments/ScrollToTop';
import Dashboard from 'components/Dashboard';
import Preloader from 'components/Preloader';
import Profile from 'components/Profile';
import GHF from 'components/GHF';
import Hub from 'components/Hub';
import AllEvents from 'components/AllEvents';
import UserSchedule from 'components/Schedule';
import {
  DASHBOARD_PATH,
  MY_STUFF_PATH,
  MY_USERDATA_PATH,
  MY_SCHEDULE_PATH,
  MY_PROFILE_PATH,
  ALL_MEETUPS_PATH,
  MY_SUBSCRIPTION_PATH,
  PAYMENT_SUCCESS_PATH,
  MEETUP_DETAIL_PATH,
  TECHX_PATH,
  COMMUNITY_PATH,
  FEEDBACK_API,
  JOBS_PATH,
  JOB_APPLICATION_PATH,
} from 'constants/app';
import 'styles/App.css';
import 'styles/App.scss.css';
import { toast } from 'react-toastify';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import Subscription from 'components/Subscription';
import { UserData } from 'components/Dashboard/UserData';
import ErrorComponent from 'components/Error';
import PaymentSuccess from 'components/Payment/Success';
import { REFERRAL_PATH, COMMUNITY_DETAIL_PATH } from './constants/app';
import Referral from 'components/Referral/Referral';
import useStore from 'globalstate';
import MeetupDetail from 'components/MeetupDetail';
import Jobs from 'components/Jobs/Jobs';
import Community from 'components/Community/Community';
// import Feedback from '@univ-ai/feedback';
import JobDetails from './components/Jobs/JobDetails';
import MyJobApplication from 'components/Jobs/MyJobApplication';
import UpcomingJobs from 'components/Jobs/UpcomingJobs';

ReactModal.setAppElement('body');
toast.configure({
  // autoClose: 8000,
});

function NarrowRoutes() {
  const [state] = useStore();
  const { isAuthenticated } = state;
  return (
    <div>
      {/* <Feedback FeedbackAPI={FEEDBACK_API} showEmail={!isAuthenticated}></Feedback> */}

      <Switch>
        <Route exact path={ALL_MEETUPS_PATH}>
          <AllEvents />
        </Route>
        <Route exact path={`${MEETUP_DETAIL_PATH}/:meetupName`}>
          <MeetupDetail />
        </Route>
        <Route exact path={`${COMMUNITY_DETAIL_PATH}/:meetupName`}>
          <MeetupDetail />
        </Route>
        {/* <Route exact path={MY_SCHEDULE_PATH}>
          <UserSchedule />
        </Route> */}
        <Route exact path={MY_STUFF_PATH}>
          <Hub />
        </Route>
        <Route exact path={`${MY_PROFILE_PATH}/:tab?`}>
          <Profile />
        </Route>
        <Route exact path={`/hub`}>
          <Hub />
        </Route>
        <Route exact path={`${MY_USERDATA_PATH}/:tab`}>
          <UserData />
        </Route>
        <Route exact path={[`${MY_SUBSCRIPTION_PATH}`, `${MY_SUBSCRIPTION_PATH}/:adurl`]}>
          <Subscription />
        </Route>
        <Route exact path={DASHBOARD_PATH}>
          <Dashboard />
        </Route>
        <Route exact path={COMMUNITY_PATH}>
          <Community />
        </Route>
        <Route exact path={PAYMENT_SUCCESS_PATH}>
          <PaymentSuccess />
        </Route>
        {/* <Route exact path={JOBS_PATH}>
          <UpcomingJobs />
        </Route> */}
        <Route exact path={JOBS_PATH}>
          <Jobs />
        </Route>
        <Route exact path={`${JOBS_PATH}/:jobId`}>
          <JobDetails />
        </Route>
        <Route exact path={JOB_APPLICATION_PATH}>
          <MyJobApplication />
        </Route>
        <Route exact path={REFERRAL_PATH}>
          <Referral />
        </Route>
        {isAuthenticated ? (
          <Route exact path="/">
            <Redirect to={DASHBOARD_PATH} />
          </Route>
        ) : (
          <Route exact path="/">
            <Redirect to={TECHX_PATH} />
          </Route>
        )}
        <Route exact path="*">
          <ErrorComponent preset="nothing" />
        </Route>
      </Switch>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Preloader>
        <ScrollToTop />
        <NavigationBar>
          <NarrowRoutes />
        </NavigationBar>
      </Preloader>
    </Router>
  );
}

export default App;
