import React from 'react';

type InfoLineProps = {
  info: string | string[] | null;
  Icon?: any;
  success?: boolean;
  color?: string;
  className?: string;
};

export function InfoLine(props: InfoLineProps) {
  const { className, info, success = false, Icon } = props;
  const color = props.color ? props.color : success ? 'green-500' : 'red-500';

  return (
    <div className={`text-${color} uppercase font-medium text-xs mt-2 ${className ? ` ${className}` : ''}`}>
      {info && Icon && <Icon className={`inline text-${color} mr-1`} />}
      {Array.isArray(info) ? info.map((line:string,idx:number) => <div key={`${line}-${idx}`}>{line}</div>) : info}
    </div>
  );
}
