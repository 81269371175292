import React,{useState} from "react";
import { REFERRAL_SUCCESS_IMG } from "constants/app";
import { REFERRAL_FAILURE_IMG } from '../../constants/app';
import Spinner from "fragments/Spinner";

type TProps = {
    type:"success" | "error",
    message:string,
    onClose: () => void,
};

const ApplyModal =({type,message,onClose}:TProps)=>{
    const [isImgLoaded,setIsImageLoaded] = useState(false);
    const getRewardHeading = () => type === "success" ? "Congratulations!" : "Oops :(";
    const getRewardImage = () => type === "success" ? REFERRAL_SUCCESS_IMG : REFERRAL_FAILURE_IMG;

    return (
    <React.Fragment>
        <div className="flex flex-col">
            <h1 className="font-switzer text-text-grey font-semibold 3xl:text-2xl xsm:text-2xl text-lg">{getRewardHeading()}</h1>
            {!isImgLoaded && (<div className="h-28 relative">
                <Spinner space show />
            </div>)
            }
            <img 
                src={getRewardImage()} 
                alt={type} 
                className="my-4"
                onLoad={()=> setIsImageLoaded(true)}
            />
            <h3 className="font-switzer text-text-grey font-semibold 3xl:text-xl xsm:text-lg text-base">{message}</h3>
        </div>
        <button onClick={onClose} className="my-4 bg-text-blue">Okay</button>
    </React.Fragment>
    );
};

export default ApplyModal;