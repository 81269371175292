import React, { useMemo } from 'react';
import moment from 'moment';
import { TUserTransaction } from 'types';

const TransactionBillRow = ({ data }: { data: TUserTransaction }) => {
    const { id, plan_name, event, timestamp, amount, currency } = data;

    const CURRENCY_SYMBOL = useMemo(() => {
      if (currency === undefined) return '';
      return currency === 'INR' ? '₹' : '$';
    }, [currency]);

    const paymentStatus = (name:string) => {
      if(name.toLowerCase()=== "captured") return ( 
      <span className="font-switzer text-base text-green-600 uppercase">
        PAID
      </span>);
      
      else if(name.toLowerCase()=== "authorized") return ( 
      <span className="font-switzer text-xs xsm:text-base text-text-blue uppercase">
        PROCESSING
      </span>);
      
      return (<span className="font-switzer text-xs xsm:text-base text-red-400 uppercase">{name}</span>);
    };

    return (
      <div className="grid grid-cols-4 pl-4 py-2 border-b border-indigo-300">
        <div className="col-span-2 gap-y-4 ">
          <h3 className="text-lg xsm:text-2xl font-normal font-switzer">
            {moment?.unix(timestamp / 1000)?.format('MMM DD, YYYY')}
          </h3>
        </div>
        <div className="col-span-2 justify-self-end">
          <h3 className="text-lg xsm:text-2xl font-normal font-switzer pr-2">
            {CURRENCY_SYMBOL} {amount}
          </h3>
        </div>
        <div className="">
          {paymentStatus(event?.split('.')[1])}
        </div>
        <div className="col-span-2 justify-center">
          <span className="text-text-grey font-switzer flex flex-row text-sm sm:text-base items-center justify-center sm:justify-start">
            <span className='hidden xl:block text-xs xsm:text-base font-switzer'>Transaction ID-</span>
            {id}
          </span>
        </div>
        <div className='col-span-1 justify-self-end'>
          <span className="font-switzer text-base text-text-blue uppercase pr-2">{plan_name}</span>
        </div>
      </div>
    );
  };

export default TransactionBillRow;