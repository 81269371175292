import React,{useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavMenu as NAVLINKS, TMenu } from '../NavMenus';
import { isLinkActive } from '../NavigationBar';
import { FaCaretDown,FaCaretUp } from 'react-icons/fa';
import useStore from 'globalstate';

type TSubMenusProps = {
  menu:TMenu[],
  label:string,
  icon:JSX.Element,
  isSubmenu:boolean,
}
export default function Sidebar() {
  const location = useLocation();
  const [State] = useStore();
  const { isAuthenticated } = State;
  
  const [showSubmenu,setShowSubmenu] = useState<string>(isAuthenticated ? "":"techx");

  const SubMenus = ({menu,label,icon,isSubmenu=false}:TSubMenusProps)=> {
    const toggleSubmenu = () => setShowSubmenu((prev) => prev === label ? "" : label);
    return (
    <div className=''>
      <div
          className={`text-text-grey font-normal hover:text-text-blue flex flex-row items-center justify-between px-4 sm:px-6 lg:px-8 py-2 cursor-pointer`}
          aria-current="page"
          onClick={toggleSubmenu}
        >
          <div className="flex flex-row items-center">
              <div className="text-xs 3xl:text-base">{icon}</div>
              <div className="text-left text-xs 3xl:text-base tracking-widest pl-4 font-switzer">{label.toUpperCase()}</div>
          </div>

          {showSubmenu === label ? 
          <FaCaretUp className=''/>:
          <FaCaretDown className=''/>
           }
      </div>
      {(showSubmenu ===label) && menu.map((menu:TMenu,index:number) => renderMenus(menu,index,isSubmenu))}
      </div>);
  };

  const renderMenus = (menu: TMenu | null, index: number, isSubmenu:boolean ) => {
    
    if (!menu) return <hr key={`Sidebar-break-${index}`} />;

    const { link, label, icon, hyperlink,submenu } = menu;

    const isActive = location.pathname === link || isLinkActive(location.pathname, label, link);
    
    if (!isAuthenticated && menu.requiresAuth) return;

    if(submenu)
       return (
        <SubMenus 
          key={`Sidebar-submenu-item-${label}`} 
          menu={submenu} 
          label={label} 
          icon={icon} 
          isSubmenu={true}
          />);
   
    return ( !hyperlink && link) ? (
      <Link
        to={link}
        key={`Sidebar-menuitem-${label}`}
        className={`${
          isActive
            ? 'bg-text-blue bg-opacity-10 text-text-blue link-white-hover border-text-blue border-r-8 font-medium'
            : ' text-text-grey font-normal hover:text-text-blue'
        } flex flex-row items-center justify-start py-2 ${isSubmenu ? "px-12 sm:px-14 lg:px-16":"px-4 sm:px-6 lg:px-8"}`}
        aria-current="page"
      >
        <div className="text-xs 3xl:text-base">{icon}</div>
        <div className="text-left text-xs 3xl:text-base tracking-widest pl-4 font-switzer">{label.toUpperCase()}</div>
      </Link>
    ) :
    (
      <a
        href={link?.includes('||') ? (isAuthenticated ? link?.split('||')[0] : link?.split('||')[1]) : link}
        key={`Sidebar-menuitem-${label}`}
        className={`${
          isActive
            ? 'bg-text-blue bg-opacity-10 text-text-blue link-white-hover border-text-blue border-r-8 font-medium'
            : ' text-text-grey font-normal hover:text-text-blue'
        } flex flex-row items-center justify-start py-2 ${isSubmenu ? "px-12 sm:px-14 lg:px-16":"px-4 sm:px-6 lg:px-8"}`}
        aria-current="page"
      >
        <div className="text-xs 3xl:text-base">{icon}</div>
        <div className="text-left text-xs 3xl:text-base tracking-widest pl-4 font-switzer">{label.toUpperCase()}</div>
      </a>
    );
  };

  // if (!linksInSidebar.includes(location.pathname)) return null;

  return (
    <React.Fragment>
      <aside className="">
        <div className="bg-navgreybg md:w-60 3xl:w-80 md:block hidden h-full md:flex flex-col justify-between overflow-y-auto">
          {/* <SidebarHeader>
              <div className="w-full my-8 flex justify-center">
                <img className="h-5" src="/logo.svg" alt="Univ.AI" />
              </div>
            </SidebarHeader> */}
          <div>
            <div>{NAVLINKS.map((menu:TMenu,index:number) => renderMenus(menu,index,false))}</div>
          </div>

          <div className="text-left w-full px-4 sm:px-6 lg:px-8">
            <h2 className="text-blue-400 text-base font-normal tracking-wider">UNIV.AI</h2>
          </div>
        </div>
      </aside>
    </React.Fragment>
  );
}
