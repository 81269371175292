import React,{useEffect,useState} from 'react';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import useStore from 'globalstate';
import CurrentPlan from './CurrentPlan';
import BillingHistory from './BillingHistory/BillingHistory';
import PlanSimple from './PlanSimple';

export default function Subscription() {
  const [State] = useStore();
  const { currentPlan , isAuthenticated} = State;
  const [,setIsRefresh] = useState<boolean>(false);

  useEffect(()=>{
    setIsRefresh(prev=> !prev);
  },[currentPlan]);

  return (
    <div>
      <h3 className="font-switzer text-text-blue font-normal text-sm h-8 3xl:text-base 3xl:h-10 flex items-center px-4">
        Subscription
      </h3>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <ReactTooltip />
      <div className="px-4">
        {(isAuthenticated && currentPlan) && <React.Fragment>
           <div className="grid grid-cols-1 gap-y-8 lg:gap-y-0 lg:grid-cols-2 border-solid lg:border-t border-b border-indigo-300">
               <CurrentPlan plan={currentPlan} />
               <BillingHistory transaction={currentPlan?.transactions} invoice={currentPlan?.invoice}/>
            </div>
          </React.Fragment>
        }
        <div className="col-span-2 w-ful">
          <PlanSimple/>
        </div>
      </div>
    </div>
  );
}
