import * as Sentry from '@sentry/browser';

import { TRate } from 'types';

const currencyFormatMap: { [key: string]: string } = { INR: 'en-IN', USD: 'en-US' };

export function formatCurrency(rate: string | number, currencyCodeRaw: string) {
  if (!rate || !currencyCodeRaw) {
    Sentry.captureException(`Formatting currency failed ${rate} ${currencyCodeRaw}`);
    return '';
  }
  if (typeof rate === 'string') return rate;

  const userCurrency = currencyCodeRaw.trim().toUpperCase();
  const formatCurrency = new Intl.NumberFormat(currencyFormatMap[userCurrency], {
    style: 'currency',
    currency: userCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  // rate / 100 for USD and INR
  return formatCurrency.format(rate / 100);
}

export function getFormattedRate(rate: TRate, countryCode: string) {
  if (typeof rate === 'string') return rate;

  const countryCurrencyMap: { [key: string]: string } = { IN: 'INR', USA: 'USD' };
  const userCurrency = countryCurrencyMap[countryCode] || 'USD';
  const userRate: number = rate[userCurrency];
  return formatCurrency(userRate, userCurrency);
}

export const getCurrencySymbol=(currency?:"INR" | "USD" | string)=> {
  if(!currency) return "";
  if(currency ==="INR") 
    return "₹"; 
  return "$";
};