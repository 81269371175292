import React from 'react';
import Switch from 'react-switch';
import { Controller } from 'react-hook-form';

type Props = {
  Icon?: any;
  label: string;
  defaultValue?: any;
  control: any;
  info?: string;
  name: string;
  error?: string;
  labels: string[];
};

export function FormSwitch(props: Props) {
  const { defaultValue, control, name, labels } = props;

  return (
    <div className="flex items-center text-sm">
      {labels && labels[0]}
      <div className="mx-2">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => <Switch {...field} checked={field.value || false} />}
        />
      </div>
      {labels && labels[1]}
    </div>
  );
}
