import { Router } from 'react-router';

export function cleanURL(url?: string): string {
  if (!url) return '';
  if (url.startsWith('/')) url = url.substr(1);
  if (url.endsWith('/')) url = url.slice(0, -1);
  return url;
}

export function getDirFromPath(path: string) {
  if (path.endsWith('/')) path = path.slice(0, -1);
  return path.substring(0, path.lastIndexOf('/') + 1);
}
export function openURL(url: string, newTab = true) {
  if (newTab) window.open(url, '_blank')?.focus();
  else window.location.href = url;
}
