import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { TFieldRequired } from 'types';
import ReactTooltip from 'react-tooltip';

type IProps = {
  required?: TFieldRequired;
  render?: any;
  children?: React.ReactNode;
  Icon?: any;
  label: string;
  info?: string;
  fieldProps: any;
  errors?: any;
  infoText?: string;
  FormComponent?: any;
  className?: string;
};

type ErrorProps = {
  error?: string;
  Icon?: any;
};

function ErrorLine(props: ErrorProps) {
  const { error, Icon } = props;

  return (
    <div className="text-red-500 font-medium text-sm flex flex-row gap-2 md:gap-3">
      {error && Icon && (
        <span>
          <Icon className="text-red-500 min-w-[14px] mt-1" />
        </span>
      )}
      {error}
    </div>
  );
}

export function FormComponentHolder(props: IProps) {
  const { required, errors, info, label, children, className, Icon, FormComponent, fieldProps, infoText } = props;
  const errorName: string = fieldProps?.name?.split('-')?.join('__');
  const error: string[] | undefined = errors ? errors[errorName] : errors;

  return (
    <div className={`mt-2 w-full py-1 rounded-sm bg-white ${className}`}>
      <ReactTooltip className="max-w-5xl" />
      <h5 className={`flex items-center gap-2 text-gray-700 tracking-wide`}>
        {required ? <span className="text-lg text-red-500 mr-1">*</span> : ''}
        {Icon && <Icon size="1.35rem" />}
        {label}
        {infoText && <FaInfoCircle className="text-gray-400" data-tip={infoText} />}
      </h5>
      {info && <div className="text-sm text-gray-400 -mt-1">{info}</div>}
      <div className="mt-1">
        {FormComponent ? (
          <FormComponent {...props} error={error ? error?.length > 0 : false} />
        ) : (
          <div className="flex items-center justify-between mt-4">{children}</div>
        )}
      </div>
      {error &&
        error?.length > 0 &&
        error.map((errorMsg: string, idx: number) => (
          <ErrorLine key={`"errormsg"-${fieldProps?.name}-${idx}`} error={errorMsg} Icon={FaInfoCircle} />
        ))}
    </div>
  );
}
