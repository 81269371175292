import React,{useState,useEffect} from 'react';
import { TCourse,TProgram,TMeetup } from 'types';
import { DASHBOARD_PATH, GET_COURSE_URL, GET_MEETUP_URL, GET_PROGRAM_URL,} from 'constants/app';
import {
  Link,
    useLocation,
    useParams
  } from "react-router-dom";
import fetch from 'utils/fetch';
import ErrorComponent from 'components/Error';
import UpdatedCard from 'fragments/UpdatedCard/UpdatedCard';
import UpdatedProgramCard from 'fragments/UpdatedCard/UpdatedProgramCard';
import Spinner from 'fragments/Spinner';
import ErrorMsg from 'components/Error';
import useStore from 'globalstate';

export const toCaptialize = (str:string)=>{
  return str.slice(0,1)?.toUpperCase()+str?.slice(1);
};

const UserData = () => {
    const [loading, setloading] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    const [showData, setShowData] = useState<TMeetup[] | TProgram[]| TCourse[] >([]);
    const [State] = useStore();
    const { isAuthenticated } = State;

    const {pathname} = useLocation<{pathname:string}>();
    const MainTab = toCaptialize(pathname.split("/")[1]);
    const SubTab = toCaptialize(pathname.split("/")[2]);
    const {tab} = useParams<{tab:string}>();

    const getMeetups = async () => {
        setloading(true);
        try {
          const resp = await fetch(`${GET_MEETUP_URL}?onlyActive=true&onlyUpcoming=false&onlyRegistered=false`, {
            credentials: 'header',
          });
          const respJSON = await resp.json();
          if (!respJSON.success) throw new Error(respJSON.error);
          const meetups = respJSON.data as unknown as TMeetup[];
    
          // TechXmeetups
          const techxMeetups:TMeetup[] = meetups.filter((val:TMeetup)=> val.meta?.domain === "techx");

          const techxUpcomingRegMeetups = techxMeetups.filter((val: TMeetup) => {
            return (!!val.registered_on || !val.meta.require_registration) && 
              ((new Date(val.scheduled_datetime)) > (new Date()));
          });

          const techXRegPastMeetups = techxMeetups.filter((val: TMeetup) => {
            return (!!val.registered_on || !val.meta.require_registration) && new Date(val.scheduled_datetime) < new Date();
          });

          // Community Meetups
          const communityMeetups:TMeetup[] = meetups.filter((val:TMeetup)=> val.meta?.domain === "community");
          
          const communityUpcomingRegMeetups = communityMeetups.filter((val: TMeetup) => {
            return (!!val.registered_on || !val.meta.require_registration) && 
              ((new Date(val.scheduled_datetime)) > (new Date()));
          });

          const communityRegPastMeetups = communityMeetups.filter((val: TMeetup) => {
            return (!!val.registered_on || !val.meta.require_registration) && new Date(val.scheduled_datetime) < new Date();
          });
          

          if (tab==="mytechxprograms"){
            setShowData(techxMeetups.filter((meetup:TMeetup )=> (meetup.type?.toLowerCase() === "workshop") && (!!meetup.registered_on || !meetup.meta.require_registration) ));
          }
          
          else if (tab==="mytechxcourses"){
            setShowData(techxMeetups.filter((meetup:TMeetup) => (meetup.type?.toLowerCase() === "event") && (!!meetup.registered_on || !meetup.meta.require_registration) ));
          }

          else if(tab === "myevents") {
            setShowData(techxUpcomingRegMeetups);
          }

          else if (tab==="mycommunityprograms"){
            setShowData(communityMeetups.filter((meetup:TMeetup )=> (meetup.type?.toLowerCase() === "workshop") && (!!meetup.registered_on || !meetup.meta.require_registration) ));
          }
          
          else if (tab==="mycommunitycourses"){
            setShowData(communityMeetups.filter((meetup:TMeetup) => (meetup.type?.toLowerCase() === "event") && (!!meetup.registered_on || !meetup.meta.require_registration) ));
          }

          else if(tab === "mycommunity") {
            setShowData(communityUpcomingRegMeetups);
          }

          else if(tab === "myupcoming"){
          const nonRegMeetups = meetups.filter((val: TMeetup) => {
            return !val.registered_on && 
              ((new Date(val.scheduled_datetime)) > (new Date()));
          });
          setShowData(nonRegMeetups);
          }
          else if(tab === "mypast"){
          setShowData(techXRegPastMeetups);
          }
          else if(tab === "mycommunitypast"){
          setShowData(communityRegPastMeetups);
          }

          setloading(false);
        } catch (e: any) {
          setError(e.message);
          setloading(false);
        }
      };

      const getPrograms = async () => {
        setloading(true);
        try {
          const resp = await fetch(`${GET_PROGRAM_URL}?`, {
            credentials: 'header',
          });
          const respJSON = await resp.json();
          if (!respJSON.success) throw new Error(respJSON.error);
          const programs = respJSON.data as unknown as TProgram[];
          setShowData(programs);
    
        } catch (e: any) {
          setError(e.message);      
        } finally {
          setloading(false);
        }
      };

      const getCourses = async () => {
        setloading(true);
        try {
          const resp = await fetch(`${GET_COURSE_URL}`, {
            credentials: 'header',
          });
          const respJSON = await resp.json();
          if (!respJSON.courses) throw new Error("Error");
          const courses = respJSON.courses as unknown as TCourse[];
          setShowData(courses);
    
        } catch (e: any) {
          setError(e.message);
        } finally{
          setloading(false);
        }
      };

    useEffect(()=>{
        if(tab === "myunivprograms")
            getPrograms();
        else if (tab === "myunivcourses")
            getCourses();
        else
            getMeetups();       
        
      },[]);

    return (
        <>
        <h3 className='font-switzer text-text-blue font-normal text-sm h-8 3xl:text-base 3xl:h-10 flex items-center'>
          <Link to={DASHBOARD_PATH} className="font-switzer cursor-pointer">
            {MainTab}
          </Link>
         / {SubTab}</h3>
        {(()=>{
            if (loading) return <Spinner space show />;
            if (!isAuthenticated) return <ErrorMsg preset="login" text={error} />;
            if (error) return <ErrorMsg preset="broken" text={error} />;
        return (
        <>
        <div className="grid xl:grid-cols-16 lg:grid-cols-12 grid-cols-8 3xl:gap-8 gap-4">
        {showData && showData?.length > 0 ? (
          <>
            {showData
              ?.map((v: any, i: number) => {
                
                if (tab === "myunivprograms" || tab === "myunivcourses")
                    return (
                        <UpdatedProgramCard
                            data={v}
                            type={tab==="myunivprograms"? "Program":"Course"}
                            key={`${tab}-meetup-` + i}
                            onStateChange={() => {
                            getMeetups();
                        }}
                        />
                    );
                else
                    return (
                        <UpdatedCard
                            data={v}
                            key={`meetup-${tab}-` + i}
                            onStateChange={() => {
                            getMeetups();
                            }}
                        />
                    );
              })}
            </>
        ) : (
          <div className="absolute mt-10 w-full object-bottom">
            <ErrorComponent preset="nothing"></ErrorComponent>
          </div>
        )}
      </div>
      </>);
    })()}
</>
  );
};

export { UserData };