import { toast } from 'react-toastify';
const TOAST_TIMEOUT = 1500;
export default class toaster {
  // toast.configure({
  //   // autoClose: 8000,
  // });
  static success(msg: string, opt?: { [key: string]: string }) {
    return toast.success(msg, { ...opt, autoClose: TOAST_TIMEOUT, hideProgressBar: true });
  }
  static error(msg: string, opt?: { [key: string]: string }) {
    return toast.error(msg, { ...opt, autoClose: TOAST_TIMEOUT, hideProgressBar: true });
  }
  static warning(msg: string, opt?: { [key: string]: string }) {
    return toast.warning(msg, { ...opt, autoClose: TOAST_TIMEOUT, hideProgressBar: true });
  }
  static info(msg: string, opt?: { [key: string]: string }) {
    return toast.info(msg, { ...opt, autoClose: TOAST_TIMEOUT, hideProgressBar: true });
  }
}
