import { TFormFields } from 'types';

const OnTypeSelected = () => {
  //type selected
};
export function getDynamicFormFields(fields?: string[]) {
  const formFields = [
    {
      defaultValue: '',
      label: 'Phone',
      name: 'phone',
      info: 'Whats you Phone ? ',
      placeholder: 'Please provide your phone number',
      constraints: { required: true, minLength: 10, maxLength: 15 },
      type: 'phone',
      for: 'all',
    },

    {
      defaultValue: '',
      label: 'country',
      name: 'country',
      info: 'Select your country',
      options: [
        { id: 'India', value: 'India', label: 'India' },
        { id: 'USA', value: 'USA', label: 'USA' },
        { id: 'Canada', value: 'Canada', label: 'Canada' },
        { id: 'Other', value: 'Other', label: 'Other' },
      ],
      onChange: OnTypeSelected,
      constraints: { required: true },
      type: 'dropdown',
      for: 'all',
      shownValue: 'text',
    },
    {
      defaultValue: '',
      label: 'city',
      name: 'city',
      info: 'Enter your city',
      constraints: { required: true, minLength: 2, maxLength: 15 },
      type: 'input',
      for: 'all',
    },
    {
      defaultValue: '',
      label: 'How did you get to know about us?',
      name: 'source',
      info: 'Select source',
      options: [
        { id: 'Facebook', text: 'Facebook' },
        { id: 'Twitter', text: 'Twitter' },
        { id: 'LinkedIn', text: 'LinkedIn' },
        { id: 'College', text: 'College' },
      ],
      onChange: OnTypeSelected,
      constraints: { required: true },
      type: 'dropdown',
      for: 'all',
      shownValue: 'text',
    },
    {
      defaultValue: '',
      label: 'Programming Experience',
      name: 'programming_exp',
      info: 'Select source',
      options: [
        { id: 'Beginner', text: 'Beginner' },
        { id: 'Intermediate', text: 'Intermediate' },
        { id: 'Advanced', text: 'Advanced' },
      ],
      onChange: OnTypeSelected,
      constraints: { required: true },
      type: 'dropdown',
      for: 'all',
      shownValue: 'text',
    },
  ];
  const filteredFields = formFields.filter((val: TFormFields) => {
    if (fields) return fields.includes(val.name);
  });
  return filteredFields;
}
