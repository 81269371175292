import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { FaBars, FaTimes, FaBell, FaMoon, FaQuestionCircle, FaSearch, FaSignOutAlt, FaCaretUp, FaCaretDown } from 'react-icons/fa';
import useStore from 'globalstate';
import { NavMenu, TMenu } from './NavMenus';
import Sidebar from './Sidebar/Sidebar';
import { getLogoutURL, getLoginURL, GET_USER_SUBSCRIPTION_PLAN } from 'constants/app';
import ReactTooltip from 'react-tooltip';
import fetch from 'utils/fetch';
import { TUserCurrentPlan } from 'types';
import { toast } from 'react-toastify';

const RenderLink = ({
  label,
  link,
  icon,
  hyperlink,
}: {
  label?: string;
  link: string;
  icon: JSX.Element;
  hyperlink?: boolean;
}) =>
  hyperlink === true ? (
    <a
      href={link}
      className={`text-black hover:text-text-blue text-2xl px-2 ${label === 'Contact Us' && 'hidden md:block'}`}
      data-tip={label}
      aria-current="page"
    >
      {icon}
    </a>
  ) : (
    <Link
      to={link}
      className={`text-black hover:text-text-blue text-2xl px-2 ${label === 'Contact Us' && 'hidden md:block'}`}
      data-tip={label}
      aria-current="page"
    >
      {icon}
    </Link>
  );
const links = [
  // {
  //   label: 'Notifications',
  //   link: '/',
  //   icon: <FaBell></FaBell>,
  // },
  // { label: 'DarkMode', link: "/", icon:<FaMoon/> },
  { label: 'ContactUs', link: 'https://www.univ.ai/contact-us', icon: <FaQuestionCircle />, hyperlink: true },
];

type TSubMenusProps = {
  menu:TMenu[],
  label:string,
  icon:JSX.Element,
  isSubmenu:boolean,
}

export const isLinkActive = (tabLink: string, label: string, link?:string) => {
  if( tabLink?.split('/')?.includes(label?.toLowerCase())) return true;
  else if (tabLink?.split('/')[1] === (link?.split('/')[1])) return true;
  return false;
};

export default function NavigationBar({ children }: { children?: React.ReactNode }) {
  const [State,Actions] = useStore();
  const location = useLocation();
  const { isAuthenticated } = State;
  const currentPath = location.pathname;

  const [showUserMenu, setshowUserMenu] = useState<boolean>(false);
  const [hideNavBar, sethideNavBar] = useState<boolean>(false);

  
  const [showSubmenu,setShowSubmenu] = useState<string>(isAuthenticated ? "":"techx");

  const SubMenus = ({menu,label,isSubmenu=false}:TSubMenusProps)=> {
    const toggleSubmenu = () => setShowSubmenu((prev) => prev === label ? "" : label);
    return (
    <div className=''>
      <div
          className={`bg-white text-gray-600 font-normal hover:text-text-blue flex flex-row items-center justify-between px-4 py-2 mb-3 cursor-pointer`}
          aria-current="page"
          onClick={toggleSubmenu}
        >
          <div className="text-left text-base tracking-widest font-switzer">{label.toUpperCase()}
          </div>

          {showSubmenu === label ? 
          <FaCaretUp className=''/>:
          <FaCaretDown className=''/>
           }
      </div>
      {(showSubmenu ===label) && menu.map((menu:TMenu,index:number) => (
            <RenderMobileLink
                menu={menu}
                index={index}
                setshowUserMenu={setshowUserMenu}
                isSubmenu={isSubmenu}
                key={`navbar-hamburger-submenus-items-${index}`}
            />))}
      </div>);
  };
  
  const RenderMobileLink = ({menu,index,setshowUserMenu,isSubmenu}:{menu: TMenu | null, index: number,setshowUserMenu:Function,isSubmenu:boolean}) => {
    if (!menu) return <hr key={`Sidebar-break-${index}`} />;
  
      const { link, label, icon, hyperlink, submenu } = menu;
      const isActive = location.pathname === link || isLinkActive(location.pathname, label, link);
      if (!isAuthenticated && menu.requiresAuth) return <span></span>;
      
      if(submenu)
      return (
       <SubMenus 
         key={`Sidebar-submenu-item-${label}`} 
         menu={submenu} 
         label={label} 
         icon={icon} 
         isSubmenu={true}
         />);

     return (!hyperlink && link) ? (
      <Link
        to={link ? link :"/"}
        className={`text-left block font-space rounded-sm mb-3 group-hover:text-text-blue py-2 
        ${isSubmenu ? "px-12":"px-4"}
        ${
            isActive
            ? 'bg-text-blue bg-opacity-10 text-text-blue link-white-hover border-text-blue border-r-8 font-medium'
            : `bg-white text-gray-600 font-normal`
        }`}
        aria-current="page"
        onClick={() => setshowUserMenu(false)}
      >
        <div className="text-left text-base tracking-widest font-switzer">{label.toUpperCase()}</div>
      </Link>
      ) : 
     (
      <a
        href={link?.includes('||') ? (isAuthenticated ? link?.split('||')[0] : link?.split('||')[1]) : link}
        className={`text-left block font-space rounded-sm mb-3 group-hover:text-text-blue py-2 
        ${isSubmenu ? "px-12":"px-4"}
        ${
            isActive
            ? 'bg-text-blue bg-opacity-10 text-text-blue link-white-hover border-text-blue border-r-8 font-medium'
            : `bg-white text-gray-600 font-normal`
        }`}
        aria-current="page"
      >
        <div className="text-left text-base tracking-widest font-switzer">{label.toUpperCase()}</div>
      </a>
    );
  };

  const getUserSubscription = async () => {
    try {
      const resp = await fetch(GET_USER_SUBSCRIPTION_PLAN, {
        credentials: 'header',
      });

      const respJSON = await resp.json();

      if (!respJSON.success) throw new Error(respJSON.error);
      const data: TUserCurrentPlan = await respJSON.data;
      Actions.setState({ currentPlan: data });
      if (data) localStorage.setItem('cp', JSON.stringify(data));
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  useEffect(()=>{
    if(isAuthenticated) 
      getUserSubscription();
  
  },[isAuthenticated]);

  useEffect(() => {
    sethideNavBar(State.hideNavBar);
  }, [State.hideNavBar]);

  return (
    <>
      { hideNavBar ? <React.Fragment> </React.Fragment> :
      (
        <nav className="">
        <ReactTooltip/>
        <div className="mx-auto px-4 sm:px-6 lg:px-8 bg-navgreybg sticky top-0">
          <div className="mx-auto relative flex items-center justify-between h-6vh">
            <div className="flex items-center  sm:w-4/6">
              {/* Title */}
              <Link to="/" className="flex-shrink-0 flex items-center">
                <img
                  className="block md:hidden"
                  src="/logoSmallBeta.png"
                  alt="Univ.AI"
                  onClick={() => setshowUserMenu(false)}
                />
                <img className="hidden md:block " src="/logoLargeBeta.png" alt="Univ.AI" />
              </Link>
              {/* Search Bar */}
              {/* <div className='hidden sm:flex w-full max-w-3xl items-center flex-row ml-20 pr-3 bg-white rounded'>
              <input className='hidden sm:block bg-white text-base text-text-grey focus:outline-none focus:shadow-none focus:ring-transparent' type="text" placeholder="Search" />
              <FaSearch className='text-2xl text-gray-300' />
            </div> */}
            </div>
            <div className='flex flex-row'>
            {isAuthenticated && (
              <div className="w-max flex justify-end items-center">
                {/* Icons Links */}
                {links.map(link => (
                  <RenderLink {...link} key={`Navbar-menuitem-${link?.label}`} />
                ))}
                {isAuthenticated ? (
                  <div
                    className={`text-black hover:text-text-blue text-2xl px-2 cursor-pointer block`}
                    aria-current="page"
                    data-tip={"Logout"}
                    onClick={() => {
                      window.location.href =  getLogoutURL( window.location.href);
                    }}
                  >
                    <FaSignOutAlt />
                  </div>
                ) : (
                  ''
                )}
              </div>
             )}

            {!isAuthenticated && (
              <div>
                <button
                  className="button primary bg-text-blue font-semibold py-2 px-6 mx-4 md:mx-0"
                  onClick={() => {
                    window.location.href = getLoginURL(window.location.href);
                  }}
                >
                  Login
                </button>
              </div>
            )}

              {/* HamburgerButton */}
              <button
                type="button"
                className="w-max md:hidden inline-flex items-center justify-center p-2 rounded-md dark bg-transparent"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => setshowUserMenu(!showUserMenu)}
              >
                {!showUserMenu && <FaBars className="text-black block h-6 w-6" aria-hidden="true" />}
                {showUserMenu && <FaTimes className="text-red-400 block h-6 w-6" aria-hidden="true" />}
              </button>
            </div>
          </div> 
        </div>
        <div
          className={`${
            showUserMenu ? 'block' : 'hidden'
          } md:hidden bg-navgreybg w-full h-94vh fixed z-1010 bottom-0 px-2 pt-2 pb-3 flex flex-col justify-between overflow-y-auto`}
          id="mobile-menu"
        >
          <div>
            {NavMenu.map((link:TMenu,idx:number) => (
              <RenderMobileLink
                menu={link}
                index={idx}
                setshowUserMenu={setshowUserMenu}
                isSubmenu={false}
                key={`navbar-hamburger-menuitem-${idx}`}
              />
            ))}
          </div>
          <div className="flex flex-row items-center justify-between">
            <h2 className="text-center text-text-blue text-base tracking-wider font-switzer">UNIV.AI</h2>
            <a
              className="text-center text-gray-600 text-base tracking-wider font-switzer"
              href="https://www.univ.ai/contact-us"
            >
              Contact Us
            </a>
          </div>
        </div>
      </nav>
      )}
      <div className="flex flex-row h-94vh w-screen mx-auto">
        {(() => {
          // if(!isAuthenticated) return (<ErrorMsg embed={false} preset="login"  />);
          return (
            <>
              {hideNavBar ? <React.Fragment></React.Fragment> : <Sidebar />}
              <div
                className={`w-2/3 flex-grow ${
                  isLinkActive(currentPath, 'profile',"/profile") ? 'bg-white' : 'bg-greybg'
                } px-4 pb-4 overflow-y-auto`}
              >
                {children}
              </div>
            </>
          );
        })()}
      </div>
    </>
  );
}
