import React from 'react';
import { HiLocationMarker, HiOfficeBuilding } from 'react-icons/hi';
import { FaUserCircle, FaRegCalendarAlt, FaTimesCircle, FaTimes, FaRupeeSign } from 'react-icons/fa';
import { AiFillRightCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import { TJobs } from 'types';
import { useHistory } from 'react-router-dom';
import { JOBS_PATH } from 'constants/app';

type TProps = {
  data: TJobs;
  onStateChange?: Function;
};

const JobChips = ({type}:{type:string}) => (
  <div className='bg-JobBluebg 3xl:text-xl text-xsm px-1 py-0.5  rounded-sm'>
    <span className='text-text-dark'>{type}</span>
  </div>);

const JobCard = (props: TProps) => {
  const {
    id,
    title,
    position,
    description,
    category,
    max_applicants,
    max_positions,
    salary_start,
    salary_end,
    location,
    nature_of_work,
    min_experience_req,
    tags,
    skills,
    company_name,
    company_description,
    company_logo,
  } = props.data;  

      
  const history = useHistory();
  const goToJobDeatils = () => history.push(`${JOBS_PATH}/${id}`) ;


  return (
    <div
      className="xl:col-span-12 col-span-8"
    >
      <ReactTooltip />
      <div
        className={`xl:min-h-120px 3xl:min-h-180px flex flex-col bg-white rounded overflow-hidden font-switzer shadow-lg relative px-4`}
        >
         <div className='flex flex-row gap-4 w-full'>
          {/* Company Logo */}
          {company_logo ? <img src={company_logo} alt={company_name} className='3xl:w-36 3xl:min-w-36 3xl:h-36 w-88px min-w-88px h-88px object-contain my-2 xsm:my-4' />:
          <div className='3xl:w-36 3xl:min-w-36 3xl:h-36 w-88px min-w-88px h-88px bg-JobBluebg text-4xl rounded-full flex justify-center items-center text-text-grey my-2 xsm:my-4' >
              {company_name?.split("")[0]}
          </div>}
          <div className='w-full'>
            <div className='flex flex-row items-center justify-between mt-2'> 
              {/* Position Title */}
              <h2 className='3xl:text-3xl text-base text-text-dark'>{title}</h2>
              {/* Source*/}
              <h2 className='3xl:text-xl text-sm text-text-blue'>Univ</h2>
            </div>
            {/* Job Basic Info */}
            <div className="flex xsm:flex-row xsm:items-center xsm:justify-between xsm:gap-0 flex-col gap-0.5  sm:justify-start my-1">
              <span className='flex items-center sm:w-1/4'>
                <FaUserCircle className='mr-1 text-text-grey-light' />
                <h3 className='3xl:text-xl text-sm leading-tight text-text-grey-light'>{position}</h3>
              </span>
              <span className='flex items-center sm:w-1/3'>
                <HiOfficeBuilding className='mr-1 text-text-grey-light' />
                <h3 className='3xl:text-xl text-sm leading-tight text-text-grey-light'>{company_name}</h3>
              </span>
              <span className='flex items-center sm:w-1/5'>
                <HiLocationMarker  className='mr-1 text-text-grey-light'/>
                <h3 className='3xl:text-xl text-sm leading-tight text-text-grey-light' >{location}</h3>
              </span>
              <span className='sm:flex hidden items-center '>
                <FaRupeeSign  className='mr-1 text-sm text-text-grey-light'/>
                <h3 className='3xl:text-xl text-sm leading-tight text-text-grey-light' >{`${salary_start}K - ${salary_end}K`}</h3>
              </span>
            </div>
            {/* Job Description */}
            <span className='hidden xsm:block  my-2 xl:my-0'>
              <p className='3xl:text-lg text-xs break-words line-clamp-4 xl:line-clamp-2 text-text-grey mb-0' >{description}</p>
            </span>
            
            <div className='xl:flex xl:flex-row xl:items-start xl:justify-between my-1 hidden'>
                {/* Badges */}
                <div className='flex flex-row flex-wrap gap-x-4 gap-y-1'>
                  {tags && tags.map((type:string,idx:number) => <JobChips type={type} key={`JobChip-${id}-${type}-tags-${idx}`}/>)}
                  {skills && skills.map((type:string,idx:number) => <JobChips type={type} key={`JobChip-${id}-${type}-skill-${idx}`}/>)}
                </div>

                {/* Learn More */}
                <div className="text-text-blue 3xl:min-w-36 min-w-100px flex flex-row items-center cursor-pointer text-space" onClick = {goToJobDeatils}>
                  <span className="mr-2 text-sm 3xl:text-xl font-semibold leading-none">Learn More</span>
                  <AiFillRightCircle className="text-base 3xl:text-xl" />
                </div>

            </div>
          </div>
        </div>
        {/* Job Description */}
        <span className='xsm:hidden mb-2'>
          <p className='text-xs break-words line-clamp-4 text-text-grey mb-0' >{description}</p>
        </span>
        <div className='xl:hidden flex flex-row items-start justify-between my-1'>
                {/* Badges */}
                <div className='flex flex-row flex-wrap gap-x-4 gap-y-1'>
                  {tags && tags.map((type:string,idx:number) => <JobChips type={type} key={`JobChip-${id}-${type}-tags-${idx}`}/>)}
                  {skills && skills.map((type:string,idx:number) => <JobChips type={type} key={`JobChip-${id}-${type}-skill-${idx}`}/>)}
                </div>
                {/* Learn More */}
                <div className="text-text-blue 3xl:min-w-36 min-w-100px flex flex-row items-center cursor-pointer text-space" onClick = {goToJobDeatils}>
                  <span className="mr-2 text-sm 3xl:text-xl font-semibold leading-none">Learn More</span>
                  <AiFillRightCircle className="text-base 3xl:text-xl" />
                </div>
        </div>
      </div>
    </div>
  );
};

export default JobCard;