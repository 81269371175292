import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';

import {
  FormComponentHolder,
  FormHolder,
  FormInput,
  FormSelect,
  FormTextArea,
  FormMultiSearchSelect,
} from 'components/Form';
import { TProcessedFormField, TProcessedFormFieldList } from 'types';

interface IProps {
  onSubmit: (values: any) => void;
  defaultValues: { [key: string]: any };
  submitting: boolean;
  workExperienceFields: TProcessedFormFieldList;
  currentWorkFields: TProcessedFormFieldList;
  pastWorkFields: TProcessedFormFieldList;
  isWorkingParams: TProcessedFormField;
  error?: any;
}

export default function WorkProfile(props: IProps) {
  const { onSubmit, defaultValues, currentWorkFields, pastWorkFields, workExperienceFields, error: formError } = props;

  const [pastorgs, setpastorgs] = useState<any[]>([]);

  const { control, register, handleSubmit, getValues, setValue, watch, formState, reset } = useForm({ defaultValues });
  const formValues = watch();
  const { isDirty } = formState;

  const processForm = (processedData: { [key: string]: any }) => {
    const currentOrgs: any = {};
    const pastOrgs: any = [];
    const workexperienceOrgs: any = {};

    for (const dataKey in processedData) {
      if (dataKey.startsWith('currentWork-')) {
        const key = dataKey.replace('currentWork-', '');
        currentOrgs[key] = processedData[dataKey];
      }
      if (dataKey.startsWith('workExperience-')) {
        const key = dataKey.replace('workExperience-', '');
        workexperienceOrgs[key] = processedData[dataKey];
      }

      if (dataKey.startsWith('pastWork-')) {
        const arrkey = dataKey.replace('pastWork-', '');
        const val = processedData[dataKey];
        const maybeMatch = arrkey.match(/^(\d)-.*$/);
        if (!maybeMatch) continue;

        const idx = maybeMatch[1];
        if (!pastOrgs[idx]) pastOrgs[idx] = {};
        const objkey = arrkey.replace(`${idx}-`, '');
        pastOrgs[idx][objkey] = val;
      }
    }

    const data = {
      currentWork: currentOrgs,
      pastWork: pastOrgs,
      workExperience: workexperienceOrgs,
      dataScienceExperience: processedData.dataScienceExperience,
      softwareDevelopmentExperience: processedData.softwareDevelopmentExperience,
    };

    onSubmit(data);
    return true;
  };

  const workexperienceorgParams: any = {};
  for (const field in workExperienceFields) {
    const f = workExperienceFields[field];
    workexperienceorgParams[field] = {
      ...f,
      errors: formError,
      error: formError[`${f.name}`]?.length > 0,
      defaultValue: defaultValues[f.name],
      fieldProps: register(f.name, f.props),
      refGenerator: register,
      setValue,
      rules: f.props,
      control,
      getValues,
      formValues,
      required: f.props?.required,
    };
  }
  const currentorgParams: any = {};
  for (const field in currentWorkFields) {
    const f = currentWorkFields[field];
    currentorgParams[field] = {
      ...f,
      errors: formError,
      error: formError[`${f.name}`]?.length > 0,
      defaultValue: defaultValues[f.name],
      fieldProps: register(f.name, f.props),
      refGenerator: register,
      setValue,
      rules: f.props,
      control,
      getValues,
      formValues,
      required: f.props?.required,
    };
  }

  const createPastorgFields = (idx: number) => {
    const pastorgParams: any = {};
    for (const field in pastWorkFields) {
      const f = pastWorkFields[field];
      const name = f.name.replace('-', `-${idx}-`);
      pastorgParams[name] = {
        ...f,
        errors: formError,
        error: formError[`${name}`]?.length > 0,
        defaultValue: defaultValues[name],
        fieldProps: register(name, f.props),
        refGenerator: register,
        setValue,
        rules: f.props,
        control,
        getValues,
        formValues,
        required: f.props?.required,
      };
    }

    return pastorgParams;
  };

  const addPastorg = () => {
    const idx = pastorgs.length;
    const pastOrg = createPastorgFields(idx);
    setpastorgs([...pastorgs, pastOrg]);
  };

  const getFieldCount = (start: string, end: string) => {
    const number = Object.keys(formValues).filter((item: string) => {
      const keyValue = item.split('-')?.join(' ');
      return keyValue.startsWith(start) && keyValue.endsWith(end);
    });
    return number.length;
  };

  const createPastorgInitialField = (
    fieldName: string,
    setFieldParams: React.Dispatch<React.SetStateAction<any[]>>,
    lastName: string
  ) => {
    for (let cnt = 0; cnt === 0 || cnt < getFieldCount(fieldName, lastName); cnt++) {
      const params = createPastorgFields(cnt);
      setFieldParams(prev => [...prev, params]);
    }
  };

  useEffect(() => {
    createPastorgInitialField('pastWork', setpastorgs, 'organization');
  }, []);

  const dataScienceExperienceField = workexperienceorgParams['dataScienceExperience'];
  const softwareDevelopmentExperienceField = workexperienceorgParams['softwareDevelopmentExperience'];

  const wetotalexperiecemonthsField = workexperienceorgParams['workExperience-totalExperieceMonths'];
  const weproglangBeginnerField = workexperienceorgParams['workExperience-progLangBeginner'];
  const weproglangIntermediateField = workexperienceorgParams['workExperience-progLangIntermediate'];
  const weproglangAdvanceField = workexperienceorgParams['workExperience-progLangAdvance'];
  const weproglibsBeginnerField = workexperienceorgParams['workExperience-progLibsBeginner'];
  const weproglibsIntermediateField = workexperienceorgParams['workExperience-progLibsIntermediate'];
  const weproglibsAdvanceField = workexperienceorgParams['workExperience-progLibsAdvance'];
  const weprogtoolsBeginnerField = workexperienceorgParams['workExperience-progToolsBeginner'];
  const weprogtoolsIntermediateField = workexperienceorgParams['workExperience-progToolsIntermediate'];
  const weprogtoolsAdvanceField = workexperienceorgParams['workExperience-progToolsAdvance'];

  // Options Filtering
  const selectedProgLangOptions =
    formValues[weproglangBeginnerField?.name] +
    ',' +
    formValues[weproglangIntermediateField?.name] +
    ',' +
    formValues[weproglangAdvanceField?.name];

  const remainingProglangOptions = useMemo(
    () =>
      [...weproglangBeginnerField.options].filter(item => !selectedProgLangOptions?.split(',')?.includes(item.value)),
    [selectedProgLangOptions]
  );

  const selectedProgLibsOptions =
    formValues[weproglibsBeginnerField?.name] +
    ',' +
    formValues[weproglibsIntermediateField?.name] +
    ',' +
    formValues[weproglibsAdvanceField?.name];

  const remainingProgLibsOptions = useMemo(
    () =>
      [...weproglibsBeginnerField.options].filter(item => !selectedProgLibsOptions?.split(',')?.includes(item.value)),
    [selectedProgLibsOptions]
  );

  const selectedProgToolsOptions =
    formValues[weprogtoolsBeginnerField?.name] +
    ',' +
    formValues[weprogtoolsIntermediateField?.name] +
    ',' +
    formValues[weprogtoolsAdvanceField?.name];

  const remainingProgToolsOptions = useMemo(
    () =>
      [...weprogtoolsBeginnerField.options].filter(item => !selectedProgToolsOptions?.split(',')?.includes(item.value)),
    [selectedProgToolsOptions]
  );

  const conameField = currentorgParams['currentWork-organization'];
  const codesignationField = currentorgParams['currentWork-designation'];
  const coctcField = currentorgParams['currentWork-ctc'];
  const coworktypeField = currentorgParams['currentWork-workType'];
  const coccityField = currentorgParams['currentWork-cCity'];
  const cojoiningdateField = currentorgParams['currentWork-joiningDate'];
  const conatureofexperienceField = currentorgParams['currentWork-natureOfExperience'];
  const coroleField = currentorgParams['currentWork-role'];
  const cosectorField = currentorgParams['currentWork-sector'];

  return (
    <FormHolder
      isDirty={isDirty}
      onSubmit={handleSubmit(processForm)}
      className={`mx-auto mt-4 sm:mt-8 2xl:px-12 ${
        typeof formError === 'object' && Object?.keys(formError)?.length > 0
          ? 'p-4 pb-12 md:p-8 md:pb-14 border-2 border-red-500'
          : ''
      }`}
    >
      <div className="w-full">
        <h2>Overall and Data Science experience</h2>
        <FormComponentHolder FormComponent={FormInput} {...wetotalexperiecemonthsField} />
        <FormComponentHolder FormComponent={FormTextArea} {...dataScienceExperienceField} />
        <FormComponentHolder FormComponent={FormTextArea} {...softwareDevelopmentExperienceField} />
        <h2>Programming Language</h2>
        <div className="grid lg:grid-cols-3 gap-4">
          <FormComponentHolder
            FormComponent={FormMultiSearchSelect}
            {...weproglangBeginnerField}
            label="Beginner Level"
            remainingOptions={remainingProglangOptions}
            defaultValue={formValues[weproglangBeginnerField.name]}
          />
          <FormComponentHolder
            FormComponent={FormMultiSearchSelect}
            {...weproglangIntermediateField}
            label="Intermediate Level"
            remainingOptions={remainingProglangOptions}
            defaultValue={formValues[weproglangIntermediateField.name]}
          />
          <FormComponentHolder
            FormComponent={FormMultiSearchSelect}
            {...weproglangAdvanceField}
            label="Advance Level"
            remainingOptions={remainingProglangOptions}
            defaultValue={formValues[weproglangAdvanceField.name]}
          />
        </div>
        <h2>Programming Library</h2>
        <div className="grid grid-cols-3 gap-4">
          <FormComponentHolder
            FormComponent={FormMultiSearchSelect}
            {...weproglibsBeginnerField}
            label="Beginner Level"
            remainingOptions={remainingProgLibsOptions}
            defaultValue={formValues[weproglibsBeginnerField.name]}
          />
          <FormComponentHolder
            FormComponent={FormMultiSearchSelect}
            {...weproglibsIntermediateField}
            label="Intermediate Level"
            options={remainingProgLibsOptions}
            defaultValue={formValues[weproglibsIntermediateField.name]}
          />
          <FormComponentHolder
            FormComponent={FormMultiSearchSelect}
            {...weproglibsAdvanceField}
            label="Advance Level"
            remainingOptions={remainingProgLibsOptions}
            defaultValue={formValues[weproglibsAdvanceField.name]}
          />
        </div>
        <h2>Programming Tools</h2>
        <div className="grid grid-cols-3 gap-4">
          <FormComponentHolder
            FormComponent={FormMultiSearchSelect}
            {...weprogtoolsBeginnerField}
            label="Beginner Level"
            remainingOptions={remainingProgToolsOptions}
            defaultValue={formValues[weprogtoolsBeginnerField.name]}
          />
          <FormComponentHolder
            FormComponent={FormMultiSearchSelect}
            {...weprogtoolsIntermediateField}
            label="Intermediate Level"
            remainingOptions={remainingProgToolsOptions}
            defaultValue={formValues[weprogtoolsIntermediateField.name]}
          />
          <FormComponentHolder
            FormComponent={FormMultiSearchSelect}
            {...weprogtoolsAdvanceField}
            label="Advance Level"
            remainingOptions={remainingProgToolsOptions}
            defaultValue={formValues[weprogtoolsAdvanceField.name]}
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-8">
        <div className="w-full">
          <h2>Current</h2>
          <FormComponentHolder FormComponent={FormSelect} {...coworktypeField} />
          <FormComponentHolder FormComponent={FormInput} {...conameField} />
          <FormComponentHolder FormComponent={FormSelect} {...cosectorField} />
          <FormComponentHolder FormComponent={FormInput} {...codesignationField} />
          <FormComponentHolder FormComponent={FormInput} {...coroleField} />
          <FormComponentHolder FormComponent={FormTextArea} {...conatureofexperienceField} />
          <FormComponentHolder FormComponent={FormInput} {...coccityField} />
          <FormComponentHolder FormComponent={FormInput} {...cojoiningdateField} />
          <FormComponentHolder FormComponent={FormSelect} {...coctcField} />
        </div>

        <div className="w-full">
          <h2>Past</h2>
          {pastorgs.map((pastorgParams, idx: number) => {
            const porgnameField = pastorgParams[`pastWork-${idx}-organization`];
            const porgdesignationField = pastorgParams[`pastWork-${idx}-designation`];
            const porgworktypeField = pastorgParams[`pastWork-${idx}-workType`];
            const porgsectorField = pastorgParams[`pastWork-${idx}-sector`];
            const porgnatureofexperienceField = pastorgParams[`pastWork-${idx}-natureOfExperience`];
            const porgjoiningdateField = pastorgParams[`pastWork-${idx}-joiningDate`];
            const porgrelieveDateField = pastorgParams[`pastWork-${idx}-relieveDate`];

            return (
              <div key={`org-${idx}`}>
                {pastorgs.length > 1 ? <h4 className="float-right">#{pastorgs.length - idx}</h4> : null}
                <FormComponentHolder
                  FormComponent={FormSelect}
                  {...porgworktypeField}
                  defaultValue={formValues[`pastWork-${idx}-workType`]}
                  errors={formError}
                />
                <FormComponentHolder
                  FormComponent={FormInput}
                  {...porgnameField}
                  defaultValue={formValues[`pastWork-${idx}-organization`]}
                  errors={formError}
                />
                <FormComponentHolder
                  FormComponent={FormSelect}
                  {...porgsectorField}
                  defaultValue={formValues[`pastWork-${idx}-sector`]}
                  errors={formError}
                />
                <FormComponentHolder
                  FormComponent={FormInput}
                  {...porgdesignationField}
                  defaultValue={formValues[`pastWork-${idx}-designation`]}
                  errors={formError}
                />
                <FormComponentHolder
                  FormComponent={FormTextArea}
                  {...porgnatureofexperienceField}
                  defaultValue={formValues[`pastWork-${idx}-natureOfExperience`]}
                  errors={formError}
                />
                <FormComponentHolder
                  FormComponent={FormInput}
                  {...porgjoiningdateField}
                  defaultValue={formValues[`pastWork-${idx}-joiningDate`]}
                  errors={formError}
                />
                <FormComponentHolder
                  FormComponent={FormInput}
                  {...porgrelieveDateField}
                  defaultValue={formValues[`pastWork-${idx}-relieveDate`]}
                  errors={formError}
                />
                {pastorgs.length > 1 ? <hr /> : null}
              </div>
            );
          })}
          {pastorgs.length < 5 && (
            <button type="button" onClick={addPastorg} className="button outline">
              Add Another Organization
            </button>
          )}
        </div>
      </div>

      <button className="button dark filled float-right mt-2" type="submit">
        <FaSave className="inline mr-2" /> Save
      </button>
    </FormHolder>
  );
}
