/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { FaRegCalendarAlt ,FaRegClock} from 'react-icons/fa';
import {AiFillRightCircle} from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import { jsx } from '@emotion/react';
import moment from 'moment';
import useStore from 'globalstate';
import { DEFAULT_CARD_IMG } from 'constants/app';
import {TProgram,TCourse } from 'types';
import Modal from 'fragments/Modal';
import globalEmitter from 'utils/emitter';
import CourseDetails from 'components/Courses/CourseDetails';
import ProgramDetails from 'components/Programs/ProgramDetails';


type TProps = {
  data: TProgram | TCourse;
  type:"Program" | "Course";
  onStateChange: Function;
};
type TExBtn = {
  text: string;
  icon: JSX.Element;
  onClick?: Function;
  newTab?: boolean;
  isLive?: boolean;
};

export default function UpdatedProgramCard(props: TProps) {
  const [State] = useStore();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);

  const eventEmitter = globalEmitter;

  const { name, description, meta, bannerurl, url } = props.data;

  const metaJSON = JSON.parse(meta);

  const getDate = (data:any)=>{
    const timeInSeconds = Math.floor(data);
    const dt = new Date(timeInSeconds);
    const dateFormatted = `${dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth() + 1).toString().padStart(2, '0')}/${dt.getFullYear()}`;
    return dateFormatted;
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  const openDetailView = () => {
    setIsDetailModalOpen(true);
  };

  return (
    <div className={`${(props.type?.toUpperCase() ==="PROGRAM")  ? "col-span-8":"col-span-8 xsm:col-span-4"}`}>
    <ReactTooltip />
    <div
      className={
        `${(props.type?.toUpperCase() ==="PROGRAM") ? "h-400px xsm:h-60":"h-60"} 3xl:h-360px p-4 flex flex-col xsm:flex-row-reverse  gap-4 rounded overflow-hidden font-switzer shadow-lg relative bg-white`
      }
    >
      {/* Program Image */}
       { (props.type?.toUpperCase() ==="PROGRAM") && 
          <div className='w-full h-36 xsm:w-1/2 xsm:h-full'>
            <img
              className="w-full h-full cursor-pointer"
              src={bannerurl ? bannerurl : DEFAULT_CARD_IMG}
              onClick={() => {
                window.open(url, '_blank')?.focus();
              }}
            />
          </div>
        }
        
      {/* Discription Section  */}
      <div className={`${(props.type?.toUpperCase() ==="PROGRAM") ? "w-full xsm:w-1/2":"w-full"} relative h-full`}>
        <div className="flex flex-row justify-start items-center">
            {/* ProgramType */}
            <span
              className='text-xs 3xl:text-base font-medium self-center m-0 text-text-blue'
              data-tip={'' + name}
              key={name}
            >
              {props.type?.toUpperCase()}
            </span>
          
        </div>

        {/* Title */}
        <div className='py-2'>
          <h1 data-tip={name} className={`h-12 3xl:h-72px 3xl:max-h-20 text-text-dark font-switzer text-base 3xl:text-2xl font-medium break-words cursor-default max-h-14 overflow-hidden ${ name.length > 35 ? "line-clamp-2": "flex items-end"}`}>{name.toUpperCase()}</h1>
        </div>

        {/* Instruction and Course */}
       <div  className='pb-2 3xl:pb-4'>
         <div className='flex flex-row items-center justify-between'>

            <div className='flex flex-row items-center'>
                <FaRegCalendarAlt className='text-text-grey-light 3xl:text-base mr-2'/>
                <div className='text-text-grey-light text-xs 3xl:text-base font-medium leading-4 cursor-default'>{metaJSON?.startDate}</div>
            </div>

            <div className='flex flex-row items-center'>
                <FaRegClock className='text-text-grey-light 3xl:text-base mr-2'/>
                <div className='text-text-grey-light text-xs 3xl:text-base font-medium cursor-default'>{metaJSON?.duration?.split(",")[0]}</div>
            </div>
         </div>
       </div>
       


        {/* Description */}
        <div>
          <p className="text-text-grey text-xs 3xl:text-base m-0 break-words line-clamp-3 3xl:line-clamp-4">{description}</p>
        </div>

        <div
          className='text-text-blue flex flex-row items-center absolute left-0 bottom-0 flex-auto cursor-pointer text-space'
          onClick={openDetailView}
        >
          <span className="mr-2 text-sm 3xl:text-xl leading-none">Learn More</span>
          <AiFillRightCircle className='text-base 3xl:text-xl'/>
        </div>
      </div>
    </div>
 
      <Modal isOpen={isDetailModalOpen} close={closeDetailModal} size="md">
        {props.type === "Program" ? 
        <ProgramDetails
          event={{
          ...props.data as unknown as TProgram,
          meta:metaJSON,
          }}
        /> 
        : 
        <CourseDetails
        event={{
          ...props.data as unknown as TCourse,
          meta:metaJSON,
        }}
        />
        }
      </Modal>
      </div>
  );
}
