import React, { useState, useEffect } from 'react';
import useStore from 'globalstate';
import ReactMarkdown from 'react-markdown';
import globalEmitter from 'utils/emitter';
import { DEFAULT_CARD_IMG, GET_PROGRAM_INFO_URL } from 'constants/app';
import toaster from 'utils/toast';
import fetch from 'utils/fetch';
import Spinner from 'fragments/Spinner';

type TProgramDetails = {
  program_id: number;
  name: string;
  description: string;
  type?: 'Program' | 'Course';
  url: string;
  bannerurl: string;
  start_date: Date;
  end_date: Date;
  meta: any;
};

type TProgramCourses = {
  program_id: number;
  course_id: number;
  course_name: string;
  course_url: string;
  course_description: string;
  course_meta: null;
  course_end_date: Date;
  course_start_date: Date;
};

type TProps = {
  event: TProgramDetails;
};
export default function (props: TProps) {
  const { event } = props;
  const [loading, setloading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [coursesData, setCoursesData] = useState<TProgramCourses[]>();

  const [, Actions] = useStore();
  const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const eventEmitter = globalEmitter;

  const getProgramCourses = async () => {
    setloading(true);
    try {
      const resp = await fetch(`${GET_PROGRAM_INFO_URL}${event.program_id}`, {
        credentials: 'header',
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const courses = respJSON.data as unknown as TProgramCourses[];
      setCoursesData(courses);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getProgramCourses();
  }, []);

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 h-96 max-h-screen ">
      <div className="  flex align-center flex-col pr-10">
        <div className="overflow-visible h-min relative ">
          <div className="absolute h-32 w-32 right-0">
            <div className="absolute -right-3 top-10 border p-2 rounded text-white bg-brand  ">Registered</div>
          </div>
          <div className={'bg-gradient-to-r to-white-500 '}>
            <img src={event?.bannerurl || DEFAULT_CARD_IMG} className="object-contain"></img>
          </div>
        </div>

        <div className="mt-6">
          <table>
            <tbody>
              <tr>
                <td className="font-bold">Duration</td>
                <td>{event?.meta?.duration?.split(',')[0]}</td>
              </tr>
              <tr>
                <td className="font-bold">Starts On</td>
                <td>{event?.meta?.startDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className=" flex flex-col">
        <h1>{event?.name}</h1>
        <hr></hr>
        <h3 className="text-base text-gray-700">Courses -</h3>
        {loading && <Spinner />}
        {error && <h3 className="text-base text-gray-700">{error}</h3>}
        {coursesData &&
          coursesData.map((value, idx) => (
            <React.Fragment key={`ProgramCard-CourseList-${idx}`}>
              <div>
                <h3 className="text-base text-gray-700">
                  {idx + 1}). {value?.course_name}
                </h3>
                {/* <p className="text-sm text-gray-600">
                    <ReactMarkdown>{value?.course_description}</ReactMarkdown>{' '}
                </p> */}
              </div>
            </React.Fragment>
          ))}
        <hr></hr>
        <div>
          <h3 className="text-base text-gray-700">Prerequisites -</h3>
          <div className="text-sm text-gray-600">
            <ReactMarkdown>{event?.meta?.prerequisites}</ReactMarkdown>{' '}
          </div>
        </div>
        <hr></hr>
        <div>
          <h3 className="text-base text-gray-700">Description -</h3>
          <div className="text-sm text-gray-600">
            <ReactMarkdown>{event?.description}</ReactMarkdown>{' '}
          </div>
        </div>
        {/* <p className="text-sm text-gray-400 capitalize"> - By {event.speakerName} </p> */}
        <hr></hr>
      </div>
    </div>
  );
}
