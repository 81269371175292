import React, { useState, useEffect, useMemo } from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import fetch from 'utils/fetch';
import Spinner from 'fragments/Spinner';
import ErrorMsg from 'components/Error';
import Modal from 'fragments/Modal';
import Payment from '../Payment/index';
import { GET_SUBSCRIPTION_PLAN_URL } from 'constants/app';
import GlobalSlot from './GlobalSlot/GlobalSlot';
import DowngradePlan from './DowngradePlan';
import useStore from 'globalstate';
import { getLoginURL } from 'constants/app';
import moment from 'moment';

export type TIndividualPlan = {
  product_id: number;
  payment_id: number;
  name: string;
  billing_frequency: string;
  displayname: string;
  features: string;
  price: number;
  currency?: 'INR' | 'USD';
  discountedprice: number | null;
  description:string;
  meetupId?:number
};

type Tplans = {
  basic: TIndividualPlan[];
  advance: TIndividualPlan[];
  free: TIndividualPlan[];
};

type TPlanTypes = 'basic' | 'advance' | 'free';

export default function PlanSimple() {
  const [basicPlans, setBasicPlans] = useState<TIndividualPlan[]>();
  const [freePlan, setfreePlan] = useState<TIndividualPlan>();
  
  const [globalStateModal, setGlobalStateModal] = useState<boolean>(false);
  const [downgradePlanModal, setDowngradePlanModal] = useState<boolean>(false);

  const [loading, setloading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  const [selectedPlan, setSelectedPlan] = useState<TIndividualPlan>();

  const [State]=useStore();

  const {currentPlan} = State;

  const agentDiscountString = localStorage.getItem('ad');
  let agentDiscount;
  let agentDiscountPercentage:number | undefined;
  let agentDiscountValidity:string | undefined;
  if(agentDiscountString){
    agentDiscount = JSON.parse(agentDiscountString);
    agentDiscountPercentage = agentDiscount?.details?.discount;
    agentDiscountValidity = agentDiscount?.details?.validTill;
  }

  const CURRENCY_SYMBOL = useMemo(() => {
    if (basicPlans === undefined) return '';
    return basicPlans[0]?.currency === 'INR' ? '₹' : '$';
  }, [basicPlans]);

  const getPlans = async () => {
    setloading(true);
    try {
      const resp = await fetch(GET_SUBSCRIPTION_PLAN_URL, {
        credentials: 'header',
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const plans: Tplans = respJSON.data;

      setBasicPlans(plans?.basic);
      setfreePlan(plans?.free[0]);

    } catch (e: any) {
      setError(e.message);
    } finally {
      setloading(false);
    }
  };

  const upgradeButtonHandler = (plan:TIndividualPlan) => {
    setSelectedPlan(plan);
    setGlobalStateModal(true);
  };

  const openDowngradePlanModal=()=> setDowngradePlanModal(true);
  const closeDowngradePlanModal=()=> setDowngradePlanModal(false);

  const Current = ({ plan }: { plan: TIndividualPlan }) => {
    return (
      currentPlan?.plan_display_name?.toLowerCase() === "free" ?
      (<div
        className="flex linked cursor-pointer items-center w-full"
      >
        <div className="text-xs sm:text-sm md:text-base lg:text-xl flex items-center justify-center font-semibold linked uppercase"> Current Plan</div>
        {/* <FaArrowCircleRight className="ml-2"></FaArrowCircleRight> */}
      </div>):
      (<div
      className="flex linked cursor-pointer items-center w-full"
      onClick={() => upgradeButtonHandler(plan)}
    >
      <div className="text-xs sm:text-sm md:text-base lg:text-xl flex items-center justify-center font-semibold linked uppercase"> Extend Plan</div>
      <FaArrowCircleRight className="ml-2 hidden sm:block"></FaArrowCircleRight>
    </div>)
    );
  };
  const Upgrade = ({ plan }: { plan: TIndividualPlan }) => {
    return (
      <div
        className="flex linked cursor-pointer items-center w-full"
        onClick={() => upgradeButtonHandler(plan)}
      >
        <div className="text-xs sm:text-sm md:text-base lg:text-xl flex items-center justify-center font-semibold linked uppercase"> Upgrade Plan</div>
        <FaArrowCircleRight className="ml-2 hidden sm:block"></FaArrowCircleRight>
      </div>
    );
  };
  const Downgrade = ({ plan }: { plan: TIndividualPlan }) => {
    return (
      <div className="flex linked cursor-pointer items-center w-full text-red-400" 
        // onClick={openDowngradePlanModal}
        >
        {/* temp remove downgrade button  */}
        {/* <div className="text-xs sm:text-sm md:text-base lg:text-xl flex items-center justify-center font-semibold linked uppercase text-red-400"> Downgrade Plan</div>
        <FaArrowCircleRight className="ml-2 hidden sm:block"></FaArrowCircleRight> */}
        <span className='w-10 lg:w-16 xsm:w-12  h-1 bg-red-400'></span>
      </div>
    );
  };

  const getUpdateButton = (plan:TIndividualPlan) => {
    if (currentPlan) {
      if(plan?.name.toLowerCase() === "free")
        if(currentPlan?.name?.toLowerCase() ==="free") 
          return <Current plan={plan} />;
        else return  <Downgrade  plan={plan} />;
      
      else if(plan?.name.toLowerCase() === "basic")
        if(currentPlan?.name?.toLowerCase() ==="basic") 
            return <Current plan={plan} />;
          else return  <Upgrade  plan={plan} />;

    } else {
      return (
        <div className="flex linked cursor-default items-center w-full">
          <div 
            className="text-xl font-semibold linked uppercase cursor-pointer"
            onClick={() => {
              window.location.href = getLoginURL(window.location.href);
            }}
            > 
            Login & Enroll 
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    getPlans();

    return () => {
      setBasicPlans([]);
    };

  }, []);

  useEffect(()=>{
    if(moment().isAfter(moment(agentDiscountValidity)))
      localStorage.removeItem("ad");
  },[]);

  return (() => {
    if (loading) return <Spinner space show />;
    if (error) return <ErrorMsg preset="broken" text={error} />;
    return (
      <div className="mt-6 relative">
        {basicPlans && (
          <>
            <div className='flex flex-row gap-4 items-start justify-between'>
              <h2 className="font-switzer font-medium text-xl text-text-grey mb-4">Subscription Plans 
              </h2>
              { agentDiscountPercentage && 
                  <span className='text-green-600'>
                      <strong>* </strong> 
                      Discount of <strong> {agentDiscountPercentage} % </strong> has been applied and is valid till  <strong>{moment(agentDiscountValidity).format('MMM Do YYYY, h:mm:ss a')}</strong> </span>
              }
            </div>
            <div className="grid grid-cols-4 gap-1 border-solid w-full text-2xl leading-10 ">
              
              <div className="flex flex-row col-span-4 py-3">
                <div className="text-text-grey text-xs sm:text-sm font-semibold w-full">Plan</div>
                {/* {freePlan && <div className="font-bold text-text-grey text-sm sm:text-lg w-full uppercase"> {freePlan?.name}</div>} */}
                {basicPlans &&
                  basicPlans.map((value: any,idx:number) => (
                    <div className="font-bold text-text-grey text-sm sm:text-lg w-full uppercase" key={`Basic-Plan-Name-${idx}`}> {value.displayname}</div>
                  ))}
              </div>

              <div className="flex flex-row col-span-4 pb-3 border-b border-blue-300">
                <div className="text-text-grey text-xs sm:text-sm font-semibold w-full"></div>
                
                {/* <div className="flex items-end text-xl sm:text-3xl lg:text-5xl w-full"> {'FREE'}
                </div> */}
                
                {basicPlans &&
                  basicPlans.map((value: any,idx:number) => (
                    <div className="text-xl sm:text-3xl lg:text-5xl w-full" key={`Basic-Plan-Price-${idx}`}>  
                      {value?.discountedprice ? 
                      (<>
                        <span className='block line-through text-base sm:text-xl lg:text-3xl text-text-grey font-normal'>{CURRENCY_SYMBOL} {value.price} </span>
                        <span className={`block ${agentDiscountPercentage && value?.billing_frequency ==="yearly" ? "line-through text-base sm:text-xl lg:text-3xl text-text-grey font-normal":""}`}>{CURRENCY_SYMBOL} { value?.discountedprice} </span>
                        {(agentDiscountPercentage && value?.billing_frequency ==="yearly") && <span className='block'>{CURRENCY_SYMBOL} {
                        Math.round(value?.discountedprice - ( (agentDiscountPercentage * value?.discountedprice)/100))}</span>}
                      </>
                      ) : 
                      value.price == 0 ? 'Free':<React.Fragment>
                        {agentDiscountPercentage && value?.billing_frequency ==="yearly" ? 
                          <React.Fragment>
                            <span className='block line-through text-base sm:text-xl lg:text-3xl text-text-grey font-normal'>{CURRENCY_SYMBOL} {value.price} </span>
                            <span className='block'>
                            {CURRENCY_SYMBOL} {Math.round(value?.price - ( (agentDiscountPercentage * value?.price)/100))}
                            </span>
                          </React.Fragment>
                        :
                        <span>
                         {CURRENCY_SYMBOL} {value.price}
                        </span> 
                        }
                      </React.Fragment>}
                    </div>
                  ))}
              </div>

              <div className="flex flex-row col-span-4 py-3 border-b border-blue-300">
                <div className="text-text-grey text-xs sm:text-sm font-semibold w-full">Description</div>
                {/* {freePlan && (<div className="text-sm w-full text-text-dark">
                  {freePlan?.description}
                </div>)} */}
                {basicPlans &&
                  basicPlans.map((value: any,idx:number) => (
                    (<div className="text-sm w-full text-text-dark" key={`Basic-Plan-Description-${idx}`}>
                      {value?.description}
                    </div>)
                  ))}
              </div>

              <div className="flex flex-row col-span-4 py-3 border-b border-blue-300">
                <div className="text-text-grey text-xs sm:text-sm font-semibold w-full">Feature Name</div>
                {/* <div className="text-red-400 text-sm md:text-base w-full">
                  {freePlan ? `${JSON.parse(freePlan?.features as string)[0]}` : 'NA'}
                </div> */}

                {basicPlans &&
                  basicPlans.map((value: any,idx:number) => (
                    <div className="text-green-600 text-sm md:text-base w-full" key={`basic-plan-feature-${idx}`}>{JSON.parse(value?.features as string)[0]}</div>
                  ))}
              </div>

              <div className="flex flex-row col-span-4 py-3">
                <div className="text-text-grey flex items-center text-xs sm:text-sm font-semibold w-full">Update Plan</div>

                {/* {freePlan && getUpdateButton(freePlan)} */}

                {basicPlans &&
                  basicPlans.map((plan: any,idx:number) =>
                  <React.Fragment key={`Basic-Plan-Button-${idx}`}>
                    {getUpdateButton(plan)}
                  </React.Fragment>
                  )
                }
              </div>
            </div>
          </>
        )}
        {selectedPlan && <Modal isOpen={globalStateModal} size="lg" bgColor='bg-white' close={() => setGlobalStateModal(false)}>
          {
          currentPlan?.is_global_slot_selected ?
            <React.Fragment>
              <Payment  productId={selectedPlan?.product_id?.toString()} paymentId={selectedPlan?.payment_id} billingFrequency={selectedPlan?.billing_frequency} slotId=""/>
            </React.Fragment> :
            <React.Fragment>
              <GlobalSlot agentDiscountPercentage={agentDiscountPercentage} product={selectedPlan} billingFrequency={selectedPlan?.billing_frequency} close={() => setGlobalStateModal(false)}/> 
            </React.Fragment> 
           }
        </Modal>}
        {/* Temp removed Downgrade plan option */}
        {/* <DowngradePlan 
          isOpen={downgradePlanModal}
          onDeregistration={() => {
            closeDowngradePlanModal();
            getUserSubscription && getUserSubscription();
          }}
          closeModal= {closeDowngradePlanModal}
        /> */}
      </div>
    );
  })();
}
