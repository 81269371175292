import { USER_EVENT_REGISTER_URL } from 'constants/app';
import Modal from 'fragments/Modal';
import React, { useState, useEffect } from 'react';
import { FaTimes, FaTrash } from 'react-icons/fa';

import { IRequestResponse } from 'types';
import fetch from 'utils/fetch';
import toaster from 'utils/toast';

type TProps = {
  isOpen: boolean;
  onDeregistration: Function;
  eventId: number;
  displayName: string;
  closeModal: any;
};
export default function DeregisterModal(props: TProps) {
  const { isOpen, closeModal } = props;

  // const closeDeregModal = () => {};

  // useEffect(() => {
  //   setIsModalOpen(isOpen);
  // }, [isOpen]);

  const deregisterEvent = async () => {
    const res = await requestDeRegistration(props.eventId);
    if (res.success == true) {
      toaster.success('You Are successfully deregistered.');
      props.onDeregistration();
    } else if (res.error) {
      toaster.error(res.error);
    }

    //closeDeregModal();
  };
  async function requestDeRegistration(eventId: number): Promise<IRequestResponse> {
    const params = {
      method: 'POST',
      body: JSON.stringify({
        event_id: eventId,
        register: false,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    };

    const response = await fetch(USER_EVENT_REGISTER_URL, {
      credentials: 'header',
      ...params,
    });

    return response.json();
  }
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} close={closeModal}>
        <div className="p-10">
          <div>
            <span className="text-center">
              {' '}
              Are You Sure you want to Deregister from <strong>{props.displayName}</strong> ?
            </span>
          </div>

          <div className="flex place-content-between  pt-5 bottom-0">
            <button className="large" onClick={closeModal}>
              <FaTimes />
              &nbsp;Cancel
            </button>
            <button onClick={deregisterEvent}>
              <FaTrash />
              &nbsp; Deregister
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
