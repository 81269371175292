/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/browser';
import Markdown from 'markdown-to-jsx';

import Spinner from 'fragments/Spinner';
import ErrorView from 'components/Error';
import fetch from 'utils/fetch';
import { PRIMARY_APP } from 'constants/color';
import { INIT_PAYMENT_SUBSCRIPTION, SUBSCRIPTION_PAYMENT_URL } from 'constants/app';

declare global {
  interface Window {
    Razorpay: any;
  }
}

interface IProps {
  applicationId: string;
  fromURL: string;
  successURL: string;
  productId: string;
  paymentId: string;
  slotId:string;
  setActiveStep:Function|null;
}

type TRazorSubscriptionData = {
  key: string;
  subscriptionId: string;
  name: string;
  description: string;
  planDescription: string;
  image: string;
  user: {
    name: string;
    email: string;
  };
};

function Checkout(props: IProps) {
  const { applicationId = '', paymentId, productId, slotId,fromURL, successURL,setActiveStep } = props;

  const [razorData, setRazorData] = useState<TRazorSubscriptionData>();
  const [error, setError] = useState<string>('');

  const getRazorDataURL = async () => {
    const queryParams = { paymentId, productId, slotId, fromURL, successURL };
    const queryString = new URLSearchParams(queryParams)?.toString();

    const sessionResult = await fetch(`${INIT_PAYMENT_SUBSCRIPTION}?${queryString}`, {
      credentials: 'header',
    });

    try {
      const razorJSON = await sessionResult.json();
      if (sessionResult.status !== 200) return setError(razorJSON.error);
      const razorData = razorJSON.data;
      setRazorData(razorData);
    } catch (e) {
      Sentry.captureException(e);
      setError('Something went wrong');
    }
  };

  const onPay = () => {
    // "handler": function (response){
    //   alert(response.razorpay_payment_id);
    //   alert(response.razorpay_order_id);
    //   alert(response.razorpay_signature)
    // },
    if (setActiveStep || setActiveStep !== null) setActiveStep(3);
    else window.location.href = successURL;
  };

  const initiatePayment = () => {
    if (!razorData) return;

    const options = {
      key: razorData.key,
      subscription_id: razorData.subscriptionId,
      buttontext: 'Pay with Razorpay',
      name: razorData.name,
      description: razorData.description,
      handler: onPay,
      image: razorData.image,
      prefill: razorData.user,
      theme: { color: PRIMARY_APP },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    // check if checkout.js had loaded before proceeding
    const maybeGetData = () => {
      if (window.Razorpay) getRazorDataURL();
      else
        setTimeout(() => {
          maybeGetData();
        }, 2000);
    };

    maybeGetData();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      </Helmet>
      <div
        css={css`
          padding: 2rem;
        `}
      >
        {(() => {
          if (error) return <ErrorView embed preset="broken" text={error} />;

          return razorData ? (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              `}
            >
              {(() => {
                if (!razorData.planDescription) return null;
                return (
                  <p
                    css={css`
                      font-family: Space Grotesk;
                      margin-bottom: 3rem !important;
                      font-weight: 300;
                      font-size: 1rem;
                      @media (max-width: 768px) {
                        font-size: 0.95rem;
                      }
                    `}
                  >
                    <Markdown>{razorData.planDescription}</Markdown>
                  </p>
                );
              })()}
              <button className="button is-primary" onClick={initiatePayment}>
                Pay Enrollment Fee
                <FaExternalLinkAlt
                  css={css`
                    margin-left: 5px;
                    color: white;
                  `}
                />
              </button>
            </div>
          ) : (
            <div
              css={css`
                text-align: center;
                padding: 2rem;
                position:relative;
              `}
            >
              <Spinner space show />
              <h2
                css={css`
                  margin-bottom: 1rem;
                `}
              >
                Preparing payment
              </h2>
            </div>
          );
        })()}
      </div>
    </React.Fragment>
  );
}

export default Checkout;
