import React, { useCallback, useRef, useState } from 'react';
import imageCompression from 'browser-image-compression';
import { FaExclamationCircle } from 'react-icons/fa';

import Spinner from 'fragments/Spinner';
import Uploader, { TCompressionResponse } from 'components/Uploader';
import { InfoLine } from 'components/Form';

type Props = {
  Icon?: any;
  render: React.ReactElement;
  fieldProps: any;
  label: string;
  info?: string;
  name: string;
  placeholder?: string;
  defaultValue?: string;
  error?: string;
  setValue: any;
  getUploadURL: string;
  imageComressionOptions?: { maxSizeMB: number; maxWidthOrHeight: number };
  fileOptions: { minSize: number; maxSize: number; getUploadURL: string; minSizeMB: number; maxSizeMB: number };
};

export function FormUpload(props: Props) {
  const { imageComressionOptions, fileOptions, defaultValue, error, name, placeholder, fieldProps, render, setValue } =
    props;

  const filename = useRef<string>();
  const [uploaderror, setuploaderror] = useState<string>('');
  const [status, setstatus] = useState<string>();

  const compressFile = async (file: File): Promise<TCompressionResponse> => {
    const options = imageComressionOptions;
    if (!options) return { success: false, error: 'Invalid upload invocation. Please contact us.' }; // capture with sentry
    try {
      const compressedFile = (await imageCompression(file, options)) as File;
      return { success: true, file: compressedFile };
    } catch (e) {
      const errMsg = (e as Error).message;
      setuploaderror(errMsg);
      return { success: false, error: errMsg };
    }
  };

  const onComplete = useCallback(
    async filePath => {
      setstatus('');
      setValue(name, filePath, { shouldDirty: true });
    },
    [filename]
  );

  const onError = () => {
    setuploaderror('Uploading failed');
    setstatus('');
  };

  const onGetURL = (data: { [key: string]: string }) => {
    filename.current = data.generatedFileName;
    setstatus('Uploading...');
  };

  // capture with sentry
  if (!fileOptions)
    return (
      <InfoLine
        success={false}
        info="There is a problem with this form. Please contact us."
        Icon={FaExclamationCircle}
      />
    );

  return (
    <React.Fragment>
      <input className="hidden" placeholder={placeholder} type="text" defaultValue={defaultValue} {...fieldProps} />
      {!defaultValue && imageComressionOptions ? (
        <div className="text-xs mb-1">Max size: {imageComressionOptions.maxSizeMB}MB</div>
      ) : null}
      {!defaultValue && fileOptions ? (
        <div className="text-xs mb-1">
          Size: {fileOptions.minSizeMB}MB - {fileOptions.maxSizeMB}MB
        </div>
      ) : null}
      <Uploader
        render={render}
        options={fileOptions}
        compressFunction={imageComressionOptions ? compressFile : undefined}
        onGetURL={onGetURL}
        onComplete={onComplete}
        onError={onError}
      />
      {error && (
        <div className="flex items-center justify-between">
          <div />
          <InfoLine success={false} info={uploaderror} Icon={FaExclamationCircle} />
        </div>
      )}
      {status && (
        <div className="flex items-center whitespace-nowrap">
          <Spinner size="tiny" /> <span className="ml-2">{status}</span>
        </div>
      )}
    </React.Fragment>
  );
}
