import { REWARD_REF_IMG } from 'constants/app';
import  React  from 'react';
import { TUserReferralReward } from './Referral';

type Tprops={
    userReferralRewards:TUserReferralReward[],
    referralCount:number
}

const ReferralModal = ({userReferralRewards, referralCount}:Tprops)=>{
    return (
    <div className='flex flex-col sm:flex-row gap-4 max-h-80vh overflow-y-auto'> 
        <div className="w-full sm:w-1/2">
            <img 
                src={REWARD_REF_IMG}
                className='h-56'
                alt="Rewards" 
            />
            <div >
                <h2 className='font-switzer text-text-dark font-semibold text-base 3xl:text-2xl my-4 3xl:my-8 cursor-default'>Referral Rewards</h2>
                <p className='font-switzer text-text-grey font-normal text-sm 3xl:text-base cursor-default'>For every 20 successful referrals you get 2 months free of TechX.AI. Please note: we update referral count once every 24 hrs.</p>
                <h2 className='font-switzer text-text-grey text-base 3xl:text-2xl my-4 3xl:my-8 cursor-default'>Your referrals count: {referralCount}</h2>
            </div>
        </div>
        <div className="w-full sm:w-1/2">
            <div>
                <h2 className='font-switzer text-text-blue font-semibold border-b-2 border-text-blue pb-1 text-base 3xl:text-xl cursor-default'>My Rewards</h2>
                {userReferralRewards.length ===0 && <div className='mt-4 mb-24 text-text-grey cursor-default'>
                    Please refer more friends to earn rewards.
                </div>}
                {userReferralRewards.length > 0 && userReferralRewards.map((reward:TUserReferralReward,idx:number) => 
                    <div
                       key={`userReward-list-${idx}`} 
                       className={`py-4 ${idx+1 < userReferralRewards.length ? "border-b border-gray-200":""}`}
                        >
                        <div className='text-text-grey font-normal text-sm 3xl:text-base cursor-default'>You have received </div>
                        {
                            reward.reward_type==="duration" ? 
                            <div className='text-text-grey font-semibold text-sm 3xl:text-base cursor-default'>{reward.reward_value} {reward.meta.symbol} subscription for FREE.</div> :
                            <div className='text-text-grey font-semibold text-sm 3xl:text-base cursor-default'>Get a {reward.reward_value}{reward.meta.symbol} on your subscription.</div> 
                        }
                    </div>
                    )}
            </div>
            <div>
                <h2 className='font-switzer text-text-blue font-semibold border-b-2 border-text-blue pb-1 text-base 3xl:text-xl cursor-default'>Rewards</h2>
                <div 
                className={`py-4 border-b border-gray-200 cursor-default`}
                >
                    <div className='text-text-grey font-normal text-sm 3xl:text-base'>On the next 20 Referrals. </div>
                    <div className='text-text-grey font-semibold text-sm 3xl:text-base'>
                        Get 60 Days subscription for FREE.
                    </div>
                </div>
                <div 
                className={`py-4 cursor-default`}
                >
                    <div className='text-text-grey font-normal text-sm 3xl:text-base'>And your friend&lsquo;s </div>
                    <div className='text-text-grey font-semibold text-sm 3xl:text-base'>
                        Get 20% of discount on their subscription. 
                    </div>
                </div>
            </div>
        </div>
    </div>);
};

export { ReferralModal } ;