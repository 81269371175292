/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

import { ERROR, SUCCESS } from 'constants/color';

type InfoLineProps = {
  cssString?: string;
  info: string | null;
  Icon?: any;
  success?: boolean;
};

function InfoLine(props: InfoLineProps) {
  const { cssString, info, success = false, Icon } = props;
  const color = success ? SUCCESS : ERROR;

  return (
    <div
      css={css`
        color: ${color};
        font-weight: 500;
        font-size: 0.85rem;
        ${cssString};
      `}
    >
      {info && Icon && (
        <Icon
          color={color}
          css={css`
            margin-right: 0.25rem;
          `}
        />
      )}
      {info}
    </div>
  );
}

export default InfoLine;
