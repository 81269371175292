import React from 'react';
import { Prompt } from 'react-router-dom';

interface IProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  isDirty: boolean;
  className: string;
  children: React.ReactNode;
}

export function FormHolder(props: IProps) {
  const { className, onSubmit, isDirty } = props;

  return (
    <form className={className} onSubmit={onSubmit}>
      <Prompt when={isDirty} message={`You have unsaved changes. Are you sure you want to leave?`} />
      {props.children}
    </form>
  );
}
