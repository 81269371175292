import React, { useState } from 'react';

type Props = {
  Icon?: any;
  defaultValue?: any;
  fieldProps: any;
  label: string;
  refGenerator: any;
  setValue: any;
  info?: string;
  options?: string[] | number[];
  placeholder?: string;
  error?: string;
};

export function FormCheck(props: Props) {
  const { defaultValue, error, options, fieldProps, setValue } = props;
  const safeDefault = defaultValue ? defaultValue.split(',') : [];
  const [selectedOptions, setSelectedOptions] = useState<Array<string | number>>(safeDefault);

  const handleInputChange = (option: any) => () => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter(opt => opt !== option)
      : [...selectedOptions, option];
    setSelectedOptions(newSelectedOptions);

    const csv = newSelectedOptions.join(',');
    setValue(fieldProps.name, csv, { shouldDirty: true });
  };

  if (!options) return null;

  const opts = [...options];

  return (
    <div className={error ? 'border border-red-500 focus:ring-red-300' : 'focus:border-blue-300'}>
      {opts.map((option: string | number) => (
        <div className="m-1" key={`check-${option}`}>
          <label>
            <input
              {...fieldProps}
              disabled={fieldProps.disabled}
              className="m-1 w-min"
              type="checkbox"
              value={option}
              onChange={handleInputChange(option)}
              onBlur={undefined}
              checked={selectedOptions.includes(option)}
            />
            {option}
          </label>
        </div>
      ))}
    </div>
  );
}
