import React, { useState,useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import Modal from '../../fragments/Modal';
import Spinner from '../../fragments/Spinner';
import toaster from 'utils/toast';
import fetch from 'utils/fetch';
import { SEND_OTP, VERIFY_OTP } from 'constants/app';
import moment from 'moment';

type Tprops ={
  getValues:Function;
  defaultValue:number | string,
  type:"phone" | "email"
}

const Otp = (props:Tprops) => {
  const {getValues,defaultValue,type="phone"} = props;
  const [verfiedType,setVerfiedType] = useState<number>(getValues(type ==="email"?"verified_email" :"verified_phone"));
  const [isOtpVerfied,setIsOtpVerfied] = useState<number>(getValues(type ==="email"?"is_email_otp_verified":"is_phone_otp_verified"));
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [otp, setOTP] = useState<string>('');
  const [otpRequested, setOtpRequested] = useState<boolean>(false);
  const [timerCount, setTimerCount] = useState<number>(300);
  
  const typeValue = getValues(type ==="email"? "iemail" :"phone");
  const typeVerified = isOtpVerfied ? typeValue === verfiedType : false;

  const CountDownTimer = ()=> {
    const formatted = moment.utc(timerCount*1000).format('mm:ss');
    useEffect(() => {
      let timer: any;
      if (timerCount > 0)
          timer = setTimeout(() => {
            setTimerCount(prev => prev - 1);
        }, 1000);
      else if (timer <= 0) setOtpRequested(false);
  
      return () => {
        if (timer) clearTimeout(timer);
      };
    }, [timerCount]);
    
    return ( 
    <div className="absolute right-8 text-base top-8 bg-red-400 w-16 h-16 rounded-full flex items-center justify-center text-white">
      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
      {formatted}
    </div>
    );
  };
  
  const handleChange = (otp: string) => {
    setOTP(otp);
  };
  const closeModal = ()=> {
    setOtpRequested(false);
    setSubmitting(false);
    setIsOpen(false);
  };

  const sendOTP = async () => {
    setSubmitting(true);
    const body = {
      [type ==="email" ? "email" : "phone"]:typeValue
    };
    try {
      const resp = await fetch(SEND_OTP, {
        method: 'POST',
        credentials: 'header',
        body:JSON.stringify(body),
        headers: { 'content-type': 'application/json' },
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      toaster.success('OTP Sent');
      setOtpRequested(true);
      setTimerCount(300);
    } catch (e: any) {
      toaster.error(e.message);
    } finally {
      setSubmitting(false);
    }
  };
  
  const verifyOTP = async () => {
    setSubmitting(true);
    const body = {
      [type ==="email" ? "email" : "phone"]:typeValue,
      otp:otp,
    };

    try {
      const resp = await fetch(VERIFY_OTP, {
        method: 'POST',
        credentials: 'header',
        body: JSON.stringify(body),
        headers: { 'content-type': 'application/json' },
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      toaster.success(`Your ${type === "email" ?"Institution Email Id":"Phone Number"} is been successfully verified`);

      setIsOtpVerfied(respJSON[type === "email" ?"is_email_otp_verified":"is_phone_otp_verified"]);

      setVerfiedType(respJSON[type === "email" ?"verified_email":"verified_phone"]);

      setIsOpen(false);
    } catch (e: any) {
      toaster.error(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="grid grid-cols-2">
      <div className="col-span-1 justify-center place-self-center">
      {  
        typeVerified ?
        <FaCheckCircle className="text-green-500 text-3xl" data-tip="Verified"/> :
        <FaExclamationTriangle className="text-yellow-500 text-3xl" />
      }
      </div>
      <div className="col-span-1 justify-center place-self-center">
        { typeVerified ?
          (<span
          className=" text-base xsm:text-lg mb-0 cursor-default"
          >
            Verified
          </span>) : (<span
          className=" text-base xsm:text-lg mb-0 cursor-pointer"
          onClick={() => {
            if(!typeValue){
              toaster.error(`Invalid ${type === "email" ?"Institution Email Id":"Phone Number"}. Please ReEnter your ${type === "email" ?"Institution Email Id":"Phone Number"}`);
              return;
            }
            if(typeValue !== defaultValue){
              toaster.error(`Please Save the updated ${type === "email" ?"Institution Email Id":"Phone Number"}`);
              return;
            }
            setIsOpen(true);
          }}
        >
          Verify
        </span>) 
        }
      </div>
      <Modal
        isOpen={isOpen}
        close={closeModal}
      >
        <div>
          <div>
            <p className="text-3xl text-center">Enter OTP</p>
          </div>
          <div className="h-44 w-full text-lg">
            {otpRequested && <CountDownTimer /> }
            <OtpInput
              value={otp}
              onChange={(otp: any) => {
                handleChange(otp);
              }}
              placeholder="XXXX"
              containerStyle="h-44 justify-center"
              numInputs={4}
              inputStyle="w-28"
              separator={<span>-</span>}
              isDisabled={!otpRequested}
            />
          </div>
          <p className="text-center text-sm">
            Click on Send OTP to get OTP on you {type==="email"?"Email Id":"Mobile"} 
            {otpRequested &&
             <span 
              className="font-medium text-base ml-3 text-brand cursor-pointer"
              onClick={sendOTP}
              >
              Resend OTP
             </span>
            }  
          </p>
          <div className="my-3 flex items-center justify-center mt-6">
            <button
              type="button"
              className="button secondary"
              onClick={closeModal}
            >
              {'Cancel'}
            </button>
            {
              otpRequested ?
              (<button
                type="submit"
                className="button ml-3 w-20 h-9 inline-flex items-center justify-center relative"
                onClick={verifyOTP}
              >
                {submitting ? <Spinner color="bg-white" size="tiny" /> : "Verify"}
              </button>) :
              (<button
              type="submit"
              className="button ml-3 w-20 h-9 inline-flex items-center justify-center relative"
              onClick={sendOTP}
            >
              {submitting ? <Spinner color="bg-white" size="tiny" /> : "Send OTP"}
            </button>)
            }
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Otp;