export const isNotDev = process.env.REACT_APP_ENV !== 'development';
const DOMAIN_URL = isNotDev
  ? (() => {
      // assuming we'll be running content and course servers as subdomains
      const { host } = window.location;
      const hostComponents = host.split('.');
      hostComponents.shift();
      return hostComponents.join('.');
    })()
  : 'dev.univ.ai';
const PROTOCOL = isNotDev ? 'https' : 'http';

export const APPLICATION_SITE_URL = `${PROTOCOL}://application.${DOMAIN_URL}`;
export const AUTH_URL = `${PROTOCOL}://user.${DOMAIN_URL}`;
export const API_URL = `${PROTOCOL}://course.${DOMAIN_URL}/api`;

export const USER_COURSE_TIMETABLE_URL = `${API_URL}/user/course-timetable`;
export const USER_COURSES_URL = `${API_URL}/user/courses`;
export const USER_EVENT_REGISTER_URL = `${API_URL}/meetup/register`;
export const GET_MEETUP_URL = `${API_URL}/meetup/events`;
export const GET_COURSE_URL = `${API_URL}/user/courses`;
export const GET_PROGRAM_URL = `${API_URL}/user/programs`;
export const GET_PROGRAM_INFO_URL = `${API_URL}/user/program?programId=`;
export const GET_USER_SUBSCRIPTION_PLAN = `${API_URL}/meetup/user-subscription-plan`;
export const GET_SUBSCRIPTION_PLAN_URL = `${API_URL}/meetup/subscription-plans`;
export const USER_CANCLE_SUBSCRIPTION_PLAN_URL = `${API_URL}/meetup/cancel-subscription-plan`;
export const GET_WORKSHOP_SUB_CATEGORIES = `${API_URL}/setting?key=MEETUP_CATEGORIES`;
export const GET_REFERRAL = `${API_URL}/referral`;
export const APPLY_REFERRAL = `${API_URL}/referral/apply`;
export const GET_REFERRAL_REWARDS = `${API_URL}/referral/rewards`;
export const GET_MEETUP_DETAIL = `${API_URL}/meetup/detail`;
export const GET_JOB = `${API_URL}/jobs`;
export const GET_JOB_DETAIL = `${API_URL}/jobs-detail`;
export const GET_JOB_APPLICATION = `${API_URL}/jobs-application`;
export const GET_JOB_APPLICATION_PROCESS = `${API_URL}/job-application-process`;
export const GET_JOB_QUESTIONNAIRE = `${API_URL}/job-questionnaire`;
export const END_JOB_TEST_URL = `${API_URL}/job-end-test`;

export const COURSE_API_URL = `${PROTOCOL}://course.${DOMAIN_URL}/api`;
export const UAS_API_URL = `${PROTOCOL}://agentapi.${DOMAIN_URL}`;
export const USER_URL = `${PROTOCOL}://user.${DOMAIN_URL}`;

export const getLoginURL = (redirectURL?: string) => `${APPLICATION_SITE_URL}/login?from=${redirectURL}`;
export const getRegistrationURL = (redirectURL?: string) => `${APPLICATION_SITE_URL}/register?from=${redirectURL}`;
export const getLogoutURL = (redirectURL?: string) =>
  `${USER_URL}/logout${redirectURL ? `?redirect=${redirectURL}` : ''}`;
export const getAuthRefreshURL = (fromURL: string) => `${USER_URL}/jwt/refresh?from=${fromURL}`;
export const SEND_OTP = `${USER_URL}/send-otp`;
export const VERIFY_OTP = `${USER_URL}/verify-otp`;
export const COUNTRY_CODE_URL = `${COURSE_API_URL}/country-code`;
export const VERIFY_CODE = `${UAS_API_URL}/verify-code`;
export const GET_JOB_PROFILE_STATS = `${USER_URL}/profile/stats`;
export const COMPLETE_JOB_PROFILE_URL = `${USER_URL}/profile-complete`;

export const DASHBOARD_PATH = '/dashboard';
export const MY_CLASSES_PATH = '/classes';
export const SUMMARY_PATH = '/summary';
export const ALL_MEETUPS_PATH = '/techx';
export const UNREGISTERED_MEETUPS_PATH = `${ALL_MEETUPS_PATH}/upcoming`;
export const MY_MEETUPS_PATH = `${ALL_MEETUPS_PATH}/my`;
export const MY_USERDATA_PATH = `/dashboard`;
export const MY_PROGRAMSDATA_PATH = `${MY_USERDATA_PATH}/myunivprograms`;
export const MY_COURSESDATA_PATH = `${MY_USERDATA_PATH}/myunivcourses`;
export const MY_TECHX_PROGRAMSDATA_PATH = `${MY_USERDATA_PATH}/mytechxprograms`;
export const MY_TECHX_COURSESDATA_PATH = `${MY_USERDATA_PATH}/mytechxcourses`;
export const MY_EVENTSDATA_PATH = `${MY_USERDATA_PATH}/myevents`;
export const MY_PASTEVENTDATA_PATH = `${MY_USERDATA_PATH}/mypast`;
export const MY_UPCOMINGEVENTDATA_PATH = `${MY_USERDATA_PATH}/myupcoming`;
export const MY_COMMUNITY_PROGRAMSDATA_PATH = `${MY_USERDATA_PATH}/mycommunityprograms`;
export const MY_COMMUNITY_COURSESDATA_PATH = `${MY_USERDATA_PATH}/mycommunitycourses`;
export const MY_COMMUNITY_PASTEVENTDATA_PATH = `${MY_USERDATA_PATH}/mycommunitypast`;
export const MY_SCHEDULE_PATH = '/schedule';
export const TECHX_PATH = '/techx';
export const MY_ATTENDANCE_PATH = '/attendance';
export const MY_EXERCISE_PATH = '/exercise';
export const MY_HACKATHONS_PATH = '/hackathons';
export const MY_ASSIGNMENTS_PATH = '/assignments';
export const MY_COURSES_PATH = '/courses';
export const MY_APPLICATIONS_PATH = `${APPLICATION_SITE_URL}/applications/latest`;
export const MY_CERTIFICATES_PATH = `${APPLICATION_SITE_URL}/certificates`;
export const JOBS_PATH = '/jobs';
export const JOB_APPLICATION_PATH = '/myjobapplication';
export const MY_PROFILE_PATH = '/profile';
export const MY_SUBSCRIPTION_PATH = '/subscription';
export const MY_STUFF_PATH = '/hub';
export const REFERRAL_PATH = '/referral';
export const MEETUP_DETAIL_PATH = '/techx';
export const COMMUNITY_DETAIL_PATH = '/community';
export const COMMUNITY_PATH = '/community';
export const MY_APPLICATIONS_PRODUCTS_PATH = `${APPLICATION_SITE_URL}/applications/products`;
export const LEARN_UNIV_PATH = `${PROTOCOL}://learn.${DOMAIN_URL}`;
export const HACKATHON_UNIV_PATH = `${PROTOCOL}://hack.${DOMAIN_URL}`;
export const UNIV_COURSES_PATH = `${PROTOCOL}://learn.${DOMAIN_URL}/courses`;

export const APPLICATIONS_URL = `${PROTOCOL}://apply.${DOMAIN_URL}`;
export const JOBS_URL = `${PROTOCOL}://jobs.${DOMAIN_URL}`;
export const CONTACT_US_URL = 'https://www.univ.ai/contact-us';
export const STORIES_URL = 'https://stories.univ.ai/';
export const COURSE_URL = `${PROTOCOL}://course.${DOMAIN_URL}`;
export const COMMUNITY_COURSES_URL = 'https://community.univ.ai';
export const PRO_COURSES_URL = 'https://welcome.univ.ai';
export const CERTIFICATES_URL = `${APPLICATIONS_URL}/certificates`;
export const DISCOURSE_URL = 'https://discourse.univ.ai';
export const DISCORD_URL = 'https://discord.gg/gHjbpHWaSw';
export const TECH_SUPPORT_URL = 'https://discourse.univ.ai/c/site-feedback/3';
export const HACKATHON_URL = `${PROTOCOL}://hack.${DOMAIN_URL}`;
export const PROFILE_URL = `${USER_URL}/profile`;

export const GHF_ROLE = 'ghf';
export const DEFAULT_PROFILE_AVATAR_IMG = 'https://nyc3.digitaloceanspaces.com/static-ai/techx/public/default-pp.jpeg';
export const DEFAULT_CARD_IMG = 'https://nyc3.digitaloceanspaces.com/static-ai/univai/public/my/regression.jpg';
export const REWARD_REF_IMG = `https://static-ai.nyc3.digitaloceanspaces.com/public/refReward.png`;
export const REFERRAL_SUCCESS_IMG = `https://static-ai.nyc3.digitaloceanspaces.com/techx/public/Frame_15.svg`;
export const REFERRAL_FAILURE_IMG = `https://static-ai.nyc3.digitaloceanspaces.com/techx/public/Frame_16.svg`;
export const JOBS_PAGE_IMG = `https://static-ai.nyc3.digitaloceanspaces.com/techx/public/jobsPage.svg`;

export const REFERRAL_WORKING_STEP_1 = `https://nyc3.digitaloceanspaces.com/static-ai/techx/public/banner/how-it-works/Step1.png`;
export const REFERRAL_WORKING_STEP_2 = `https://nyc3.digitaloceanspaces.com/static-ai/techx/public/banner/how-it-works/Step2.png`;
export const REFERRAL_WORKING_STEP_3 = `https://nyc3.digitaloceanspaces.com/static-ai/techx/public/banner/how-it-works/Step3.png`;
export const REFERRAL_WORKING_STEP_4 = `https://nyc3.digitaloceanspaces.com/static-ai/techx/public/banner/how-it-works/Step4.png`;
// PAYMENT

export const APP_BASEPATH = isNotDev ? process.env.PUBLIC_URL : window.location.origin;
export const APP_BASENAME = isNotDev ? process.env.REACT_APP_BASENAME : '';

export const HELP_PATH = '/help';
export const REGISTRATION_PATH = '/register';
export const LOGIN_PATH = '/login';

export const PAYMENT_PATH = '/payment';
export const PAYMENT_SUCCESS_PATH = `${PAYMENT_PATH}/success`;
export const USER_APPLICATION_PATH = '/applications';
export const USER_ACTIVE_APPLICATION_PATH = '/applications/latest';
export const USER_APPLICATION_SUCCESS_PATH = `${USER_APPLICATION_PATH}/success`;

export const PAYMENT_RATE_URL = `${COURSE_API_URL}/payment`;
export const STRIPE_PAYMENT_URL = `${COURSE_API_URL}/payment/stripe`;
export const ZEST_PAYMENT_URL = `${COURSE_API_URL}/payment/zest`;
export const RAZOR_PAYMENT_URL = `${COURSE_API_URL}/payment/razor`;
export const SUBSCRIPTION_PAYMENT_URL = `${COURSE_API_URL}/payment/subscription`;
export const RECURRING_PAYMENT_URL = `${COURSE_API_URL}/payment/recurring`;
export const GET_SUBSCRIPTION_RATE = `${COURSE_API_URL}/payment/subscription-plan-rate`;
export const INIT_PAYMENT_SUBSCRIPTION = `${COURSE_API_URL}/payment/subscription-plan-init`;
export const GET_GLOBAL_SLOTS = `${COURSE_API_URL}/meetup/global-slots`;

// export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const STRIPE_KEY = 'temp_key';
export const FINANCING_MINIMUM_VALUE = 100000;
export const STRIPE = 'Stripe';
// export const ZEST = 'ZestMoney';
export const RAZOR = 'RazorPay';
export const RAZORSUB = 'RazorPay Subscription';
export const RAZORSUBBILLED = 'RazorPay Billed';
export const RAZORSUBFAKE = 'RazorPay Recurring';
export const ISA = 'Income Sharing Agreement';

//feedback API
export const FEEDBACK_API = `${COURSE_API_URL}/feedback`;

//proctor
export const EXERCISE_API_URL = `${PROTOCOL}://api.exercise.${DOMAIN_URL}`;
