import React, { Suspense, useEffect } from 'react';
import { useState } from 'react';
import fetch from 'utils/fetch';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  COMPLETE_JOB_PROFILE_URL,
  GET_JOB_APPLICATION,
  GET_JOB_APPLICATION_PROCESS,
  GET_JOB_PROFILE_STATS,
  GET_JOB_QUESTIONNAIRE,
  END_JOB_TEST_URL,
} from '../../constants/app';
import { TCustomJobApplicationFields, TFormFields, TJobStatus, TProcessedFormFieldList } from 'types';

import {
  FormCheck,
  FormComponentHolder,
  FormDate,
  FormHolder,
  FormInput,
  FormRadio,
  FormSelect,
  FormSwitch,
  FormTextArea,
  FormUpload,
  preProcessForm,
} from 'components/Form';
import { FaSpinner } from 'react-icons/fa';
import JobStageStepper from './JobStageStepper';
import { TApplicationProcess, TApplicationProcessResult, TProfileStatsData, TResultProfileStats } from './types';
import JobStartApplication from './JobStartApplication';
import { captureException } from '@sentry/react';
import Error from 'components/Error';
import Spinner from 'fragments/Spinner';
import useFields from 'components/Profile/Fields';

const ProfilePage = React.lazy(() => import('components/Profile'));
const ExerciseWrapper = React.lazy(() => import('./ExerciseWrapper'));
import {} from '@univ-ai/proctor';
import InfoBox from './InfoBox';

type TJobApplicationPageProp = {
  position: string;
  company_name: string;
  job_id: number;
  // applicationFields?: TCustomJobApplicationFields[];
  profileFields?: string[];
  defaultValues?: { [key: string]: any };
  close: () => void;
  setApplyStatus: React.Dispatch<React.SetStateAction<TJobStatus | null>>;
};
const section = (text?: string) => {
  return (
    <div className="relative py-4">
      <div className="absolute inset-0 flex items-center">
        <div className="w-full border-b border-gray-300"></div>
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-4 text-sm text-gray-500">{text}</span>
      </div>
    </div>
  );
};
const JobApplicationPage = ({
  position,
  company_name,
  job_id,
  close,
  setApplyStatus,
  defaultValues,
}: TJobApplicationPageProp) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [profileCompletion, setProfileCompletion] = useState<number>(0);
  const [applicationID, setApplicationID] = useState<number | null>(null);
  const [applicationProcess, setApplicationProcess] = useState<TApplicationProcess[] | null>();
  const [lastEvent, setLastEvent] = useState<string | null>();
  const [hasApplication, setHasApplication] = useState<boolean>(false);
  const { control, register, handleSubmit, getValues, setValue, watch, formState, reset } = useForm({ defaultValues });
  const { errors, isDirty } = formState;
  const formValues = watch();
  const [currentStage, setCurrentStage] = useState<number>(-1);
  const [applicationFields, setApplicationFields] = useState<TCustomJobApplicationFields[]>();
  const [testStarted, setTestStarted] = useState<boolean>(false);

  const fields = useFields();

  const getProfileStats = async () => {
    try {
      const resp = await fetch(GET_JOB_PROFILE_STATS, {
        credentials: 'header',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      const respJSON = await resp.json();

      if (!respJSON.success) throw Error(respJSON.error);
      const processResult: TProfileStatsData = respJSON.data;
      setProfileCompletion(+processResult.profileCompletionPercent);
      return processResult.profileCompletionPercent;
    } catch (err) {
      captureException(err);
    }
  };

  const createJobApplication = async () => {
    const jobApplicationBody = {
      data: {
        job_id: job_id,
      },
    };
    try {
      const resp = await fetch(GET_JOB_APPLICATION, {
        credentials: 'header',
        body: JSON.stringify(jobApplicationBody),
        method: 'POST',
        headers: { 'content-type': 'application/json' },
      });

      const respJSON = await resp.json();
      if (!respJSON.success) throw Error(respJSON.error);
      setApplicationID(+respJSON.data.id);
      setHasApplication(true);
      setCurrentStage(0);
    } catch (e: any) {
      toast.error(e.message);
    }

    close();
  };
  const processForm = async (processedData: { [key: string]: any }) => {
    const FormFields = Object.fromEntries(Object.entries(processedData).filter(([key]) => !key.includes('section')));
    const params = {
      data: FormFields,
      jobApplicationId: applicationID,
      event: applicationProcess && applicationProcess[currentStage].event,
    };
    try {
      const resp = await fetch(GET_JOB_QUESTIONNAIRE, {
        credentials: 'header',
        body: JSON.stringify(params),
        method: 'POST',
        headers: { 'content-type': 'application/json' },
      });

      const respJSON = await resp.json();
      if (!respJSON.success) throw Error(respJSON.error);
      toast.success('Application Submitted ');
      setCurrentStage(prev => prev + 1);
    } catch (e: any) {
      toast.error(e.message);
    }
  };
  const startApplication = () => {
    createJobApplication();
  };
  const getApplicationProcess = async () => {
    try {
      const resp = await fetch(GET_JOB_APPLICATION_PROCESS + `?jobId=${job_id}`, {
        credentials: 'header',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw Error(respJSON.error);
      const processResult: TApplicationProcessResult = respJSON.data;
      if (!processResult.application_process) setApplicationProcess(null);
      setApplicationProcess(processResult.application_process);
      setLastEvent(processResult.last_event);
      setApplicationID(processResult.job_application_id || 0);
      if (processResult.has_application) setHasApplication(true);
    } catch (err) {
      captureException(err);
    }
  };

  useEffect(() => {
    getApplicationProcess();
  }, []);
  useEffect(() => {
    getCurrentStage();
  }, [lastEvent, applicationProcess]);

  useEffect(() => {
    if (applicationProcess && applicationProcess[currentStage].resource == 'questionnaire')
      getQuestionnaire(applicationProcess[currentStage].resource_id);
  }, [currentStage]);

  const getCurrentStage = () => {
    if (!lastEvent || !applicationProcess) return setCurrentStage(-1);
    let currentIndex = applicationProcess?.findIndex(stage => stage.event == lastEvent);

    if (lastEvent && lastEvent === 'job_application_created') currentIndex = 0;
    else currentIndex += 1;

    if (currentIndex != undefined) setCurrentStage(currentIndex);
  };

  const checkProfileProgress = async (val: { [key: string]: string }) => {
    await getProfileStats();
    if (profileCompletion >= 100) {
      const params = {
        data: {
          jobId: +job_id,
        },
      };
      try {
        const resp = await fetch(COMPLETE_JOB_PROFILE_URL, {
          credentials: 'header',
          body: JSON.stringify(params),
          method: 'POST',
          headers: { 'content-type': 'application/json' },
        });

        const respJSON = await resp.json();
        if (!respJSON.success) throw Error(respJSON.error);
        setCurrentStage(prev => prev + 1);
      } catch (e: any) {
        toast.error(e.message);
      }
    }
  };

  const getQuestionnaire = async (qId: number) => {
    try {
      const resp = await fetch(GET_JOB_QUESTIONNAIRE + '?qId=' + qId + '&jobId=' + job_id, {
        credentials: 'header',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      const respJSON = await resp.json();

      if (!respJSON.success) throw Error(respJSON.error);
      const data = respJSON.data;

      setApplicationFields(JSON.parse(data.questions));
    } catch (err) {
      captureException(err);
    }
  };

  const questionnaire = () => {
    if (applicationFields)
      return (
        <div>
          {/* <DynamicForm closeModal={close} eventName="asd" formFields={applicationFields as TFormFields[]}></DynamicForm> */}
          <FormHolder
            isDirty={isDirty}
            onSubmit={handleSubmit(processForm)}
            className={'mx-auto mt-4 sm:mt-8 2xl:px-12 w-1/2'}
          >
            {preProcessForm(applicationFields as TFormFields[])?.map(field => {
              const fc = {
                ...field,
                errors:
                  Object?.keys(errors)?.length > 0
                    ? {
                        [`${field.name}`]: errors[`${field.name}`]?.message
                          ? [errors[`${field.name}`]?.message]
                          : undefined,
                      }
                    : undefined,
                error: errors[`${field.name}`] && errors[`${field.name}`].message,
                defaultValue: defaultValues ? defaultValues[field.name] : '',
                fieldProps: register(field.name, field.props.constraints),
                refGenerator: register,
                setValue,
                control,
                getValues,
                formValues,
                required: field.required,
              };

              if (field.type == 'text') return <FormComponentHolder FormComponent={FormInput} {...fc} />;
              if (field.type == 'radio') return <FormComponentHolder FormComponent={FormRadio} {...fc} />;
              if (field.type == 'dropdown') return <FormComponentHolder FormComponent={FormSelect} {...fc} />;
              if (field.type == 'date') return <FormComponentHolder FormComponent={FormDate} {...fc} />;
              if (field.type == 'switch') return <FormComponentHolder FormComponent={FormSwitch} {...fc} />;
              if (field.type == 'check') return <FormComponentHolder FormComponent={FormCheck} {...fc} />;
              if (field.type == 'textarea') return <FormComponentHolder FormComponent={FormTextArea} {...fc} />;
              if (field.type == 'textarea') return <FormComponentHolder FormComponent={FormTextArea} {...fc} />;
              if (field.type == 'file') return <FormComponentHolder FormComponent={FormUpload} {...fc} />;
              if (field.type == 'section') return section(field.label);
            })}

            <div className="flex flex-row items-center justify-center gap-4 my-2">
              <button type="submit" className="bg-text-blue 3xl:text-xl">
                {submitting ? <FaSpinner size={'tiny'}></FaSpinner> : 'Submit'}
              </button>
              <button className="bg-text-blue 3xl:text-xl" onClick={close}>
                Cancel
              </button>
            </div>
          </FormHolder>
        </div>
      );
  };
  const createEndTestEvent = async () => {
    const jobApplicationBody = {
      data: {
        applicationID,
        resourceId: applicationProcess && applicationProcess[currentStage].resource_id,
        resource: 'test',
        event: applicationProcess && applicationProcess[currentStage].event,
      },
    };
    try {
      const resp = await fetch(END_JOB_TEST_URL, {
        credentials: 'header',
        body: JSON.stringify(jobApplicationBody),
        method: 'POST',
        headers: { 'content-type': 'application/json' },
      });

      const respJSON = await resp.json();

      setCurrentStage(prev => prev + 1);
    } catch (e: any) {
      toast.error(e.message);
    }
  };
  const preTestDisplay = () => {
    return (
      <div className="flex flex-col items-center my-2">
        <span>{applicationProcess && applicationProcess[currentStage].description}</span>

        <div>
          <button
            className="btn btn-primary mt-4"
            onClick={() => {
              setTestStarted(true);
            }}
          >
            Start Test
          </button>
        </div>
      </div>
    );
  };
  const ProgressBar = (progressPercentage: number) => {
    return (
      <div className="relative my-4 flex h-5 w-full items-center rounded-md bg-greybg">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={`absolute top-0 z-10 h-full rounded-md text-white  ${
            progressPercentage < 100 ? 'bg-indigo-600' : 'bg-green-600'
          }`}
        />
        <span className="relative left-4 z-20 font-semibold text-white">{progressPercentage} %</span>
      </div>
    );
  };

  const getCurrentStageElements = () => {
    if (!applicationProcess) return <p>No Application process . </p>;
    if (!hasApplication)
      return (
        <JobStartApplication
          displayText={
            'To Apply for the Job please complete all the above steps. Click on Start Application to continue'
          }
          startApplicationHandler={() => {
            startApplication();
          }}
        />
      );

    const currentStageProcess = applicationProcess[currentStage];
    if (!currentStageProcess) return <Spinner></Spinner>;

    if (currentStageProcess.event === 'profile_complete') {
      getProfileStats();
      return (
        <div>
          <div className="flex flex-col items-center font-switzer font-semibold text-center text-grey-400 text-base md:text-lg">
            {profileCompletion >= 100 ? (
              <span> Review & Save profile to continue to next step</span>
            ) : (
              <span>{currentStageProcess.description}</span>
            )}
            {ProgressBar(profileCompletion)}
          </div>
          <Suspense
            fallback={
              <div>
                Loading... <Spinner></Spinner>
              </div>
            }
          >
            <ProfilePage
              pTab="Background"
              onSubmit={(val: any) => {
                checkProfileProgress(val);
              }}
            ></ProfilePage>
          </Suspense>
        </div>
      );
    }

    if (currentStageProcess.resource === 'questionnaire') return questionnaire();
    if (currentStageProcess.event === 'interview') return <InfoBox message={currentStageProcess.description}></InfoBox>;
    if (currentStageProcess.event === 'approval') return <InfoBox message={currentStageProcess.description}></InfoBox>;
    if (currentStageProcess.resource === 'test' && !testStarted) return preTestDisplay();
    if (currentStageProcess.resource === 'test' && testStarted)
      return (
        <div className="select-none ">
          <Suspense
            fallback={
              <div>
                Loading... <Spinner></Spinner>
              </div>
            }
          >
            <ExerciseWrapper
              onEndTest={(res: { success: boolean }) => {
                if (res.success)
                  //create end test event

                  createEndTestEvent();
              }}
              testId={currentStageProcess.resource_id}
            ></ExerciseWrapper>
          </Suspense>
        </div>
      );
  };

  return (
    <div className="my-2  overflow-auto">
      <h4 className="text-center text-xl 3xl:text-3xl text-text-blue font-bold mb-4">
        {position} at {company_name}
      </h4>
      {<JobStageStepper process={applicationProcess} currentStage={currentStage}></JobStageStepper>}
      {!applicationProcess && <Spinner></Spinner>}
      {applicationProcess && !isNaN(currentStage) && getCurrentStageElements()}

      {/* <ReactPaginate
        breakLabel="..."
        previousLabel="< Previous"
        nextLabel="Next >"
        pageCount={2}
        forcePage={1}
        onPageChange={pageChangeHandler}
        containerClassName={'my-4 h-10 w-full flex flex-row items-center justify-center gap-4 text-xl text-text-blue'}
        pageClassName={'bg-white py-1 px-2.5'}
        activeClassName={'bg-text-blue text-white'}
        previousClassName={'bg-white py-1 px-2.5'}
        nextClassName={'bg-white py-1 px-2.5'}
        disabledLinkClassName={`cursor-not-allowed`}
      ></ReactPaginate> */}
    </div>
  );
};

export default JobApplicationPage;
