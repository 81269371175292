import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaExclamationCircle, FaLongArrowAltRight } from 'react-icons/fa';

import Modal from 'fragments/Modal';
import fetch from 'utils/fetch';
import useStore from 'globalstate';
import { getAuthRefreshURL, getLoginURL, COURSE_API_URL } from 'constants/app';

const GHF_ENROL_URL = `${COURSE_API_URL}/enrol/ghf`;
const GHF_PAGE = 'https://univ.ai/ghf';

interface IProps {
  isOpen: boolean;
  close: () => void;
}

export const enrol = async (): Promise<boolean | string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const resp = await fetch(GHF_ENROL_URL, { credentials: 'header' });
      if (resp.status === 200) {
        const nonCnfrmd = localStorage.getItem('regEvntNonCnfrmd');
        if (nonCnfrmd) {
          localStorage.setItem('regEvntCnfrmd', nonCnfrmd);
          localStorage.removeItem('regEvntNonCnfrmd');
        }
        await window.location.replace(getAuthRefreshURL(window.location.href));

        resolve(true);
      }

      const respJSON = await resp.json();
      reject(respJSON.error);
    } catch (e) {
      reject((e as Error).message);
    }
  });
};

export default function GHFRegistration(props: IProps) {
  const { isOpen, close } = props;

  const [State] = useStore();
  const { isAuthenticated, hasGHF } = State;
  const loginURL = getLoginURL(window.location.href);

  const [error, seterror] = useState<string>();
  const [showLearnMore, setshowLearnMore] = useState<boolean>(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setshowLearnMore(true);
  //   }, 30000);
  // }, []);

  const enrol = async () => {
    try {
      const resp = await fetch(GHF_ENROL_URL, { credentials: 'header' });
      if (resp.status === 200) {
        const nonCnfrmd = localStorage.getItem('regEvntNonCnfrmd');
        if (nonCnfrmd) {
          localStorage.setItem('regEvntCnfrmd', nonCnfrmd);
          localStorage.removeItem('regEvntNonCnfrmd');
        }
        return window.location.replace(getAuthRefreshURL(window.location.href));
      }

      const respJSON = await resp.json();
      seterror(respJSON.error);
    } catch (e) {
      seterror((e as Error).message);
    }
  };

  return (
    <Modal isOpen={isOpen} close={close} onTop={true}>
      <div className="font-space">
        <div className="flex flex-col items-center justify-center">
          {hasGHF ? (
            <div className="p-8 md:p-4">
              <div className="text-3xl tracking-normal">
                <p className="text-gray-500">Welcome to GHF Community !</p>
                <div className="mt-2 text-base font-sans">
                  You&apos;ve joined the GHF community!, where you&apos;ll find free and low cost workshops and courses.
                  Learn more &nbsp;
                </div>
                <div className="flex flex-col items-center justify-between sm:flex-row">
                  <a href={GHF_PAGE} className="button dark outline text-base sm:text-xl" target="_blank">
                    Read about GHF <FaLongArrowAltRight className="ml-1 inline" />
                  </a>
                  <Link to="/" className="button dark text-base sm:text-xl" onClick={close}>
                    Close <FaLongArrowAltRight className="ml-1 inline" />
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-8 md:p-4">
              <div className="text-3xl tracking-normal">
                <p className="text-gray-500">
                  {isAuthenticated ? 'Welcome to GHF Community ! ' : 'Login to your account to join the GHF community!'}
                  <div className="mt-2 text-base font-sans">
                    {/* GHF is the community side of Univ.AI. Our mission here is to unearth and unleash data science and AI
                    talent from all parts of the globe. You <b>LEARN</b> with free workshops and low cost, short
                    timeframe (free for 6 months, with no commitments) courses. You <b>HACK</b> by solving tons of
                    exercises, and by collaborating and competing on periodic hackathons on real world data. Finally -{' '}
                    <b> EARN</b>! We match you with great companies for outstanding opportunities. */}
                    You&apos;ve joined the GHF community!, where you&apos;ll find free and low cost workshops and
                    courses. Learn more &nbsp;
                    <a href={GHF_PAGE} className="text-base" target="_blank">
                      here
                    </a>
                  </div>
                </p>
              </div>
              <div className="mt-4">
                {!isAuthenticated ? (
                  <a href={loginURL} className="text-3xl float-right">
                    Login <FaLongArrowAltRight className="ml-4 inline" />
                  </a>
                ) : (
                  <div className="whitespace-nowrap text-xl">
                    {(() => {
                      return (
                        <React.Fragment>
                          <div className="flex flex-col items-center justify-between sm:flex-row">
                            {/* {showLearnMore ? (
                              <a href={GHF_PAGE} className="text-base sm:text-xl" target="_blank">
                                Read about GHF <FaLongArrowAltRight className="ml-1 inline" />
                              </a>
                            ) : (
                              <div />
                            )} */}
                            {/* <button onClick={enrol} className="text-xl button dark">
                              Join <FaLongArrowAltRight className="ml-4 inline" />
                            </button> */}
                            <button onClick={close} className="text-xl button dark">
                              Close <FaLongArrowAltRight className="ml-4 inline" />
                            </button>
                          </div>
                          {error && (
                            <div className="text-red-500">
                              <FaExclamationCircle className="inline mr-1 text-sm" />
                              {error}
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })()}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="absolute bottom-0 w-full flex items-center justify-between p-4">
          <div />
          <div className="text-white whitespace-nowrap"></div>
        </div>
      </div>
    </Modal>
  );
}
