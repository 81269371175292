import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import RegistrationModal from 'components/GHF/Modal';
import useStore from 'globalstate';
import { getCookieValue } from 'utils/cookies';
import { getAuthRefreshURL, getLoginURL, COUNTRY_CODE_URL } from 'constants/app';
import { GHF_ROLE } from 'constants/app';
import globalEmitter from 'utils/emitter';
import { PROFILE_URL } from '../../constants/app';
import fetch from 'utils/fetch';
import toaster from 'utils/toast';
import Spinner from 'fragments/Spinner';
import { useLocation } from 'react-router';
import { VERIFY_CODE } from '../../constants/app';

type AuthCookie = {
  aud: string;
  dashboard: string;
  tokens: string;
  exp: number;
  full: string;
  iat: number;
  iss: string;
  roles: string;
  sub: string;
  userId: number;
  username: string;
  name: string;
};

interface IProps {
  children: React.ReactNode;
}

export default function Preloader(props: IProps) {
  const [resettingToken, setResettingToken] = useState<boolean>(false);
  const [hasPreloaded, sethasPreloaded] = useState<boolean>(false);
  const [State, Actions] = useStore();
  const {isAuthenticated} = State;
  const eventEmitter = globalEmitter;
  const [profileDetails,setProfileDetails] = useState<any>();
  const [currentLocation, setCurrentLocation] = useState<string>("IN");
  const {href:fromCompleteURL} = window.location;
  const {search} =  useLocation<{pathname:string,search:string}>();

  const [isRegistrationModalOpen, setisRegistrationModalOpen] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);

  const resetCookie = async () => {
    setResettingToken(true);
    localStorage.setItem('lastReset', Date.now().toString());
    window.location.replace(getAuthRefreshURL(window.location.href));
  };

  const maybeResetCookie = () => {
    const maybeAuthCookie = getCookieValue('border-patrol-jwt');
    if (!maybeAuthCookie) return;

    const authInfo: AuthCookie = jwtDecode(maybeAuthCookie);
    const loginURL = getLoginURL(window.location.href);
    if (!authInfo.userId) return window.location.replace(loginURL);

    const lastResetRaw = localStorage.getItem('lastReset');
    if (!lastResetRaw) return resetCookie();

    const lastReset = parseInt(lastResetRaw);
    const oneMinute = 1000 * 60;
    if (lastReset < Date.now() + oneMinute) return;

    resetCookie();
  };

  const checkAuthentication = () => {
    const maybeAuthCookie = getCookieValue('border-patrol-jwt');
    if (!maybeAuthCookie) return Actions.setState({ userDetails: null, isAuthenticated: false, token: null });

    const authInfo: AuthCookie = jwtDecode(maybeAuthCookie);
    const userDetails = { ...authInfo };
    const cookieHasExpired = authInfo.exp > Date.now();
    if (cookieHasExpired) return resetCookie();

    const isAuthenticated = !!authInfo.username;
    const roles = authInfo.roles && authInfo.roles.split(',');
    const hasGHF = roles && roles.includes(GHF_ROLE);
    Actions.setState({ isAuthenticated, userDetails, hasGHF, token: maybeAuthCookie });
  };

  const areMandatoryFieldFill:(cCity:string,definesYou:string,phone:string, organization:string,college:string,is_phone_otp_verified:boolean,verified_phone:string,) => boolean = (cCity,definesYou,phone,organization,college,is_phone_otp_verified,verified_phone) => {
    if(!cCity || !definesYou || !phone)
      return false;
    if(currentLocation === "IN")
      if(!is_phone_otp_verified || phone !== verified_phone) 
        return false;
    if (definesYou === "working professional" && !organization)
      return false;   
    if (definesYou === "college" && !college)
      return false;   

    return true;
  };

  const getCurrentLocation = async () => {
    setloading(true);
    try {
          const response = await fetch(COUNTRY_CODE_URL, { credentials: 'header' }); 
          const respJSON = await response.json();
          if (response.status !== 200) 
            throw new Error('We are unable to connect to the server');
          
          const { countryCode } = respJSON;
          setCurrentLocation(countryCode);
    } catch (e) {
      toaster.error((e as Error).message);
    } finally {
      setloading(false);
    }
  };

  const getCurrentProfile = async () => {
    setloading(true);
    try {
      const response = await fetch(PROFILE_URL, { credentials: 'header' });
      if (response.status !== 200) {
        toaster.error('Please check your internet');
        throw new Error('We are unable to connect to the server');
      }
      const respJSON = await response.json();
      const { profile } = respJSON;
      setProfileDetails(profile);
    } catch (e) {
      toaster.error((e as Error).message);
    } finally{
      setloading(false);
    }
  };



  useEffect(() => {
    maybeResetCookie();
    checkAuthentication();
    sethasPreloaded(true);
    return () => {
      eventEmitter.removeAllListeners();
    };
  }, []);

  useEffect(()=>{
    if(isAuthenticated){
      getCurrentLocation();
      getCurrentProfile();
    }
  },[isAuthenticated]);

  eventEmitter.on('openGHFRegModal', (open: boolean) => {
    // if (!State.openRegistrationModal) return;
    if (open) setisRegistrationModalOpen(open);
  });

  // Agent Discount URL
  useEffect(()=>{
    const params = new URLSearchParams(search);
    // const agentDiscountParam = {
    //   userEmail:params.get('ue'),
    //   agentId:params.get('72'),
    //   couponCode:params.get('ac'),
    //   discountPercentage:params.get('d'),
    //   validity:params.get('v'),
    // } ;
    const couponCode = params.get('ac');
    
    if(!couponCode) {
      localStorage.removeItem('ad');
      return;
    }

    (async()=>{
        const body = {
          code:couponCode
        };
        try {
          const resp = await fetch(VERIFY_CODE, {
            credentials: 'header',
            body: JSON.stringify(body),
            method: 'POST',
            headers: { 'content-type': 'application/json' },
          });
          const respJSON = await resp.json();
          if (!respJSON.success) throw new Error(respJSON.error);
          const data = respJSON.data;
          localStorage.setItem("ad",JSON.stringify(data));
        } catch (e: any) {
          localStorage.removeItem('ad');
        };
    
    })();

  },[State.isAuthenticated]);

  // If form is been skipped
  const skip = getCookieValue("univ-skip-mandatoryform");
  let skipForm;
  if(skip)
    skipForm = JSON.parse(skip);

  if(isAuthenticated && profileDetails && !areMandatoryFieldFill(profileDetails?.cCity, profileDetails?.definesYou, profileDetails?.phone, profileDetails?.currentWork?.organization, profileDetails?.undergraduate?.college, profileDetails?.is_phone_otp_verified, profileDetails?.verified_phone)){
    if(currentLocation === "IN")   
      window.location.replace(getLoginURL(fromCompleteURL));
    else if (currentLocation !== "IN" && skipForm !== true)
      window.location.replace(getLoginURL(fromCompleteURL));  
  }

  else if (loading) { return (<Spinner space show />);}

  if (!hasPreloaded || resettingToken)
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <div className="h-screen w-screen flex items-center justify-center">
          <img src="logo.svg" className="h-8 w-auto" />
        </div>
      </div>
    );

  return (
    <React.Fragment>
      <RegistrationModal
        isOpen={isRegistrationModalOpen}
        close={() => {
          setisRegistrationModalOpen(false);
          Actions.setState({ openRegistrationModal: false });
        }}
      />
      {props.children}
    </React.Fragment>
  );
}
