import React, { useState, useEffect, useMemo } from 'react';
import {
  FaExclamationCircle,
  FaArrowCircleRight,
  FaFacebookSquare,
  FaWhatsappSquare,
  FaTwitterSquare,
  FaLinkedin,
  FaLink,
} from 'react-icons/fa';
import useStore from 'globalstate';
import fetch from 'utils/fetch';
import Spinner from 'fragments/Spinner';
import ErrorMsg from 'components/Error';
import { toast } from 'react-toastify';
import Modal from 'fragments/Modal';
import moment from 'moment';
import { APPLY_REFERRAL, GET_REFERRAL, GET_REFERRAL_REWARDS } from '../../constants/app';
import { ReferralModal } from './ReferralModal';
import ApplyModal from './ApplyModal';
import ReferralFlowModal from './ReferralFlowModal';

type TFormValues = {
  emailIds: string;
  emailMessage: string;
};

type TUserReferral = {
  id: number;
  referral_code: string;
  registration_count: number;
  payment_count: number;
  referral_applied: boolean;
};

export type TUserReferralReward = {
  reward_type: 'discount' | 'duration';
  reward_value: number;
  reward_id: string;
  is_redeemed: boolean;
  meta: {
    unit: 'percent' | 'days';
    symbol: '%' | 'Days';
  };
  awarded_on: Date;
};

export default function Referral() {
  const [State] = useStore();
  const { isAuthenticated } = State;
  const [error, setError] = useState<string>();
  const [loading, setloading] = useState<boolean>(false);
  const [rewardsModal, setRewardsModal] = useState<boolean>(false);
  const [applyReferralResponse, setApplyReferralResponse] = useState<{type:"success" | "error" | "",message:string}>({type:"",message:""});
  const [applyModal, setApplyModal] = useState<boolean>(false);
  const [referralFlowModal, setReferralFlowModal] = useState<boolean>(false);
  const [userReferral, setUserReferral] = useState<TUserReferral>();
  const [userReferralRewards, setUserReferralRewards] = useState<TUserReferralReward[]>([]);
  const [inputReferral, setInputReferral] = useState<string>('');

  const {
    userDetails: { username },
  } = State;
  const initialFormValues = {
    emailIds: '',
    emailMessage: '',
  };
  const [formValues, setFormValues] = useState<TFormValues>(initialFormValues);
  const [errorFormValues, setErrorFormValues] = useState<TFormValues>(initialFormValues);

  const REFERRAL_LINK = useMemo(
    () => `https://application.univ.ai/login?c=${userReferral?.referral_code}`,
    [userReferral]
  );

  const inputHandler = (event: any) => {
    const { name, value } = event.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const inputReferralhandler = (event: any) => setInputReferral(event.target.value);

  const openRewardsModal = () => setRewardsModal(true);

  const closeRewardsModal = () => setRewardsModal(false);

  const openReferralFlowModal = () => setReferralFlowModal(true);

  const closeReferralFlowModal = () => setReferralFlowModal(false);

  const openApplyModal = () => setApplyModal(true);

  const closeApplyModal = () => {
    setApplyModal(false);
    setInputReferral("");
    setApplyReferralResponse({
      type:"",
      message:"",
    });
  };

  const validateFormValues = () => {
    const error: TFormValues = {
      emailIds: '',
      emailMessage: '',
    };

    const regx = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
    if (formValues?.emailIds?.length === 0) error.emailIds = `Enter Email Id`;
    else if (!regx.test(formValues?.emailIds)) error.emailIds = `Enter a valid email address seperated by ' , ' `;

    if (formValues?.emailMessage?.length === 0) error.emailMessage = `Enter message with your referral code in it`;

    setErrorFormValues(error);

    if (JSON.stringify(initialFormValues) === JSON.stringify(error)) return true;
    return false;
  };

  const sendMailsHandler = () => {
    if (validateFormValues()) {
      const link =
        `mailto:${formValues.emailIds}` +
        '?cc=' +
        '&subject=' +
        encodeURIComponent('Univ.AI Referral Code') +
        '&body=' +
        encodeURIComponent(formValues.emailMessage);
      window.open(link, '_blank');
      setFormValues({
        emailIds: '',
        emailMessage: `I've started my journey towards landing great tech jobs with TechX.AI courses - you should too! Use my referral code ${userReferral?.referral_code} to get 20% off on your subscription. Join the coolest tech community today!`,
      });
    }
  };

  const getReferralData = async () => {
    setloading(true);
    try {
      const resp = await fetch(GET_REFERRAL, {
        credentials: 'header',
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const data: TUserReferral = await respJSON.data;
      setUserReferral(data);
      setFormValues({
        emailIds: '',
        emailMessage: `I've started my journey towards landing great tech jobs with TechX.AI courses - you should too! Use my referral code ${data?.referral_code} to get 20% off on your subscription. Join the coolest tech community today!`,
      });
    } catch (e: any) {
      setError(e.message);
    } finally {
      setloading(false);
    }
  };
  const getReferralRewardsData = async () => {
    setloading(true);
    try {
      const resp = await fetch(GET_REFERRAL_REWARDS, {
        credentials: 'header',
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const data: TUserReferralReward[] = await respJSON.data;
      setUserReferralRewards(data);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setloading(false);
    }
  };

  const applyReferralCode = async () => {
    if (inputReferral === '') return toast.warning('Please Enter Referral Code');

    const referralBody = {
      referralCode: inputReferral,
    };
    try {
      const resp = await fetch(APPLY_REFERRAL, {
        credentials: 'header',
        body: JSON.stringify(referralBody),
        method: 'POST',
        headers: { 'content-type': 'application/json' },
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const data = await respJSON.data;
      setApplyReferralResponse({type:"success",message:data});
      setInputReferral('');
    } catch (e: any) {
      setApplyReferralResponse({type:"error",message:e.message});
    } finally {
      setloading(false);
      openApplyModal();
    }
  };

  const shareOnTwitter = () => {
    const link =
      `https://twitter.com/intent` +
      '/tweet?text=' +
      encodeURIComponent('Univ.AI Referral Link') +
      '&url=' +
      encodeURIComponent(REFERRAL_LINK);

    window.open(link, '_blank');
  };

  const shareOnWhatsup = () => {
    const link =
      `https://wa.me/` + '?text=' + encodeURIComponent('Univ.AI Referral Link ') + encodeURIComponent(REFERRAL_LINK);

    window.open(link, '_blank');
  };
  const shareOnFacebook = () => {
    const link =
      `https://www.facebook.com/sharer/sharer.php?u=` + encodeURIComponent(REFERRAL_LINK) + '&description=' + encodeURIComponent('Univ.AI Referral Link ');

    window.open(link, '_blank');
  };
  const shareOnLinkedin = () => {
    const link =
      `https://www.linkedin.com/shareArticle?mini=true&url=` + encodeURIComponent(REFERRAL_LINK) + `&description=` +encodeURIComponent(REFERRAL_LINK) + `&source=LinkedIn`;

    window.open(link, '_blank');
  };
  const copyLink = () => {
    navigator.clipboard
      .writeText(REFERRAL_LINK)
      .then(() => toast.success('Link Copied to clipboard'))
      .catch(error => toast.warning(error));
  };

  const copyReferralCode = () => {
    if (userReferral?.referral_code)
      navigator.clipboard
        .writeText(userReferral.referral_code)
        .then(() => toast.success('Referral Code Copied to clipboard'))
        .catch(error => toast.warning(error));
  };

  useEffect(() => {
    if (isAuthenticated) {
      getReferralData();
      getReferralRewardsData();
    }
    // es-lint-disable-next-line
  }, []);

  return (
    <div>
      <h3 className="font-switzer text-text-blue font-normal text-sm h-8 3xl:text-base 3xl:h-10 flex items-center px-4">
        Referrals
      </h3>
      <div className="w-full flex flex-col md:flex-row md:gap-4 mt-8 px-4">
        {(() => {
          if (loading) return <Spinner space show />;
          else if (error) return <ErrorMsg embed={false} preset="broken" text={error} />;
          else
            return (
              <>
                <div className="w-full md:w-1/2">
                  <h2 className="font-switzer text-base xl:text-xl 3xl:text-2xl font-semibold text-text-grey cursor-default">
                    Hello,
                  </h2>
                  <h1 className="font-switzer text-5xl xl:text-6xl 3xl:text-7xl font-semibold text-text-dark uppercase xl:mt-2 3xl:mt-4 cursor-default">
                    {username}
                  </h1>
                  <h2 className="font-switzer text-base xl:text-xl 3xl:text-2xl font-semibold text-text-grey xl:mt-2 3xl:mt-4 cursor-default">
                    Your referral code is,
                  </h2>
                  <h1
                    className="font-switzer text-4xl xl:text-5xl 3xl:text-7xl font-semibold text-text-dark xl:mt-2 3xl:mt-4 cursor-pointer"
                    onClick={copyReferralCode}
                  >
                    {userReferral?.referral_code}
                  </h1>

                  <span 
                    className="pointer linked font-switzer text-base 3xl:text-xl inline-flex flex-row items-center mt-9 xl:mt-11 3xl:mt-24 cursor-pointer"
                    onClick={openReferralFlowModal}
                  >
                      <h2 className="pointer linked font-switzer text-sm xl:text-base 3xl:text-xl flex flex-row items-center">
                        How It Works ?
                      </h2>
                      <FaExclamationCircle className="self-center ml-2 text-base xsm:text-xl" />
                  </span>

                  <h2 className="font-switzer text-sm xl:text-base 3xl:text-2xl font-semibold text-text-grey w-8/12 cursor-default mt-6  3xl:mt-12">
                    For every 20 successful referrals you get 2 months free of TechX.AI. Please note: we update
                    referral count once every 24 hrs.
                  </h2>

                  <h2 className="font-switzer text-base xl:text-xl 3xl:text-2xl font-normal text-text-grey mt-3 3xl:mt-7 cursor-default">
                    Your Referral count: {userReferral?.registration_count}
                  </h2>

                  <div className="linked xsm:text-base flex flex-row items-center mr-auto mt-3 3xl:mt-9">
                    <span className="flex flex-row cursor-pointer" onClick={openRewardsModal}>
                      <h2 className="pointer linked font-switzer text-sm xl:text-base 3xl:text-xl flex flex-row items-center">
                        View Referral Rewards
                      </h2>
                      <FaArrowCircleRight className="self-center ml-2 text-base xsm:text-xl" />
                    </span>
                  </div>

                  <h2 className="font-switzer text-base xl:text-lg 3xl:text-2xl font-normal text-text-grey mt-10  xl:mt-14 3xl:mt-14 mb-2 3xl:mb-6">
                  {!userReferral?.referral_applied ? 'Enter your referral code here' : 'Referral code applied !'}
                  </h2>
                  <div className="flex flex-row w-64 xl:w-72 3xl:w-80">
                    <input
                      type="text"
                      placeholder="Enter Referral Code"
                      autoComplete="false"
                      disabled={userReferral?.referral_applied}
                      value={inputReferral}
                      onChange={inputReferralhandler}
                      className="font-switzer text-sm xl:text-base 3xl:text-xl text-text-grey tracking-wider disabled:opacity-50"
                    />
                    <button
                      className="bg-text-blue text-base lg:text-lg font-bold disabled:opacity-50"
                      disabled={userReferral?.referral_applied}
                      onClick={applyReferralCode}
                    >
                      Apply
                    </button>
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <h2 className="font-switzer text-base xl:text-xl 3xl:text-2xl font-semibold text-text-grey mt-10 md:mt-0">
                    Share your referral link
                  </h2>
                  <h2 className="font-switzer text-sm xl:text-xl 3xl:text-2xl font-normal text-text-grey my-4 3xl:my-6">
                    Invite people to sign up for the platform by entering in their emails. Your referral link and
                    program details shall be added automatically.
                  </h2>
                  <textarea
                    name="emailIds"
                    cols={30}
                    rows={3}
                    spellCheck={false}
                    className="text-xs xl:text-base 3xl:text-xl text-text-grey"
                    value={formValues.emailIds}
                    onChange={inputHandler}
                    placeholder="Add multiple emails separated by commas&#10;e.g:- abc@univ.com,efg@univ.com,hij@gmail.com"
                  />
                  <span className="text-xs lg:text-base text-red-400 font-semibold">
                    {errorFormValues.emailIds.length > 0 ? (
                      '* ' + errorFormValues.emailIds
                    ) : (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                  </span>

                  <h2 className="font-switzer text-sm xl:text-xl 3xl:text-2xl font-normal text-text-grey mt-4 3xl:mt-7 mb-3 3xl:mb-8">
                    Write a Message
                  </h2>

                  <textarea
                    name="emailMessage"
                    cols={30}
                    rows={6}
                    className="text-xs xl:text-base 3xl:text-xl text-text-grey"
                    value={formValues.emailMessage}
                    onChange={inputHandler}
                    placeholder={`I've started my journey towards landing great tech jobs with TechX.AI courses - you should too! Use my referral code ${userReferral?.referral_code} to get 20% off on your subscription. Join the coolest tech community today!`}
                  />
                  <span className="text-xs lg:text-base text-red-400 font-semibold">
                    {errorFormValues.emailMessage.length > 0 ? (
                      '* ' + errorFormValues.emailMessage
                    ) : (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                  </span>

                  <div className="linked xsm:text-base flex flex-row items-center mt-3 xl:mt-2 3xl:mt-10">
                    <span className="flex flex-row cursor-pointer" onClick={sendMailsHandler}>
                      <h2 className="pointer linked font-switzer text-sm xl:text-base 3xl:text-xl flex flex-row items-center">
                        Send Mail
                      </h2>
                      <FaArrowCircleRight className="self-center ml-2 text-base xsm:text-xl" />
                    </span>
                  </div>

                  <h2 className="font-switzer text-base xl:text-xl 3xl:text-2xl font-normal text-text-grey mt-8 xl:mt-4 3xl:mt-16 mb-2 3xl:mb-6">
                    Share your link on social media
                  </h2>
                  <div className="flex flex-row gap-2 lg:gap-4">
                    <FaFacebookSquare className="text-2xl cursor-pointer" onClick={shareOnFacebook} />
                    <FaWhatsappSquare className="text-2xl cursor-pointer" onClick={shareOnWhatsup} />
                    <FaTwitterSquare className="text-2xl cursor-pointer" onClick={shareOnTwitter} />
                    <FaLinkedin className="text-2xl cursor-pointer" onClick={shareOnLinkedin} />
                    <FaLink className="text-2xl cursor-pointer" onClick={copyLink} />
                  </div>
                </div>
                {userReferralRewards && (
                  <Modal isOpen={rewardsModal} size="lg" bgColor="bg-white" close={closeRewardsModal}>
                    <ReferralModal
                      userReferralRewards={userReferralRewards}
                      referralCount={userReferral?.registration_count ? userReferral?.registration_count : 0}
                    />
                  </Modal>
                )}
                {(applyModal && applyReferralResponse.type !== "") && (
                  <Modal isOpen={applyModal} bgColor="bg-white" close={closeApplyModal}>
                    <ApplyModal 
                      type={applyReferralResponse.type} 
                      message={applyReferralResponse.message}
                      onClose={closeApplyModal}
                    />
                  </Modal>
                )}
                <Modal isOpen={referralFlowModal} bgColor="bg-white" close={closeReferralFlowModal}>
                    <ReferralFlowModal/>
                </Modal>
              </>
            );
        })()}
      </div>
    </div>
  );
}
