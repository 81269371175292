/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { FaChevronLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { PRIMARY_APP } from 'constants/color';
import {
  getLogoutURL,
  APP_BASEPATH,
  LOGIN_PATH,
  HELP_PATH,
  REGISTRATION_PATH,
} from 'constants/app';

export type IError = {
  emotext?: any;
  emoji?: string;
  text?: string;
  extrablock?: any;
  preset?: string;
  noback?: boolean;
  embed?: boolean;
};

const presets: { [key: string]: any } = {
  broken: {
    emotext: 'Something is broken',
    emoji: '(╯°□°)╯︵ ┻━┻',
    text: (
      <p>
        Please let us know if this problem persists via our <Link to={HELP_PATH}>Help page</Link>
      </p>
    ),
  },
  nothing: {
    emotext: 'No results found',
    emoji: '（>﹏< )彡',
  },
  login: {
    emoji: '┃(・_┃',
    emotext: 'Login to see things here',
    text: 'Content on this page is based on what you do. Log in to do more!',
    noback: true,
    extrablock: (
      <div
        css={css`
          margin-top: 2rem;
          display: flex;
          justify-content: center;
        `}
      >
        <Link className="button is-outlined" to={`${LOGIN_PATH}?from=${window.location.href}`}>
          Login or Register
        </Link>
      </div>
    ),
  },
};

export default function (props: IError) {
  const { preset, embed = false } = props;
  let { emotext, emoji, text, extrablock, noback = false } = props;

  const history = useHistory();

  if (preset) {
    const maybePreset = presets[preset];
    if (maybePreset) {
      if (!emotext && maybePreset.emotext !== undefined) emotext = maybePreset.emotext;
      if (!emoji && maybePreset.emoji !== undefined) emoji = maybePreset.emoji;
      if (!text && maybePreset.text !== undefined) text = maybePreset.text;
      if (maybePreset.extrablock !== undefined) extrablock = maybePreset.extrablock;
      if (maybePreset.noback !== undefined) noback = maybePreset.noback;
    }
  }

  const loginPath = `${APP_BASEPATH}${LOGIN_PATH}?from=${window.location.href}`;
  const registrationPath = `${APP_BASEPATH}${REGISTRATION_PATH}?from=${window.location.href}`;
  const hasInvalidJWT = text && text.startsWith('Invalid authentication token');
  const hasPartialJWT = text && text.startsWith('Complete registration to proceed');

  return (
    <div
      css={css`
        ${embed
          ? 'margin: 6%; width: 66%; padding: 4rem 1rem;'
          : 'height: 90vh; width: 100vw; padding: 1rem;'}
        @media (max-width: 768px) {
          width: 100%;
        }
      `}
    >
      <div
        css={css`
          font-family: Space Grotesk;
          ${embed
            ? ''
            : 'position: absolute; left: 50%; top: 50%; transform: translate(-50%, -60%);'}
          @media (max-width: 768px) {
            width: 80%;
          }
        `}
      >
        <h4
          css={css`
            text-align: right;
          `}
        >
          {emoji}
        </h4>
        <h4
          css={css`
            color: ${PRIMARY_APP} !important;
          `}
        >
          {emotext}
        </h4>
        <div>{text}</div>
        {extrablock}
        {!hasPartialJWT && !hasInvalidJWT && !embed && !noback && (
          <div
            css={css`
              padding-top: 5vh;
              display: flex;
              justify-content: center;
            `}
          >
            <div onClick={history.goBack} className="button is-outlined">
              <FaChevronLeft />
              <span
                css={css`
                  margin-left: 0.5rem;
                `}
              >
                Go Back
              </span>
            </div>
          </div>
        )}
        {hasInvalidJWT && (
          <a className="button is-outlined" href={getLogoutURL(loginPath)}>
            Log in Again
          </a>
        )}
        {hasPartialJWT && (
          <a className="button is-outlined" href={registrationPath}>
            Complete Registration
          </a>
        )}
      </div>
    </div>
  );
}
