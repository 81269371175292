import React,{useState} from "react";
import { Carousel } from "react-responsive-carousel";
import { REFERRAL_WORKING_STEP_1, REFERRAL_WORKING_STEP_2, REFERRAL_WORKING_STEP_3, REFERRAL_WORKING_STEP_4 } from "constants/app";
import Spinner from "fragments/Spinner";

type TWorking = {
    imgsrc:string,
    text:string
}
const working:TWorking[] = [
    {
        imgsrc: REFERRAL_WORKING_STEP_1,
        text :"Share your Referral code with a friend!"
    },
    {
        imgsrc: REFERRAL_WORKING_STEP_2,
        text :"For every 20 successful referrals, you get two FREE months of our subscription plan."
    },
    {
        imgsrc: REFERRAL_WORKING_STEP_3,
        text :"Every time somebody registers onto our platform using your referral link, it is considered as a sucessful referral."
    },
    {
        imgsrc: REFERRAL_WORKING_STEP_4,
        text :"Your referee also receives a 20% discount."
    },
];
const ReferralFlowModal =()=>{
    const [isImgLoaded,setIsImageLoaded] = useState(false);
    
    const workingSlides = working.map((item:TWorking,idx:number) => (
    <div key={`referral-working-step-${idx}`} >
        {!isImgLoaded && 
        (<div className="h-20 relative">
            <Spinner space show />
        </div>)
        }
        <img src={item.imgsrc} className="my-4" alt="carousel"  onLoad={()=> setIsImageLoaded(true)}/>
        <p className="mb-4 text-left text-sm md:text-base xl:text-lg 3xl:text-2xl text-text-grey font-switzer">
            <span className="font-medium text-text-dark">Step {idx+1}: </span>
            {item.text}
        </p>
    </div>));


    return (
    <React.Fragment>
        <div className="flex flex-col justify-start items-start">
            <h1 className="text-text-dark font-switzer">How it works ?</h1>
            <div className="w-full">
            {workingSlides && 
            <Carousel
                className="w-full"
                infiniteLoop={true}
                showThumbs={false}
                emulateTouch={true}
                dynamicHeight={false}
                autoPlay={true}
                autoFocus={true}
                stopOnHover={true}
                showIndicators={true}
                showArrows={true}
                showStatus={false}
                >
                {workingSlides}
            </Carousel>
            }
            </div>
        </div>
    </React.Fragment>
    );
};

export default ReferralFlowModal;