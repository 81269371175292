import React from 'react';
import { useState } from 'react';
import fetch from 'utils/fetch';
import { toast } from 'react-toastify';
import { GET_JOB_APPLICATION } from '../../constants/app';
import { TJobStatus } from 'types';

type TJobApplicationModalProp = {
    position:string;
    company_name:string;
    job_id:number;
    close:() => void;
    setApplyStatus:React.Dispatch<React.SetStateAction<TJobStatus | null>>;
};

const JobApplicationModal = ({position,company_name,job_id,close,setApplyStatus}:TJobApplicationModalProp) => {
  const [sop,setSop] = useState<string>("");
  const [error,setError] = useState<boolean>(false);

  const submitJobApplication = async()=> {
    const isError = sop?.trim()?.split(" ").length > 100;
    if(!isError){
      setError(true);
      return ;
    }
    const jobApplicationBody = {
      data: {
        status:"applied",
        job_id:job_id,
        sop:sop,
      },
    };
    try {
      const resp = await fetch(GET_JOB_APPLICATION, {
        credentials: 'header',
        body: JSON.stringify(jobApplicationBody),
        method: 'POST',
        headers: { 'content-type': 'application/json' },
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const { status }:{status:TJobStatus} = respJSON.data;
      toast.success("Applied Success");
      setApplyStatus(status);
    } catch (e: any) {
      toast.error(e.message);
    };

    close();
  };

  return (
    <React.Fragment>
        <h2 className='text-center text-xl 3xl:text-3xl text-text-blue font-bold mb-4'>{position} at {company_name}</h2>
        <div className='my-2'>
            <h3 className='text-left text-lg 3xl:text-2xl text-text-dark mb-2'>Please provide brief SOP for recruiter</h3> 
            <textarea 
              cols={30} rows={10} 
              placeholder={`Please elaborate why you think you are best fit for ${position} job at ${company_name} .`} 
              value={sop}
              className='3xl:text-xl'
              onChange={(event) => setSop(event.target.value)}
              />  
              <span className={error && (sop?.trim()?.split(" ").length < 100) ? 'text-red-500 3xl:text-xl':'text-text-grey-light 3xl:text-xl'} >*Minimum of 100 words</span>
        </div>
        <div className='flex flex-row items-center justify-end gap-4 my-2'>
          <button className='bg-text-blue 3xl:text-xl' onClick={submitJobApplication} >Submit & Apply</button>
          <button className='bg-text-blue 3xl:text-xl' onClick={close} >Cancel</button>
        </div>
    </React.Fragment>
  );
};

export default JobApplicationModal;