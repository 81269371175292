import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import Spinner from 'fragments/Spinner';
import ErrorMsg from 'components/Error';
import Modal from 'fragments/Modal';
import useStore from 'globalstate';
import { API_URL, GET_WORKSHOP_SUB_CATEGORIES } from 'constants/app';
import fetch from 'utils/fetch';
import { openURL } from 'utils/url';
import { TMeetup } from 'types';
import EventDetail from './EventDetail';
import globalEmitter from 'utils/emitter';
import UpdatedCard from 'fragments/UpdatedCard/UpdatedCard';
import moment from 'moment';

type TDetails = {
  passedMeetups?: TMeetup[];
};

type TFilters = 'All' | 'Past' | 'Upcoming' | 'Event' | 'Workshop';

export default function AllEvents(props: TDetails) {
  const [loading, setloading] = useState<boolean>(true);
  const [allMeetups, setAllMeetups] = useState<TMeetup[]>();
  const [shownMeetups, setShownMeetups] = useState<TMeetup[]>();
  const [subWorkshopCategory, setSubWorkshopCategory] = useState<{ id: number; name: string }[]>();
  const [filteredType, setFilteredType] = useState<TFilters>('All');
  const [subFilterType, setSubFilteredType] = useState<number | null>(null);

  const [meetupDetailed, setMeetupDetailed] = useState<TMeetup | undefined>(undefined);
  const [error, setError] = useState<string>();

  const { eventName } = useParams() as any;
  const [State] = useStore();
  const { isAuthenticated } = State;
  const barredEventNames = ['my', 'upcoming'];
  const MEETUPS_SHOWN_KEY = 'MEETUPS_SHOWN';
  const eventEmitter = globalEmitter;

  const getMeetups = async () => {
    setloading(true);
    try {
      const resp = await fetch(
        `${API_URL}/meetup/events?onlyActive=true&onlyUpcoming=false&domain=techx&&onlyRegistered=false`,
        {
          credentials: 'header',
        }
      );
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const tmeetups = await respJSON.data;
      setAllMeetups(tmeetups);
      setShownMeetups(tmeetups);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setloading(false);
    }
  };

  const getWorkshopSubCategories = async () => {
    setloading(true);
    try {
      const resp = await fetch(GET_WORKSHOP_SUB_CATEGORIES, {
        credentials: 'header',
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const subCategories: { id: number; name: string }[] = await respJSON.data[0]?.value;
      setSubWorkshopCategory(subCategories);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getMeetups();
    getWorkshopSubCategories();
    const ev = eventEmitter.on('refresh_meetups', () => {
      getWorkshopSubCategories();
      getMeetups();
    });
    return () => {
      eventEmitter.removeAllListeners();
    };
  }, []);

  const filterEventByUserReg = () => {
    if (allMeetups) {
      const filteredMeetup = allMeetups?.filter((val: TMeetup) => {
        return !!val.registered_on;
      });

      if (filteredMeetup) setShownMeetups(filteredMeetup);
    }
  };
  const filterEventByUserNonReg = () => {
    if (allMeetups) {
      const filteredMeetup = allMeetups?.filter((val: TMeetup) => {
        return val.registered_on == null ? true : false;
      });
      if (filteredMeetup) setShownMeetups(filteredMeetup);
    }
  };

  const filterByUpcoming = () => {
    const data = allMeetups?.filter((val: TMeetup) => new Date(val.scheduled_datetime) > new Date() || new Date(val.end_datetime) > new Date());
    setShownMeetups(data);
  };
  const filterByPast = () => {
    const data = allMeetups?.filter((val: TMeetup) => new Date(val.scheduled_datetime) < new Date() && new Date(val.end_datetime) < new Date() );
    setShownMeetups(data);
  };
  const filterByAll = () => {
    const sortedMeetups = allMeetups?.sort((a,b)=>{
      return moment(b.end_datetime).diff(a.end_datetime);
    });
    setShownMeetups(sortedMeetups);
  };
  const filterByEvents = () => {
    const data = allMeetups?.filter((val: TMeetup) => val.type.toUpperCase() === 'EVENT');
    setShownMeetups(data);
  };
  const filterByWorkshop = () => {
    let data = allMeetups?.filter((val: TMeetup) => val.type.toUpperCase() === 'WORKSHOP');
    if (subFilterType) data = data?.filter((val: TMeetup) => val?.meta?.category_id === subFilterType);
    setShownMeetups(data);
  };

  const filterBySubCategory = (id: number) => setSubFilteredType(id);

  const ClearBySubCategory = () => setSubFilteredType(null);

  const Filters = () => {
    setloading(true);

    if (filteredType === 'All') filterByAll();
    else if (filteredType === 'Past') filterByPast();
    else if (filteredType === 'Upcoming') filterByUpcoming();
    else if (filteredType === 'Event') filterByEvents();
    else if (filteredType === 'Workshop') filterByWorkshop();

    setloading(false);
  };

  useEffect(() => {
    Filters();
  }, [filteredType, subFilterType]);

  const filterNamesList: { name: TFilters }[] = [
    {
      name: 'Upcoming',
    },
    {
      name: 'All',
    },
    {
      name: 'Past',
    },
    {
      name: 'Event',
    },
  ];

  return (() => {
    if (loading) return <Spinner space show />;
    // if (!isAuthenticated) return <ErrorMsg preset="login" text={error} />;
    if (error) return <ErrorMsg preset="broken" text={error} />;
    return (
      <div className="flex flex-col w-full h-full">
        <div className="w-full h-8 3xl:h-10 flex flex-row items-center justify-between bg-greybg sticky top-0 right-0 z-10">
          <h3 className="font-switzer text-sm 3xl:text-base text-text-blue font-normal">TechX</h3>
          <div className="flex flex-row items-center">
            {filterNamesList &&
              filterNamesList.map(val => (
                <h3
                  className={`text-sm 3xl:text-base ${
                    filteredType === val.name ? 'text-text-blue' : 'text-text-grey'
                  } hover:text-text-blue font-normal mx-2 font-switzer cursor-pointer`}
                  onClick={() => setFilteredType(val.name)}
                  key={`filter-Names-${val.name}`}
                >
                  {val.name === 'Event' ? 'Courses' : val.name}
                </h3>
              ))}
            {/* DropDown Workshop  */}
            <div className="group inline-block relative">
              <h3
                className={`text-sm 3xl:text-base flex items-center ${
                  filteredType === 'Workshop' ? 'text-text-blue' : 'text-text-grey'
                } hover:text-text-blue font-normal mx-2 font-switzer cursor-pointer`}
                onClick={() => {
                  ClearBySubCategory();
                  setFilteredType('Workshop');
                }}
                key={`filter-Names-${'Workshop'}`}
                // className="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center"
              >
                <span className="mr-1">Programs</span>
                {subWorkshopCategory && (
                  <>
                    <FaAngleDown className="block  group-hover:hidden" />
                    <FaAngleUp className="hidden  group-hover:block" />
                  </>
                )}
              </h3>
              {subWorkshopCategory && (
                <ul className="w-60 absolute right-2 hidden bg-navgreybg text-text-grey text-sm 3xl:text-base pt-1 group-hover:block list-none">
                  {subWorkshopCategory &&
                    subWorkshopCategory.map(category => (
                      <li
                        key={`workshop-sub-category-${category.id}`}
                        className="text-text-grey hover:text-text-blue py-2 px-4 block whitespace-no-wrap cursor-pointer"
                        onClick={() => {
                          setFilteredType('Workshop');
                          filterBySubCategory(category.id);
                        }}
                      >
                        {category.name}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {shownMeetups && shownMeetups?.length === 0 && (
          <ErrorMsg
            preset="nothing"
            text={`${
              filteredType === 'Past' || filteredType === 'Upcoming'
                ? `${filteredType} Courses / Programs`
                : filteredType === 'Event'
                ? 'Courses'
                : 'Programs'
            } are are not available at the moment`}
          />
        )}
        <div className="grid xl:grid-cols-16 lg:grid-cols-12 grid-cols-8 3xl:gap-8 gap-4">
          {shownMeetups && shownMeetups?.length > 0 && (
            <>
              {shownMeetups?.map((v: TMeetup, i: number) => {
                return (
                  <UpdatedCard
                    data={v}
                    key={'meetup-' + i}
                    onStateChange={() => {
                      getMeetups();
                    }}
                  />
                );
              })}
            </>
          )}
        </div>
        <Modal
          isOpen={eventName && !barredEventNames.includes(eventName) ? true : false}
          size="lg"
          close={() => {
            openURL('/meetups', false);
          }}
        >
          {!!meetupDetailed ? (
            <EventDetail
              event={{
                name: meetupDetailed.meta.display_name ? meetupDetailed.meta.display_name : meetupDetailed.name,
                description: meetupDetailed.description,
                startDate: meetupDetailed.scheduled_datetime,
                endDate: meetupDetailed.end_datetime,
                isLive: false,
                heroImage: meetupDetailed.meta.hero_image,
                registrationLink: meetupDetailed.meta.registration_url,
                isRegistered: !!meetupDetailed.registered_on,
                meetupLink: meetupDetailed.meta.meetup_link,
                pageLink: meetupDetailed.page_url,
                speakerName: meetupDetailed.meta.speaker_name,
                speakerBio: meetupDetailed.meta.speaker_bio,
                formFieldName: meetupDetailed.meta.required_form_fields,
                eventId: meetupDetailed.id,
                require_registration: meetupDetailed.meta.require_registration,
                difficulty: meetupDetailed.meta.difficulty_level,
                eventCode: meetupDetailed.meta.event_code,
                alacarte_rate: meetupDetailed.alacarte_rate,
                currency: meetupDetailed.currency,
                product_id: meetupDetailed.product_id,
                payment_id: meetupDetailed.payment_id,
              }}
              isAuthenticated={isAuthenticated}
            />
          ) : loading ? (
            <h1>Fetching Event</h1>
          ) : (
            <h1>NO SUCH EVENT</h1>
          )}
        </Modal>
      </div>
    );
  })();
}
