import React from "react";
import useStore from 'globalstate';
import ReactMarkdown from 'react-markdown';
import globalEmitter from 'utils/emitter';
import { DEFAULT_CARD_IMG } from 'constants/app';

type TCourseDetails = {
    id: number;
    name: string;
    description: string;
    type?: "Program" | "Course";
    url: string;
    bannerurl: string;
    meta: any;
};

type TProps = {
  event: TCourseDetails;
};
export default function (props: TProps) {
  const { event } = props;

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 h-96 max-h-screen ">
      <div className="  flex align-center flex-col pr-10">
        <div className="overflow-visible h-min relative ">
          <div className="absolute h-32 w-32 right-0">
            <div className="absolute -right-3 top-10 border p-2 rounded text-white bg-brand  ">Registered</div>
          </div>
          <div className={'bg-gradient-to-r to-white-500 '}>
            <img src={event?.bannerurl || DEFAULT_CARD_IMG} className="object-contain"></img>
          </div>
        </div>
        
        <div className="mt-6">
          <table>
            <tbody>
              <tr>
                <td className="font-bold">Duration</td>
                <td>{event?.meta?.duration?.split(",")[0]}</td>
              </tr>
              <tr>
                <td className="font-bold">Starts On</td>
                <td>{event?.meta?.startDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className=" flex flex-col">
        <h1>{event?.name}</h1>
        <hr></hr>
        <div>
          <h3 className='text-base text-gray-700'>Prerequisites -</h3>  
          <div className="text-sm text-gray-600">
            <ReactMarkdown>{event?.meta?.prerequisites}</ReactMarkdown>{' '}
          </div>
        </div>
        <hr></hr>
        <div>
          <h3 className='text-base text-gray-700'>Description -</h3>  
          <div className="text-sm text-gray-600">
            <ReactMarkdown>{event?.description}</ReactMarkdown>{' '}
          </div>
        </div>
        {/* <p className="text-sm text-gray-400 capitalize"> - By {event.speakerName} </p> */}
        <hr></hr>
      </div>
    </div>
  );
}
