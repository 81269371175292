import * as colors from 'constants/color';
import React from 'react';
import { Stepper } from 'react-form-stepper';
import { StepStyleDTO, StepDTO } from 'react-form-stepper/dist/components/Step/StepTypes';
import { FaCaretDown, FaCheck } from 'react-icons/fa';
import { TApplicationProcess } from './types';
type TProps = {
  process?: TApplicationProcess[] | null;
  currentStage: number; //-1 means application not started rest are for application stages
};

function JobStageStepper(props: TProps) {
  const noProcessSteps = [
    {
      label: 'Submit Application',
      children: (
        <div>
          1<FaCaretDown color="white" />
        </div>
      ),
    },
  ];

  const calculateSteps = (process: TApplicationProcess[], currentStage: number) => {
    const steps = process.map((stage, index) => {
      const isCompleted = index < currentStage ? true : false;
      const isActive = index == currentStage ? true : false;
      let step: StepDTO & { children?: JSX.Element } = { label: stage.bubbleTitle };
      if (isCompleted) step = { label: stage.bubbleTitle, completed: isCompleted, children: <FaCheck color="white" /> };
      if (isActive)
        step = {
          label: stage.bubbleTitle,
          active: true,
          children: (
            <div>
              {index + 1}
              <FaCaretDown color="white" />
            </div>
          ),
        };

      return step;
    });
    return steps;
  };
  const styleStepper: StepStyleDTO = {
    activeBgColor: colors.BRAND,
    activeTextColor: 'white',
    completedBgColor: colors.SUCCESS_PALE,
    completedTextColor: '#fff',
    inactiveBgColor: colors.PRIMARY_BACKGROUND_COMPLEMENT,
    inactiveTextColor: '',
    size: '4rem',
    circleFontSize: '1rem',
    labelFontSize: '1rem',
    borderRadius: '50%',
    fontWeight: '500',
  };
  return (
    <div>
      {
        <Stepper
          steps={props.process ? calculateSteps(props.process, props.currentStage) : noProcessSteps}
          activeStep={0}
          styleConfig={styleStepper}
        />
      }
    </div>
  );
}

export default JobStageStepper;
