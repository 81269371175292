import React from 'react';
type TProps = {
  message: string;
};
function InfoBox(prop: TProps) {
  const { message } = prop;
  return (
    <div className="font-switzer font-semibold text-center text-grey-400 text-base md:text-lg flex flex-col flex-wrap ">
      {message}
    </div>
  );
}

export default InfoBox;
