/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import { FaStripe } from 'react-icons/fa';

import Spinner from 'fragments/Spinner';
import ErrorView from 'components/Error2';
import { FormHolder } from 'components/Form/Holder';
import { captureEvent } from 'utils/gtm';
import { formatCurrency, getCurrencySymbol } from 'utils/currency';
import fetch from 'utils/fetch';
import {
  APP_BASEPATH,
  PAYMENT_RATE_URL,
  RAZOR,
  RAZORSUB,
  RAZORSUBFAKE,
  STRIPE,
  RAZORSUBBILLED,
  // ZEST,
  PAYMENT_SUCCESS_PATH,
  USER_APPLICATION_PATH,
  GET_SUBSCRIPTION_RATE,
  getAuthRefreshURL,
  // STRIPE_KEY
} from 'constants/app';
import {
  DEFAULT_TEXT,
  // PALE_SEPARATOR,
  // PALER_SEPARATOR,
  PALEST_SEPARATOR,
  PALE_SEPARATOR,
  PRIMARY_APP,
  // PRIMARY_APP,
} from 'constants/color';

// import Zest from './zest.jpg';
import Razor from './razor.png';
// import CheckoutForm from './CheckoutForm';
import StripeCheckout from './Stripe/Checkout';
// import ZestCheckout from './Zest/Checkout';
import RazorCheckout from './Razor/Checkout';
import RazorSubCheckout from './Razor/CheckoutSubscription';
import RazorCheckoutBilled from './Razor/CheckoutSubscriptionBilled';
import Discount from './Discount';
import RazorSubFakeCheckout from './Razor/CheckoutSubscriptionFake';
import { reqRegisterUser } from 'fragments/UpdatedCard/UpdatedCard';
import moment from 'moment';
import toaster from 'utils/toast';

interface IProps {
  applicationId?: string;
  productId?: string;
  paymentId?: string | number;
  fromURL?: string;
  hasSub?: boolean;
  slotId?: string;
  setActiveStep?: Function;
  meetupId?: number;
  billingFrequency?:string;
}

type TMethodKey =
  | 'Stripe'
  | 'RazorPay'
  | 'RazorPay Subscription'
  | 'RazorPay Recurring'
  | 'Income Sharing Agreement'
  | 'RazorPay Billed';

type TPaymentMethodData = {
  [key in TMethodKey]?: TPricingData;
};

type TPricingData = {
  rate?: number;
  currency: string;
  discountedRate?: number;
  description?: string;
  paymentPlan?: {
    rate: number;
    paymentPlanId: number;
    planId: string;
    cycleCount: number;
    enrollmentRate: number;
    planMeta: any;
  };
};

// type TPaymentRateMethod = TPricingData & TPaymentMethod;
type TPaymentRateMethod = {
  rate: number;
  currency: string;
  discountedRate?: number;
  paymentPlanId?: number;
  planId?: string;
  cycleCount?: number;
  enrollmentRate?: number;
  planMeta?: any;
  description?: string;
  referralApplied?: boolean;
} & TPaymentMethod;

type TPaymentMethod = {
  type: string;
  Icon?: any;
  Img?: string;
  color?: string;
  title: string;
  description: string;
  recommended?: boolean;
};

const APPLICATIONS_PAGE = `${APP_BASEPATH}${USER_APPLICATION_PATH}`;
const PAYMENT_SUCCESS_PAGE = `${APP_BASEPATH}${PAYMENT_SUCCESS_PATH}`;
const getSuccessURL = (productId: string) => `${PAYMENT_SUCCESS_PAGE}?productId=${productId}`;

// stripe intents api
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(STRIPE_KEY);

// function Payment() {
//   return (
//     <Elements stripe={stripePromise}>
//       <CheckoutForm />
//     </Elements>
//   );
// };

export default function Payment(props: IProps) {
  const [allowedMethods, setAllowedMethods] = useState<TPaymentRateMethod[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<TPaymentRateMethod | null>(null);
  const [code, setcode] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isCodeValid, setisCodeValid] = useState<boolean>();
  const [hasFullDiscount, setHasFullDiscount] = useState<boolean>(false);
  const [error, seterror] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const fromURL = props.fromURL || new URLSearchParams(useLocation().search).get('from') || APPLICATIONS_PAGE;
  const productId = props.productId || new URLSearchParams(useLocation().search).get('productId');
  const applicationId = props.applicationId || new URLSearchParams(useLocation().search).get('applicationId') || '';
  const paymentId = props.paymentId || new URLSearchParams(useLocation().search).get('paymentId') || '';

  const slotId: string | number = props.slotId || '';
  const setActiveStep = props.setActiveStep || null;

  const PAYMENT_METHODS: { [key: string]: TPaymentMethod } = {
    // [STRIPE]: {
    //   type: STRIPE,
    //   Icon: FaStripe,
    //   color: '#008cdd',
    //   title: 'Pay via Stripe',
    //   description: 'Credit or debit cards',
    // },
    [RAZOR]: {
      type: RAZOR,
      Img: Razor,
      title: 'Pay via Razorpay',
      description: 'Credit or debit cards, UPI or net banking',
      recommended: true,
    },
    // [ZEST]: {
    //   Img: Zest,
    //   title: 'Pay via EMI',
    //   recommended: true,
    //   description:
    //     'Avail of an interest free loan and pay via EMI (subject to loan application and approval)',
    //   action: () => setPaymentMethod(ZEST),
    // },
    [RAZORSUBFAKE]: {
      type: RAZORSUBFAKE,
      Img: Razor,
      title: 'Pay as you go',
      description:
        'Pay an upfront amount for enollment, followed by installments once every 4-8 weeks till the entire fee is paid.',
    },
    [RAZORSUB]: {
      type: RAZORSUB,
      Img: Razor,
      title: 'Pay as you go',
      description:
        'Create a subscription with your credit or debit card that automatically charges your card. ' +
        'Installments are collected once every 3 months till the entire fee is paid. Only Some Specific cards are supported.',
    },
    [RAZORSUBBILLED]: {
      type: RAZORSUBBILLED,
      Img: Razor,
      title: 'Pay as planned',
      description:
        'Create a subscription with any payment mode and pay every 3 months.' +
        'Installments are supposed to be payed when invoices are generated by due date.',
    },
  };

  const onGetRate = (methodDataList: TPaymentMethodData) => {
    const allowedMethods = Object.keys(methodDataList) as TMethodKey[];
    const methods = allowedMethods.map((method: TMethodKey) => {
      const methodData = methodDataList[method] as TPricingData;
      if ('description' in methodData && methodData.description) setDescription(methodData.description);
      return {
        ...methodData,
        ...(PAYMENT_METHODS[method] as TPaymentMethod),
        ...(methodData?.paymentPlan ? methodData?.paymentPlan : {}),
      };
    }) as TPaymentRateMethod[];

    if (code) {
      const hasDicount = methods.some(m => {
        return m.discountedRate != undefined && m.discountedRate != null && !m.referralApplied;
      });
      const hasFullDicount = methods.some(m => {
        return m.discountedRate === 0;
      });
      setHasFullDiscount(hasDicount && hasFullDicount);
      setisCodeValid(hasDicount);
    }

    const sortedMethods = methods.sort((m1, m2) => (m1.rate > m2.rate ? -1 : 1)).sort(m1 => (m1.recommended ? -1 : 1));
    setAllowedMethods(sortedMethods);
    if (methods.length === 1) return setPaymentMethod(methods[0]);
  };

  const getRate = async () => {
    if (!productId) return;

    const queryParams = {
      paymentId: paymentId.toString(),
      productId,
      code: code,
    };
    const queryString = new URLSearchParams(queryParams).toString();
    const sessionResult = await fetch(`${GET_SUBSCRIPTION_RATE}?${queryString}`, {
      credentials: 'header',
    });
    const respJSON = await sessionResult.json();
    if (sessionResult.status !== 200) {
      setLoading(false);
      return seterror(respJSON.error);
    }
    onGetRate(respJSON.data.paymentMethods);
    setLoading(false);
  };

  useEffect(() => {
    captureEvent('Pay', 'Select Pay Method', productId);
  }, []);

  useEffect(() => {
    getRate();
  }, [code]);

  // Agent Discount
  const [agentDiscountCode, setAgentDiscountCode] = useState<string>();
  useEffect(()=>{
    if(props.billingFrequency !== "yearly") return;
    const agentDiscountString = localStorage.getItem('ad');
    let agentDiscount;
    if(agentDiscountString ){
      agentDiscount = JSON.parse(agentDiscountString);
      setAgentDiscountCode(agentDiscount?.details?.code);
    }
    if(agentDiscountCode) setcode(agentDiscountCode);
  },[agentDiscountCode]);


  if (!productId) return <ErrorView embed text="This page needs a product id to work" />;

  if (error)
    return (
      <ErrorView
        embed
        preset="broken"
        text={error}
        extrablock={
          <button
            className="button is-light"
            css={css`
              float: right;
            `}
            onClick={() => {
              seterror('');
              getRate();
            }}
          >
            Reset
          </button>
        }
      />
    );

  if (loading) return <Spinner bar />;

  const params = {
    applicationId: applicationId,
    successURL: productId ? getSuccessURL(productId) : '',
    code,
    productId,
    paymentId: paymentId.toString(),
    fromURL,
    description,
    slotId,
    setActiveStep,
  };

  if (!allowedMethods.length) return <ErrorView embed preset="broken" text="Payment data unavailable" />;

  return (
    <FormHolder
      embed
      title={paymentMethod ? `${paymentMethod.type} Payment` : 'Choose Payment'}
      cssString={'height: auto; width: auto; background-color: white;'}
      width="550px"
    >
      <React.Fragment>
        {/* {formattedRate && (
          <div
            css={css`
              padding: 2rem 0 1rem;
              display: flex;
              justify-content: center;
              color: white;
              font-size: 2rem;
              font-weight: 600;
            `}
          >
            <div
              css={css`
                padding: 0.5rem 2rem 1rem 2rem;
                background-color: rgba(50, 75, 217, 0.95);
                border-radius: 0.33rem;
              `}
            >
              <div
                css={css`
                  font-weight: 300;
                  font-size: 0.85rem;
                `}
              >
                Amount
              </div>
              <span
                css={css`
                  ${paymentData.discountedRate
                    ? `text-decoration: line-through; font-size: 0.85rem; margin-left: -1rem; font-weight: 400;`
                    : ''}
                `}
              >
                {formattedRate}
              </span>
              {paymentData.discountedRate &&
                formatCurrency(paymentData.discountedRate, paymentData.currency)}
            </div>
          </div>
        )} */}
        {hasFullDiscount && (
          <div className=" grid grid-cols-3 justify-self-center mb-10 mt-10">
            <div></div>
            <button
              className="button is-white content-center "
              css={css`
                font-weight: 100;
              `}
              onClick={async () => {
                if (!props.meetupId) return toaster.error('No Meetup ID Provided to register');
                const resp = await reqRegisterUser(
                  { registerdOn: moment().format(), code: code, discountedPrice: '0', globalSlotId: slotId },
                  +props.meetupId
                );
                const respJson = await resp.json();
                if (respJson.success) {
                  toaster.success('Successfully Registered');
                  window.location.href = getAuthRefreshURL(window.location.href);
                }
              }}
            >
              Register
            </button>
            <div></div>
          </div>
        )}
        {(() => {
          if (paymentMethod && !hasFullDiscount) {
            // if (paymentMethod.type === STRIPE) return <StripeCheckout {...params} />;
            // if (paymentMethod === ZEST) return <ZestCheckout {...params} />;
            if (paymentMethod.type === RAZOR) return <RazorCheckout {...params} />;
            if (paymentMethod.type === RAZORSUBBILLED) return <RazorCheckoutBilled {...params} />;
            if (paymentMethod.type === RAZORSUB) return <RazorSubCheckout {...params} />;
            if (paymentMethod.type === RAZORSUBFAKE) return <RazorSubFakeCheckout {...params} />;
          }

          return allowedMethods.map(method => {
            if (!method || hasFullDiscount) return null;
            const { Img, Icon, color, title, description, recommended, discountedRate, rate, currency } = method;
            const methodRate = formatCurrency(rate, currency);
            const methodRateDiscounted = discountedRate && formatCurrency(discountedRate, currency);
            return (
              <div
                css={css`
                  color: ${DEFAULT_TEXT};
                  padding: 1rem 2rem;
                  cursor: pointer;
                  box-shadow: 0 1px 0 1px ${PALEST_SEPARATOR};
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  &:hover {
                    background-color: ${PALEST_SEPARATOR} !important;
                  }
                  @media (max-width: 768px) {
                    padding: 1rem 0;
                    box-shadow: none;
                  }
                `}
                onClick={() => setPaymentMethod(method)}
              >
                <div
                  css={css`
                    width: 66%;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      padding: 1rem 0;
                      @media (max-width: 768px) {
                        padding: 0 0.33rem 0.66rem;
                      }
                    `}
                  >
                    {Icon && <Icon size="60px" color={color} />}
                    {Img && (
                      <img
                        src={Img}
                        css={css`
                          height: 2.5rem;
                          width: 2.5rem;
                        `}
                      />
                    )}
                    <h3
                      css={css`
                        margin: 0 0 0 1rem !important;
                        @media (max-width: 768px) {
                          font-size: 1.33rem !important;
                        }
                      `}
                    >
                      {title}
                    </h3>
                  </div>
                  <div
                    css={css`
                      padding-bottom: 1rem;
                      font-size: 0.9rem;
                      line-height: 1rem;
                      @media (max-width: 768px) {
                        padding: 0 0.33rem 0.66rem;
                      }
                    `}
                  >
                    {description}
                  </div>
                </div>
                <div
                  css={css`
                    width: 33%;
                    padding-left: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  {recommended ? (
                    <div
                      css={css`
                        border-radius: 5rem;
                        padding: 0.2rem 0.8rem;
                        font-size: 0.66rem;
                        color: ${PALE_SEPARATOR};
                        font-weight: bold;
                      `}
                    >
                      RECOMMENDED
                    </div>
                  ) : null}
                  {methodRate ? (
                    <div
                      css={css`
                        border-radius: 0.33rem;
                        padding: 0.5rem 1rem;
                        background-color: ${PRIMARY_APP};
                        color: white;
                      `}
                    >
                      <span
                        css={css`
                          ${
                            methodRateDiscounted
                              ? 'text-decoration: line-through; margin-right: 5px; font-size: 0.9rem;'
                              : ''
                          }
                        `}
                      >
                        {methodRate}
                      </span>
                      {methodRateDiscounted}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          });
        })()}

        {paymentMethod?.referralApplied && paymentMethod.discountedRate && !loading ? (
          <div className="w-full text-center">
            <div>
              <span className="line-through text-xl">
                {getCurrencySymbol(paymentMethod?.currency) + paymentMethod.rate / 100}
              </span>
              <span className=" text-3xl">
                {getCurrencySymbol(paymentMethod?.currency) + paymentMethod.discountedRate / 100}
              </span>
            </div>
            <div className="w-full text-center">
              Your<span className="font-bold"> Referral discount </span>is applied
              <span className="text-4xl"> &nbsp;🎉</span>
            </div>
          </div>
        ) : (
          <Discount
            clearAppliedCode={() => setcode('')}
            appliedCode={code}
            submitCode={(code: string) => setcode(code)}
            isCodeValid={isCodeValid}
          />
        )}
        {paymentMethod && allowedMethods.length > 1 && (
          <div
            css={css`
              display: flex;
              justify-content: center;
              padding: 10px;
            `}
          >
            <button
              className="button is-white"
              css={css`
                font-weight: 100;
              `}
              onClick={() => {
                setPaymentMethod(null);
              }}
            >
              Choose a different payment method
            </button>
          </div>
        )}
      </React.Fragment>
    </FormHolder>
  );
}
