/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';

import { PALER_SEPARATOR, PALEST_SEPARATOR } from 'constants/color';

interface IProps {
  embed?: boolean;
  children: React.ReactNode;
  title?: string;
  cssString?: string;
  width?: string;
}

export function FormHolder(props: IProps) {
  const { children, cssString, embed, title, width = '500px' } = props;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        ${embed ? '' : 'min-height: 90vh;'}
        ${embed ? '' : 'padding: 0;'}
        width: 100%;
        @media (max-width: 768px) {
          align-items: flex-start;
          background-color: white;
          height: auto;
          box-shadow: none;
          border: 0 solid ${PALER_SEPARATOR};
          border-width: 1px 0;
          padding: 0;
        }
        ${cssString};
      `}
    >
      <motion.div
        css={css`
          background-color: white;
          box-shadow: 0 0 0 2px ${PALEST_SEPARATOR};
          margin:.5rem;
          /* margin-top: ${embed ? ".5rem" : '-5vh'}; */
          width: ${width};
          @media (max-width: 768px) {
            width: auto;
            /* margin-top: ${embed ? '2vh' : '5vh'}; */
            box-shadow: none;
          }
        `}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.3 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 2rem;
            border: 0 solid ${PALEST_SEPARATOR};
            border-bottom-width: 1px;
            @media (max-width: 768px) {
              padding: 0.33rem 0.66rem;
            }
          `}
        >
          <h3
            css={css`
              max-width: 70%;
              margin-bottom: 0 !important;
              font-weight: 300 !important;
              @media (max-width: 768px) {
                font-size: 1.5rem !important;
              }
            `}
          >
            {title}
          </h3>
          <Link
            to="/"
            css={css`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <img
              src="/logo.svg"
              css={css`
                width: auto;
                height: auto;
                @media (max-width: 768px) {
                  width: 75%;
                }
              `}
            />
          </Link>
        </div>
        {children}
      </motion.div>
    </div>
  );
}
