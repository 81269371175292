import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaClipboard,
  FaList,
  FaCalendarAlt,
  FaCalendarDay,
  FaBox,
  FaBoxes,
  FaBriefcase,
  FaPhoneAlt,
  FaCommentAlt,
  FaDiscourse,
  FaMedal,
  FaDesktop,
  FaBook,
  FaGraduationCap,
} from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

import {
  MY_APPLICATIONS_PATH,
  MY_PROFILE_PATH,
  JOBS_URL,
  CONTACT_US_URL,
  STORIES_URL,
  COURSE_URL,
  CERTIFICATES_URL,
  DISCOURSE_URL,
  // DISCORD_URL,
  TECH_SUPPORT_URL,
  HACKATHON_URL,
  COMMUNITY_COURSES_URL,
  PRO_COURSES_URL,
  MY_SCHEDULE_PATH,
  ALL_MEETUPS_PATH,
} from 'constants/app';

const Card = ({
  Icon,
  label,
  link,
  isLocal = false,
  comingSoon = false,
}: {
  Icon: IconType;
  label: string;
  link: string;
  isLocal?: boolean;
  comingSoon?: boolean;
}) => {
  const element = (
    <div
      className={`border-2 sm:border-4 border-brand-lightest text-brand rounded-lg p-4 sm:p-12 w-full
       text-base ${
         label.length > 15
           ? 'sm:text-base'
           : label.length > 12
           ? 'sm:text-md'
           : label.length > 10
           ? 'sm:text-xl'
           : 'sm:text-2xl'
       } sm:w-80 relative 
        ${
          comingSoon
            ? ''
            : 'transform transition duration-500 hover:bg-brand-dark hover:border-brand hover:text-white hover:scale-105'
        }
    `}
    >
      {comingSoon && <div className="text-xs p-2 text-darkbg absolute top-2 right-2">Coming soon</div>}
      <Icon className={`inline mr-4 text-4xl`} /> {label}
    </div>
  );
  if (comingSoon) return <div className="w-full sm:w-min">{element}</div>;
  if (isLocal)
    return (
      <Link className="w-full sm:w-min" to={link}>
        {element}
      </Link>
    );
  return (
    <a className="w-full sm:w-min" href={link}>
      {element}
    </a>
  );
};

const mystuff = [
  { Icon: FaClipboard, label: 'Applications', link: MY_APPLICATIONS_PATH },
  { Icon: FaList, label: 'Profile', link: MY_PROFILE_PATH, isLocal: true },
  { Icon: FaGraduationCap, label: 'Certificates', link: CERTIFICATES_URL },
  { Icon: FaCalendarAlt, label: 'Schedule', link: MY_SCHEDULE_PATH, isLocal: true },
  { Icon: FaCalendarDay, label: 'Events', link: ALL_MEETUPS_PATH, isLocal: true },
  { Icon: FaBook, label: 'Courses', link: COURSE_URL, comingSoon: true },
  // { Icon: FaDiscord, label: 'Chat', link: DISCORD_URL },
];

const otherstuff = [
  { Icon: FaCommentAlt, label: 'Stories', link: STORIES_URL },
  { Icon: FaDiscourse, label: 'Community Forum', link: DISCOURSE_URL },
  { Icon: FaBox, label: 'Pro Courses', link: PRO_COURSES_URL },
  { Icon: FaBoxes, label: 'Community Courses', link: COMMUNITY_COURSES_URL },
  { Icon: FaMedal, label: 'Hackathons', link: HACKATHON_URL },
  { Icon: FaDesktop, label: 'Tech Support', link: TECH_SUPPORT_URL },
  { Icon: FaPhoneAlt, label: 'Contact Us', link: CONTACT_US_URL },
  { Icon: FaBriefcase, label: 'Jobs', link: JOBS_URL, comingSoon: true },
];

export default function () {
  return (
    <div className="content">
      <h1 className="mb-4">My Stuff</h1>
      <div className="flex flex-row flex-wrap sm:gap-x-12 gap-y-2 sm:gap-y-12">
        {mystuff.map(s => (
          <Card {...s} />
        ))}
      </div>
      <h1 className="mt-8 mb-4">Places</h1>
      <div className="flex flex-row flex-wrap sm:gap-x-12 gap-y-2 sm:gap-y-12">
        {otherstuff.map(s => (
          <Card {...s} />
        ))}
      </div>
    </div>
  );
}
