import React from 'react';
import { FaChevronLeft, FaLongArrowAltRight } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { FaExclamationTriangle, FaSignInAlt, FaStopCircle } from 'react-icons/fa';

import { getLoginURL } from '../constants/app';

export type IError = {
  emotext?: any;
  emoji?: JSX.Element;
  text?: string;
  extrablock?: (...args: any[]) => JSX.Element;
  preset?: string;
  noback?: boolean;
  embed?: boolean;
};

const presets: { [key: string]: any } = {
  broken: {
    emotext: 'Something is broken',
    emoji: <FaStopCircle />,
  },
  nothing: {
    emotext: 'No results found',
    emoji: <FaExclamationTriangle />,
  },
  login: {
    emoji: "My Stuff",
    emotext: 'Login to see things here',
    noback: true,
    extrablock: (loginPath: string) => (
      <div className="mt-8 mx-auto">
        <a className="button dark inline-block" href={loginPath}>
          Login <FaLongArrowAltRight className="inline ml-1" />
        </a>
      </div>
    ),
  },
};

export default function (props: IError) {
  const { preset, embed = true } = props;
  let { emotext, emoji, text, extrablock, noback = true } = props;

  const history = useHistory();
  const loginURL = getLoginURL(window.location.href);

  if (preset) {
    const maybePreset = presets[preset];
    if (maybePreset) {
      if (!emotext && maybePreset.emotext !== undefined) emotext = maybePreset.emotext;
      if (!emoji && maybePreset.emoji !== undefined) emoji = maybePreset.emoji;
      if (!text && maybePreset.text !== undefined) text = maybePreset.text;
      if (maybePreset.extrablock !== undefined) extrablock = maybePreset.extrablock;
      if (maybePreset.noback !== undefined) noback = maybePreset.noback;
    }
  }

  return (
    <div
      className={`${embed ? 'w-full p-8 mx-0 my-auto' : 'w-full p-8 mt-24'}
      md:w-1/2 md:mx-auto lg:w-5/12
    `}
    >
      <div className={`w-3/4 md:w-full`}>
        <div className="flex align-center justify-between">
          
          <h1 className="text-blue-600">{emoji}</h1>
        </div>
        <h1 className="mt-8" style={{ fontSize: '1.2em' }}>{emotext}</h1>
        <div className="mt-8 leading-relaxed">{text}</div>
        {extrablock && extrablock(loginURL)}
        {!embed && !noback && (
          <div className="pt-20 flex justify-center">
            <button
              onClick={history.goBack}
              className="bg-blue-500 font-bold text-white px-4 py-3 transition duration-300 ease-in-out hover:bg-blue-600 mr-6"
            >
              <FaChevronLeft size={10} />
              <span className="m-l-2">Go Back</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
