import React from 'react';
import { TJobs, TJobStatus } from 'types';

export enum TjobsEmploymentType {
  Internship = 'internship',
  PartTime = 'part-time',
  FullTime = 'full-time',
}

export type TjobFilterEmployementTypes = {
  name: string;
  value: '' | TjobsEmploymentType.Internship | TjobsEmploymentType.PartTime | TjobsEmploymentType.FullTime;
  id: string;
};

type TjobType = {
  loading: boolean;
  error: null | string;
  jobsData: TJobs[];
  numOfExperience: number;
  location: string;
  employementType: '' | TjobsEmploymentType.Internship | TjobsEmploymentType.PartTime | TjobsEmploymentType.FullTime;
  salaryRange: string;
  searchParam: string;
};

const INITIAL_STATE: TjobType = {
  loading: false,
  error: null,
  jobsData: [],
  numOfExperience: 10,
  location: '',
  employementType: '',
  salaryRange: '',
  searchParam: '',
};

type TjobAction =
  | {
      type: 'FETCH_START';
    }
  | {
      type: 'FETCH_SUCCESS';
      payload: TJobs[];
    }
  | {
      type: 'FETCH_ERROR';
      payload: string;
    }
  | {
      type: 'ADD_NUM_OF_EXPERIENCE';
      payload: number;
    }
  | {
      type: 'ADD_LOCATION';
      payload: string;
    }
  | {
      type: 'ADD_EMPLOYEMENT_TYPE';
      payload: '' | TjobsEmploymentType.Internship | TjobsEmploymentType.PartTime | TjobsEmploymentType.FullTime;
    }
  | {
      type: 'ADD_SALARY_RANGE';
      payload: string;
    }
  | {
      type: 'ADD_SEARCH';
      payload: string;
    }
  | {
      type: 'CLEAR_FILTERS';
    };

const JobsReducer = (prevState: TjobType, action: TjobAction): TjobType => {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...prevState,
        loading: true,
      };

    case 'FETCH_SUCCESS':
      return {
        ...prevState,
        loading: false,
        error: null,
        jobsData: action.payload,
      };

    case 'FETCH_ERROR':
      return {
        ...prevState,
        loading: false,
        error: action.payload,
      };

    case 'ADD_NUM_OF_EXPERIENCE':
      return {
        ...prevState,
        numOfExperience: action.payload,
      };

    case 'ADD_LOCATION':
      return {
        ...prevState,
        location: action.payload,
      };

    case 'ADD_EMPLOYEMENT_TYPE':
      return {
        ...prevState,
        employementType: action.payload,
      };

    case 'ADD_SALARY_RANGE':
      return {
        ...prevState,
        salaryRange: action.payload,
      };

    case 'ADD_SEARCH':
      return {
        ...prevState,
        searchParam: action.payload,
      };

    case 'CLEAR_FILTERS':
      return {
        ...INITIAL_STATE,
        jobsData: prevState.jobsData,
      };

    default:
      return prevState;
  }
};

const JobStatusChip = ({ status }: { status: TJobStatus | null }) => {
  if (status === null) return <span></span>;

  const getChipColor = (status: TJobStatus) => {
    if (status === TJobStatus.applied || status === TJobStatus.shortlisted)
      return 'bg-chip-success-bg text-chip-success-text border border-chip-success-border';
    else if (
      status === TJobStatus.rejected ||
      status === TJobStatus.cancelled ||
      status === TJobStatus.deleted ||
      status === TJobStatus.finished ||
      status === TJobStatus.expired
    )
      return 'bg-chip-danger-bg text-chip-danger-text border border-chip-danger-border';
    else return 'bg-chip-primary-bg text-chip-primary-text border border-chip-primary-border';
  };

  return (
    <div
      className={`uppercase px-2 3xl:my-3 my-1 xsm:my-2 3xl:w-40 w-32 3xl:text-xl text-sm font-semibold rounded-sm ${getChipColor(
        status
      )}`}
    >
      {status}
    </div>
  );
};

export { JobsReducer, INITIAL_STATE, JobStatusChip };
