/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { FaCircle, FaPlus, FaExternalLinkAlt, FaVideo, FaTimes, FaTrash } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { css, jsx } from '@emotion/react';
import moment from 'moment';
import { pulse } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import DynamicForm from 'components/DynamicForm';
import EventDetail from 'components/AllEvents/EventDetail';
import useStore from 'globalstate';
import { trimString } from 'utils/slugify';
import { openURL } from 'utils/url';
import { EDifficultyLevels, EMeetupTypes, IRequestResponse, TMeetup } from 'types';
import fetch from 'utils/fetch';
import { USER_EVENT_REGISTER_URL, DEFAULT_CARD_IMG } from 'constants/app';
import { getDynamicFormFields } from 'utils/form-fields';
import Modal from './Modal';
import { captureException } from '@sentry/minimal';
import globalEmitter from 'utils/emitter';
import { enrol } from 'components/GHF/Modal';
import { getSeriesColor } from 'utils/seriesColors';
import toaster from 'utils/toast';

type TProps = {
  data: TMeetup;
  onStateChange: Function;
};
type TExBtn = {
  text: string;
  icon: JSX.Element;
  onClick?: Function;
  newTab?: boolean;
  isLive?: boolean;
};
export const ExpandableBtn = (props: TExBtn) => {
  const { text, icon, onClick, newTab = true, isLive = false } = props;
  //create animation to say that the event is live
  const pulseAnimation = keyframes`${pulse}`;

  const LiveDiv = styled.div`
  color:red;
  opacity:0.7;
  animation: 0.8s ${pulseAnimation} infinite;
`;
  const animatedIcon = (
    <LiveDiv>
      {' '}
      <FaCircle></FaCircle>{' '}
    </LiveDiv>
  );
  const isClickable = onClick ? 'cursor-pointer' : 'disabled';

  return (
    <div
      className={'flex flex-column align-center rounded-md items-center pr-1 mr-3 pt-1 pb-1 z-[9] ' + isClickable}
      css={css`
      border: 0px solid black;
      background-color:#EFF4FF;
      &:hover > span  {
            max-width:max-content !important;
            padding-left: 12px
      }
      
    `}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <span
        className="mr-1 text-xs"
        style={{
          maxWidth: '0rem',
          WebkitTransition: 'max-width 1s',
          transition: 'max-width 0.8s, padding-left 0.5s',
          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {!isLive ? text : text + ' LIVE'}
      </span>

      {isLive ? animatedIcon : icon}
    </div>
  );
};

export async function saveRegistrationDetails(data: { [key: string]: string }, eventId: number): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    const response = await reqRegisterUser(data, eventId);

    let respJSON;
    try {
      respJSON = await response.json();
    } catch (e) {
      captureException(e);
    }
    if (response.status !== 200)
      if (response.status == 403) {
        if (respJSON.error.startsWith('You do not have the required permissions to access this event'))
          localStorage.setItem('regEvntNonCnfrmd', JSON.stringify({ data: data, event: { id: eventId } }));

        return reject(respJSON.error);
      }
    toaster.success('You are successfully registered!');
    return resolve(respJSON);
  });
}

export async function reqRegisterUser(data: { [key: string]: string }, eventId: number) {
  const params = {
    method: 'POST',
    body: JSON.stringify({
      data: data,
      event_id: eventId,
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  const response = await fetch(USER_EVENT_REGISTER_URL, {
    credentials: 'header',
    ...params,
  });

  return response;
}
export async function requestDeRegistration(eventId: number): Promise<IRequestResponse> {
  const params = {
    method: 'POST',
    body: JSON.stringify({
      event_id: eventId,
      register: false,
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  const response = await fetch(USER_EVENT_REGISTER_URL, {
    credentials: 'header',
    ...params,
  });

  return response.json();
}
export default function DefaultCard(props: TProps) {
  const [State] = useStore();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const [isDeregisterModalOpen, setIsDeregisterModalOpen] = useState<boolean>(false);
  const { isAuthenticated } = State;

  const { name, description, scheduled_datetime, meta, page_url, end_datetime, registered_on, alacarte_rate } = props.data;
  const displayName = meta.display_name ? meta.display_name : name;
  const [isRegModalOpen, setIsRegModalOpen] = useState<boolean>(false);

  const getPillColor = (): string => {
    if (meta.difficulty_level == EDifficultyLevels.beginner) return '#d0e92c40';
    else if (meta.difficulty_level == EDifficultyLevels.intermediate) return '#ffdf0040';
    else if (meta.difficulty_level == EDifficultyLevels.advanced) return '#ef352940';
    else return '#75C9C840';
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };
  const closeDeregModal = () => {
    setIsDeregisterModalOpen(false);
  };
  function openMeetupLink() {
    if (meta.meetup_link) openURL(meta.meetup_link, true);
  }

  function openDeregisterModal() {
    setIsDeregisterModalOpen(true);
  }

  function openRegModal() {
    if (meta.require_registration)
      if (meta.required_form_fields) setIsRegModalOpen(true);
      else
        saveRegistrationDetails({ registered_on: moment().format('DD-MM-YYYY HH:mm:ss') }, props.data.id)
          .then(() => {
            props.onStateChange();
          })
          .catch(async e => {
            //Actions.setState({ openRegistrationModal: true });
            if (e.startsWith('You do not have the required permissions to access this event')) {
              const isEnrolled: boolean | string = await enrol();
              if (isEnrolled == false) {
                toaster.error('Unable to register you to GHF!');
                captureException(`Unable to Register  for event ${props.data.name}`);
                return false;
              }
              return true;
            }
            return false;
            // toaster.error(e, {
            //   position: 'top-right',
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: false,
            //   pauseOnHover: true,
            //   draggable: false,
            // });
          });
  }
  function closeRegModal() {
    setIsRegModalOpen(false);
  }
  const openDetailView = () => {
    setIsDetailModalOpen(true);
  };
  const deregisterEvent = async () => {
    const res = await requestDeRegistration(props.data.id);
    if (res.success == true) {
      toaster.success('You Are successfully deregistered.');
      props.onStateChange();
    } else if (res.error) {
      toaster.error(res.error);
    }

    closeDeregModal();
  };
  async function registerFromForm(data: { [key: string]: string }) {
    saveRegistrationDetails(data, props.data.id)
      .then(res => {
        props.onStateChange();
      })
      .catch(async e => {
        {
          toaster.error(e);
          if (e.startsWith('You do not have the required permissions to access this event')) {
            const isEnrolled: boolean | string = await enrol();
            if (isEnrolled == false) {
              toaster.error('Unable to register you to GHF!');
              captureException(`Unable to Register  for event ${props.data.name}`);
            }
            return true;
          }
          return false;
        }
      });
  }

  const getCardAction = () => {
    if (meta.meetup_link && moment().isAfter(end_datetime))
      return (
        <ExpandableBtn
          text={'View Recorded'}
          icon={<FaVideo id="info_btn" className="collapsable" css={css`display: 'inline-block',`} />}
          onClick={openMeetupLink}
          isLive={false}
        />
      );
    else if (!registered_on && meta.require_registration)
      return (
        <ExpandableBtn
          text={'REGISTER'}
          icon={<FaPlus id="info_btn" className="collapsable" css={css`display: 'inline-block'`}></FaPlus>}
          onClick={openRegModal}
          newTab={false}
        />
      );
    else if ((registered_on || !meta.require_registration) && meta.meetup_link)
      return (
        <React.Fragment>
          <ExpandableBtn
            text={'JOIN'}
            icon={<FaExternalLinkAlt id="info_btn" className="collapsable" css={css`display: 'inline-block',`} />}
            onClick={openMeetupLink}
            isLive={moment().isBetween(moment(scheduled_datetime), moment(end_datetime)) ? true : false}
          />
          <ExpandableBtn
            text={'Deregister'}
            icon={<FaTimes id="info_btn" className="collapsable" css={css`display: 'inline-block',`} />}
            onClick={openDeregisterModal}
            isLive={false}
          />
        </React.Fragment>
      );
    else
      return (
        <React.Fragment>
          <ExpandableBtn
            text={'Awaiting Link'}
            icon={
              <FaExternalLinkAlt id="info_btn" className="collapsable disabled" css={css`display: 'inline-block',`} />
            }
            isLive={false}
          />
          <ExpandableBtn
            text={'Deregister'}
            icon={<FaTimes id="info_btn" className="collapsable" css={css`display: 'inline-block',`} />}
            onClick={openDeregisterModal}
            isLive={false}
          />
        </React.Fragment>
      );
  };

  return (
    <div className="p-2 ">
      <ReactTooltip />
      <div
        className={
          'h-96 rounded overflow-hidden shadow-lg relative bg-gradient-to-r to-white-500 ' +
          getSeriesColor(meta.event_code)
        }
      >
        <div>
          <img
            className="w-full cursor-pointer"
            src={meta.hero_image ? meta.hero_image : DEFAULT_CARD_IMG}
            onClick={() => {
              window.open(page_url, '_blank')?.focus();
            }}
          />
          <div className="absolute top-2 right-2  flex flex-row">{getCardAction()}</div>
        </div>
        <div className="px-2 py-1">
          <div className="flex flex-column justify-items-center h-10">
            <div
              style={{ color: '#2557D6', backgroundColor: getPillColor(), fontSize: '10px', height: 'fit-content' }}
              className="p-1 text-center  self-center rounded-lg mr-2 min-w-min "
            >
              <p style={{ margin: '0px' }} className="mr-4 w-full" data-tip={meta.difficulty_level?.toUpperCase()}>
                {meta.event_code ? trimString(meta.event_code, 6, false).toUpperCase() : 'univ'}
              </p>
            </div>
            <div>
              <p
                className="text-sm self-center m-0 flex-shrink break-words "
                data-tip={'' + meta.display_name}
                key={name}
              >
                {trimString(displayName, 30)}
              </p>
            </div>
          </div>
          <div>
            <p className="text-gray-700 text-xs h-full pt-4 break-words">{trimString(description, 140)}</p>
          </div>

          <div className="flex flex-row flex-grow bottom-8 absolute ">
            <div className="grid grid-rows-2 ">
              {meta.difficulty_level ? (
                <div
                  style={{ color: '#2557D6', backgroundColor: getPillColor(), height: 'fit-content' }}
                  className="ml-4 mr-4 text-center text-xs  self-center rounded-lg col-start-3 md:col-start-1"
                >
                  {meta.difficulty_level.toUpperCase()}
                </div>
              ) : (
                ''
              )}
              <p className="text-xs font-extralight">
                {moment(end_datetime).diff(moment(scheduled_datetime), 'days') < 1
                  ? moment(scheduled_datetime).format('Do MMM | HH:mm ') + ' to ' + moment(end_datetime).format('HH:mm')
                  : moment(scheduled_datetime).format('Do MMM | HH:mm ') +
                    ' to ' +
                    moment(end_datetime).format('Do MMM | HH:mm')}
              </p>
            </div>
          </div>
          <div
            className="flex flex-row flex-auto cursor-pointer text-space absolute bottom-4 left-4"
            onClick={openDetailView}
          >
            Know More
          </div>
        </div>
      </div>
      <Modal isOpen={isRegModalOpen} close={closeRegModal}>
        <DynamicForm
          closeModal={closeRegModal}
          onUpdate={registerFromForm}
          formFields={getDynamicFormFields(meta.required_form_fields)}
          eventName={name}
        />
      </Modal>

      <Modal isOpen={isDeregisterModalOpen} close={closeDeregModal}>
        <div className="p-10">
          <div>
            <span className="text-center">
              {' '}
              Are You Sure you want to Deregister from <strong>{displayName}</strong> ?
            </span>
          </div>

          <div className="flex place-content-between  pt-5 bottom-0">
            <button className="large" onClick={closeDeregModal}>
              <FaTimes />
              &nbsp;Cancel
            </button>
            <button onClick={deregisterEvent}>
              <FaTrash />
              &nbsp; Deregister
            </button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isDetailModalOpen} close={closeDetailModal} size="md">
        <EventDetail
          event={{
            name: displayName,
            description: description,
            startDate: scheduled_datetime,
            endDate: end_datetime,
            isLive: false,
            heroImage: meta.hero_image,
            registrationLink: meta.registration_url,
            isRegistered: !!registered_on,
            meetupLink: meta.meetup_link,
            pageLink: page_url,
            speakerName: meta.speaker_name,
            speakerBio: meta.speaker_bio,
            formFieldName: meta.required_form_fields,
            eventId: props.data.id,
            require_registration: meta.require_registration,
            difficulty: meta.difficulty_level,
            eventCode: meta.event_code,
            alacarte_rate:alacarte_rate,
            currency:props.data.currency,
            payment_id:props.data.payment_id,
            product_id:props.data.product_id
          }}
          isAuthenticated={isAuthenticated}
        />
      </Modal>
    </div>
  );
}
