import React from 'react';
import { useState, useEffect } from 'react';
import { TJobApplication, TJobs } from 'types';
import fetch from "utils/fetch";
import Spinner from 'fragments/Spinner';
import JobApplicationCard from '../../fragments/Jobs/JobApplicationCard';
import { GET_JOB_APPLICATION } from '../../constants/app';
import  ErrorMsg  from 'components/Error';

const MyJobApplication = () => {
    const [loading,setLoading] = useState<boolean>(false);
    const [error,setError] = useState<string>();
    const [jobsData,setJobsData] = useState<TJobApplication[]>([]);

    useEffect(()=> {
        let subscribed = true;
        (async()=> {
          setLoading(true);
          try {
            const resp = await fetch(
              GET_JOB_APPLICATION,
              {
                credentials: 'header',
              }
            );
            const respJSON = await resp.json();
            if (!respJSON.success) throw new Error(respJSON.error);
            const jobData:TJobApplication[] = await respJSON.data;
            if(subscribed)
                setJobsData(jobData);    
            
          } catch (e: any) {
            setError(e.message);
          } finally {
            setLoading(false);
          }
        })();
  
        return () => {
          subscribed=false;
        };
      },[]);
  
  return (
    <div className="flex flex-col w-full h-full">
        <div className="w-full h-8 3xl:h-10 flex flex-row items-center justify-between bg-greybg sticky top-0 right-0 z-10">
            <h3 className="font-switzer text-sm 3xl:text-base text-text-blue font-normal">My Job Applications</h3>
        </div>
        <div className="grid xl:grid-cols-16 lg:grid-cols-12 grid-cols-8 3xl:gap-8 gap-4 relative">
        { loading===true && <div className="h-80vh">
            <Spinner space show />
        </div> }
        {
          error && loading===false && 
          <div className="col-span-full text-text-dark text-base md:text-xl">
            <ErrorMsg embed={false} preset="broken" text={error} />
          </div>
        }

        {jobsData && !loading && jobsData?.length === 0 && (
            <h3 className="col-span-full text-text-dark">No Jobs Found at the Moment !!!!! </h3>
        )}
        {jobsData && !loading && jobsData?.length > 0 && (
            <React.Fragment>
            {jobsData?.map((v: TJobApplication, i: number) => (
                <JobApplicationCard
                    data={v}
                    key={'my-job-application-cards-' + i}
                />
                )
            )}
            </React.Fragment>
        )}
        </div>
    </div>
  );
};

export default MyJobApplication;
