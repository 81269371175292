/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { FormHolder } from 'components/Form/Holder';
import { DEFAULT_TEXT, PALEST_SEPARATOR } from 'constants/color';

export default function () {
  const [redirect, setredirect] = useState<string>();

  const navToDashboard = () => {
    setredirect(
      `/dashboard`
    );
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <FormHolder title="🎉 Payment Success" embed>
      <div
        css={css`
          padding: 1rem 2rem;
          font-size: 1.33rem;
          font-weight: 300;
          line-height: 2rem;
          color: ${DEFAULT_TEXT};
          text-align: justify;
          box-shadow: 0 2px 0 0 ${PALEST_SEPARATOR};
          @media (max-width: 768px) {
            box-shadow: none;
          }
        `}
      >
        Please note that payment might take some time to reflect on our systems. You will get an
        email receipt once we receive your payment.
      </div>
      <div
        css={css`
          padding: 15px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <button className="button is-medium is-primary" onClick={navToDashboard}>
          Back to Dashboard
        </button>
      </div>
    </FormHolder>
  );
}
