import React from 'react';
import { TFieldRequired } from 'types';

type Props = {
  Icon?: any;
  fieldProps: any;
  label: string;
  info?: string;
  placeholder?: string;
  defaultValue?: string;
  error?: string;
  required?: TFieldRequired;
};

export function FormInput(props: Props) {
  const { defaultValue, error, placeholder, fieldProps } = props;

  return (
    <input
      className={`text-sm sm:text-base relative w-full  placeholder-gray-400
        ${fieldProps.disabled ? 'bg-white border-none' : ''}
        ${error ? 'border-2 border-red-500 focus:ring-red-300' : 'border border-gray-300 focus:border-blue-300'}
      `}
      key={fieldProps.name}
      placeholder={placeholder}
      type="text"
      defaultValue={defaultValue}
      {...fieldProps}
      required={props.required ? props.required.value : false}
    />
  );
}
