import React, { useEffect, useReducer, useRef, useState } from 'react';
import JobCard from 'fragments/Jobs/JobCard';
import { API_URL, GET_JOB } from '../../constants/app';
import { TJobs } from 'types';
import fetch from 'utils/fetch';
import Spinner from 'fragments/Spinner';
import ReactPaginate from 'react-paginate';
import { FaSearch } from 'react-icons/fa';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { JobsReducer, INITIAL_STATE, TjobFilterEmployementTypes, TjobsEmploymentType } from './JobsUtils';
import { debounce } from 'utils/ratelimit';
import { BiFilterAlt } from 'react-icons/bi';
import { HiFilter } from 'react-icons/hi';
import ErrorMsg from 'components/Error';
import useStore from 'globalstate';

const jobFilterEmployementTypes: TjobFilterEmployementTypes[] = [
  {
    name: 'Internship',
    value: TjobsEmploymentType.Internship,
    id: '1',
  },
  {
    name: 'Full Time',
    value: TjobsEmploymentType.FullTime,
    id: '2',
  },
  {
    name: 'Part Time',
    value: TjobsEmploymentType.PartTime,
    id: '3',
  },
];

const Jobs = () => {
  const [State] = useStore();
  const { isAuthenticated } = State;

  const [jobsState, dispatchJobs] = useReducer(JobsReducer, INITIAL_STATE);
  const { loading, error, jobsData, numOfExperience, location, employementType, salaryRange, searchParam } = jobsState;

  const locationRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const searchParamRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [pageNo, setPageNo] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [filterSection, setFilterSection] = useState<boolean>(false);
  const [maximumSalaryRange, setMaximumSalaryRange] = useState<number>(1000);

  const toggleFilterSection = () => setFilterSection(prev => !prev);

  const pageChangeHandler = ({ selected }: { selected: number }) => {
    setPageNo(selected);
  };

  const numOfExperienceHandler = debounce((value: number | number[]) => {
    setPageNo(0);
    dispatchJobs({ type: 'ADD_NUM_OF_EXPERIENCE', payload: value as number });
  }, 300);

  const locationHandler = debounce(() => {
    setPageNo(0);
    const locationValue: string = locationRef?.current?.value;
    dispatchJobs({ type: 'ADD_LOCATION', payload: locationValue });
  }, 300);

  const searchParamHandler = debounce(() => {
    setPageNo(0);
    const searchValue: string = searchParamRef?.current?.value;
    dispatchJobs({ type: 'ADD_SEARCH', payload: searchValue });
  }, 300);

  const employementTypeHandler = (
    name: '' | TjobsEmploymentType.Internship | TjobsEmploymentType.PartTime | TjobsEmploymentType.FullTime
  ) => {
    setPageNo(0);
    dispatchJobs({ type: 'ADD_EMPLOYEMENT_TYPE', payload: name });
  };

  const salaryRangeHandler = debounce((value: number | number[]) => {
    setPageNo(0);
    const ans = maximumSalaryRange - parseInt(value?.toString());
    dispatchJobs({ type: 'ADD_SALARY_RANGE', payload: ans?.toString() });
  }, 300);

  const clearFiltersHandler = () => {
    setPageNo(0);
    locationRef.current.value = '';
    searchParamRef.current.value = '';
    dispatchJobs({ type: 'CLEAR_FILTERS' });
  };

  useEffect(() => {
    let subscribed = true;
    (async () => {
      dispatchJobs({ type: 'FETCH_START' });
      try {
        const resp = await fetch(
          `${GET_JOB}?page=${
            pageNo + 1
          }&onlyActive=true&numOfExperience=${numOfExperience}&location=${location}&natureOfWork=${employementType}&salaryRange=${salaryRange}&searchParam=${searchParam}`,
          {
            credentials: 'header',
          }
        );
        const respJSON = await resp.json();
        if (!respJSON.success) throw new Error(respJSON.error);
        const {
          data: jobData,
          count,
          maxSalaryRange,
        }: { data: TJobs[]; count: { count: string }; maxSalaryRange: number } = await respJSON.data;
        if (subscribed) {
          setTotalPages(Math.ceil(parseInt(count.count) / 20));
          dispatchJobs({ type: 'FETCH_SUCCESS', payload: jobData });
          setMaximumSalaryRange(maxSalaryRange);
        }
      } catch (e: any) {
        dispatchJobs({ type: 'FETCH_ERROR', payload: e.message });
      }
    })();

    return () => {
      subscribed = false;
    };
  }, [
    pageNo,
    jobsState.numOfExperience,
    jobsState.location,
    jobsState.employementType,
    jobsState.salaryRange,
    jobsState.searchParam,
  ]);

  if (!isAuthenticated)
    return <ErrorMsg embed={false} preset="login" text={'You are not authenticated. Login to continue.'} />;

  return (
    <div className="flex flex-col w-full h-full">
      <div className="w-full h-8 3xl:h-10 flex flex-row items-center justify-between bg-greybg sticky top-0 right-0 z-10">
        <h3 className="font-switzer text-sm 3xl:text-base text-text-blue font-normal">Jobs</h3>
        {filterSection ? (
          <HiFilter className="text-xl block lg:hidden" onClick={toggleFilterSection} />
        ) : (
          <BiFilterAlt className="text-xl block lg:hidden" onClick={toggleFilterSection} />
        )}
      </div>
      <div className="grid xl:grid-cols-16 lg:grid-cols-12 grid-cols-8 3xl:gap-8 gap-4 relative">
        {/* Loader */}
        {loading === true && (
          <div className="h-80vh">
            <Spinner space show />
          </div>
        )}
        {/* Error Message */}
        {error && (
          <div className="col-span-full text-text-dark text-base md:text-xl">
            <ErrorMsg embed={false} preset="broken" text={error} />
          </div>
        )}
        {jobsData && !loading && jobsData?.length === 0 && (
          <div className="col-span-full text-text-dark text-base md:text-xl">
            <ErrorMsg embed={false} preset="nothing" text={'No Jobs found at a moment !!!'} />
          </div>
        )}
        {/* Filters  */}
        <div
          className={`min-w-full xl:col-start-13 xl:col-end-17 lg:col-start-9 lg:col-end-13 lg:bg-transparent lg:block ${
            filterSection ? 'block z-1010 bg-greybg bottom-0 left-0' : 'hidden'
          } absolute top-0 right-0`}
        >
          {/* Search Bar */}
          <div className="w-full flex flex-row items-center bg-white rounded pr-2">
            <input
              ref={searchParamRef}
              onChange={searchParamHandler}
              className="bg-white text-base text-text-grey focus:outline-none focus:shadow-none focus:ring-transparent"
              type="text"
              placeholder="Title / Company / Skill"
            />
            <FaSearch className="text-2xl text-gray-300" />
          </div>
          <div className="mt-4 mb-8 mx-2">
            <h5 className="text-text-dark">Years of Experience</h5>
            <Slider
              min={0}
              max={10}
              defaultValue={numOfExperience}
              value={numOfExperience}
              step={1}
              marks={{
                0: 0,
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
                6: 6,
                7: 7,
                8: 8,
                9: 9,
                10: '10+',
              }}
              onChange={numOfExperienceHandler}
            />
          </div>

          {/* Location */}
          <div className="mt-8 mb-4 w-full flex flex-row items-center bg-white rounded pr-2">
            <input
              ref={locationRef}
              onChange={locationHandler}
              className="bg-white text-base text-text-grey focus:outline-none focus:shadow-none focus:ring-transparent"
              type="text"
              placeholder="Preferred Location/Remote"
            />
            <FaSearch className="text-2xl text-gray-300" />
          </div>
          {/* Exployement Type */}
          <div className="mx-2">
            <h5 className="text-text-dark">Employement Type</h5>
            <ul className="my-2">
              {jobFilterEmployementTypes.length > 0 &&
                jobFilterEmployementTypes.map(item => (
                  <li
                    key={`filter-employement-type-${item.id}`}
                    className="flex flex-row items-center justify-start gap-4"
                  >
                    <input
                      type="radio"
                      name={`employement-type`}
                      id={item.id}
                      checked={item.value === employementType ? true : false}
                      onChange={() => employementTypeHandler(item.value)}
                      className="w-4 h-4 ml-4 p-0 rounded-full appearance-none border border-gray-300 bg-white checked:bg-text-blue checked:border-text-blue focus:ring-offset-1 focus:outline-none"
                    />
                    <label className="text-text-dark text-sm" htmlFor={item.id}>
                      {' '}
                      {item.name}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
          <div className="mt-4 mb-8 mx-2">
            <h5 className="text-text-dark mb-1">Salary Range</h5>
            <Slider
              min={0}
              max={maximumSalaryRange}
              reverse
              onChange={salaryRangeHandler}
              defaultValue={maximumSalaryRange}
              step={1}
              marks={{
                [maximumSalaryRange]: '0',
                0: [maximumSalaryRange],
              }}
              className="my-1"
            />
            {salaryRange && <h5 className="text-text-blue text-sm font-bold text-center">{salaryRange}K</h5>}
          </div>
          <button className="bg-text-blue" onClick={clearFiltersHandler}>
            Clear Filters
          </button>
        </div>
        {jobsData && !filterSection && !loading && jobsData?.length > 0 && (
          <React.Fragment>
            {jobsData?.map((v: TJobs, i: number) => {
              return <JobCard data={v} key={'jobs-cards-' + i} />;
            })}
          </React.Fragment>
        )}
      </div>
      {totalPages > 1 && !filterSection && (
        <ReactPaginate
          breakLabel="..."
          previousLabel="< Previous"
          nextLabel="Next >"
          pageCount={totalPages}
          forcePage={pageNo}
          onPageChange={pageChangeHandler}
          containerClassName={'my-4 h-10 w-full flex flex-row items-center justify-center gap-4 text-xl text-text-blue'}
          pageClassName={'bg-white py-1 px-2.5'}
          activeClassName={'bg-text-blue text-white'}
          previousClassName={'bg-white py-1 px-2.5'}
          nextClassName={'bg-white py-1 px-2.5'}
          disabledLinkClassName={`cursor-not-allowed`}
        />
      )}
    </div>
  );
};

export default Jobs;
