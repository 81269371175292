import React from 'react';
type TProps = {
  startApplicationHandler: Function;
  displayText?: string;
};
function JobStartApplication(props: TProps) {
  const { startApplicationHandler, displayText } = props;
  return (
    <div className="flex flex-col justify-center items-center p-4">
      <div>
        <p>{displayText}</p>
      </div>

      <button
        className="btn btn-primary"
        onClick={() => {
          startApplicationHandler();
        }}
      >
        {' '}
        Start Application
      </button>
    </div>
  );
}

export default JobStartApplication;
