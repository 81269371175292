import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { TProcessedFormFieldList } from 'types';

import { FormComponentHolder, FormHolder, FormInput, FormSwitch, FormSelect } from 'components/Form';

interface IProps {
  onSubmit: (values: any) => void;
  defaultValues: { [key: string]: any };
  submitting: boolean;
  error?: any;
  hackathonFields: TProcessedFormFieldList;
}

const defaultRequiredFields = ['isWorking', 'highestEducation'];

export default function HackathonProfile(props: IProps) {
  const { onSubmit, defaultValues, error: formError, hackathonFields } = props;
  const [requiredFields, setrequiredFields] = useState<string[]>(defaultRequiredFields);

  const { control, register, handleSubmit, getValues, setValue, watch, formState, reset } = useForm({ defaultValues });
  const { isDirty } = formState;
  const formValues = watch();

  const inputParams: any = {};
  for (const field in hackathonFields) {
    const f = hackathonFields[field];
    const props = { ...f.props };
    if (!props.validate)
      if (requiredFields.includes(f.name)) props.required = { value: true, message: 'Required' };
      else props.required = false;

    inputParams[field] = {
      ...f,
      errors: formError,
      error: formError[`${f.name}`]?.length > 0,
      defaultValue: defaultValues[f.name],
      fieldProps: register(f.name, props),
      refGenerator: register,
      setValue,
      control,
      getValues,
      formValues,
      required: props?.required,
    };
  }

  const wpField = inputParams.isWorking;
  const highestEducationField = inputParams.highestEducation;
  const workField = inputParams['currentWork-organization'];
  const educationFields: { [key: string]: any } = {
    school: inputParams['school-name'],
    undergraduate: inputParams['undergraduate-university'],
    postgraduate: inputParams['postgraduate-university'],
    doctorate: inputParams['doctorate-university'],
  };
  const highestEducation = formValues.highestEducation;
  const educationField = educationFields[highestEducation];

  const processForm = (processedData: { [key: string]: any }) => {
    const educationData: any = {};
    const currentWorkData: any = {};
    const { highestEducation, isWorking } = processedData;

    for (const dataKey in processedData) {
      if (dataKey.startsWith(`${highestEducation}-`)) {
        const key = dataKey.replace(`${highestEducation}-`, '');
        educationData[key] = processedData[dataKey];
      }

      if (dataKey.startsWith('currentWork-')) {
        const key = dataKey.replace('currentWork-', '');
        currentWorkData[key] = processedData[dataKey];
      }
    }

    const data = {
      highestEducation,
      isWorking,
      [highestEducation]: educationData,
      currentWork: currentWorkData,
    };

    onSubmit(data);
    reset(processedData);
    return true;
  };

  const computeRequiredFields = (formValues: any) => {
    const requiredFields = [...defaultRequiredFields];
    if (formValues.isWorking) requiredFields.push('currentWork-organization');
    if (educationField) requiredFields.push(educationField.name);
    setrequiredFields(requiredFields);
  };

  const dependentFields = [formValues.isWorking, highestEducation];

  useEffect(() => {
    computeRequiredFields(formValues);
  }, dependentFields);

  return (
    <FormHolder
      isDirty={isDirty}
      onSubmit={handleSubmit(processForm)}
      className={`mx-auto max-w-xl mt-4 sm:mt-8 ${
        typeof formError === 'object' && Object?.keys(formError)?.length > 0
          ? 'p-4 pb-12 md:p-8 md:pb-14 border-2 border-red-500'
          : ''
      }`}
    >
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-2xl md:text-4xl">Hackathon Registration</h1>
        <img className="w-14 md:w-20" src="/logo.svg" />
      </div>
      <hr />
      <FormComponentHolder FormComponent={FormSelect} {...highestEducationField} />
      {educationField ? <FormComponentHolder FormComponent={FormInput} {...educationField} /> : null}
      <FormComponentHolder FormComponent={FormSwitch} {...wpField} />
      {formValues.isWorking ? <FormComponentHolder FormComponent={FormInput} {...workField} /> : null}
      <button className="button dark filled float-right mt-2" type="submit">
        Proceed <FaLongArrowAltRight className="inline ml-2" />
      </button>
    </FormHolder>
  );
}
