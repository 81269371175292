import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Otp from './Otp';

type Props = {
  Icon?: any;
  label: string;
  defaultValue?: any;
  placeholder?: string;
  control: any;
  info?: string;
  fieldProps: any;
  error?: string;
  getValues: Function;
};

export function FormPhone(props: Props) {
  const { defaultValue, control, fieldProps, placeholder, getValues, error } = props;

  return (
    <div className="grid grid-cols-9">
      <div className="col-span-7">
        <Controller
          name={fieldProps.name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <PhoneInput
              placeholder={placeholder}
              {...field}
              className={`text-sm sm:text-base relative w-full pl-4 placeholder-gray-400 ${
                error ? 'border-2 border-red-500 focus:ring-red-300' : 'border border-gray-300 focus:border-blue-300'
              }
        `}
            />
          )}
        />
      </div>
      <div className="col-span-2">
        <Otp getValues={getValues} defaultValue={defaultValue} type="phone" />
      </div>
    </div>
  );
}
