import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory, Redirect } from 'react-router-dom';
import { FaExclamationCircle } from 'react-icons/fa';

import Tabs from 'fragments/Tabs';
import { InfoLine } from 'components/Form';
import RenderError from 'components/Error';

import { MY_PROFILE_PATH, PROFILE_URL } from 'constants/app';
import fetch from 'utils/fetch';
import Spinner from 'fragments/Spinner';
import useStore from 'globalstate';
import { TProcessedFormFieldList } from 'types';

import useFields from './Fields';
import Background from './Background';
import Education from './Education';
import Work from './Work';
import Miscellaneous from './Miscellaneous';
// import Other from './Other';
import Hackathon from './Hackathon';
import AdmissionProgram from './AdmissionProgram';
import AdmissionCourse from './AdmissionCourse';
import toaster from 'utils/toast';
// import Jobs from './Jobs';

type TTab = 'Background' | 'Education' | 'Work' | 'Miscellaneous' | 'Other';

const tabs: { label: TTab }[] = [
  { label: 'Background' },
  { label: 'Education' },
  { label: 'Work' },
  { label: 'Miscellaneous' },
];
// const tabs: { label: TTab }[] = [{ label: 'Background' }, { label: 'Education' }, { label: 'Work' }, { label: 'Other' }];

export default function ProfilePage(props?: { pTab?: TTab; onSubmit?: Function }) {
  const fields = useFields();
  const context = new URLSearchParams(useLocation().search).get('context') || '';
  const fromURL = new URLSearchParams(useLocation().search).get('from') || '';
  let { tab = props?.pTab } = useParams() as any;
  const history = useHistory();

  const [State, Actions] = useStore();
  const { isAuthenticated } = State;

  const [error, seterror] = useState<string | string[]>();

  const [propTab, setPropTab] = useState<string>(tab);
  const [pageError, setpageError] = useState<string>();
  const [submitting, setsubmitting] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(true);
  const [values, setvalues] = useState<{ [key: string]: any }>({});
  const isJobs = window.location.href.split('/').slice(3, 4)[0] === 'jobs';
  const processAndSetValues = (values: { [key: string]: any }) => {
    const processedValues: any = {};

    for (const key in values) {
      const value = values[key];
      if (Array.isArray(value)) {
        for (const idx in value) {
          const arrval = value[idx];
          for (const arrkey in arrval) {
            const arrkeyval = arrval[arrkey];
            const formkey = `${key}-${idx}-${arrkey}`;
            processedValues[formkey] = arrkeyval;
          }
        }
        continue;
      }

      if (typeof value === 'object') {
        for (const objkey in value) {
          const objval = value[objkey];
          const formkey = `${key}-${objkey}`;
          processedValues[formkey] = objval;
        }
        continue;
      }

      processedValues[key] = values[key];
    }

    setvalues(processedValues);
  };

  const getCurrent = async () => {
    try {
      const response = await fetch(PROFILE_URL, { credentials: 'header' });
      if (response.status !== 200) {
        toaster.error('Please check your internet');
        throw new Error('We are unable to connect to the server');
      }
      const respJSON = await response.json();
      const { profile } = respJSON;
      processAndSetValues(profile);
      setloading(false);
    } catch (e) {
      setloading(false);
      setpageError((e as Error).message);
    }
  };

  const getDefaultValues = (fields: TProcessedFormFieldList) => {
    const defaultValues: { [key: string]: any } = {};

    for (const field in fields) {
      const f = fields[field];
      if (!f.hasOwnProperty('defaultValue')) continue;
      defaultValues[f.name] = f.defaultValue;
    }

    return defaultValues;
  };

  const submitValues = () => async (values: any) => {
    seterror('');
    setsubmitting(true);
    try {
      const response = await fetch(`${PROFILE_URL}?context=${context}`, {
        method: 'PATCH',
        body: JSON.stringify({ profile: values }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'header',
      });
      const respJSON = await response.json();
      if (response.status !== 200) return seterror(respJSON.errors);

      if (context) window.top?.postMessage({ complete: true }, '*');
      if (fromURL) return window.location.replace(fromURL);
      const { profile } = respJSON;
      processAndSetValues(profile);

      if (context) toaster.info('Processing...');
      else toaster.success('Profile updated!');

      setsubmitting(false);

      if (props?.onSubmit) props.onSubmit(values);
    } catch (e) {
      seterror((e as Error).message);
      setsubmitting(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    getCurrent();
  }, []);

  useEffect(() => {
    seterror('');
    setPropTab(tab);
  }, [tab]);

  useEffect(() => {
    if (!context) return;
    Actions.setState({ hideNavBar: true });
  }, [context]);

  const params = { submitting, error };

  if (!isAuthenticated) return <RenderError preset="login" embed={false} />;

  if (!tab && !context && !propTab) return <Redirect to={`${MY_PROFILE_PATH}/Background`} />;

  if (!fields) return <RenderError text="This form is unavailable at the moment" preset="broken" />;

  return (
    <div className="content">
      {context ? null : <h1>Profile</h1>}
      {context ? null : <hr />}
      <div>
        {pageError ? (
          <RenderError preset="broken" text={pageError} />
        ) : loading ? (
          <Spinner bar />
        ) : context ? (
          <React.Fragment>
            {(() => {
              if (context === 'hackathon' && fields.hackathonFields)
                return (
                  <Hackathon
                    {...params}
                    onSubmit={submitValues()}
                    hackathonFields={fields.hackathonFields}
                    defaultValues={{ ...getDefaultValues(fields.hackathonFields), ...values }}
                  />
                );
              // if (context === 'jobs')
              //   return (
              //     <JobsProfile
              //       {...params}
              //       onSubmit={submitValues()}
              //       jobsFields={fields.hackathonFields}
              //       defaultValues={{ ...getDefaultValues(fields.hackathonFields), ...values }}
              //     />
              //   );

              if (context === 'admission-program' && fields.admissionProgramFields)
                return (
                  <AdmissionProgram
                    {...params}
                    onSubmit={submitValues()}
                    admissionFields={fields.admissionProgramFields}
                    defaultValues={{ ...getDefaultValues(fields.admissionProgramFields), ...values }}
                  />
                );

              if (context === 'admission-course' && fields.admissionCourseFields)
                return (
                  <AdmissionCourse
                    {...params}
                    onSubmit={submitValues()}
                    admissionFields={fields.admissionCourseFields}
                    defaultValues={{ ...getDefaultValues(fields.admissionCourseFields), ...values }}
                  />
                );

              return null;
            })()}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Tabs
              tabs={tabs}
              currentTab={isJobs ? propTab : tab}
              setTab={(ntab: string) => {
                if (isJobs) {
                  setPropTab(ntab);
                  tab = ntab;
                } else {
                  history.replace(ntab);
                }
              }}
            />
            {(() => {
              if (isJobs) tab = propTab;
              if (tab === 'Background' && fields.backgroundFields)
                return (
                  <Background
                    {...params}
                    onSubmit={submitValues()}
                    backgroundFields={fields.backgroundFields}
                    defaultValues={{ ...getDefaultValues(fields.backgroundFields), ...values }}
                  />
                );
              if (
                tab === 'Education' &&
                fields.schoolEducationFields &&
                fields.ugEducationFields &&
                fields.pgEducationFields &&
                fields.phdEducationFields &&
                fields.backgroundFields
              )
                return (
                  <Education
                    {...params}
                    onSubmit={submitValues()}
                    schoolEducationFields={fields.schoolEducationFields}
                    ugEducationFields={fields.ugEducationFields}
                    pgEducationFields={fields.pgEducationFields}
                    phdEducationFields={fields.phdEducationFields}
                    highestEducationParams={fields.backgroundFields.highestEducation}
                    defaultValues={{
                      ...getDefaultValues({
                        ...fields.backgroundFields,
                        ...fields.schoolEducationFields,
                        ...fields.ugEducationFields,
                        ...fields.pgEducationFields,
                        ...fields.phdEducationFields,
                      }),
                      ...values,
                    }}
                  />
                );
              if (
                tab === 'Work' &&
                fields.overallExperienceFields &&
                fields.currentWorkFields &&
                fields.pastWorkFields &&
                fields.backgroundFields
              )
                return (
                  <Work
                    {...params}
                    onSubmit={submitValues()}
                    workExperienceFields={fields.overallExperienceFields}
                    currentWorkFields={fields.currentWorkFields}
                    pastWorkFields={fields.pastWorkFields}
                    isWorkingParams={fields.backgroundFields.isWorking}
                    defaultValues={{
                      ...getDefaultValues({
                        ...fields.overallExperienceFields,
                        ...fields.currentWorkFields,
                        ...fields.pastWorkFields,
                      }),
                      ...values,
                    }}
                  />
                );
              if (tab === 'Miscellaneous' && fields.miscellaneousFields && fields.miscellaneousExtraFields)
                return (
                  <Miscellaneous
                    {...params}
                    onSubmit={submitValues()}
                    miscellaneousFields={fields.miscellaneousFields}
                    miscellaneousExtraFields={fields.miscellaneousExtraFields}
                    defaultValues={{
                      ...getDefaultValues({ ...fields.miscellaneousFields, ...fields.miscellaneousExtraFields }),
                      ...values,
                    }}
                  />
                );
              // if (tab === 'Other')
              //   return (
              //     <Other
              //       {...params}
              //       onSubmit={submitValues()}
              //       otherFields={fields.otherFields}
              //       defaultValues={{ ...getDefaultValues(fields.otherFields), ...values }}
              //     />
              //   );
            })()}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
