import React from 'react';
import { Controller } from 'react-hook-form';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';

type Props = {
  Icon?: any;
  label: string;
  defaultValue?: any;
  control: any;
  info?: string;
  fieldProps: any;
  error?: boolean;
};

export function FormDate(props: Props) {
  const { defaultValue, error, control, fieldProps } = props;

  return (
    <Controller
      name={fieldProps.name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <DatePickerInput
          displayFormat="YYYY-MM-DD"
          showOnInputClick
          placeholder="Date"
          {...field}
          className={`
        ${error ? 'border-2 border-red-500 focus:ring-red-300' : 'border border-gray-300 focus:border-blue-300'}
      `}
        />
      )}
    />
  );
}
