import React, { useEffect } from 'react';
export type TTabs = {
  label: string;
  Component: Function;
};
export default function JTabs(props: { color: string; tabContent: TTabs[]; changeTabTo?: number }) {
  const [openTab, setOpenTab] = React.useState(0);
  const { tabContent, changeTabTo } = props;
  if (openTab >= tabContent.length) setOpenTab(0);
  useEffect(() => {
    if (changeTabTo && !isNaN(changeTabTo) && openTab != changeTabTo) setOpenTab(changeTabTo);
  }, [changeTabTo]);
  return (
    <>
      <div className="flex md:flex-wrap">
        <div className="w-full">
          <ul className="flex mb-0 list-none pt-3 pb-4 flex-row w-1/3" role="tablist">
            {tabContent.map((v: TTabs, i: number) => {
              return (
                <li
                  key={`tabContent-1-${v.label}-${i}`}
                  className="-mb-px mr-2 last:mr-0 flex-auto text-center list-none"
                >
                  <a
                    className={
                      'text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal ' +
                      (openTab === i ? 'text-white bg-indigo-600' : 'text-indigo-600 bg-gray-50')
                    }
                    onClick={e => {
                      e.preventDefault();
                      setOpenTab(i);
                    }}
                    data-toggle="tab"
                    href={`#` + v.label}
                    role="tablist"
                  >
                    {v.label}
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6   h-full">
            <div className="py-4 flex-auto">
              <div className="tab-content tab-space">
                {tabContent.map((v: TTabs, i: number) => {
                  return (
                    <div
                      key={`tabContent-2-${v.label}-${i}`}
                      className={openTab === i ? 'block' : 'hidden'}
                      id={'#' + v.label}
                    >
                      {v.Component()}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
