import React from 'react';

type Props = {
  Icon?: any;
  label: string;
  fieldProps: any;
  defaultValue?: any;
  info?: string;
  name: string;
  options?: TOption[];
  placeholder?: string;
  error?: string;
};

type TOption =
  | {
      label: string;
      value: string;
    }
  | string;

export function FormRadio(props: Props) {
  const { defaultValue, options, error, fieldProps } = props;

  if (!options) return null;

  const opts = [...options];

  return (
    <div className={error ? 'border border-red-500 focus:ring-red-300' : 'focus:border-blue-300'}>
      {opts.map((option: TOption) =>
        typeof option === 'string' ? (
          <div className="m-2" key={`radio-${option}`}>
            <label>
              <input
                {...fieldProps}
                key={option}
                className="mr-2 w-min"
                type="radio"
                defaultValue={defaultValue}
                value={option}
              />
              {option}
            </label>
          </div>
        ) : (
          <div className="m-2" key={`radio-${option.value}`}>
            <label>
              <input
                {...fieldProps}
                key={option.value}
                className="mr-2 w-min"
                type="radio"
                value={option.value}
                defaultValue={defaultValue}
              />
              {option.label}
            </label>
          </div>
        )
      )}
    </div>
  );
}
