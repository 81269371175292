import React, { useMemo,useState } from 'react';
import moment from 'moment';
import { TUserInvoice } from 'types';
import { getCurrencySymbol } from 'utils/currency';
import Payment from 'components/Payment/index';
import Modal from 'fragments/Modal';

const InvoiceBillRow = ({ data }: { data: TUserInvoice }) => {
    const { meta, timestamp, currency ,paid_amount} = data;
    const {status,productName,} = meta;
    const IS_DUE = useMemo(() => status.toLowerCase() === "due" ? true:false,[] );
    const [isPaymentModalOpen,setIsPaymentModalOpen] = useState(false);

    const openPaymentModal= () => setIsPaymentModalOpen(true);

    const closePaymentModal= () => setIsPaymentModalOpen(false);

    return (
      <div className={`grid grid-cols-3 pl-4 py-2 border-b border-indigo-300 ${IS_DUE ? "bg-text-blue bg-opacity-10":""}`}>
        <div className={`${IS_DUE ? "col-span-1" : "col-span-2"} gap-y-4 `}>
          <h3 className="text-lg xsm:text-2xl font-normal font-switzer">
            {moment?.(timestamp)?.format('MMM DD, YYYY')}
          </h3>
        </div>
        {IS_DUE && <div className="col-span-1 justify-self-center">
          <button 
            className="btn font-normal font-switzer pr-2" 
            onClick={openPaymentModal}>
            Pay Now
          </button>
        </div>}
        <div className="col-span-1 justify-self-end">
          <h3 className="text-lg xsm:text-2xl font-normal font-switzer pr-2">
            {getCurrencySymbol(currency)} {paid_amount}
          </h3>
        </div>
        <div className={`font-switzer text-base uppercase col-span-1 ${IS_DUE ? "text-red-400" : "text-text-blue"}`}>
            {status}
        </div>
        {/* <div className="col-span-2 justify-center">
          <span className="text-text-grey font-switzer flex flex-row text-sm sm:text-base">
            <span className='hidden xl:block'>Transaction ID-</span>
            {id}
          </span>
        </div> */}
        <div className='col-span-2 justify-self-end'>
          <span className="font-switzer text-base text-text-blue uppercase pr-2">{productName}</span>
        </div>
        <Modal isOpen={isPaymentModalOpen} close={closePaymentModal} size="lg" bgColor='bg-white'>
            <React.Fragment>
              <Payment  
                productId={data?.product_id?.toString()} 
                paymentId={data?.payment_id} 
                slotId=""
              />
            </React.Fragment>
        </Modal>
      </div>
    );
  };

export default InvoiceBillRow;