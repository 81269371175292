import React, { useState, useEffect, ReactNode, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { Carousel } from 'react-responsive-carousel';
import ReactTooltip from 'react-tooltip';
import { ToastContainer } from 'react-toastify';
import { css } from '@emotion/react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-toastify/dist/ReactToastify.css';

import useStore from 'globalstate';
import Card, { saveRegistrationDetails } from 'fragments/DefaultCard';
import {
  API_URL,
  GET_COURSE_URL,
  GET_MEETUP_URL,
  MY_EVENTSDATA_PATH,
  MY_PROGRAMSDATA_PATH,
  MY_UPCOMINGEVENTDATA_PATH,
  GET_PROGRAM_URL,
  MY_COURSESDATA_PATH,
  MY_PASTEVENTDATA_PATH,
  MY_TECHX_PROGRAMSDATA_PATH,
  DASHBOARD_PATH,
  MY_COMMUNITY_COURSESDATA_PATH,
  MY_COMMUNITY_PASTEVENTDATA_PATH,
} from 'constants/app';
import fetch from 'utils/fetch';
import Spinner from 'fragments/Spinner';
import ErrorMsg from 'components/Error';
import { TBanner, TMeetup, TProgram, TCourse } from 'types';
import globalEmitter from 'utils/emitter';
import UpdatedCard from 'fragments/UpdatedCard/UpdatedCard';
import UpdatedProgramCard from 'fragments/UpdatedCard/UpdatedProgramCard';
import { MY_TECHX_COURSESDATA_PATH, MY_COMMUNITY_PROGRAMSDATA_PATH } from '../../constants/app';

type TDashBoardTabs = 'ALL' | 'TECHX' | 'UNIV' | 'COMMUNITY';
type TdashboardTabsList = { name: TDashBoardTabs; id: number };

export default function Dashboard() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setloading] = useState<boolean>(true);

  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [meetups, setMeetups] = useState<TMeetup[]>([]); //all meetups
  const [myMeetups, setMyMeetups] = useState<TMeetup[]>([]); // all registered Meetups
  const [myMeetupsShown, setMyMeetupsShown] = useState<TMeetup[]>([]); // shown my meetups when clicked calendar day
  const [banner, setBanner] = useState<TBanner[]>([]);
  const [error, setError] = useState<string>();
  const [State] = useStore();
  // Univ meetups
  const [courseData, setCourseData] = useState<TCourse[]>([]); //all Courses
  const [programData, setProgramData] = useState<TProgram[]>([]); //all All Programs
  // TechX meetups
  const [myPastMeetups, setMyPastMeetups] = useState<TMeetup[]>([]);
  const [myProgramMeetups, setMyProgramMeetups] = useState<TMeetup[]>([]);
  const [myCoursesMeetups, setMyCoursesMeetups] = useState<TMeetup[]>([]);
  // Community Meetups
  const [myCommunityPastMeetups, setMyCommunityPastMeetups] = useState<TMeetup[]>([]);
  const [myCommunityProgramMeetups, setMyCommunityProgramMeetups] = useState<TMeetup[]>([]);
  const [myCommunityCoursesMeetups, setMyCommunityCoursesMeetups] = useState<TMeetup[]>([]);
  const [selectedTab, setSelectedTab] = useState<TDashBoardTabs>('UNIV');
  const { isAuthenticated } = State;
  const eventEmitter = globalEmitter;

  const dashboardTabsList: TdashboardTabsList[] = [
    // {
    //   name:"ALL",
    //   id:1,
    // },
    {
      name: 'UNIV',
      id: 2,
    },
    {
      name: 'TECHX',
      id: 3,
    },
    {
      name: 'COMMUNITY',
      id: 4,
    },
  ];

  const getMeetups = async () => {
    setloading(true);
    try {
      const resp = await fetch(`${GET_MEETUP_URL}?onlyActive=true&onlyUpcoming=false&onlyRegistered=false`, {
        credentials: 'header',
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const meetups = respJSON.data as unknown as TMeetup[];

      const techxMeetups: TMeetup[] = meetups.filter((val: TMeetup) => val.meta?.domain === 'techx');

      const communityMeetups: TMeetup[] = meetups.filter((val: TMeetup) => val.meta?.domain === 'community');

      const regMeetups = meetups.filter((val: TMeetup) => {
        return (!!val.registered_on || !val.meta.require_registration) && new Date(val.scheduled_datetime) > new Date();
      });

      // removed temp
      // const nonRegMeetups = meetups.filter((val: TMeetup) => {
      //   return !val.registered_on && new Date(val.scheduled_datetime) > new Date();
      // });

      // techX Meetups
      const pastMeetups = techxMeetups.filter((val: TMeetup) => {
        return (!!val.registered_on || !val.meta.require_registration) && new Date(val.scheduled_datetime) < new Date();
      });

      const myPrograms = techxMeetups.filter(
        (program: TMeetup) =>
          program.type.toLowerCase() === 'workshop' && (!!program.registered_on || !program.meta.require_registration)
      );

      const myCourses = techxMeetups.filter(
        (course: TMeetup) =>
          course.type.toLowerCase() === 'event' && (!!course.registered_on || !course.meta.require_registration)
      );

      // Community Meetups
      const communityPastMeetups = communityMeetups.filter((val: TMeetup) => {
        return (!!val.registered_on || !val.meta.require_registration) && new Date(val.scheduled_datetime) < new Date();
      });

      const communityMyPrograms = communityMeetups.filter(
        (program: TMeetup) =>
          program.type.toLowerCase() === 'workshop' && (!!program.registered_on || !program.meta.require_registration)
      );

      const communityMyCourses = communityMeetups.filter(
        (course: TMeetup) =>
          course.type.toLowerCase() === 'event' && (!!course.registered_on || !course.meta.require_registration)
      );

      setMeetups(meetups);
      setMyMeetups(regMeetups);
      setMyMeetupsShown(regMeetups);
      // techX meetups
      setMyProgramMeetups(myPrograms);
      setMyCoursesMeetups(myCourses);
      setMyPastMeetups(pastMeetups);
      //Community meetups
      setMyCommunityProgramMeetups(communityMyPrograms);
      setMyCommunityCoursesMeetups(communityMyCourses);
      setMyCommunityPastMeetups(communityPastMeetups);

      setloading(false);
    } catch (e: any) {
      setError(e.message);
      setloading(false);
    }
  };
  const getPrograms = async () => {
    setloading(true);
    try {
      const resp = await fetch(`${GET_PROGRAM_URL}?`, {
        credentials: 'header',
      });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const programs = respJSON.data as unknown as TProgram[];
      setProgramData(programs);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setloading(false);
    }
  };
  const getCourses = async () => {
    setloading(true);
    try {
      const resp = await fetch(`${GET_COURSE_URL}`, {
        credentials: 'header',
      });
      const respJSON = await resp.json();
      if (!respJSON.courses) throw new Error('Error');
      const courses = respJSON.courses as unknown as TCourse[];
      setCourseData(courses);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setloading(false);
    }
  };

  const onBannerClicked = (img: number, item: ReactNode) => {
    if (banner[img] && banner[img].onClick) {
      const tab = window.open(banner[img].onClick, '_blank');
      if (tab) tab.focus();
    }
    //if (window != null) window.open(banner[img].onClick, '_blank').focus();
  };

  const getBannerImages = async () => {
    setloading(true);
    try {
      const resp = await fetch(`${API_URL}/meetup/banner-media?key=MY_STUFF_BANNER`, { credentials: 'header' });
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      const bannerMedia = respJSON.data[0].value;

      setBanner(bannerMedia);
      setloading(false);
    } catch (e: any) {
      setError(e.message);
      setloading(false);
    }
  };

  const filterEvents = async (dateToFilter: Moment) => {
    const filteredMeetups = myMeetups.filter(val => {
      if (moment(val.scheduled_datetime).format('DD/MM/YYYY') == dateToFilter.format('DD/MM/YYYY')) return true;
      else return false;
    });
    return filteredMeetups;
  };
  const monitorEvents = () => {
    eventEmitter.on('refresh_meetups', () => {
      getPrograms();
      getCourses();
      getMeetups();
    });
  };
  useEffect(() => {
    if (!isAuthenticated) return;
    getPrograms();
    getCourses();
    getMeetups();
    getBannerImages();
    checkDesiredRegistration();
    monitorEvents();
    return () => {
      eventEmitter.removeAllListeners();
    };
  }, []);

  const checkDesiredRegistration = () => {
    const cnfrmReqReg = localStorage.getItem('regEvntCnfrmd');
    if (cnfrmReqReg) {
      const cnfrmEvent = JSON.parse(cnfrmReqReg);
      const data = cnfrmEvent.data;
      const eventId = cnfrmEvent.event.id;
      saveRegistrationDetails(data, eventId)
        .then(() => {
          getMeetups();
          eventEmitter.emit('openTechXRegModal', true);
        })
        .catch(e => {
          //sentry capture
        });
      localStorage.removeItem('regEvntCnfrmd');
    }
  };

  const onDaySelect = async (value: any, event: any) => {
    setSelectedDate(moment(value).toDate());
    const scheduledDates = meetups.map(val => {
      return moment(val.scheduled_datetime).format('DD/MM/YYYY');
    });
    if (scheduledDates.includes(moment(value).format('DD/MM/YYYY'))) {
      const events = await filterEvents(moment(value));
      setMyMeetupsShown(events);
      setIsFiltered(true);
    }
  };

  const resetFilter = () => {
    setIsFiltered(false);
    setMyMeetupsShown(myMeetups);
  };

  const bannerSlides = (): ReactElement[] => {
    const slides = banner.map((v: TBanner, i: number) => (
      <div key={`banner-image-${i}`}>
        <img
          src={v.img}
          data-v={v.onClick}
          className="object-left object-cover h-64 md:h-full md:object-contain w-full"
        />
      </div>
    ));
    return slides;
  };

  const EventRowHeaders = (title: string, subtitle: string, isEmpty: boolean) => {
    const getSeeAllLink = (name: string) => {
      if (title === 'My Programs' && selectedTab === 'TECHX') return MY_TECHX_PROGRAMSDATA_PATH;
      else if (title === 'My Courses' && selectedTab === 'TECHX') return MY_TECHX_COURSESDATA_PATH;
      else if (title === 'Past Programs & Courses' && selectedTab === 'TECHX') return MY_PASTEVENTDATA_PATH;

      if (title === 'My Programs' && selectedTab === 'COMMUNITY') return MY_COMMUNITY_PROGRAMSDATA_PATH;
      else if (title === 'My Courses' && selectedTab === 'COMMUNITY') return MY_COMMUNITY_COURSESDATA_PATH;
      else if (title === 'Past Programs & Courses' && selectedTab === 'COMMUNITY')
        return MY_COMMUNITY_PASTEVENTDATA_PATH;
      else if (title === 'My Events') return MY_EVENTSDATA_PATH;
      else if (title === 'New Events') return MY_UPCOMINGEVENTDATA_PATH;
      else return DASHBOARD_PATH;
    };
    const getSeeAllButtonName = (name: string) => {
      if (title === 'My Programs') return 'See All Programs';
      else if (title === 'My Courses') return 'See All Courses';
      else if (title === 'Past Programs & Courses') return 'See All';
      else if (title === 'My Events') return 'See All Events';
      else if (title === 'New Events') return 'See All Events';
      else return 'See All';
    };

    return (
      <div className="flex flex-row justify-between items-start mb-4">
        <div className="flex flex-col">
          <h2 className="text-base md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl font-medium font-switzer">{title}</h2>
          <p className="text-sm text-gray-400 font-light font-switzer mb-0">{subtitle}</p>
        </div>

        {!isEmpty && (
          <div className="">
            <Link
              to={getSeeAllLink(title)}
              className="font-switzer text-text-grey hover:text-text-blue text-xs md:text-base lg:text-lg 2xl:text-xl cursor-pointer"
            >
              {getSeeAllButtonName(title)}
            </Link>
          </div>
        )}
      </div>
    );
  };
  type TPropsEventRowdata = { data: TMeetup[]; title: string; subtitle: string; emptyMsg: string };
  const EventRow = (props: TPropsEventRowdata) => {
    const { data, title, subtitle, emptyMsg } = props;

    return data.length == 0 ? (
      EventRowHeaders(title, emptyMsg, true)
    ) : (
      <div className="my-4">
        {EventRowHeaders(title, subtitle, false)}
        <div className="grid lg:h-60 3xl:h-360px lg:overflow-y-hidden xl:grid-cols-16 lg:grid-cols-12 grid-cols-8 3xl:gap-8 gap-4">
          {/* <AnimatedList animation={'grow'}> */}
          {data.slice(0, 4).map((v, i) => {
            return (
              <UpdatedCard
                data={v}
                key={`meetup-` + name + i}
                onStateChange={() => {
                  getMeetups();
                }}
              />
            );
          })}
          {/* </AnimatedList> */}
        </div>
      </div>
    );
  };

  type TPropsProgramRowdata = {
    data: TProgram[] | TCourse[];
    title: string;
    subtitle: string;
    emptyMsg: string;
    type: 'Program' | 'Course';
  };
  const ProgramRow = (props: TPropsProgramRowdata) => {
    const { data, title, subtitle, emptyMsg, type } = props;

    return data?.length === 0 ? (
      <div className="mt-5">
        <div>
          <h2 className="text-base md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl font-medium font-switzer lg:max-w-md ">
            {' '}
            {title}
          </h2>
        </div>

        <div className="text-base md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl font-medium font-switzer">
          <h4 className="text-sm text-gray-400 font-light font-switzer mb-4 ">{emptyMsg}</h4>
        </div>
      </div>
    ) : (
      <div className="my-4">
        <div className="flex flex-row justify-between items-start mb-4">
          <div className="flex flex-col">
            <h2 className="text-base md:text-xl lg:text-xl xl:text-2xl 2xl:text-3xl font-medium font-switzer">
              {title}
            </h2>
            <p className="text-sm text-gray-400 font-light font-switzer mb-0">{subtitle}</p>
          </div>

          <div className="">
            <Link
              to={title == 'My Programs' ? MY_PROGRAMSDATA_PATH : MY_COURSESDATA_PATH}
              className="font-switzer text-text-grey hover:text-text-blue text-xs md:text-base lg:text-lg 2xl:text-xl cursor-pointer"
            >
              {title == 'My Programs' ? 'See All Program' : 'See All Courses'}
            </Link>
          </div>
        </div>

        <div className="grid lg:h-60 3xl:h-360px lg:overflow-y-hidden xl:grid-cols-16 lg:grid-cols-12 grid-cols-8 3xl:gap-8 gap-4">
          {/* <AnimatedList animation={'grow'}> */}
          {data?.slice(0, parseInt(`${type === 'Program' ? 2 : 4}`)).map((v, i) => {
            return (
              <UpdatedProgramCard
                data={v}
                type={type}
                key={`${type}-meetup-` + title + i}
                onStateChange={() => {
                  getMeetups();
                }}
              />
            );
          })}
          {/* </AnimatedList> */}
        </div>
      </div>
    );
  };
  return (
    <div className="">
      <h3 className="font-switzer text-text-blue font-normal text-sm h-8 3xl:text-base 3xl:h-10 flex items-center">
        Dashboard
      </h3>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <ReactTooltip />
      <div className={` ${isAuthenticated ? 'grid' : ''} `} css={css`grid-template-colums:20fr 1fr;`}>
        {(() => {
          if (!isAuthenticated) return <ErrorMsg embed={false} preset="login" text={error} />;
          if (loading) return <Spinner space show />;
          if (error) return <ErrorMsg embed={false} preset="broken" text={error} />;
          return (
            <React.Fragment>
              <div>
                {banner.length > 0 && (
                  <Carousel
                    className=" w-full"
                    infiniteLoop={true}
                    showThumbs={false}
                    emulateTouch={true}
                    dynamicHeight={false}
                    autoPlay={true}
                    autoFocus={true}
                    onClickItem={onBannerClicked}
                  >
                    {bannerSlides()}
                  </Carousel>
                )}
                <div className="flex flex-row items-center my-4">
                  {dashboardTabsList &&
                    dashboardTabsList.map((tab: TdashboardTabsList) => (
                      <button
                        key={`dashbord-tab-${tab.id}`}
                        className={`mr-4 px-6 shadow-md font-switzer rounded-md font-semibold  text-base md:text-lg  hover:bg-text-blue hover:text-white cursor-pointer ${
                          selectedTab === tab.name ? 'bg-text-blue text-white' : 'bg-navgreybg text-text-blue'
                        }`}
                        onClick={() => setSelectedTab(tab.name)}
                      >
                        {tab.name}
                      </button>
                    ))}
                </div>
                {(selectedTab === 'ALL' || selectedTab === 'UNIV') && (
                  <React.Fragment>
                    <ProgramRow
                      data={programData}
                      type={'Program'}
                      title={'My Programs'}
                      subtitle={'Our Flagship offerings'}
                      emptyMsg={'It Seems you are not enrolled in any Univ programs.'}
                    />
                    <ProgramRow
                      data={courseData}
                      type={'Course'}
                      title={'My Courses'}
                      subtitle={'Short duration courses'}
                      emptyMsg={'It Seems you are not enrolled in any Univ course.'}
                    />
                  </React.Fragment>
                )}
                {(selectedTab === 'ALL' || selectedTab === 'TECHX') && (
                  <React.Fragment>
                    <EventRow
                      data={myProgramMeetups}
                      title={'My Programs'}
                      subtitle={'TechX programs you have enrolled for'}
                      emptyMsg={'It Seems you are not enrolled in any TechX programs.'}
                    />
                    <EventRow
                      data={myCoursesMeetups}
                      title={'My Courses'}
                      subtitle={'TechX courses you have enrolled for'}
                      emptyMsg={'It Seems you are not enrolled in any TechX courses.'}
                    />
                    <EventRow
                      data={myPastMeetups}
                      title={'Past Programs & Courses'}
                      subtitle={'Re-run Recorded Programs & Courses that you have attended.'}
                      emptyMsg={'No Past Programs & Courses, check back later , soon.'}
                    />
                    {/* Remove previous meetups Event Rows */}
                    {/* <EventRow
                      data={myMeetupsShown}
                      title={'My Events'}
                      subtitle={'TechX Events you have enrolled for'}
                      emptyMsg={'It Seems you are not enrolled in any TechX Events.'}
                    />
                    <EventRow
                      data={nonRegisteredMeetups}
                      title={'New Events'}
                      subtitle={'Register for the below events to participate.'}
                      emptyMsg={'No Upcoming unregistered Events, check back later , soon.'}
                    />
                    <EventRow
                      data={myPastMeetups}
                      title={'Past Events'}
                      subtitle={'Re-run Recorded Events that you have attended.'}
                      emptyMsg={'No Past Events, check back later , soon.'}
                    /> */}
                  </React.Fragment>
                )}
                {(selectedTab === 'ALL' || selectedTab === 'COMMUNITY') && (
                  <React.Fragment>
                    <EventRow
                      data={myCommunityProgramMeetups}
                      title={'My Programs'}
                      subtitle={'Community programs you have enrolled for'}
                      emptyMsg={'It Seems you are not enrolled in any Community programs.'}
                    />
                    <EventRow
                      data={myCommunityCoursesMeetups}
                      title={'My Courses'}
                      subtitle={'Community courses you have enrolled for'}
                      emptyMsg={'It Seems you are not enrolled in any Community courses.'}
                    />
                    <EventRow
                      data={myCommunityPastMeetups}
                      title={'Past Programs & Courses'}
                      subtitle={'Re-run Recorded Programs & Courses that you have attended.'}
                      emptyMsg={'No Past Programs & Courses, check back later , soon.'}
                    />
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          );
        })()}
      </div>
    </div>
  );
}
