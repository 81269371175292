import React from 'react';
import { HiLocationMarker, HiOfficeBuilding } from 'react-icons/hi';
import { FaUserCircle, FaRegCalendarAlt, FaTimesCircle, FaTimes } from 'react-icons/fa';
import { AiFillRightCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import { TJobApplication, TJobStatus } from '../../types';
import { useHistory } from 'react-router-dom';
import { JOBS_PATH } from 'constants/app';
import Modal from 'fragments/Modal';
import { useState } from 'react';
import { JobStatusChip } from 'components/Jobs/JobsUtils';

type TProps = {
  data: TJobApplication;
  onStateChange?: Function;
};

const JobApplicationCard = (props: TProps) => {
  const { job_id, title, position, salary_start, salary_end, location, company_name, company_logo, status, meta } =
    props.data;

  const [viewApplicaionModal, setViewApplicationModal] = useState<boolean>(false);
  const history = useHistory();
  const goToJobDeatils = () => history.push(`${JOBS_PATH}/${job_id}`);

  const openApplicationModal = () => setViewApplicationModal(true);

  const closeApplicationModal = () => setViewApplicationModal(false);

  return (
    <div className="col-span-8 xsm:col-span-12">
      <ReactTooltip />
      <div
        className={`3xl:h-180px lg:h-120px h-36 flex flex-col xsm:flex-row-reverse bg-white rounded overflow-hidden font-switzer shadow-lg relative px-4`}
      >
        <div className="flex flex-row gap-4 w-full">
          {/* Company Logo */}
          {company_logo ? (
            <img
              src={company_logo}
              alt={company_name}
              className="3xl:w-36 3xl:min-w-36 3xl:h-36 w-88px min-w-88px h-88px object-contain my-4"
            />
          ) : (
            <div className="3xl:w-36 3xl:min-w-36 3xl:h-36 w-88px min-w-88px h-88px bg-JobBluebg 3xl:text-6xl text-4xl rounded-full flex justify-center items-center text-text-grey my-4">
              {company_name.split('')[0]}
            </div>
          )}
          <div className="w-full">
            <div className="flex flex-row items-center justify-between mt-1 xsm:mt-2">
              {/* Position Title */}
              <h2 className="3xl:text-3xl text-base text-text-dark">{title}</h2>
              {/* Source*/}
              <h2 className="3xl:text-xl text-sm hidden xsm:block text-text-blue">Univ</h2>
            </div>
            {/* Job Basic Info */}
            <div className="flex flex-col xsm:flex-row items-start xsm:items-center gap-0 xsm:gap-4 lg:gap-0 my-1">
              <span className="flex items-center lg:w-1/4">
                <FaUserCircle className="mr-1 text-text-grey-light" />
                <h3 className="3xl:text-xl text-sm text-text-grey-light">{position}</h3>
              </span>
              <span className="flex items-center lg:w-1/3">
                <HiOfficeBuilding className="mr-1 text-text-grey-light" />
                <h3 className="3xl:text-xl text-sm text-text-grey-light">{company_name}</h3>
              </span>
              <span className="flex items-center lg:w-1/5 ">
                <HiLocationMarker className="mr-1 text-text-grey-light" />
                <h3 className="3xl:text-xl text-sm text-text-grey-light">{location}</h3>
              </span>
              <span className="lg:flex items-center w-1/6 hidden">
                <HiLocationMarker className="mr-1 text-text-grey-light" />
                <h3 className="3xl:text-xl text-sm text-text-grey-light">{`${salary_start}K - ${salary_end}K`}</h3>
              </span>
            </div>
            <div className="flex flex-row gap-4 justify-start 3xl:my-2 my-1">
              {/* View Application Button */}
              {/* <div className="text-text-blue flex flex-row items-center cursor-pointer text-space" onClick = {openApplicationModal}>
                  <span className="mr-2 text-sm 3xl:text-xl font-semibold leading-none">View Application</span>
                  <AiFillRightCircle className="text-base 3xl:text-xl" />
                </div> */}

              {/* Learn More */}
              <div
                className="text-text-blue flex flex-row items-center cursor-pointer text-space"
                onClick={goToJobDeatils}
              >
                <span className="mr-2 text-sm 3xl:text-xl font-semibold leading-none">Job Details</span>
                <AiFillRightCircle className="text-base 3xl:text-xl" />
              </div>
            </div>
            <JobStatusChip status={status} />
          </div>
        </div>
      </div>
      <Modal isOpen={viewApplicaionModal} size="lg" bgColor="bg-white" close={closeApplicationModal}>
        <React.Fragment>
          <h2 className="text-center text-xl 3xl:text-3xl text-text-blue font-bold mb-4">
            {position} at {company_name}
          </h2>
          <div className="my-2">
            <h3 className="text-left text-lg 3xl:text-2xl text-text-dark mb-2">Brief SOP for recruiter</h3>
            <textarea
              cols={30}
              rows={10}
              placeholder={`Please elaborate why you think you are best fit for ${position} job at ${company_name} .`}
              value={meta.sop}
              className="3xl:text-xl"
              disabled
            />
          </div>
          <div className="flex flex-row items-center justify-end gap-4 my-2">
            <button className="bg-text-blue 3xl:text-xl" onClick={closeApplicationModal}>
              Cancel
            </button>
          </div>
        </React.Fragment>
      </Modal>
    </div>
  );
};

export default JobApplicationCard;
