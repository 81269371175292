export function throttle(fn: Function, wait = 300) {
  let time = Date.now();
  return function (...args: any) {
    if (time + wait - Date.now() < 0) {
      fn(args);
      time = Date.now();
    }
  };
}

export function debounce(fn: Function, wait = 300) {
  let debounceTimer: number;
  return function (...args: any) {
    if (debounceTimer) window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(() => {
      fn(args);
    }, wait);
  };
}
