import React from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';

interface IProps {
  isOpen: boolean;
  close: () => void;
  children: JSX.Element | null;
  size?: 'sm' | 'md' | 'lg';
  onTop?: boolean;
  bgColor?:string;
}

export default function (props: IProps) {
  const { close, isOpen, children, size = 'sm', onTop = false,bgColor="bg-gray-50" } = props;
  const sizeClasses = (() => {
    if (size === 'sm') return 'xl:w-5/12 lg:w-6/12 md:w-7/12 sm:w-8/12';
    if (size === 'md') return 'xl:w-6/12 lg:w-7/12 md:w-8/12 sm:w-9/12';
    if (size === 'lg') return 'xl:w-7/12 lg:w-8/12 md:w-9/12 sm:w-10/12';
  })();
  const zIndex = onTop ? 'z-20' : 'z-10';
  return (
    <Modal
      isOpen={isOpen}
      portalClassName="absolute top-0 left-0"
      overlayClassName={`fixed bg-black bg-opacity-70 h-screen w-screen flex items-center justify-center ${zIndex}`}
      className={`p-4 m-4 w-full overflow-x-hidden ${sizeClasses} md:px-12 md:py-8 ${bgColor}`}
      onRequestClose={close}
      style={{
        content: {
          position: 'relative',
          padding: '0',
          paddingTop: '0',
          paddingBottom: '0',
          paddingLeft: '0',
          paddingRight: '0',
        }
      }}
    >
      <div className="absolute cursor-pointer text-2xl right-0 top-0 text-red-600 p-2" onClick={close}>
        <FaTimes />
      </div>
      <div className="h-full w-full">{children}</div>
    </Modal>
  );
}
