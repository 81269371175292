/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { FaLongArrowAltLeft } from 'react-icons/fa';

import { ERROR_PALE, PALEST_SEPARATOR, SUCCESS_PALE } from 'constants/color';
import InfoLine from 'components/Form/infoLine2';

interface IProps {
  appliedCode: string;
  clearAppliedCode: () => void;
  submitCode: (code: string) => void;
  isCodeValid?: boolean;
}

export default function Discount(props: IProps) {
  const { appliedCode, clearAppliedCode, isCodeValid, submitCode } = props;
  const [isOpen, setisOpen] = useState<boolean>(false);
  const [code, setcode] = useState<string>();
  const [error, seterror] = useState<string>();

  const onSubmit = () => {
    if (!code) {
      seterror('Type your code to proceed');
      return;
    }

    submitCode(code);
  };

  if (appliedCode)
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-weight: 300;
          font-size: 0.9rem;
          padding: 1rem;
          display: flex;
          justify-content: center;
          border-top: 1px solid ${PALEST_SEPARATOR};
        `}
      >
        <span
          css={css`
            color: ${isCodeValid ? SUCCESS_PALE : ERROR_PALE};
            margin-right: 5px;
          `}
        >
          {appliedCode}
        </span>
        <div className="pr-4">{isCodeValid ? 'has been applied ' : 'is not a valid code '}</div>
        <button onClick={clearAppliedCode} className="button is-white">
          <FaLongArrowAltLeft className="text-lg " />
        </button>
      </div>
    );

  if (!isOpen)
    return (
      <div
        css={css`
          padding: 0.33rem;
          display: flex;
          justify-content: center;
          border-top: 1px solid ${PALEST_SEPARATOR};
        `}
      >
        <button
          className="button is-white"
          onClick={() => setisOpen(true)}
          css={css`
            text-transform: uppercase;
            font-weight: 300;
            font-size: 0.9rem;
          `}
        >
          Have a code for tuition credits?
        </button>
      </div>
    );

  return (
    <div
      css={css`
        padding: 1rem;
        border-top: 1px solid ${PALEST_SEPARATOR};
        display:flex;
        flex-flow:column;
      `}
    >
      <div
        css={css`
        display:flex;
        flex-flow:row;
      `}
      >
        <input
          className={`input ${isCodeValid === false ? 'is-danger' : ''}`}
          css={css`
            width: 69%;
            margin-right: 2%;
          `}
          placeholder="DSS832J3F08FKS"
          onChange={e => setcode(e.target.value)}
          onFocus={() => {
            clearAppliedCode();
            seterror('');
          }}
          size={20}
          value={code}
        />
        <button
          onClick={onSubmit}
          css={css`
            width: 29%;
          `}
          className="button is-light"
        >
          Apply Code
        </button>
      </div>
      {error && <InfoLine info={error} cssString={`margin-top: 0.33rem; font-weight: 500;`} />}
    </div>
  );
}
