import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  FormComponentHolder,
  FormInput,
  FormRadio,
  FormSelect,
  preProcessForm,
  InfoLine,
  FormCheck,
  FormPhone,
  // FormFile,
} from 'components/Form';
import { TFormFields } from 'types';

import Spinner from 'fragments/Spinner';
// import { FormPhone } from 'components/Form/Phone';

type TProps = {
  onUpdate?: Function;
  closeModal?: Function;
  isActive?: boolean;
  formFields: TFormFields[];
  eventName: string;
};

export default function DynamicForm(props: TProps) {
  const { isActive = true, formFields, eventName } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [error] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  // } = useForm({ defaultValues });

  const formValues = watch(); // watching every fields in the form

  const publishForm = (data: { [key: string]: any }) => {
    setSubmitting(true);
    setLoading(true);

    if (props.onUpdate) props.onUpdate(data);
    if (props.closeModal != undefined) props.closeModal();
    setSubmitting(false);
    setLoading(false);
  };

  const processedFormFields = preProcessForm(formFields);
  const inputParams = processedFormFields.map(f => ({
    ...f,
    errors,
    error: errors[`${f.name}`] && errors[`${f.name}`].message,
    fieldProps: register(f.name, f.constraints),
    refGenerator: register,
    formValues,
  }));

  const cancel = () => {
    if (props.closeModal) props.closeModal();
  };

  if (loading) return <Spinner bar />;

  return (
    <div>
      <p className="font-switzer font-semibold text-center text-grey-400 text-base md:text-lg capitalize">{eventName}</p>
      <p className="font-switzer text-center text-grey-400 text-sm">
        Please fill the required fields and click Register to Register for the Event.
      </p>
      <fieldset disabled={!isActive}>
        <React.Fragment>
          <form onSubmit={submitting ? undefined : handleSubmit(publishForm)} className="w-full m-2 p-8">
            {inputParams.map(ip => {
              if (ip.type === 'input' || ip.type === 'phone')
                return <FormComponentHolder FormComponent={FormInput} {...ip} className="p-8" />;
              if (ip.type === 'check') return <FormComponentHolder FormComponent={FormCheck} {...ip} className="p-8" />;
              if (ip.type === 'dropdown')
                return <FormComponentHolder FormComponent={FormSelect} {...ip} className="p-8" />;
              // if (ip.type === 'phone') return <FormComponentHolder FormComponent={FormPhone} {...ip} />;
              if (ip.type === 'radio') return <FormComponentHolder FormComponent={FormRadio} {...ip} className="p-8" />;
              // if (ip.type === 'file' )
              //   return <FormComponentHolder FormComponent={FormFile} {...ip} />;
            })}
            {error ? <InfoLine info={error} /> : null}
            <hr />
            <div className="my-2 flex items-center justify-between mt-4">
              <button type="button" className="button secondary" onClick={cancel}>
                {'Cancel'}
              </button>
              <button type="submit" className="button">
                {submitting ? <Spinner size="tiny" /> : null}
                {'Register '}
              </button>
            </div>
          </form>
        </React.Fragment>
      </fieldset>
    </div>
  );
}
