import { TFormFields } from 'types';

const getError = (key: string, value: number | { value: number | string | RegExp; message: string }) => {
  if (key === 'required') return 'Required';
  if (key === 'maxLength') return `Maximum of ${value} characters`;
  if (key === 'minLength') return `Minimum of ${value} characters`;
};

const processConstraints = (key: string, value: number | { value: number | string | RegExp; message: string }) => {
  // bound with message { value: number; message: string; }
  if (typeof value === 'object') return value;
  // no message - generate
  return { value, message: getError(key, value) };
};

export function preProcessForm(formFields: TFormFields[]) {
  return formFields.map((f: any) => {
    const formProps = f.props ? { ...f.props } : {};
    if (f.constraints) {
      for (const constraintKey in f.constraints)
        f.constraints[`${constraintKey}`] = processConstraints(constraintKey, f.constraints[constraintKey]);
      formProps.constraints = f.constraints;
    }
    return { ...f, props: formProps };
  });
}

export function preProcessFormObject(formFields: { [key: string]: TFormFields }) {
  const processedFields: any = {};
  for (const field in formFields) {
    const f = formFields[field] as any;
    const formProps = f.props ? { ...f.props } : {};
    if (f.constraints) {
      for (const constraintKey in f.constraints) {
        if (constraintKey === 'pattern') {
          const patternObj = f.constraints[constraintKey];
          // eg:-  /RegExpattern/ => RegExpattern
          const RegExPattern = patternObj['value']?.substring(1, patternObj['value'].length - 1);

          f.constraints[`${constraintKey}`] = processConstraints(constraintKey, {
            value: RegExp(RegExPattern),
            message: patternObj['message'],
          });

          break;
        }
        f.constraints[`${constraintKey}`] = processConstraints(constraintKey, f.constraints[constraintKey]);
      }
      formProps.constraints = f.constraints;
    }
    processedFields[field] = { ...f, props: formProps };
  }
  return processedFields;
}

export function postProcessForm(formFields: TFormFields[], data: { [key: string]: any }) {
  const dataKeys = Object.keys(data);

  for (const formField of formFields)
    if (formField.type === 'multiinput') {
      // example: questionname-arrayindex-value
      const multiInputRegex = /(?:.*)-(.*)-(.*)/;
      if (dataKeys.some(k => k.startsWith(formField.name))) {
        const questionVals = dataKeys.filter(k => k.startsWith(formField.name));
        data[`${formField.name}`] = [];
        for (const qv of questionVals) {
          const maybeMatch = qv.match(multiInputRegex);
          if (!maybeMatch) continue;

          const [, index, subfield] = maybeMatch;
          if (!data[`${formField.name}`][index]) data[`${formField.name}`][index] = {};
          data[`${formField.name}`][index][subfield] = data[`${qv}`];

          delete data[`${qv}`];
        }
      }
    }

  return data;
}
