import React ,{useState,useEffect,useMemo} from "react";
import fetch from "utils/fetch";
import { API_URL } from 'constants/app';
import globalEmitter from "utils/emitter";
import UpdatedCard from 'fragments/UpdatedCard/UpdatedCard';
import { TMeetup } from 'types';
import Spinner from 'fragments/Spinner';
import ErrorMsg from 'components/Error';
import moment from "moment";

type TFilters = 'All' | 'Past' | 'Upcoming';

const Community = ()=>{
    const [loading, setloading] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    const [communityMeetups,setCommunityMeetups] = useState<TMeetup[]>();
    const [filteredType,setFilteredType] = useState<TFilters>("Upcoming");

    const eventEmitter = globalEmitter;

    const getMeetups = async () => {
        setloading(true);
        try {
          const resp = await fetch(`${API_URL}/meetup/events?onlyActive=true&onlyUpcoming=false&domain=community&&onlyRegistered=false`, {
            credentials: 'header',
          });
          const respJSON = await resp.json();
          if (!respJSON.success) throw new Error(respJSON.error);
          const tmeetups:TMeetup[] = await respJSON.data;
          setCommunityMeetups(tmeetups);
        } catch (e: any) {
          setError(e.message);
        } finally {
          setloading(false);
        }
      };


      useEffect(() => {
        getMeetups();
        const ev = eventEmitter.on('refresh_meetups', () => {
          getMeetups();
        });
        return () => {
          eventEmitter.removeAllListeners();
        };
      }, []);
      

      const filterByUpcoming = () => {
        const data = communityMeetups?.filter((val: TMeetup) => new Date(val.scheduled_datetime) > new Date() || new Date(val.end_datetime) > new Date());
        return (data);
      };
      const filterByPast = () => {
        const data = communityMeetups?.filter((val: TMeetup) => new Date(val.scheduled_datetime) < new Date() && new Date(val.end_datetime) < new Date() );
        return (data);
      };
      const filterByAll = () => {
        return (communityMeetups?.sort((a:TMeetup,b:TMeetup)=>{
          return moment(b.end_datetime).diff(a.end_datetime);
        }));
      };
      
      const filterMeetups = useMemo(()=>{
        if(filteredType === "All") return filterByAll();
        else if (filteredType==="Past") return filterByPast();
        else if (filteredType ==="Upcoming") return filterByUpcoming();
      },[filteredType,communityMeetups]);
    
      const filterNamesList: { name: TFilters }[] = [
        {
          name: 'Upcoming',
        },
        {
          name: 'All',
        },
        {
          name: 'Past',
        },
      ];
      
      return (() => {
        if (loading) return <Spinner space show />;
        // if (!isAuthenticated) return <ErrorMsg preset="login" text={error} />;
        if (error) return <ErrorMsg preset="broken" text={error} />;
        return (
          <div className="flex flex-col w-full h-full">
            <div className="w-full h-8 3xl:h-10 flex flex-row items-center justify-between bg-greybg sticky top-0 right-0 z-10">
              <h3 className="font-switzer text-sm 3xl:text-base text-text-blue font-normal">Community</h3>
              <div className="flex flex-row items-center">
                {filterNamesList &&
                  filterNamesList.map(val => (
                    <h3
                      className={`text-sm 3xl:text-base ${
                        filteredType === val.name ? 'text-text-blue' : 'text-text-grey'
                      } hover:text-text-blue font-normal mx-2 font-switzer cursor-pointer`}
                      onClick={() => setFilteredType(val.name)}
                      key={`filter-Names-${val.name}`}
                    >
                      {val.name}
                    </h3>
                  ))}
              </div>
            </div>
            {(!filterMeetups) && <ErrorMsg preset="nothing" text={`Program / Courses are are not available at the moment`} />}

            {(filterMeetups && filterMeetups?.length === 0) && <ErrorMsg preset="nothing" text={`${filteredType=== "Past" || filteredType=== "Upcoming" ? `${filteredType} Programs / Courses`: "Programs / Courses"  } are not available at the moment`} />}
            <div className="grid xl:grid-cols-16 lg:grid-cols-12 grid-cols-8 3xl:gap-8 gap-4">
              {filterMeetups && filterMeetups?.length > 0 && (
                <>
                  {filterMeetups?.map((v: TMeetup, i: number) => {
                    return (
                      <UpdatedCard
                        data={v}
                        key={'Community-meetup-' + i}
                        onStateChange={() => {
                          getMeetups();
                        }}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
        );
      })();
};
    

export default Community;