/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { FaExternalLinkAlt, FaTimesCircle } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import Spinner from 'fragments/Spinner';

import fetch from 'utils/fetch';

import { DEFAULT_TEXT, ERROR, PRIMARY_APP } from 'constants/color';
import { RAZOR_PAYMENT_URL } from 'constants/app';
import Markdown from 'markdown-to-jsx';

declare global {
  interface Window {
    Razorpay: any;
  }
}

interface IProps {
  applicationId: string;
  code: string;
  fromURL: string;
  successURL: string;
  productId: string;
  paymentId: string;
  description?: string;
  slotId: string;
  setActiveStep: Function | null;
}

type TRazorOrderData = {
  key: string;
  amount: number;
  currency: string;
  orderId: string;
  name: string;
  description: string;
  image: string;
  user: {
    name: string;
    email: string;
  };
};

function Checkout(props: IProps) {
  const { applicationId = '', code, paymentId, productId, fromURL, successURL, setActiveStep, slotId } = props;

  const [razorData, setRazorData] = useState<TRazorOrderData | null>(null);
  const [error, setError] = useState<string>('');

  const getRazorDataURL = async () => {
    setRazorData(null);
    const queryParams = {
      applicationId,
      code,
      paymentId,
      productId,
      fromURL,
      successURL,
      slotId,
    };
    const queryString = new URLSearchParams(queryParams).toString();

    const sessionResult = await fetch(`${RAZOR_PAYMENT_URL}?${queryString}`, {
      credentials: 'header',
    });

    const razorJSON = await sessionResult.json();
    if (sessionResult.status !== 200) return setError(razorJSON.error);
    const razorData = razorJSON.data;
    setRazorData(razorData);
  };

  const onPay = () => {
    if (setActiveStep || setActiveStep !== null) setActiveStep(3);
    else window.location.href = successURL;
  };

  const initiatePayment = () => {
    if (!razorData) return;

    const options = {
      key: razorData.key,
      amount: razorData.amount,
      currency: razorData.currency,
      order_id: razorData.orderId,
      buttontext: 'Pay with Razorpay',
      name: razorData.name,
      description: razorData.description,
      handler: onPay,
      image: razorData.image,
      prefill: razorData.user,
      theme: { color: PRIMARY_APP },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    // check if checkout.js had loaded before proceeding
    const maybeGetData = () => {
      if (window.Razorpay) getRazorDataURL();
      else
        setTimeout(() => {
          maybeGetData();
        }, 2000);
    };

    maybeGetData();
  }, [code]);

  return (
    <React.Fragment>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      </Helmet>
      <div
        css={css`
          padding: 2rem;
        `}
      >
        {(() => {
          if (error)
            return (
              <div
                css={css`
                  color: ${DEFAULT_TEXT};
                  padding: 1rem;
                  text-align: center;
                  font-weight: 300;
                  @media (max-width: 768px) {
                    font-size: 18px;
                  }
                `}
              >
                <FaTimesCircle
                  css={css`
                    margin-right: 1rem;
                    color: ${ERROR};
                  `}
                />
                {error}
              </div>
            );

          return razorData ? (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              `}
            >
              {props.description && (
                <p
                  css={css`
                    font-family: Space Grotesk;
                    margin-bottom: 3rem !important;
                    font-weight: 300;
                    font-size: 1rem;
                    @media (max-width: 768px) {
                      font-size: 0.95rem;
                    }
                  `}
                >
                  <Markdown>{props.description}</Markdown>
                </p>
              )}
              <button className="button is-primary" onClick={initiatePayment}>
                Initiate Payment
                <FaExternalLinkAlt
                  css={css`
                    margin-left: 1rem;
                    color: white;
                  `}
                />
              </button>
            </div>
          ) : (
            <div
              css={css`
                text-align: center;
                padding: 2rem;
                position:relative;
              `}
            >
              <Spinner space show />
              <div
                css={css`
                  margin-bottom: 1rem;
                  font-weight: 600;
                  font-size: 2rem;
                `}
              >
                Preparing payment
              </div>
            </div>
          );
        })()}
      </div>
    </React.Fragment>
  );
}

export default Checkout;
