import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PROFILE_URL } from 'constants/app';
import fetch from 'utils/fetch';

import { USER_URL } from 'constants/app';
import { TFormFields, TProcessedFormFieldList } from 'types';
import { preProcessFormObject } from 'components/Form';

export default function useFields() {
  const [fields, setfields] = useState<TFormFields[]>();
  const [backgroundFields, setBackgroundFields] = useState<TProcessedFormFieldList>();
  const [schoolEducationFields, setschoolEducationFields] = useState<TProcessedFormFieldList>();
  const [ugEducationFields, setugEducationFields] = useState<TProcessedFormFieldList>();
  const [pgEducationFields, setpgEducationFields] = useState<TProcessedFormFieldList>();
  const [phdEducationFields, setphdEducationFields] = useState<TProcessedFormFieldList>();
  const [overallExperienceFields, setoverallExperienceFields] = useState<TProcessedFormFieldList>();
  const [currentWorkFields, setcurrentWorkFields] = useState<TProcessedFormFieldList>();
  const [pastWorkFields, setpastWorkFields] = useState<TProcessedFormFieldList>();
  const [admissionCourseFields, setadmissionCourseFields] = useState<TProcessedFormFieldList>();
  const [admissionProgramFields, setadmissionProgramFields] = useState<TProcessedFormFieldList>();
  const [hackathonFields, sethackathonFields] = useState<TProcessedFormFieldList>();
  const [miscellaneousFields, setmiscellaneousFields] = useState<TProcessedFormFieldList>();
  const [miscellaneousExtraFields, setmiscellaneousExtraFields] = useState<TProcessedFormFieldList>();
  const [fieldserror, setfieldserror] = useState<string>();

  const buildBackgroundFields = (fields: TFormFields) => {
    setBackgroundFields(
      preProcessFormObject({
        picture: fields.picture,
        'sname-firstname': { ...fields.sname.firstname, name: 'sname-firstname' },
        'sname-lastname': { ...fields.sname.lastname, name: 'sname-lastname' },
        gender: fields.gender,
        profileBlurb: fields.blurb,
        profileTopSkill: fields.topskills,
        birthYear: fields.birthYear,
        currentCity: fields.cCity,
        phone: fields.phone,
        iemail: fields.iemail,
        isLookingPlacement: fields.isLookingPlacement,
        hasValidInstitutionalMail: fields.hasValidInstitutionalMail,
        idcard: fields.idcard,
        isWorking: fields.isWorking,
        highestEducation: fields.highestEducation,
        linkedinUrl: fields.linkedinUrl,
        username: {
          label: 'Username',
          name: 'username',
          placeholder: 'Username',
          props: { disabled: true },
        },
        email: {
          label: 'Email',
          name: 'email',
          placeholder: 'Email',
          props: { disabled: true },
        },
      })
    );
  };

  const buildWorkFields = (fields: TFormFields) => {
    setoverallExperienceFields(
      preProcessFormObject({
        dataScienceExperience: { ...fields.dataScienceExperience, name: 'dataScienceExperience' },
        softwareDevelopmentExperience: {
          ...fields.softwareDevelopmentExperience,
          name: 'softwareDevelopmentExperience',
        },
        'workExperience-progLangBeginner': {
          ...fields.workExperience.progLangBeginner,
          name: 'workExperience-progLangBeginner',
        },
        'workExperience-progLangIntermediate': {
          ...fields.workExperience.progLangIntermediate,
          name: 'workExperience-progLangIntermediate',
        },
        'workExperience-progLangAdvance': {
          ...fields.workExperience.progLangAdvance,
          name: 'workExperience-progLangAdvance',
        },
        'workExperience-progLibsBeginner': {
          ...fields.workExperience.progLibsBeginner,
          name: 'workExperience-progLibsBeginner',
        },
        'workExperience-progLibsIntermediate': {
          ...fields.workExperience.progLibsIntermediate,
          name: 'workExperience-progLibsIntermediate',
        },
        'workExperience-progLibsAdvance': {
          ...fields.workExperience.progLibsAdvance,
          name: 'workExperience-progLibsAdvance',
        },
        'workExperience-progToolsBeginner': {
          ...fields.workExperience.progToolsBeginner,
          name: 'workExperience-progToolsBeginner',
        },
        'workExperience-progToolsIntermediate': {
          ...fields.workExperience.progToolsIntermediate,
          name: 'workExperience-progToolsIntermediate',
        },
        'workExperience-progToolsAdvance': {
          ...fields.workExperience.progToolsAdvance,
          name: 'workExperience-progToolsAdvance',
        },
        'workExperience-totalExperieceMonths': {
          ...fields.workExperience.totalExperieceMonths,
          name: 'workExperience-totalExperieceMonths',
        },
      })
    );

    setcurrentWorkFields(
      preProcessFormObject({
        'currentWork-workType': { ...fields.currentWork.workType, name: 'currentWork-workType' },
        'currentWork-organization': { ...fields.currentWork.organization, name: 'currentWork-organization' },
        'currentWork-designation': { ...fields.currentWork.designation, name: 'currentWork-designation' },
        'currentWork-ctc': { ...fields.currentWork.ctc, name: 'currentWork-ctc' },
        'currentWork-cCity': { ...fields.currentWork.cCity, name: 'currentWork-cCity' },
        'currentWork-joiningDate': { ...fields.currentWork.joiningDate, name: 'currentWork-joiningDate' },
        'currentWork-relieveDate': { ...fields.currentWork.relieveDate, name: 'currentWork-relieveDate' },
        'currentWork-natureOfExperience': {
          ...fields.currentWork.natureOfExperience,
          name: 'currentWork-natureOfExperience',
        },
        'currentWork-role': { ...fields.currentWork.role, name: 'currentWork-role' },
        'currentWork-sector': { ...fields.currentWork.sector, name: 'currentWork-sector' },
      })
    );

    setpastWorkFields(
      preProcessFormObject({
        'pastWork-organization': { ...fields.pastWork.organization, name: 'pastWork-organization' },
        'pastWork-designation': { ...fields.pastWork.designation, name: 'pastWork-designation' },
        'pastWork-joiningDate': { ...fields.pastWork.joiningDate, name: 'pastWork-joiningDate' },
        'pastWork-natureOfExperience': { ...fields.pastWork.natureOfExperience, name: 'pastWork-natureOfExperience' },
        'pastWork-relieveDate': { ...fields.pastWork.relieveDate, name: 'pastWork-relieveDate' },
        'pastWork-sector': { ...fields.pastWork.sector, name: 'pastWork-sector' },
        'pastWork-workType': { ...fields.pastWork.workType, name: 'pastWork-workType' },
      })
    );
  };

  const buildEducationFields = (fields: TFormFields) => {
    setschoolEducationFields(
      preProcessFormObject({
        'school-name': { ...fields.school.name, name: 'school-name' },
        'school-graduation': { ...fields.school.graduation, name: 'school-graduation' },
        'school-grade': { ...fields.school.grade, name: 'school-grade' },
      })
    );

    setugEducationFields(
      preProcessFormObject({
        'undergraduate-university': { ...fields.undergraduate.university, name: 'undergraduate-university' },
        'undergraduate-college': { ...fields.undergraduate.college, name: 'undergraduate-college' },
        'undergraduate-major': { ...fields.undergraduate.major, name: 'undergraduate-major' },
        'undergraduate-cgpa': { ...fields.undergraduate.cgpa, name: 'undergraduate-cgpa' },
        'undergraduate-eduStatus': { ...fields.undergraduate.eduStatus, name: 'undergraduate-eduStatus' },
        'undergraduate-startYear': { ...fields.undergraduate.startYear, name: 'undergraduate-startYear' },
        'undergraduate-endYear': { ...fields.undergraduate.endYear, name: 'undergraduate-endYear' },
        'undergraduate-subject': { ...fields.undergraduate.subject, name: 'undergraduate-subject' },
      })
    );

    setpgEducationFields(
      preProcessFormObject({
        'postgraduate-university': { ...fields.postgraduate.university, name: 'postgraduate-university' },
        'postgraduate-college': { ...fields.postgraduate.college, name: 'postgraduate-college' },
        'postgraduate-major': { ...fields.postgraduate.major, name: 'postgraduate-major' },
        'postgraduate-cgpa': { ...fields.postgraduate.cgpa, name: 'postgraduate-cgpa' },
        'postgraduate-eduStatus': { ...fields.postgraduate.eduStatus, name: 'postgraduate-eduStatus' },
        'postgraduate-startYear': { ...fields.postgraduate.startYear, name: 'postgraduate-startYear' },
        'postgraduate-endYear': { ...fields.postgraduate.endYear, name: 'postgraduate-endYear' },
        'postgraduate-subject': { ...fields.postgraduate.subject, name: 'postgraduate-subject' },
      })
    );

    setphdEducationFields(
      preProcessFormObject({
        'doctorate-university': { ...fields.doctorate.university, name: 'doctorate-university' },
        'doctorate-cgpa': { ...fields.doctorate.cgpa, name: 'doctorate-cgpa' },
        'doctorate-eduStatus': { ...fields.doctorate.eduStatus, name: 'doctorate-eduStatus' },
        'doctorate-startYear': { ...fields.doctorate.startYear, name: 'doctorate-startYear' },
        'doctorate-subject': { ...fields.doctorate.subject, name: 'doctorate-subject' },
        'doctorate-topic': { ...fields.doctorate.topic, name: 'doctorate-topic' },
      })
    );
  };

  const buildHackathonFields = (fields: TFormFields) => {
    sethackathonFields({
      isWorking: fields.isWorking,
      highestEducation: fields.highestEducation,
      'currentWork-organization': { ...fields.currentWork.organization, name: 'currentWork-organization' },
      'school-name': { ...fields.school.name, name: 'school-name' },
      'undergraduate-university': {
        ...fields.undergraduate.university,
        name: 'undergraduate-university',
        label: 'Undergraduate University',
      },
      'postgraduate-university': {
        ...fields.postgraduate.university,
        name: 'postgraduate-university',
        label: 'Postgraduate University',
      },
      'doctorate-university': {
        ...fields.doctorate.university,
        name: 'doctorate-university',
        label: 'Doctorate University',
      },
    });
  };

  const buildAdmissionFields = (fields: TFormFields) => {
    setadmissionProgramFields({
      phone: fields.phone,
      source: fields.source,
      gender: fields.gender,
      isWorking: fields.isWorking,
      highestEducation: fields.highestEducation,
      'currentWork-organization': { ...fields.currentWork.organization, name: 'currentWork-organization' },
      'currentWork-ctc': { ...fields.currentWork.ctc, name: 'currentWork-ctc' },
      'school-name': { ...fields.school.name, name: 'school-name' },
      'undergraduate-university': {
        ...fields.undergraduate.university,
        name: 'undergraduate-university',
        label: 'Undergraduate University',
      },
      'postgraduate-university': {
        ...fields.postgraduate.university,
        name: 'postgraduate-university',
        label: 'Postgraduate University',
      },
      'doctorate-university': {
        ...fields.doctorate.university,
        name: 'doctorate-university',
        label: 'Doctorate University',
      },
      payment: fields.payment,
    });

    setadmissionCourseFields({
      phone: fields.phone,
      source: fields.source,
      gender: fields.gender,
      isWorking: fields.isWorking,
      highestEducation: fields.highestEducation,
      'currentWork-organization': { ...fields.currentWork.organization, name: 'currentWork-organization' },
      'currentWork-ctc': { ...fields.currentWork.ctc, name: 'currentWork-ctc' },
      'school-name': { ...fields.school.name, name: 'school-name' },
      'undergraduate-university': {
        ...fields.undergraduate.university,
        name: 'undergraduate-university',
        label: 'Undergraduate University',
      },
      'postgraduate-university': {
        ...fields.postgraduate.university,
        name: 'postgraduate-university',
        label: 'Postgraduate University',
      },
      'doctorate-university': {
        ...fields.doctorate.university,
        name: 'doctorate-university',
        label: 'Doctorate University',
      },
      programming: fields.programming,
      calculus: fields.calculus,
      statistics: fields.statistics,
      algebra: fields.algebra,
    });
  };

  const buildMiscellaneousFields = (fields: TFormFields) => {
    setmiscellaneousFields(
      preProcessFormObject({
        activites: { ...fields.activites },
        award: { ...fields.award },
        academicWork: { ...fields.academicWork },
        certifications: { ...fields.certifications },
        udspOthers: { ...fields.udspOthers },
      })
    );
    setmiscellaneousExtraFields(
      preProcessFormObject({
        orgLikeToWorkIn: { ...fields.orgLikeToWorkIn },
        interestedIndustry: { ...fields.interestedIndustry },
      })
    );
  };

  const buildFields = (fields: TFormFields) => {
    try {
      buildBackgroundFields(fields);
      buildEducationFields(fields);
      buildWorkFields(fields);
      buildHackathonFields(fields);
      buildAdmissionFields(fields);
      buildMiscellaneousFields(fields);
    } catch (e) {
      setfieldserror((e as Error).message);
    }
  };

  const getFields = async () => {
    try {
      const response = await fetch(`${PROFILE_URL}/fields`, { credentials: 'header' });
      if (response.status !== 200) {
        toast.error('Please check your internet');
        throw new Error('We are unable to connect to the server');
      }
      const respJSON = await response.json();
      const fields = respJSON.fields;
      for (const key in fields) {
        const field = fields[key];
        if (field.type !== 'file') continue;
        fields[key] = {
          ...field,
          fileOptions: {
            ...field.fileOptions,
            getUploadURL: `${USER_URL}/profile/upload-url${field.fileOptions.urlparams}`,
          },
        };
      }

      setfields(fields);
      buildFields(fields);
    } catch (e) {
      setfieldserror((e as Error).message);
    }
  };

  useEffect(() => {
    getFields();
  }, []);

  return {
    fields,
    backgroundFields,
    currentWorkFields,
    pastWorkFields,
    hackathonFields,
    admissionCourseFields,
    admissionProgramFields,
    schoolEducationFields,
    ugEducationFields,
    pgEducationFields,
    phdEducationFields,
    overallExperienceFields,
    miscellaneousFields,
    miscellaneousExtraFields,
  };
}
