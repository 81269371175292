import { RegisterOptions, ValidationRule } from 'react-hook-form';
import { Moment } from 'moment';
export type ICourseTimetable = {
  id: number;
  class_name: string;
  class_description: string;
  meeting_link: string;
  schedule_date_time: Date;
  status: number;
  created_on: Date;
  updated_on: Date;
  admin_meeting_link: string;
  class_page_link: string;
  course_id: string;
  class_end_date_time?: Date;
  class_start_date_time?: Date;
  class_ta_id: number;
  meeting_meta?: any;
  course_name: string;
};

export type IParsedCourseTimetable = {
  admin_meeting_link: string;
  class_description: string;
  class_end_date_time: Date;
  class_name: string;
  class_page_link: string;
  class_start_date_time: Date;
  class_ta_id: number;
  course_id: string;
  course_name: string;
  created_on: Date;
  eventDate: string;
  eventText: string;
  eventTime: string;
  id: number;
  meeting_link: string;
  meeting_meta?: any;
  schedule_date_time: Date;
  status: number;
  updated_on: Date;
};

export enum EMeetupTypes {
  workshop = 'Workshop',
  hackathon = 'Hackathon',
  event = 'Event',
  interview = 'Interview',
  program = 'Program',
}

export enum EDifficultyLevels {
  beginner = 'beginner',
  intermediate = 'intermediate',
  advanced = 'advanced',
}
export type TMeetup = {
  id: number;
  name: string;
  description: string;
  type: EMeetupTypes;
  page_url: string;
  scheduled_datetime: Date;
  end_datetime: Date;
  is_active: boolean;
  plan_name: string;
  payment_id: number;
  product_id: number;
  program_id: string;
  alacarte_rate: number;
  currency: 'INR' | 'USD';
  meta: {
    domain?: 'techx' | 'community' | 'univ';
    meetup_link?: string;
    display_name?: string;
    allowed_roles?: string;
    require_registration?: boolean;
    registration_fields?: string[];
    registration_url?: string;
    recording_link?: string;
    speaker_name?: string;
    hero_image?: string;
    event_code?: string;
    required_form_fields?: string[];
    speaker_bio: string;
    difficulty_level?: EDifficultyLevels;
    total_slots?: number;
    available_slots?: number;
    slot_status_text?: string;
    category_id?: number;
    learn_topics: string[];
    standalone_registrable: boolean;
  };
  program_name?: string;
  program_meta?: string;
  course_meta?: string;
  payment_meta?: string;
  is_free?: boolean;
  created_at: Date;
  updated_at: Date;
  registered_on?: Date;
  schedule: TSchedule[];
  included_meetups?: TMeetup[];
  has_access?: boolean;
};

export type TProgram = {
  program_id: number;
  name: string;
  description: string;
  type?: 'Program' | 'Course';
  url: string;
  bannerurl: string;
  start_date: Date;
  end_date: Date;
  meta: string;
};
export type TCourse = {
  id: number;
  name: string;
  description: string;
  type?: 'Program' | 'Course';
  url: string;
  bannerurl: string;
  meta: string;
};

export type TBanner = {
  img: string;
  onClick: string;
};

export type ICourse = {
  product_id: number;
  start_date: string;
  end_date: string;
  id: string;
  name: string;
  rate: number;
  currency: string;
  description: string;
  url: string;
  tags: string[];
  links: { url: string; type: string }[];
  bannerurl: string;
};

export type TSchedulerEvents = {
  title: string;
  date: Date; //used to decide which day event should popup at usually = start
  start: Date;
  end: Date;
  color?: string;
  type: string;
  name: string;
};

export type TField = {
  maxLength?: number;
  minLength?: number;
  regex?: string;
  required: boolean;
  url?: string;
};

export type TFieldRequired = undefined | TConstraint;
export type TConstraint = { value: boolean | number | string; message: string };
export type TProcessedFormField = {
  name: string;
  label: string;
  type: string;
  defaultValue: any;
  props: RegisterOptions & { constraints: { required: TFieldRequired } };
};

export type TProcessedFormFieldList = { [key: string]: TProcessedFormField };

export type TFormFields = {
  constraints?: RegisterOptions;
  [key: string]: any;
};

export type THackathon = {
  id: number;
  title: string;
  description: string;
  externalId: string;
  startDate: string;
  endDate: string;
  difficulty: string;
};

export interface IRequestResponse<T = {}> {
  success?: boolean;
  data?: T;
  status?: number;
  error?: string;
}

export type TUserTransaction = {
  id: string;
  product_id: number;
  provider: string;
  event: string;
  meta: any;
  timestamp: number;
  amount: number;
  plan_name: string;
  currency: 'USD' | 'INR';
};
export type TUserInvoice = {
  id: 1;
  product_id: number;
  payment_id: number;
  transaction_id: string;
  meta: {
    slotId: number;
    status: string;
    userId: number;
    billCount: number;
    intent_id: string;
    paymentId: number;
    product_id: number;
    couponCode: string;
    payment_id: number;
    nextDueDate: Date;
    productName: 'free' | 'basic' | 'advance';
    productType: string;
    productRefId: string;
    applicationId: string;
    isBilledParts: boolean;
    nextDueAmount: number;
    currency: 'INR' | 'USD';
  };
  timestamp: Date;
  due_date: Date;
  due_amount: number;
  paid_amount: number;
  currency: 'INR' | 'USD';
};

export type TUserCurrentPlan = {
  name?: string;
  plan_name?: string;
  plan_display_name: string;
  start_date?: string;
  end_date?: string;
  payment_plan_id?: number;
  currency?: 'USD' | 'INR';
  billing_frequency?: string;
  upcoming_payment: number;
  trail_period?: any;
  payment_due_date?: Date;
  is_free: boolean | string;
  is_global_slot_selected: boolean;
  transactions?: TUserTransaction[];
  invoice?: TUserInvoice[];
  timestamp?: Date;
};

export type TUserBillingHistory = {
  bill_date: string;
  status: string;
  paid_amount: number;
  billed_amount: number;
  currency: string;
  transaction_id: string;
};

export type TPlans = {
  [key: string]: TPlan[];
};
export type TPlan = {
  product_id: number;
  payment_id: number;
  name: string;
  billing_frequency: string;
  displayname: string;
  features: string;
  price: number;
  currency: string;
  discountedprice?: number;
};

export type TRate = string | { [key: string]: number };

export type TSchedule = {
  date: string;
  lessons: number;
  title: string;
  subTitle: string;
  from: string;
  to: string;
  duration: string;
  description?: string;
  image?: string;
  url?: string;
};

export enum TJobStatus {
  applied = 'applied',
  shortlisted = 'shortlisted',
  accepted = 'accepted',
  rejected = 'rejected',
  deleted = 'deleted',
  cancelled = 'cancelled',
  finished = 'finished',
  expired = 'expired',
}

export type TJobs = {
  id: number;
  title: string;
  position: string;
  description: string;
  category: string;
  max_applicants: number;
  max_positions: number;
  salary_start: number;
  salary_end: number;
  posted_date: string;
  expiry_date: string;
  meta: {
    other_details?: string;
    hasProcessFlow?: boolean;
    student_domain?: string;
    technical_blog?: string;
  };
  status?: null | TJobStatus;
  created_at: string;
  updated_at: string;
  company_id: number;
  recruiter_id: number;
  is_active: number;
  location: string;
  nature_of_work: string;
  min_experience_req: number;
  tags: null | string[];
  skills: null | string[];
  company_name: string;
  company_description: string;
  company_logo: string;
  recommended_course?: { id: string; name: string; description: string; domain: 'univ' | 'techx' | 'community' }[];
  recommended_job?: { id: number; title: string; company_name: string }[];
};

export type TJobApplication = {
  id: number;
  status: TJobStatus;
  created_at: string;
  position: string;
  title: string;
  description: string;
  meta: {
    sop: string;
  };
  updated_at: string;
  job_id: number;
  user_id: number;
  company_name: string;
  company_id: number;
  company_description: string;
  company_logo: string;
  salary_start: number;
  salary_end: number;
  nature_of_work: string;
  location: string;
};
export type TCustomJobApplicationFields = {
  name: string;
  label: string;
  placeholder: string;
  constraints: { [key: string]: string };
  type: string;
  options?: { value: string; id: string }[];
  defaultValue: any;
  props: RegisterOptions & { required: TFieldRequired };
};
