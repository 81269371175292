import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaLongArrowAltRight } from 'react-icons/fa';

import {
  FormPhone,
  FormComponentHolder,
  FormHolder,
  FormInput,
  FormSwitch,
  FormSelect,
  FormRadio,
} from 'components/Form';
import { TProcessedFormFieldList } from 'types';

interface IProps {
  onSubmit: (values: any) => void;
  defaultValues: { [key: string]: any };
  submitting: boolean;
  error?: any;
  admissionFields: TProcessedFormFieldList;
}

const defaultRequiredFields = [
  'highestEducation',
  'phone',
  'source',
  'gender',
  'programming',
  'calculus',
  'statistics',
  'algebra',
];

export default function AdmissionContext(props: IProps) {
  const { onSubmit, defaultValues, error: formError, admissionFields } = props;
  const [requiredFields, setrequiredFields] = useState<string[]>(defaultRequiredFields);

  const { control, register, handleSubmit, getValues, setValue, watch, formState, reset } = useForm({ defaultValues });
  const { isDirty } = formState;
  const formValues = watch();

  const inputParams: any = {};
  for (const field in admissionFields) {
    const f = admissionFields[field];
    const props = { ...f.props };
    if (!props.validate)
      if (requiredFields.includes(f.name)) props.required = { value: true, message: 'Required' };
      else props.required = false;

    inputParams[field] = {
      ...f,
      errors: formError,
      error: formError[`${f.name}`]?.length > 0,
      defaultValue: defaultValues[f.name],
      fieldProps: register(f.name, props),
      refGenerator: register,
      setValue,
      control,
      getValues,
      formValues,
      required: props?.required,
    };
  }

  const phoneField = inputParams.phone;
  const sourceField = inputParams.source;
  const genderField = inputParams.gender;
  const wpField = inputParams.isWorking;
  const programmingField = inputParams.programming;
  const calculusField = inputParams.calculus;
  const statisticsField = inputParams.statistics;
  const algebraField = inputParams.algebra;
  const highestEducationField = inputParams.highestEducation;
  const workField = inputParams['currentWork-organization'];
  const ctcField = inputParams['currentWork-ctc'];
  const educationFields: { [key: string]: any } = {
    school: inputParams['school-name'],
    undergraduate: inputParams['undergraduate-university'],
    postgraduate: inputParams['postgraduate-university'],
    doctorate: inputParams['doctorate-university'],
  };
  const highestEducation = formValues.highestEducation;
  const educationField = educationFields[highestEducation];

  const processForm = (processedData: { [key: string]: any }) => {
    const educationData: any = {};
    const currentWorkData: any = {};
    const { highestEducation, ...rest } = processedData;

    for (const dataKey in processedData) {
      if (dataKey.startsWith(`${highestEducation}-`)) {
        const key = dataKey.replace(`${highestEducation}-`, '');
        educationData[key] = processedData[dataKey];
        delete rest[dataKey];
      }

      if (dataKey.startsWith('currentWork-')) {
        const key = dataKey.replace('currentWork-', '');
        currentWorkData[key] = processedData[dataKey];
        delete rest[dataKey];
      }
    }

    const data = {
      highestEducation,
      [highestEducation]: educationData,
      currentWork: currentWorkData,
      ...rest,
    };

    onSubmit(data);
    reset(processedData);
    return true;
  };

  const computeRequiredFields = (formValues: any) => {
    const requiredFields = [...defaultRequiredFields];
    if (formValues.isWorking) requiredFields.push('currentWork-organization');
    if (educationField) requiredFields.push(educationField.name);
    setrequiredFields(requiredFields);
  };

  const dependentFields = [formValues.isWorking, highestEducation];

  useEffect(() => {
    computeRequiredFields(formValues);
  }, dependentFields);

  return (
    <FormHolder
      isDirty={isDirty}
      onSubmit={handleSubmit(processForm)}
      className={`mx-auto max-w-2xl mt-4 sm:mt-8 ${
        typeof formError === 'object' && Object?.keys(formError)?.length > 0
          ? 'p-4 pb-12 md:p-8 md:pb-14 border-2 border-red-500'
          : ''
      }`}
    >
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-2xl md:text-4xl">Course Admission Form</h1>
        <img className="w-14 md:w-20" src="/logo.svg" />
      </div>
      <hr />
      <FormComponentHolder FormComponent={FormSelect} {...highestEducationField} />
      {educationField ? <FormComponentHolder FormComponent={FormInput} {...educationField} /> : null}
      <FormComponentHolder FormComponent={FormSwitch} {...wpField} />
      {formValues.isWorking ? <FormComponentHolder FormComponent={FormInput} {...workField} /> : null}
      {formValues.isWorking ? <FormComponentHolder FormComponent={FormSelect} {...ctcField} /> : null}
      <FormComponentHolder FormComponent={FormPhone} {...phoneField} />
      <FormComponentHolder FormComponent={FormSelect} {...sourceField} />
      <FormComponentHolder FormComponent={FormSelect} {...genderField} />
      <FormComponentHolder FormComponent={FormRadio} {...programmingField} />
      <FormComponentHolder FormComponent={FormRadio} {...calculusField} />
      <FormComponentHolder FormComponent={FormRadio} {...statisticsField} />
      <FormComponentHolder FormComponent={FormRadio} {...algebraField} />
      <button className="button dark filled float-right mt-2" type="submit">
        Proceed <FaLongArrowAltRight className="inline ml-2" />
      </button>
    </FormHolder>
  );
}
