import { COMMUNITY_DETAIL_PATH, JOBS_PATH, MEETUP_DETAIL_PATH, MY_APPLICATIONS_PRODUCTS_PATH } from 'constants/app';
import React from 'react';
import { AiFillRightCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';

type TJobRecommendationProp = {
  recommended_course:{id:string,name:string,description:string,domain:"univ" | "techx" | "community" }[],
  recommended_job:{id:number,title:string,company_name:string}[],
}
// {}
const JobRecommendation = ({recommended_course,recommended_job}:TJobRecommendationProp) => {
  const history = useHistory();

  const goToJobDeatils = (id:number) => history.push(`${JOBS_PATH}/${id}`) ;

  const goToCourseDetails = (domain:"univ" | "techx" | "community",name:string) => {
    if (domain === "community") 
      history.push(`${COMMUNITY_DETAIL_PATH}/` + name);
    else if(domain === "techx")
      history.push(`${MEETUP_DETAIL_PATH}/` + name) ;
    else if(domain === "univ")
      window.location.href = MY_APPLICATIONS_PRODUCTS_PATH;
  };

  return (
    <div className='flex flex-col w-full lg:max-w-xs xl:min-w-320px gap-4'>
        <div className='bg-white p-2 rounded-md'>
          <span className='text-text-grey-light'>Recommended Jobs</span>
          {recommended_job && recommended_job.map(item => 
          <div className='flex flex-col items-start justify-center border-t border-text-grey-light cursor-default mt-2'>
            <h3 className='text-base text-text-dark cursor-pointer' onClick={() => goToJobDeatils(item.id)}>{item.title}</h3>
            <div className='flex flex-row items-center justify-between w-full text-text-blue'>
              <span className='text-sm'>{item.company_name}</span>
              <AiFillRightCircle className='text-base cursor-pointer' onClick={() => goToJobDeatils(item.id)}/>
            </div>
          </div>)
          }
        </div>
        <div className='bg-white p-2 rounded-md'>
          <span className='text-text-grey-light'>Recommended Courses</span>
          {recommended_course && recommended_course.map(item => 
          <div className='flex flex-col items-start justify-center border-t border-text-grey-light mt-2'>
            <h3 className='text-base text-text-dark cursor-pointer' onClick={()=> goToCourseDetails(item.domain,item.name)} >{item.name}</h3>
            <div className='flex flex-row items-start justify-between w-full text-text-blue'>
              <span className='text-sm cursor-default line-clamp-2'>{item.description}</span>
              <AiFillRightCircle className='text-base min-w-min cursor-pointer' onClick={()=> goToCourseDetails(item.domain,item.name)}/>
            </div>
          </div>)
          }
        </div>
    </div>
  );
};

export default JobRecommendation;

