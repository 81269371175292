import moment from 'moment';
import * as tz from 'moment-timezone';
import React, { useState } from 'react';
import { reqRegisterUser } from 'fragments/DefaultCard';
import Modal from 'fragments/Modal';
import useStore from 'globalstate';
import { getDynamicFormFields } from 'utils/form-fields';
import { openURL } from 'utils/url';
import DynamicForm from '../DynamicForm';
import ReactMarkdown from 'react-markdown';
import { captureException } from '@sentry/minimal';
import DeregisterModal from './Deregister';
import globalEmitter from 'utils/emitter';
import { DEFAULT_CARD_IMG, getLoginURL } from 'constants/app';
import { enrol } from 'components/GHF/Modal';
import { getSeriesColor } from 'utils/seriesColors';
import toaster from 'utils/toast';
import FreeSubscriptionModal from './FreeSubscription';
import GlobalSlot from 'components/Subscription/GlobalSlot/GlobalSlot';
import Payment from 'components/Payment';
import { getCurrencySymbol } from 'utils/currency';

type TEventDetails = {
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  isLive: boolean;
  heroImage?: string;
  registrationLink?: string;
  isRegistered?: boolean;
  meetupLink?: string;
  pageLink?: string;
  require_registration?: boolean;
  speakerName?: string;
  speakerBio?: string;
  formFieldName?: string[];
  eventId: number;
  difficulty?: string;
  eventCode?: string;
  planName?:string;
  totalSlots?:number;
  availableSlots?:number;
  slotStatusText?:string;
  alacarte_rate:number,
  currency:"INR" | "USD",
  payment_id:number,
  product_id:number
};
type TProps = {
  event: TEventDetails;
  isAuthenticated: boolean;
};
export default function (props: TProps) {
  const { event, isAuthenticated } = props;

  const [ State] = useStore();
  const {currentPlan} = State;

  const [isRegModalOpen, setIsRegModalOpen] = useState(false);
  const [isFreeSubscriptionModalOpen, setIsFreeSubscriptionModalOpen] = useState(false);
  const [isDeregisterModalOpen, setIsDeregisterModalOpen] = useState<boolean>(false);
  const [isGlobalSlotModalOpen, setIsGlobalSlotModalOpen] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(event.isRegistered || false);
  const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const eventEmitter = globalEmitter;

  const closeRegModal = () => {
    setIsRegModalOpen(false);
  };

  function openDeregisterModal() {
    setIsDeregisterModalOpen(true);
  }
  function closeIsFreeSubscriptionModal() {
    setIsFreeSubscriptionModalOpen(false);
  }

  function openGlobalSlotModal() {
    setIsGlobalSlotModalOpen(true);
  }
  function closeIsGlobalSlotModal() {
    setIsGlobalSlotModalOpen(false);
  }
  const openMeetupLink = () => {
    if (event.meetupLink) openURL(event.meetupLink);
  };


  async function saveRegistration(data: { [key: string]: string }): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let respJSON;
      try {
        const response = await reqRegisterUser(data, event.eventId);
        respJSON = await response.json();
        if (
          response.status == 403 &&
          respJSON.error.startsWith('You do not have the required permissions to access this event')
        ) {
          const data = { registered_on: moment().format('DD-MM-YYYY HH:mm:ss') };
          localStorage.setItem('regEvntNonCnfrmd', JSON.stringify({ data: data, event: { id: event.eventId } }));
          const isEnrolled: boolean | string = await enrol();
          if (isEnrolled == false) {
            toaster.error('Unable to register you to GHF!');
            captureException(`Unable to Register  for event ${event.name}`);
            return false;
          }
          // return toaster.error(respJSON.error + 'Join our community to get access!', {
          //   position: 'top-right',
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: false,
          //   pauseOnHover: true,
          //   draggable: false,
          // });
        }
        if (response.status !== 200) return reject(respJSON.error);
        toaster.success('You are successfully registered!');
        setIsRegistered(true);
        eventEmitter.emit('refresh_meetups');
        resolve(respJSON);
      } catch (e) {
        captureException(e);
      }
    });
  }

  async function openRegModal() {
    
    if (isAuthenticated)       
      if(State?.currentPlan?.plan_display_name?.toLowerCase() === "free" && event?.planName?.toLowerCase()==="basic"){
      setIsFreeSubscriptionModalOpen(true);
      return;
    }
    if (isAuthenticated)      
      if (event.require_registration)
        if (event.formFieldName) setIsRegModalOpen(true);
        else
          saveRegistration({ registered_on: moment().format('DD-MM-YYYY HH:mm:ss') })
            .then()
            .catch(err => {
              toaster.error(err);
            });
      else
        saveRegistration({ registered_on: moment().format('DD-MM-YYYY HH:mm:ss') })
          .then()
          .catch(err => {
            toaster.error(err);
          });

    else window.location.href = getLoginURL(window.location.href);
}
  
  const getActions = () => {
    if (event.require_registration && !isRegistered)
      if(event.alacarte_rate !== null)
        return (
          <button type="button" className="mr-3 font-switzer" onClick={openGlobalSlotModal}>
            {isAuthenticated ? 'Buy Now' : 'Login to Register'}
          </button>
        );
      else 
        return (
          <button type="button" className="mr-3" onClick={openRegModal}>
            {isAuthenticated ? 'Register' : 'Login to Register'}
          </button>
        );
    else if (event.meetupLink)
      return (
        <div className="flex">
          <a type="button" className="button mr-3 pr-3 pl-3" href={event.pageLink} target="_blank">
            Go to Course
          </a>

          <button type="button" className="mr-3 pr-3 pl-3" onClick={openMeetupLink}>
            Join
          </button>

          <button type="button" className="mr-3 pr-3 pl-3" onClick={openDeregisterModal}>
            Deregister
          </button>
        </div>
      );
    else
      return (
        <React.Fragment>
          <button type="button" className="mr-3 pr-3 pl-3 cursor-not-allowed" disabled>
            Awaiting Meetup Link
          </button>
          <button type="button" className="mr-3 pr-3 pl-3" onClick={openDeregisterModal}>
            Deregister
          </button>
        </React.Fragment>
      );
  };
  return (
    <div className={`grid md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 h-96 max-h-screen`}>
      <div className="  flex align-center flex-col pr-10">
        <div className="overflow-visible h-min relative ">
          <div className="absolute h-32 w-32 right-0">
            {isRegistered ? (
              <div className="absolute -right-3 top-10 border p-2 rounded text-white bg-brand  ">Registered</div>
            ) : (
              ''
            )}
          </div>
          <div className='flex row items-center justify-between'>
              {event?.slotStatusText ? (<span
                className="text-base 3xl:text-lg font-bold self-center m-0 text-red-400 font-switzer"
              >
                {event?.slotStatusText}
              </span>):(<span
                className="text-base 3xl:text-lg font-medium self-center m-0 text-text-blue font-switzer"
              >
                {event?.availableSlots && event?.totalSlots ? `${event?.availableSlots} / ${event?.totalSlots} Slots`:null}
              </span>)}
              <span
                className="text-base 3xl:text-lg font-semibold self-center m-0 text-text-blue uppercase font-switzer"
              >
                {event?.planName}
              </span>
          </div>
          <div className={'bg-gradient-to-r to-white-500 ' + getSeriesColor(event.eventCode)}>
            <img src={event.heroImage || DEFAULT_CARD_IMG} className="object-contain"></img>
          </div>
        </div>

        <div className="mt-6">
          <table>
            <tbody>
              <tr>
                <td className="font-bold">Starts On</td>
                <td>{moment(event.startDate).format('DD/MM/YYYY HH:mm ') + tz.tz(userTz).format('z')}</td>
              </tr>
              <tr>
                <td className="font-bold">Ending On</td>
                <td>{moment(event.endDate).format('DD/MM/YYYY HH:mm ') + tz.tz(userTz).format('z')}</td>
              </tr>
              <tr>
                <td className="font-bold"> Speaker</td>
                <td className="capitalize">{event.speakerName ? event.speakerName : 'NA'}</td>
              </tr>
              <tr>
                <td className="font-bold"> Difficulty</td>
                <td>{event.difficulty ? event.difficulty.toUpperCase() : 'NA'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className=" flex flex-col">
        <h1>{event.name}</h1>
        <div>
          <div className="text-sm text-gray-600 mt-6">
            <ReactMarkdown>{event.description}</ReactMarkdown>{' '}
          </div>
        </div>
        <p className="text-sm text-gray-400 capitalize"> - By {event.speakerName} </p>
        <hr></hr>
        {event.speakerBio ? (
          <div>
            <h4 className="capitalize">About {event.speakerName}</h4>
            <div className="text-sm text-gray-400 mt-4">
              <ReactMarkdown>{event.speakerBio}</ReactMarkdown>{' '}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className='flex flex-row items-center gap-4 p-3'>
          <span className="text-lg font-bold">
            {getCurrencySymbol(event?.currency)} {event?.alacarte_rate}
          </span>
          <div className="">{getActions()}</div>
        </div>
      </div>
      <Modal isOpen={isRegModalOpen} close={closeRegModal}>
        <DynamicForm
          closeModal={closeRegModal}
          onUpdate={saveRegistration}
          formFields={getDynamicFormFields(event.formFieldName)}
          eventName={event.name}
        ></DynamicForm>
      </Modal>
      <Modal 
        isOpen={isGlobalSlotModalOpen} 
        close={closeIsGlobalSlotModal} 
        size="lg" bgColor='bg-white'
      >
        {
          currentPlan?.is_global_slot_selected ?
          <React.Fragment>
            <Payment 
              productId={event?.product_id?.toString()} 
              paymentId={event?.payment_id} 
              slotId="" 
              />
          </React.Fragment> :
          <React.Fragment>
          <GlobalSlot 
            product={{
              product_id: event?.product_id,
              payment_id: event.payment_id,
              name: event.name,
              billing_frequency: "",
              displayname: event.name,
              features: "",
              price: event.alacarte_rate,
              currency:event.currency,
              discountedprice: null,
              description: event.description,
            }} 
            close={closeIsGlobalSlotModal}
          />
        </React.Fragment>
                  
        }
      </Modal>

      <DeregisterModal
        isOpen={isDeregisterModalOpen}
        onDeregistration={() => {
          setIsRegistered(false);
          setIsDeregisterModalOpen(false);
          eventEmitter.emit('refresh_meetups');
        }}
        eventId={event.eventId}
        displayName={event.name}
        closeModal={() => {
          setIsDeregisterModalOpen(false);
        }}
      ></DeregisterModal>
      <FreeSubscriptionModal 
        isOpen={isFreeSubscriptionModalOpen} 
        closeModal={closeIsFreeSubscriptionModal}  
      />
    </div>
  );
}
