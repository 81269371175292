import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';

import { FormComponentHolder, FormHolder, FormInput, FormTextArea, FormSelect } from 'components/Form';
import { TProcessedFormFieldList } from 'types';

interface IProps {
  onSubmit: (values: any) => void;
  defaultValues: { [key: string]: any };
  submitting: boolean;
  miscellaneousFields: TProcessedFormFieldList;
  miscellaneousExtraFields: TProcessedFormFieldList;
  error?: any;
}

export default function MiscellaneousProfile(props: IProps) {
  const { onSubmit, defaultValues, miscellaneousFields, miscellaneousExtraFields, error: formError } = props;

  const [activites, setactivites] = useState<any[]>([]);
  const [award, setaward] = useState<any[]>([]);
  const [academicwork, setacademicwork] = useState<any[]>([]);
  const [certifications, setcertifications] = useState<any[]>([]);
  const [udspothers, setudspothers] = useState<any[]>([]);

  const { control, register, handleSubmit, getValues, setValue, watch, formState, reset } = useForm({ defaultValues });
  const formValues = watch();
  const { isDirty, errors } = formState;

  const processForm = (processedData: { [key: string]: any }) => {
    const activitesData: any = [];
    const awardsData: any = [];
    const academicworkData: any = [];
    const certificationsData: any = [];
    const udspothersData: any = [];

    for (const dataKey in processedData) {
      if (dataKey.startsWith('activites-')) {
        const arrkey = dataKey.replace('activites-', '');
        const val = processedData[dataKey];
        const maybeMatch = arrkey.match(/^(\d)-.*$/);
        if (!maybeMatch) continue;

        const idx = maybeMatch[1];
        if (!activitesData[idx]) activitesData[idx] = {};
        const objkey = arrkey.replace(`${idx}-`, '');
        activitesData[idx][objkey] = val;
      }

      if (dataKey.startsWith('award-')) {
        const arrkey = dataKey.replace('award-', '');
        const val = processedData[dataKey];
        const maybeMatch = arrkey.match(/^(\d)-.*$/);
        if (!maybeMatch) continue;

        const idx = maybeMatch[1];
        if (!awardsData[idx]) awardsData[idx] = {};
        const objkey = arrkey.replace(`${idx}-`, '');
        awardsData[idx][objkey] = val;
      }

      if (dataKey.startsWith('academicWork-')) {
        const arrkey = dataKey.replace('academicWork-', '');
        const val = processedData[dataKey];
        const maybeMatch = arrkey.match(/^(\d)-.*$/);
        if (!maybeMatch) continue;

        const idx = maybeMatch[1];
        if (!academicworkData[idx]) academicworkData[idx] = {};
        const objkey = arrkey.replace(`${idx}-`, '');
        academicworkData[idx][objkey] = val;
      }

      if (dataKey.startsWith('certifications-')) {
        const arrkey = dataKey.replace('certifications-', '');
        const val = processedData[dataKey];
        const maybeMatch = arrkey.match(/^(\d)-.*$/);
        if (!maybeMatch) continue;

        const idx = maybeMatch[1];
        if (!certificationsData[idx]) certificationsData[idx] = {};
        const objkey = arrkey.replace(`${idx}-`, '');
        certificationsData[idx][objkey] = val;
      }

      if (dataKey.startsWith('udspOthers-')) {
        const arrkey = dataKey.replace('udspOthers-', '');
        const val = processedData[dataKey];
        const maybeMatch = arrkey.match(/^(\d)-.*$/);
        if (!maybeMatch) continue;

        const idx = maybeMatch[1];
        if (!udspothersData[idx]) udspothersData[idx] = {};
        const objkey = arrkey.replace(`${idx}-`, '');
        udspothersData[idx][objkey] = val;
      }
    }
    const data = {
      activites: activitesData,
      award: awardsData,
      academicWork: academicworkData,
      certifications: certificationsData,
      udspOthers: udspothersData,
      orgLikeToWorkIn: processedData.orgLikeToWorkIn,
      interestedIndustry: processedData.interestedIndustry,
    };

    onSubmit(data);
    return true;
  };

  const createFields = (idx: number, fieldName: string) => {
    const params: any = {};
    const fieldList = miscellaneousFields[fieldName] as unknown as TProcessedFormFieldList;

    for (const field in fieldList) {
      const f = fieldList[field];
      const name = `${fieldName}-${idx}-${field}`;
      params[name] = {
        ...f,
        errors: formError,
        error: formError[`${name}`]?.length > 0,
        defaultValue: defaultValues[name],
        fieldProps: register(name, f.props),
        refGenerator: register,
        setValue,
        rules: f?.props,
        control,
        getValues,
        formValues,
        required: f.props?.constraints.required,
      };
    }

    return params;
  };

  const addFields = (fields: any[], setField: Function, fieldName: string) => {
    const idx = fields.length;
    const field = createFields(idx, fieldName);
    setField([...fields, field]);
  };

  const getFieldCount = (start: string, end: string) => {
    const number = Object.keys(formValues).filter((item: string) => {
      const keyValue = item.split('-')?.join(' ');
      return keyValue.startsWith(start) && keyValue.endsWith(end);
    });
    return number.length;
  };

  const createInitialField = (
    fieldName: string,
    setFieldParams: React.Dispatch<React.SetStateAction<any[]>>,
    lastName: string
  ) => {
    for (let cnt = 0; cnt === 0 || cnt < getFieldCount(fieldName, lastName); cnt++) {
      const params = createFields(cnt, fieldName);
      setFieldParams(prev => [...prev, params]);
    }
  };

  const miscellaneousExtraParams: any = {};
  for (const field in miscellaneousExtraFields) {
    const f = miscellaneousExtraFields[field];
    miscellaneousExtraParams[field] = {
      ...f,
      errors: formError,
      error: formError[`${f.name}`]?.length > 0,
      defaultValue: defaultValues[f.name],
      fieldProps: register(f.name, f.props),
      refGenerator: register,
      setValue,
      rules: f.props,
      control,
      getValues,
      formValues,
      required: f.props?.required,
    };
  }
  const orgLikeToWorkInField = miscellaneousExtraParams['orgLikeToWorkIn'];
  const interestedIndustryField = miscellaneousExtraParams['interestedIndustry'];

  useEffect(() => {
    createInitialField('award', setaward, 'name');
    createInitialField('activites', setactivites, 'name');
    createInitialField('academicWork', setacademicwork, 'name');
    createInitialField('certifications', setcertifications, 'name');
    createInitialField('udspOthers', setudspothers, 'name');
  }, []);

  return (
    <FormHolder
      isDirty={isDirty}
      onSubmit={handleSubmit(processForm)}
      className={`mx-auto mt-4 sm:mt-8 2xl:px-12 ${
        typeof formError === 'object' && Object?.keys(formError)?.length > 0
          ? 'p-4 pb-12 md:p-8 md:pb-14 border-2 border-red-500'
          : ''
      }`}
    >
      <div className="flex flex-col space-y-2 sm:space-y-0">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4">
          <FormComponentHolder FormComponent={FormSelect} {...orgLikeToWorkInField} />
          <FormComponentHolder FormComponent={FormSelect} {...interestedIndustryField} />
        </div>
        <div className="w-full pb-4">
          <h2>Awards</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-5 gap-x-4 xl:gap-x-8 3xl:gap-x-12">
            {award.map((awardParams, idx: number) => {
              const awardnameField = awardParams[`award-${idx}-name`];
              const awardgrantorgField = awardParams[`award-${idx}-grantOrg`];
              const awardyearField = awardParams[`award-${idx}-year`];

              return (
                <div key={`award-${idx}`}>
                  {award.length > 1 ? <h4 className="float-right">#Award-{idx + 1}</h4> : null}
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...awardnameField}
                    defaultValue={formValues[`award-${idx}-name`]}
                    errors={formError}
                  />
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...awardgrantorgField}
                    defaultValue={formValues[`award-${idx}-grantOrg`]}
                    errors={formError}
                  />
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...awardyearField}
                    defaultValue={formValues[`award-${idx}-year`]}
                    errors={formError}
                  />
                  {award.length > 1 ? <hr /> : null}
                </div>
              );
            })}
          </div>
          {award.length > 0 && award.length < 5 && (
            <button type="button" onClick={() => addFields(award, setaward, 'award')} className="button outline">
              Add Another Award
            </button>
          )}
        </div>

        <div className="w-full pb-4">
          <h2>Activites</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-5 gap-x-4 xl:gap-x-8 3xl:gap-x-12">
            {activites.map((activitesParams, idx: number) => {
              const activitynameField = activitesParams[`activites-${idx}-name`];
              const activitydurationField = activitesParams[`activites-${idx}-duration`];
              const activitydescriptionField = activitesParams[`activites-${idx}-description`];
              return (
                <div key={`activity-${idx}`}>
                  {activites.length > 1 ? <h4 className="float-right">#Activity-{idx + 1}</h4> : null}
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...activitynameField}
                    defaultValue={formValues[`activites-${idx}-name`]}
                    errors={formError}
                  />
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...activitydescriptionField}
                    defaultValue={formValues[`activites-${idx}-description`]}
                    errors={formError}
                  />
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...activitydurationField}
                    defaultValue={formValues[`activites-${idx}-duration`]}
                    errors={formError}
                  />
                  {activites.length > 1 ? <hr /> : null}
                </div>
              );
            })}
          </div>
          {activites.length > 0 && activites.length < 5 && (
            <button
              type="button"
              onClick={() => addFields(activites, setactivites, 'activites')}
              className="button outline"
            >
              Add Another Activity
            </button>
          )}
        </div>

        <div className="w-full pb-4">
          <h2>Academic Work</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-5 gap-x-4 xl:gap-x-8 3xl:gap-x-12">
            {academicwork.map((academicworkParams, idx: number) => {
              const academicworknameField = academicworkParams[`academicWork-${idx}-name`];
              const academicworkpublicationField = academicworkParams[`academicWork-${idx}-publication`];

              return (
                <div key={`academicwork-${idx}`}>
                  {academicwork.length > 1 ? <h4 className="float-right">#AcademicWork-{idx + 1}</h4> : null}
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...academicworknameField}
                    defaultValue={formValues[`academicWork-${idx}-name`]}
                    errors={formError}
                  />
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...academicworkpublicationField}
                    defaultValue={formValues[`academicWork-${idx}-publication`]}
                    errors={formError}
                  />
                  {academicwork.length > 1 ? <hr /> : null}
                </div>
              );
            })}
          </div>
          {academicwork.length > 0 && academicwork.length < 5 && (
            <button
              type="button"
              onClick={() => addFields(academicwork, setacademicwork, 'academicWork')}
              className="button outline"
            >
              Add Another Academic Work
            </button>
          )}
        </div>

        <div className="w-full pb-4">
          <h2>Certification</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-5 gap-x-4 xl:gap-x-8 3xl:gap-x-12">
            {certifications.map((certificationsParams, idx: number) => {
              const certificationsnameField = certificationsParams[`certifications-${idx}-name`];
              const certificationsdescriptionField = certificationsParams[`certifications-${idx}-description`];
              const certificationsdurationField = certificationsParams[`certifications-${idx}-duration`];

              return (
                <div key={`certifications-${idx}`}>
                  {certifications.length > 1 ? <h4 className="float-right">#certifications-{idx + 1}</h4> : null}
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...certificationsnameField}
                    defaultValue={formValues[`certifications-${idx}-name`]}
                    errors={formError}
                  />
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...certificationsdescriptionField}
                    defaultValue={formValues[`certifications-${idx}-description`]}
                    errors={formError}
                  />
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...certificationsdurationField}
                    defaultValue={formValues[`certifications-${idx}-duration`]}
                    errors={formError}
                  />
                  {certifications.length > 1 ? <hr /> : null}
                </div>
              );
            })}
          </div>
          {certifications.length > 0 && certifications.length < 5 && (
            <button
              type="button"
              onClick={() => addFields(certifications, setcertifications, 'certifications')}
              className="button outline"
            >
              Add Another Certifications
            </button>
          )}
        </div>
        <div className="w-full pb-4">
          <h2>Univ Data Science Projects and Others</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-5 gap-x-4 xl:gap-x-8 3xl:gap-x-12">
            {udspothers.map((udspothersParams, idx: number) => {
              const udspothersnameField = udspothersParams[`udspOthers-${idx}-name`];
              const udspothersdescriptionField = udspothersParams[`udspOthers-${idx}-description`];
              const udspothersdurationField = udspothersParams[`udspOthers-${idx}-duration`];

              return (
                <div key={`udspothers-${idx}`}>
                  {udspothers.length > 1 ? <h4 className="float-right">#udspothers-{idx + 1}</h4> : null}
                  <FormComponentHolder
                    FormComponent={FormInput}
                    {...udspothersnameField}
                    defaultValue={formValues[`udspOthers-${idx}-name`]}
                    errors={formError}
                  />
                  <FormComponentHolder
                    FormComponent={FormTextArea}
                    {...udspothersdescriptionField}
                    defaultValue={formValues[`udspOthers-${idx}-description`]}
                    errors={formError}
                  />
                  <FormComponentHolder
                    FormComponent={FormTextArea}
                    {...udspothersdurationField}
                    defaultValue={formValues[`udspOthers-${idx}-duration`]}
                    errors={formError}
                  />
                  {udspothers.length > 1 ? <hr /> : null}
                </div>
              );
            })}
          </div>
          {udspothers.length > 0 && udspothers.length < 5 && (
            <button
              type="button"
              onClick={() => addFields(udspothers, setudspothers, 'udspOthers')}
              className="button outline"
            >
              Add Another Projects/Other
            </button>
          )}
        </div>
      </div>
      <button className="button dark filled float-right mt-2" type="submit">
        <FaSave className="inline mr-2" /> Save
      </button>
    </FormHolder>
  );
}
