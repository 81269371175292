declare global {
  interface Window {
    dataLayer: any;
  }
}

export function captureEvent(
  category: string,
  label: string,
  maybeProductId?: null | string | number,
  value = 1
) {
  if (!window.dataLayer) return;

  // checks if value might be productId and converts to number if it can
  const action = maybeProductId
    ? typeof maybeProductId === 'string'
      ? parseInt(maybeProductId)
      : typeof maybeProductId === 'number'
      ? maybeProductId
      : 0
    : 0;
  window.dataLayer.push({
    event: 'event',
    eventProps: {
      category,
      action,
      label,
      value,
    },
  });
}

export function capturePage() {
  if (!window.dataLayer) return;
  window.dataLayer.push({ event: 'pageview' });
}

// export function capturePage(title: string) {
// window.dataLayer.push({
//   event: 'pageview',
//   page: {
//     url: window.location.href,
//     title,
//   }
// });
