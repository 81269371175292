import {
  MY_APPLICATIONS_PATH,
  MY_APPLICATIONS_PRODUCTS_PATH,
  LEARN_UNIV_PATH,
  HACKATHON_UNIV_PATH,
  UNIV_COURSES_PATH,
  COMMUNITY_PATH,
  DASHBOARD_PATH,
  ALL_MEETUPS_PATH,
  MY_SUBSCRIPTION_PATH,
  REFERRAL_PATH,
  JOBS_PATH,
  JOB_APPLICATION_PATH,
} from 'constants/app';
import React from 'react';
import {
  FaVoteYea,
  FaCode,
  FaBookReader,
  FaCalendarAlt,
  FaCoins,
  FaUserCircle,
  FaDiceD6,
  FaUsers,
  FaTh,
  FaThLarge,
  FaGraduationCap,
  FaBriefcase,
  FaUserPlus,
} from 'react-icons/fa';

export type TMenu = {
  label: string;
  link?: string;
  icon: JSX.Element;
  hyperlink?: boolean;
  submenu?: TMenu[];
  requiresAuth: boolean;
};

export const NavMenu: TMenu[] = [
  {
    label: 'Dashboard',
    link: DASHBOARD_PATH,
    icon: <FaDiceD6 />,
    requiresAuth: true,
  },
  {
    label: 'techx',
    icon: <FaTh />,
    requiresAuth: false,
    submenu: [
      {
        label: 'Offerings',
        link: ALL_MEETUPS_PATH,
        icon: <FaThLarge />,
        requiresAuth: false,
      },
      {
        label: 'Subscription',
        link: MY_SUBSCRIPTION_PATH,
        icon: <FaCoins />,
        requiresAuth: false,
      },
      {
        label: 'Referrals',
        link: REFERRAL_PATH,
        icon: <FaUserPlus />,
        requiresAuth: true,
      },
    ],
  },
  {
    label: 'Univ',
    icon: <FaGraduationCap />,
    hyperlink: true,
    requiresAuth: false,
    submenu: [
      {
        label: 'Offerings',
        link: `${MY_APPLICATIONS_PRODUCTS_PATH}||${UNIV_COURSES_PATH}`, //auth||unauth Link
        icon: <FaThLarge />,
        hyperlink: true,
        requiresAuth: false,
      },
      {
        label: 'Applications',
        link: MY_APPLICATIONS_PATH,
        icon: <FaVoteYea />,
        hyperlink: true,
        requiresAuth: true,
      },
      {
        label: 'Learn',
        link: LEARN_UNIV_PATH,
        icon: <FaBookReader />,
        hyperlink: true,
        requiresAuth: false,
      },
      { label: 'Hackathon', link: HACKATHON_UNIV_PATH, icon: <FaCode />, hyperlink: true, requiresAuth: false },
    ],
  },
  {
    label: 'Community',
    link: COMMUNITY_PATH,
    icon: <FaUsers />,
    requiresAuth: false,
  },
  // {
  //   label: 'Calendar',
  //   link: '/schedule',
  //   icon: <FaCalendarAlt />,
  //   requiresAuth: true,
  // },
  {
    label: 'Profile',
    link: '/profile',
    icon: <FaUserCircle />,
    requiresAuth: true,
  },
  {
    label: 'Jobs',
    link: JOBS_PATH,
    icon: <FaBriefcase />,
    requiresAuth: true,
    submenu: [
      {
        label: 'Offerings',
        link: JOBS_PATH,
        icon: <FaThLarge />,
        requiresAuth: true,
      },
      {
        label: 'Applications',
        link: JOB_APPLICATION_PATH,
        icon: <FaVoteYea />,
        requiresAuth: true,
      },
    ],
  },
];
