import React,{useMemo} from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import { TUserCurrentPlan } from 'types';
import moment from 'moment';
import Discount from './Discount';
import { getCurrencySymbol } from 'utils/currency';

type TProps = {
  plan: TUserCurrentPlan;
};
export default function CurrentPlan({plan}: TProps) {
  const {plan_display_name,is_free,start_date,end_date,currency,upcoming_payment} = plan;

  return (
    <div className="grid grid-cols-2 grid-rows-3 ">

      <div className="col-span-1 self-end justify-self-stretch">
        {plan_display_name?.toLowerCase()!=="free" && <span className="linked text-xl font-semibold font-switzer">{plan_display_name?.split(" ")[0]}</span>}
        <div className='flex flex-row items-center justify-between'>
          <h1 className="font-switzer text-4xl md:text-6xl w-full uppercase">{plan_display_name?.split(" ")[1] || plan_display_name?.split(" ")[0]}</h1>
        </div>
      </div>

        <div className='col-span-1 self-end justify-self-stretch cursor-default'>
          <span 
            className='text-base font-semibold text-text-grey font-switzer'
            data-tip="Start date refers to the date from which you would be able to register for upcoming classes"
          >
            Start Date
          </span>
          <h5 
          className="font-switzer text-lg sm:text-2xl"
          data-tip="Start date refers to the date from which you would be able to register for upcoming classes"
          >
            {!(is_free===true) ? moment(start_date)?.format('MMM DD, YYYY'):"NA"}
          </h5>
        </div>
      
        <div className="col-span-1 self-end justify-self-stretch">
          <span className='text-base font-semibold text-text-grey font-switzer'>Upcoming Payment</span>
          <h2 className="font-switzer text-4xl md:text-5xl font-normal">{!(is_free===true) ? (`${getCurrencySymbol(currency)} ${upcoming_payment}`) :"NA"}</h2>
        </div>

        <div 
          className='col-span-1 self-end justify-self-stretch cursor-default'>
            <span 
              className='font-switzer text-base font-semibold text-text-grey' 
              data-tip="Expiry date refers to the date from which you would not be able to register for upcoming classes">
              Expires On
            </span>
            <h5 
              className="font-switzer text-lg sm:text-2xl"
              data-tip="Expiry date refers to the date from which you would not be able to register for upcoming classes"
            >
              {!(is_free===true) ? moment(end_date)?.format('MMM DD, YYYY'):"NA"}
            </h5>
        </div>
    
        {/* Temp Remove Discount Code Component */}
        {/* <div className='col-span-2  sm:col-span-1 self-end'>
          <Discount/>
        </div> */}
        {/* <div className="flex linked cursor-pointer items-center">
          <h2 className="pointer linked font-switzer">Update Billing Details</h2>
          <FaArrowCircleRight className="self-center ml-2"></FaArrowCircleRight>
        </div> */}

    </div>
  );
}
