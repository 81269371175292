import React from 'react';

type Props = {
  Icon?: any;
  defaultValue?: any;
  fieldProps: any;
  label: string;
  info?: string;
  name: string;
  options?: TOption[];
  placeholder?: string;
  error?: boolean;
};

type TOption =
  | {
      label: string;
      value: string;
    }
  | string;

export function FormSelect(props: Props) {
  const { defaultValue, error, options, fieldProps, placeholder } = props;

  if (!options) return null;

  const opts = [...options];

  return (
    <select
      key={fieldProps.name}
      className={`text-sm sm:text-base relative w-full  placeholder-gray-400
        ${fieldProps.disabled ? 'bg-white border-none' : ''}
        ${error ? 'border-2 border-red-500 focus:ring-red-300' : 'border border-gray-300 focus:border-blue-300'}
      `}
      {...fieldProps}
      placeholder={placeholder}
      defaultValue={defaultValue || ''}
    >
      {!defaultValue && (
        <option disabled hidden value={''}>
          {placeholder}
        </option>
      )}
      {opts.map((option: TOption) =>
        typeof option === 'string' ? (
          <option key={option} value={option}>
            {option}
          </option>
        ) : (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        )
      )}
    </select>
  );
}
