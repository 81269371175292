import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Otp from "./Otp";

type Props = {
  Icon?: any;
  fieldProps: any;
  label: string;
  info?: string;
  placeholder?: string;
  defaultValue: string;
  error?: string;
  getValues :Function;
};

export function FormEmailVerify(props: Props) {
  const { defaultValue, error, placeholder, fieldProps, getValues } = props;

  return (
    <div className="grid grid-cols-9">
        <div className="col-span-7">
            <input
            className={`text-sm sm:text-base relative w-full border border-gray-300 placeholder-gray-400
                ${fieldProps.disabled ? 'bg-white border-none' : ''}
                ${error ? 'border border-red-500 focus:ring-red-300' : 'focus:border-blue-300'}
            `}
            key={fieldProps.name}
            placeholder={placeholder}
            type="text"
            defaultValue={defaultValue}
            {...fieldProps}
            />
        </div>
        <div className="col-span-2">
          <Otp getValues={getValues} defaultValue={defaultValue} type="email"/>
      </div>
    </div>
  );
}
