import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { JOBS_PATH, API_URL, GET_JOB_DETAIL } from 'constants/app';
import fetch from 'utils/fetch';
import { TJobs, TJobStatus } from 'types';
import { useState } from 'react';
import { HiLocationMarker, HiOfficeBuilding, HiClock } from 'react-icons/hi';
import { FaUserCircle, FaRupeeSign } from 'react-icons/fa';
import { AiFillRightCircle } from 'react-icons/ai';
import Markdown from 'markdown-to-jsx';
import Modal from 'fragments/Modal';
import JobApplicationModal from './JobApplicationModal';
import { JobStatusChip } from './JobsUtils';
import JobRecommendation from './JobRecommendation';
import Spinner from 'fragments/Spinner';
import ErrorMsg from 'components/Error';
import JobApplicationPage from './JobApplicationPage';
import JTabs from './tabs';
import useStore from 'globalstate';

const JobChips = ({ type }: { type: string }) => (
  <div className="bg-JobBluebg 3xl:text-xl text-xsm px-1 py-0.5  rounded-sm">
    <span className="text-text-dark">{type}</span>
  </div>
);

const JobDetails = () => {
  const [State] = useStore();
  const { isAuthenticated } = State;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [jobDetail, setJobDetail] = useState<TJobs>();
  const [applyStatus, setApplyStatus] = useState<TJobStatus | null>(null);
  const [jobApplicationModal, setJobApplicationModal] = useState<boolean>(false);
  const [openedTabIndex, setOpenedTabIndex] = useState<number>();
  const { jobId } = useParams<{ jobId: string }>();

  const openJobApplication = () => setOpenedTabIndex(2);

  const closeJobApplication = () => setJobApplicationModal(false);

  const companyDescriptionTab = () => {
    return (
      <div className="flex flex-col bg-white rounded-lg overflow-hidden font-switzer shadow-lg relative p-4">
        <h2 className="text-lg font-semibold text-text-dark">About Company</h2>
        <span className="flex items-center text-text-blue">
          <HiOfficeBuilding className="mr-1" />
          <h3 className="text-sm text-text-blue">{jobDetail?.company_name}</h3>
        </span>
        {jobDetail?.company_description && (
          <Markdown className="font-switzer text-text-dark">{jobDetail?.company_description}</Markdown>
        )}
      </div>
    );
  };
  const jobDescriptionTab = () => {
    return (
      <div className="flex flex-col bg-white rounded-lg overflow-hidden font-switzer shadow-lg relative p-4">
        <div className="w-full">
          <h2 className="text-lg font-semibold text-text-dark">Job Description</h2>
          <p className="text-sm text-text-dark">{jobDetail?.description}</p>
          <Markdown className="font-switzer mt-2">{jobDetail?.meta?.other_details || ''}</Markdown>
        </div>

        {jobDetail?.status && jobDetail?.status === null && applyStatus === null && (
          <div
            className="inline-flex text-text-blue items-center cursor-pointer text-space ml-auto"
            onClick={openJobApplication}
          >
            <span className="mr-2 text-sm 3xl:text-xl font-semibold leading-none">Apply Now</span>
            <AiFillRightCircle className="text-base 3xl:text-xl" />
          </div>
        )}
      </div>
    );
  };

  const applicationTab = () => {
    if (!jobDetail) return <h1>No Application Process</h1>;
    return (
      <div className="flex flex-col bg-white rounded-lg overflow-hidden font-switzer shadow-lg relative p-4">
        <div className="w-full">
          {jobDetail?.meta?.hasProcessFlow ? (
            <JobApplicationPage
              position={jobDetail.position}
              company_name={jobDetail.company_name}
              job_id={jobDetail.id}
              close={closeJobApplication}
              setApplyStatus={setApplyStatus}
            />
          ) : (
            <JobApplicationModal
              position={jobDetail.position}
              company_name={jobDetail.company_name}
              job_id={jobDetail.id}
              close={closeJobApplication}
              setApplyStatus={setApplyStatus}
            />
          )}
        </div>
      </div>
    );
  };
  const tabs = [
    {
      label: 'Job Description', // Tab Title - String
      Component: jobDescriptionTab, // Tab Body - JSX.Element
    },
    {
      label: 'About ' + jobDetail?.company_name, // Tab Title - String
      Component: companyDescriptionTab, // Tab Body - JSX.Element
    },
    {
      label: 'Apply',
      Component: applicationTab,
    },
  ];
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const resp = await fetch(`${GET_JOB_DETAIL}?jobId=${jobId}`, {
          credentials: 'header',
        });
        const respJSON = await resp.json();
        if (!respJSON.success) throw new Error(respJSON.error);
        const jobDetailData: TJobs = await respJSON.data;
        setJobDetail(jobDetailData);
      } catch (e: any) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [jobId]);

  if (!isAuthenticated)
    return <ErrorMsg embed={false} preset="login" text={'You are not authenticated. Login to continue.'} />;

  return (
    <div className="flex flex-col w-full h-full">
      <div className="w-full h-8 3xl:h-10 flex flex-row items-center justify-between bg-greybg sticky top-0 right-0 z-10">
        <h3 className="font-switzer text-sm 3xl:text-base text-text-blue font-normal">
          <Link to={JOBS_PATH} className="font-switzer text-sm 3xl:text-base text-text-blue font-normal">
            Jobs
          </Link>{' '}
          &nbsp;/&nbsp; Job Details
        </h3>
      </div>
      {/* Loader */}
      {loading === true && (
        <div className="h-80vh">
          <Spinner space show />
        </div>
      )}
      {/* Error message */}
      {error && loading === false && (
        <div className="col-span-full text-text-dark text-base md:text-xl">
          <ErrorMsg embed={false} preset="broken" text={error} />
        </div>
      )}
      <div className="flex flex-col lg:flex-row items-start gap-4">
        {jobDetail && loading === false && (
          <div className="flex flex-col gap-4 w-full">
            {/* Deatils Card  */}
            <div className={`flex flex-col bg-white rounded-lg overflow-hidden font-switzer shadow-lg relative px-4`}>
              <div className="flex flex-row gap-4 w-full">
                {/* Company Logo */}
                {jobDetail?.company_logo ? (
                  <img
                    src={jobDetail?.company_logo}
                    alt={jobDetail?.company_name}
                    className="3xl:w-36 3xl:min-w-36 3xl:h-36 w-88px min-w-88px h-88px object-contain my-4"
                  />
                ) : (
                  <div className="3xl:w-36 3xl:min-w-36 3xl:h-36 w-88px min-w-88px h-88px bg-JobBluebg text-4xl rounded-full flex justify-center items-center text-text-grey my-4">
                    {jobDetail?.company_name.split('')[0]}
                  </div>
                )}
                <div className="w-full cursor-default">
                  {/* Source*/}
                  <h2 className="3xl:text-xl text-sm text-text-blue absolute right-4 top-2.5 text-right">Univ</h2>
                  {/* Position Title */}
                  <h2 className="3xl:text-3xl text-base mt-4 text-text-dark">{jobDetail?.title}</h2>
                  {/* Company Name */}
                  <span className="flex items-center text-text-blue">
                    <HiOfficeBuilding className="3xl:text-xl mr-1" />
                    <h3 className="3xl:text-xl text-sm text-text-blue">{jobDetail?.company_name}</h3>
                  </span>
                  {/* Job Basic Info */}
                  <div className="flex flex-row flex-wrap gap-x-4 items-center my-1">
                    <span className="flex items-center">
                      <FaUserCircle className="mr-1 3xl:text-xl text-text-grey-light" />
                      <h3 className="3xl:text-xl text-sm text-text-grey-light">{jobDetail?.position}</h3>
                    </span>
                    <span className="flex items-center">
                      <HiClock className="mr-1 3xl:text-xl text-text-grey-light" />
                      <h3 className="3xl:text-xl text-sm text-text-grey-light">{jobDetail?.nature_of_work}</h3>
                    </span>
                    <span className="flex items-center">
                      <HiLocationMarker className="mr-1 3xl:text-xl text-text-grey-light" />
                      <h3 className="3xl:text-xl text-sm text-text-grey-light">{jobDetail?.location}</h3>
                    </span>
                    <span className="flex items-center">
                      <FaRupeeSign className="mr-1 3xl:text-xl text-sm text-text-grey-light" />
                      <h3 className="3xl:text-xl text-sm text-text-grey-light">{`${jobDetail?.salary_start}K - ${jobDetail?.salary_end}K`}</h3>
                    </span>
                  </div>
                  <div className="hidden xsm:flex xsm:flex-row xsm:items-start xsm:justify-between my-1">
                    {/* Badges */}
                    <div className="flex flex-row flex-wrap gap-x-4 gap-y-1">
                      {jobDetail.tags &&
                        jobDetail.tags.map((type: string, idx: number) => (
                          <JobChips type={type} key={`JobChip-${jobDetail.id}-${type}-tags-${idx}`} />
                        ))}
                      {jobDetail.skills &&
                        jobDetail.skills.map((type: string, idx: number) => (
                          <JobChips type={type} key={`JobChip-${jobDetail.id}-${type}-skill-${idx}`} />
                        ))}
                    </div>

                    {/* Learn More */}
                    {(jobDetail?.status && jobDetail?.status !== null) || applyStatus !== null ? (
                      <JobStatusChip status={jobDetail?.status ? jobDetail?.status : applyStatus} />
                    ) : (
                      <div
                        className="text-text-blue 3xl:min-w-36 min-w-100px flex flex-row items-center cursor-pointer text-space"
                        onClick={openJobApplication}
                      >
                        <span className="mr-2 text-sm 3xl:text-xl font-semibold leading-none">Apply Now</span>
                        <AiFillRightCircle className="text-base 3xl:text-xl" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="xsm:hidden flex flex-row items-start justify-between my-1">
                {/* Badges */}
                <div className="flex flex-row flex-wrap gap-x-4 gap-y-1">
                  {jobDetail.tags &&
                    jobDetail.tags.map((type: string, idx: number) => (
                      <JobChips type={type} key={`JobChip-${jobDetail.id}-${type}-tags-${idx}`} />
                    ))}
                  {jobDetail.skills &&
                    jobDetail.skills.map((type: string, idx: number) => (
                      <JobChips type={type} key={`JobChip-${jobDetail.id}-${type}-skill-${idx}`} />
                    ))}
                </div>

                {/* Learn More */}
                {(jobDetail?.status && jobDetail?.status !== null) || applyStatus !== null ? (
                  <JobStatusChip status={jobDetail?.status ? jobDetail?.status : applyStatus} />
                ) : (
                  <div
                    className="text-text-blue 3xl:min-w-36 min-w-100px flex flex-row items-center justify-end cursor-pointer text-space"
                    onClick={openJobApplication}
                  >
                    <span className="mr-2 text-sm 3xl:text-xl font-semibold leading-none">Apply Now</span>
                    <AiFillRightCircle className="text-base 3xl:text-xl" />
                  </div>
                )}
              </div>
            </div>

            <JTabs color="indigo" tabContent={tabs} changeTabTo={openedTabIndex}></JTabs>
          </div>
        )}
        {loading === false && jobDetail?.recommended_course && jobDetail?.recommended_job && (
          <JobRecommendation
            recommended_course={jobDetail?.recommended_course}
            recommended_job={jobDetail?.recommended_job}
          />
        )}
      </div>
      {jobDetail && (
        <Modal isOpen={jobApplicationModal} size="lg" bgColor="bg-white" close={closeJobApplication}>
          {jobDetail.meta.hasProcessFlow ? (
            <JobApplicationPage
              position={jobDetail.position}
              company_name={jobDetail.company_name}
              job_id={jobDetail.id}
              close={closeJobApplication}
              setApplyStatus={setApplyStatus}
            />
          ) : (
            <JobApplicationModal
              position={jobDetail.position}
              company_name={jobDetail.company_name}
              job_id={jobDetail.id}
              close={closeJobApplication}
              setApplyStatus={setApplyStatus}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default JobDetails;
