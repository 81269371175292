/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react';
import { FaTimes, FaTrash } from 'react-icons/fa';
import { FaUserCircle, FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import { AiFillRightCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import { jsx } from '@emotion/react';
import moment from 'moment';
import DynamicForm from 'components/DynamicForm';
import EventDetail from 'components/AllEvents/EventDetail';
import useStore from 'globalstate';
import { IRequestResponse, TMeetup } from 'types';
import fetch from 'utils/fetch';
import { USER_EVENT_REGISTER_URL, DEFAULT_CARD_IMG, MEETUP_DETAIL_PATH } from 'constants/app';
import { getDynamicFormFields } from 'utils/form-fields';
import Modal from 'fragments/Modal';
import { captureException } from '@sentry/minimal';
import { enrol } from 'components/GHF/Modal';
import toaster from 'utils/toast';
import { getCardColor } from 'utils/getCardColor';
import { getCurrencySymbol } from 'utils/currency';
import { Link, useHistory } from 'react-router-dom';
import { COMMUNITY_DETAIL_PATH } from '../../constants/app';

type TProps = {
  data: TMeetup;
  onStateChange: Function;
};

export async function saveRegistrationDetails(data: { [key: string]: string }, eventId: number): Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    const response = await reqRegisterUser(data, eventId);

    let respJSON;
    try {
      respJSON = await response.json();
    } catch (e) {
      captureException(e);
    }
    if (response.status !== 200)
      if (response.status == 403) {
        if (respJSON.error.startsWith('You do not have the required permissions to access this event'))
          localStorage.setItem('regEvntNonCnfrmd', JSON.stringify({ data: data, event: { id: eventId } }));

        return reject(respJSON.error);
      }
    toaster.success('You are successfully registered!');
    return resolve(respJSON);
  });
}

export async function reqRegisterUser(data: { [key: string]: string }, eventId: number) {
  const params = {
    method: 'POST',
    body: JSON.stringify({
      data: data,
      event_id: eventId,
      code: data.code,
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  const response = await fetch(USER_EVENT_REGISTER_URL, {
    credentials: 'header',
    ...params,
  });

  return response;
}
export async function requestDeRegistration(eventId: number): Promise<IRequestResponse> {
  const params = {
    method: 'POST',
    body: JSON.stringify({
      event_id: eventId,
      register: false,
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  const response = await fetch(USER_EVENT_REGISTER_URL, {
    credentials: 'header',
    ...params,
  });

  return response.json();
}
export default function UpdatedCard(props: TProps) {
  const [State] = useStore();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const [isDeregisterModalOpen, setIsDeregisterModalOpen] = useState<boolean>(false);
  const { isAuthenticated } = State;
  const {
    name,
    description,
    scheduled_datetime,
    meta,
    page_url,
    end_datetime,
    registered_on,
    type,
    plan_name,
    alacarte_rate,
    currency,
    payment_id,
    product_id,
  } = props.data;
  const displayName = meta.display_name ? meta.display_name : name;
  const domain = meta?.domain;
  const [isRegModalOpen, setIsRegModalOpen] = useState<boolean>(false);

  const history = useHistory();

  const getHours = (scheduled_datetime: any, end_datetime: any) => {
    const start = moment(scheduled_datetime);
    const end = moment(end_datetime);
    const hours = moment.duration(end.diff(start)).as('hours');

    if (hours <= 24) {
      return `${hours === 1 ? `${hours} Hour` : `${hours} Hours`}`;
    } else if (hours > 24 && hours <= 168) {
      const day = end.diff(start, 'days');
      return `${day === 1 ? `${day} Day` : `${day} Days`}`;
    } else {
      const week = end.diff(start, 'weeks');
      return `${week === 1 ? `${week} Week` : `${week} Weeks`}`;
    }
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };
  const closeDeregModal = () => {
    setIsDeregisterModalOpen(false);
  };

  function closeRegModal() {
    setIsRegModalOpen(false);
  }
  const openDetailView = () => {
    //if (window.location.href.split('/')[3] === 'event') setIsDetailModalOpen(true);
    domain === 'community'
      ? history.push(`${COMMUNITY_DETAIL_PATH}/` + name)
      : history.push(`${MEETUP_DETAIL_PATH}/` + name);
  };
  const deregisterEvent = async () => {
    const res = await requestDeRegistration(props.data.id);
    if (res.success == true) {
      toaster.success('You Are successfully deregistered.');
      props.onStateChange();
    } else if (res.error) {
      toaster.error(res.error);
    }

    closeDeregModal();
  };
  async function registerFromForm(data: { [key: string]: string }) {
    saveRegistrationDetails(data, props.data.id)
      .then(res => {
        props.onStateChange();
      })
      .catch(async e => {
        {
          toaster.error(e);
          if (e.startsWith('You do not have the required permissions to access this event')) {
            const isEnrolled: boolean | string = await enrol();
            if (isEnrolled == false) {
              toaster.error('Unable to register you to GHF!');
              captureException(`Unable to Register  for event ${props.data.name}`);
            }
            return true;
          }
          return false;
        }
      });
  }

  return (
    <div
      className={`${
        type?.toUpperCase() === 'PROGRAM' || type?.toUpperCase() === 'WORKSHOP'
          ? 'col-span-8'
          : 'col-span-8 xsm:col-span-4'
      }`}
    >
      <ReactTooltip />
      <div
        className={`${
          type?.toUpperCase() === 'PROGRAM' || type?.toUpperCase() === 'WORKSHOP' ? 'h-400px xsm:h-60' : 'h-60'
        } 3xl:h-360px p-6 flex flex-col xsm:flex-row-reverse  gap-4 rounded overflow-hidden font-switzer shadow-lg relative  ${getCardColor(
          plan_name
        )}`}
      >
        {/* Program Image */}
        {(type?.toUpperCase() === 'PROGRAM' || type?.toUpperCase() === 'WORKSHOP') && (
          <div className="w-full h-36 xsm:w-1/2 xsm:h-full">
            <img
              className="w-full h-full cursor-pointer"
              src={meta.hero_image ? meta.hero_image : DEFAULT_CARD_IMG}
              onClick={openDetailView}
            />
          </div>
        )}

        {/* Discription Section  */}
        <div
          className={`${
            type?.toUpperCase() === 'PROGRAM' || type?.toUpperCase() === 'WORKSHOP' ? 'w-full xsm:w-1/2' : 'w-full'
          } relative h-full`}
        >
          <div className="flex flex-row justify-between items-center">
            {/* ProgramType */}
            {meta?.slot_status_text ? (
              <span
                className="text-xs 3xl:text-base font-bold self-center m-0 text-red-400 font-switzer uppercase"
                data-tip={type?.toUpperCase() === 'WORKSHOP' ? 'PROGRAM' : 'COURSE'}
                key={name}
              >
                {meta?.slot_status_text}
              </span>
            ) : (
              <span
                className="text-xs 3xl:text-base font-medium self-center m-0 text-text-blue font-switzer"
                data-tip={type?.toUpperCase() === 'WORKSHOP' ? 'PROGRAM' : 'COURSE'}
                key={name}
              >
                {meta?.available_slots && meta?.total_slots
                  ? `${meta?.available_slots} / ${meta?.total_slots} Slots`
                  : null}
              </span>
            )}
            {/* Subscription Type*/}
            <span
              className="text-xs 3xl:text-base font-semibold self-center m-0 text-text-blue uppercase font-switzer"
              data-tip={plan_name && plan_name?.toUpperCase()}
            >
              {plan_name}
            </span>
          </div>

          {/* Title */}
          <div className="py-2">
            <h1
              data-tip={name}
              className={` text-ellipsis h-12 3xl:h-72px 3xl:max-h-20 text-text-dark font-switzer text-base 3xl:text-2xl font-medium line-clamp-2 cursor-default max-h-14 overflow-hidden ${
                name.length > 35 ? 'line-clamp-2' : 'flex items-end'
              }`}
            >
              {displayName?.toUpperCase()}
            </h1>
          </div>

          {/* Instruction and Course */}
          <div className="pb-2 3xl:pb-4">
            <div className="flex flex-row items-center justify-between mb-2">
              <div className="flex flex-row items-center">
                <FaUserCircle className="text-text-grey-light 3xl:text-base mr-2" />
                <div className="text-text-grey-light text-xs 3xl:text-base font-medium leading-4 cursor-default">
                  {meta.speaker_name ? meta?.speaker_name?.toUpperCase() : ' '}
                </div>
              </div>
              <div className="flex flex-row items-center">
                <span className="text-text-grey-light text-sm leading-4 3xl:text-base mr-1">
                  {getCurrencySymbol(currency)}
                </span>
                <div className="text-text-grey-light text-xs 3xl:text-base font-medium leading-4 cursor-default">
                  {alacarte_rate}
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center justify-between mt-2">
              <div className="flex flex-row items-center">
                <FaRegCalendarAlt className="text-text-grey-light 3xl:text-base mr-2" />
                <div className="text-text-grey-light text-xs 3xl:text-base font-medium leading-4 cursor-default ">
                  {moment(scheduled_datetime).format('Do MMM YYYY')}
                </div>
              </div>

              <div className="flex flex-row items-center">
                <FaRegClock className="text-text-grey-light 3xl:text-base mr-2" />
                <div className="text-text-grey-light text-xs 3xl:text-base font-medium cursor-default">
                  {getHours(scheduled_datetime, end_datetime)}
                </div>
              </div>
            </div>
          </div>

          {/* Description */}
          <div>
            <p className="text-text-grey text-xs 3xl:text-base m-0 break-words line-clamp-3 3xl:line-clamp-4">
              {description}
            </p>
          </div>

          <div
            onClick={openDetailView}
            className="text-text-blue flex flex-row items-center absolute left-0 bottom-0 flex-auto cursor-pointer text-space pt-3"
          >
            <span className="mr-2 text-sm 3xl:text-xl leading-none">Learn More</span>
            <AiFillRightCircle className="text-base 3xl:text-xl" />
          </div>
        </div>
      </div>

      <Modal isOpen={isRegModalOpen} close={closeRegModal}>
        <DynamicForm
          closeModal={closeRegModal}
          onUpdate={registerFromForm}
          formFields={getDynamicFormFields(meta.required_form_fields)}
          eventName={name}
        />
      </Modal>

      <Modal isOpen={isDeregisterModalOpen} close={closeDeregModal}>
        <div className="p-10">
          <div>
            <span className="text-center">
              {' '}
              Are You Sure you want to Deregister from <strong>{displayName}</strong> ?
            </span>
          </div>

          <div className="flex place-content-between  pt-5 bottom-0">
            <button className="large" onClick={closeDeregModal}>
              <FaTimes />
              &nbsp;Cancel
            </button>
            <button onClick={deregisterEvent}>
              <FaTrash />
              &nbsp; Deregister
            </button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isDetailModalOpen} close={closeDetailModal} size="lg" bgColor={getCardColor(plan_name)}>
        <EventDetail
          event={{
            name: displayName,
            description: description,
            startDate: scheduled_datetime,
            endDate: end_datetime,
            isLive: false,
            heroImage: meta.hero_image,
            registrationLink: meta.registration_url,
            isRegistered: !!registered_on,
            meetupLink: meta.meetup_link,
            pageLink: page_url,
            speakerName: meta.speaker_name,
            speakerBio: meta.speaker_bio,
            formFieldName: meta.required_form_fields,
            eventId: props.data.id,
            require_registration: meta.require_registration,
            difficulty: meta.difficulty_level,
            eventCode: meta.event_code,
            planName: plan_name,
            totalSlots: meta.total_slots,
            availableSlots: meta.available_slots,
            slotStatusText: meta?.slot_status_text,
            alacarte_rate: alacarte_rate,
            currency: currency,
            payment_id: payment_id,
            product_id: product_id,
          }}
          isAuthenticated={isAuthenticated}
        />
      </Modal>
    </div>
  );
}
