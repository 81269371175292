import React, { useState, useEffect, useMemo } from 'react';
import { Stepper, Step } from 'react-form-stepper';
import fetch from 'utils/fetch';
import Spinner from 'fragments/Spinner';
import ErrorMsg from 'components/Error';
import moment from 'moment';
import { TIndividualPlan } from 'components/Subscription/PlanSimple';
import Payment from 'components/Payment';
import { GET_GLOBAL_SLOTS, getAuthRefreshURL } from 'constants/app';
import { setTimeout } from 'timers';
type TSlots = {
  id: number;
  name: string;
  is_active: boolean;
  start_date: number;
  total_slots: number;
  available_slots: number;
};

const GlobalSlot = ({ agentDiscountPercentage, product, close, billingFrequency }: {agentDiscountPercentage?: number, product: TIndividualPlan,billingFrequency?:string ,close: Function }) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [loading, setloading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  const [slotData, setSlotData] = useState<TSlots[]>();
  const [selectedSlot, setSelectedSlot] = useState<TSlots>();
  const [isSlotSelected, setIsSlotSelected] = useState<boolean>();

  const CURRENCY_SYMBOL = useMemo(() => {
    if (product === undefined) return '';
    return product?.currency === 'INR' ? '₹' : '$';
  }, [product]);

  const connectorStyleConfig = {
    disabledColor: '#bdbdbd',
    activeColor: '#5D9DE0',
    completedColor: '#2b41bc',
    size: 5,
    stepSize: '2em',
    style: 'solid',
  };

  const styleConfig = {
    activeBgColor: '#5D9DE0',
    activeTextColor: 'white',
    completedBgColor: '#2b41bc',
    completedTextColor: 'white',
    inactiveBgColor: '#e0e0e0',
    inactiveTextColor: '#2b41bc',
    size: '2em',
    circleFontSize: '1rem',
    labelFontSize: '1.2rem',
    borderRadius: '50%',
    fontWeight: '500',
  };

  const ifSelected = (id: number) =>
    selectedSlot?.id === id ? 'bg-brand-dark text-white font-semibold' : 'border-text-blue text-text-blue';

  const finishBtnHandler = () => {
    setActiveStep(4);
    window.location.href = getAuthRefreshURL(window.location.href);
    close();
  };
  const backBtnHandler = () => setActiveStep(prev => (prev !== 0 ? prev - 1 : 0));
  const nextBtnHandler = () =>
    setActiveStep((prev: number) => {
      if (prev === 1 && !selectedSlot) {
        setIsSlotSelected(false);
        return 1;
      } else if (prev === 1 && selectedSlot) {
        setIsSlotSelected(true);
        return 2;
      }

      if (prev < 2) return prev + 1;
      return 2;
    });

  const InputSlot = ({ item }: { item: TSlots }) => (
    <div
      className={`p-4 border-2 rounded-md flex flex-col cursor-pointer ${ifSelected(item.id)}`}
      onClick={() => setSelectedSlot(item)}
    >
      <span>{moment?.unix(item?.start_date)?.format('MMM DD, YYYY')}</span>
      <span>
        Slots -{item?.available_slots}/{item?.total_slots}
      </span>
    </div>
  );

  const [timerCount, SetTimerCount] = useState<number>(3);
  useEffect(() => {
    let timeInterval: any;
    if (activeStep === 3)
      timeInterval = setTimeout(() => {
        if (timerCount === 0) {
          clearTimeout(timeInterval);
          window.location.href = getAuthRefreshURL(window.location.href);
        } else {
          SetTimerCount(prev => prev - 1);
        }
      }, 1000);

    return () => {
      if (timeInterval) clearTimeout(timeInterval);
    };
  }, [timerCount, activeStep]);

  const getForm = (stepNo: number) => {
    switch (stepNo) {
      case 0:
        return (
          <div className="text-center">
            <br />
            {/* <h1 className='font-switzer text-center'>Plan Summary</h1>
          <br /> */}
            <h1 className="font-switzer text-text-grey font-bold">{product?.displayname}</h1>
            <br />
            <h3 className="font-switzer text-text-grey text-justify md:w-1/2 m-auto">{product?.description}</h3>
            <br />
            <h2 className="font-switzer text-text-grey font-bold text">
              Amount - {CURRENCY_SYMBOL} {product?.discountedprice ? (
                  agentDiscountPercentage && product?.billing_frequency === "yearly" ? Math.round(product?.discountedprice - ( (agentDiscountPercentage * product?.discountedprice)/100)) : product?.discountedprice
                ) : (
                  agentDiscountPercentage && product?.billing_frequency === "yearly" ? Math.round(product?.price - ( (agentDiscountPercentage * product?.price)/100)) :  product?.price
                )}
            </h2>
          </div>
        );
      case 1:
        return (
          <div className="grid grid-cols-2 gap-2 sm:grid-cols-4 sm:gap-4">
            {(() => {
              if (loading) return <Spinner space show />;
              if (error) return <ErrorMsg preset="broken" text={error} />;
              return (
                <React.Fragment>
                  <br />
                  <div className="col-span-2 sm:col-span-4">
                    <h1 className="font-switzer text-text-grey text-center ">Choose the Slot</h1>
                    <p className="font-switzer text-text-grey text-center ">
                      Slot determines your subscription start date i.e after which you can register for the upcoming
                      courses.
                    </p>
                    {/* <p className="font-switzer text-text-grey text-center m-0 ">
                      If the slot date is in future then your subscription would start from the slot date else if it is
                      in past then your payment date would be your subscription start date.Subscription start date is decided by what slot date you select.

                      <br />
                    </p> */}
                  </div>
                  <div className="col-span-2 sm:col-span-4 justify-self-center">
                    <h3 className="font-bold text-center">Example:</h3>
                    <table className="table-auto border-collapse table p-0 m-0">
                      <tr className="p-0 m-0 md:p-4 md:m-4">
                        <th className="p-0 m-0 md:p-4 md:m-4">Case</th>
                        <th className="p-0 m-0 md:p-4 md:m-4">Payment Date</th>
                        <th className="p-0 m-0 md:p-4 md:m-4">Slot Date</th>
                        <th className="p-0 m-0 md:p-4 md:m-4">Subscription Start Date</th>
                      </tr>
                      <tr className="p-0 m-0 md:p-4 md:m-4">
                        <th className="p-0 m-0 md:p-4 md:m-4">Payment done before slot date</th>
                        <td className="p-0 m-0 md:p-4 md:m-4">10-Sept-2022</td>
                        <td className="p-0 m-0 md:p-4 md:m-4">3-Oct-2022</td>
                        <td className="p-0 m-0 md:p-4 md:m-4">3-Oct-2022</td>
                      </tr>

                      <tr className="p-0 m-0 md:p-4 md:m-4">
                        <th className="p-0 m-0 md:p-4 md:m-4">Payment done after slot date</th>
                        <td className="p-0 m-0 md:p-4 md:m-4">6-Oct-2022</td>
                        <td className="p-0 m-0 md:p-4 md:m-4">3-Oct-2022</td>
                        <td className="p-0 m-0 md:p-4 md:m-4">6-Oct-2022</td>
                      </tr>
                    </table>
                  </div>

                  {slotData && slotData.map((item, idx) => <InputSlot item={item} key={`InputSlot-${idx}`} />)}
                  {isSlotSelected === false && !selectedSlot && (
                    <h3 className="col-span-2 sm:col-span-4 text-red-400">* Please select the slot first</h3>
                  )}
                </React.Fragment>
              );
            })()}
          </div>
        );
      case 2:
        return (
          <div>
            <Payment
              productId={product?.product_id?.toString()}
              paymentId={product?.payment_id}
              slotId={selectedSlot?.id?.toString()}
              setActiveStep={setActiveStep}
              meetupId={product.meetupId}
              billingFrequency={billingFrequency}
            />
          </div>
        );
      case 3:
        return (
          <h1 className="font-switzer text-center flex flex-col items-center justify-center relative">
            <br />
            <img className="m-auto w-1/4" src={'./sucessPaymentIcon.gif'} />
            <br />
            <h3 className="text-text-dark text-xl">Payment Successfull 🎉🎉🎉</h3>
            <br />
            <p className="font-sans text-text-dark font-bold text-lg md:w-1/2 m-auto text-justify">
              * Please don&apos;t close or refresh the the window.
            </p>
            <p className="text-text-grey text-lg md:w-1/2 m-auto text-justify">
              * Please note that payment might take some time to reflect on our systems. You will get an email receipt
              once we receive your payment.
            </p>
            <h1 className="absolute right-8 top-8 bg-red-400 w-16 h-16 rounded-full flex items-center justify-center text-white">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
              {timerCount}
            </h1>
          </h1>
        );
    }
  };

  useEffect(() => {
    (async () => {
      setloading(true);
      try {
        const resp = await fetch(GET_GLOBAL_SLOTS, {
          credentials: 'header',
        });
        const respJSON = await resp.json();
        if (!respJSON.success) throw new Error(respJSON.error);
        const slots: TSlots[] = respJSON.data;
        setSlotData(slots);
      } catch (e: any) {
        setError(e.message);
      } finally {
        setloading(false);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <h3 className="font-switzer text-text-blue font-normal text-sm h-8 3xl:text-base 3xl:h-10 flex items-center px-4">
        Subscription / Globalslot
      </h3>
      <Stepper
        activeStep={activeStep}
        className="text-brand-darker p-0 sm:p-4"
        connectorStateColors={true}
        connectorStyleConfig={connectorStyleConfig}
      >
        <Step styleConfig={styleConfig} className="text-brand-darker font-semibold" label="Plan Summary" />
        <Step styleConfig={styleConfig} className="text-brand-darker font-semibold" label="Select Slot" />
        <Step styleConfig={styleConfig} className="text-brand-darker font-semibold" label="Intialize Payment" />
        <Step styleConfig={styleConfig} className="text-brand-darker font-semibold" label="End" />
      </Stepper>

      <div className="h-60vh overflow-y-auto">{getForm(activeStep)}</div>

      <div className="flex flex-row justify-between items-center w-44 my-2">
        <button className="w-20 bg-text-blue" onClick={backBtnHandler}>
          {' '}
          Back{' '}
        </button>
        {activeStep === 3 ? (
          <button className="w-20 bg-text-blue" onClick={finishBtnHandler}>
            {' '}
            Finish{' '}
          </button>
        ) : (
          <button className="w-20 bg-text-blue" onClick={nextBtnHandler}>
            {' '}
            Next{' '}
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

export default GlobalSlot;
