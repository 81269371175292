import Modal from 'fragments/Modal';
import React from 'react';
import { FaTimes, FaCoins } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

type TProps = {
  isOpen: boolean;
  closeModal: any;
};
export default function FreeSubscriptionModal(props: TProps) {
  const { isOpen, closeModal } = props;
  const history = useHistory();

  const navigateToSubscription = ()=>{
    history.push('/subscription');
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} close={closeModal}>
        <div className="p-10">
          <div>
            <span className="text-center">
              You are currently on <strong>Free plan</strong> please upgrade ?
            </span>
          </div>

          <div className="flex place-content-between  pt-5 bottom-0">
            <button className="large bg-text-blue text-white" onClick={closeModal}>
              <FaTimes />
              &nbsp;Cancel
            </button>
            <button className='large bg-text-blue text-white' onClick={navigateToSubscription} >
              <FaCoins />
              &nbsp;Subscribe
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
