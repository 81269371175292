import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaUpload, FaCheck, FaTimes, FaRedoAlt } from 'react-icons/fa';
import * as Sentry from '@sentry/react';

import Spinner from 'fragments/Spinner';
import fetchXHR from 'utils/fetch-xhr';
import fetch from 'utils/fetch';

type TUploadOpts = {
  accept?: string[];
  minSize?: number;
  maxSize?: number;
  getUploadURL: string;
};

export type TUpFields =
  | 'Key'
  | 'bucket'
  | 'X-Amz-Algorithm'
  | 'X-Amz-Credential'
  | 'X-Amz-Date'
  | 'Policy'
  | 'X-Amz-Signature';

export type TUploadParams = {
  url: string;
  isPublic: boolean;
  fields: { [key in TUpFields]: string };
};

export type TCompressionResponse = { success: true; file: File } | { success: false; error: string };

interface IProps {
  onComplete?: (filePath: string) => void;
  compressFunction?: (file: File) => Promise<TCompressionResponse>;
  onError?: (err: string) => void;
  onGetURL?: (data: any) => void;
  render: React.ReactElement;
  options: TUploadOpts;
}

export default function (props: IProps) {
  const { options, render } = props;
  const { accept, minSize, maxSize, getUploadURL } = options;

  const [error, seterror] = useState<string>();
  const [uploadProgress, setuploadProgress] = useState<number>(0);
  const [isUploadDone, setisUploadDone] = useState<boolean>(false);
  const [isUploading, setisUploading] = useState<boolean>(false);

  const captureUploadProgress = (e: ProgressEvent) => {
    const { loaded, total } = e;
    setuploadProgress((loaded / total) * 100);
  };

  const doUpload = async (file: File, uploadParams: TUploadParams, extension: string) => {
    if (!uploadParams) {
      seterror('Was unable to get upload URL. Please try again later.');
      return;
    }

    const form = new FormData();
    (Object.keys(uploadParams.fields) as TUpFields[]).forEach(key => form.append(key, uploadParams.fields[key]));
    form.append('file', file);

    fetchXHR(
      uploadParams.url,
      {
        method: 'POST',
        body: form,
      },
      captureUploadProgress
    )
      .then(resp => {
        if (resp.status !== 200 && resp.status !== 204) throw new Error(resp.text);
        setisUploadDone(true);
        setisUploading(false);
        if (props.onComplete) {
          const filePath = `${uploadParams.url}/${uploadParams.fields.Key}`;
          props.onComplete(filePath);
        }
      })
      .catch(e => {
        Sentry.captureMessage(`${file.name} ${file.size} ${file.type} - ${e}`);
        seterror('Upload failed');
        if (props.onError) props.onError('Upload failed');
        setisUploadDone(true);
        setisUploading(false);
      });
  };

  const resetFail = () => {
    seterror('');
    setisUploadDone(false);
  };

  const onFileSelect = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      const errMsg = rejectedFiles[0].errors.map((e: { code: string; message: string }) => e.message).join('. ');
      seterror(errMsg);
      return;
    }

    resetFail();
    setisUploading(true);
    let file: File = acceptedFiles[0];
    if (props.compressFunction) {
      const compressionResponse = await props.compressFunction(file);
      if (!compressionResponse.success) {
        const genericResponse = 'File seems to be invalid. Please make sure it meets the prescribed criteria.';
        seterror(compressionResponse.error || genericResponse);
        if (props.onError) props.onError('Upload failed');
        setisUploadDone(true);
        setisUploading(false);
        return;
      }
      file = compressionResponse.file;
    }

    const extension = file.type.split('/')[1];
    const queryParams = { extension };
    const queryString = new URLSearchParams(queryParams).toString();
    const resp = await fetch(`${getUploadURL}&${queryString}`, { credentials: 'header' });
    try {
      const respJSON = await resp.json();
      if (!respJSON.success) throw new Error(respJSON.error);
      if (props.onGetURL) props.onGetURL(respJSON.params);
      doUpload(file, respJSON.params, extension);
    } catch (e: any) {
      if (props.onError) props.onError('Upload failed');
      setisUploadDone(true);
      setisUploading(false);
      seterror(e.message);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    minSize,
    maxSize,
    onDrop: onFileSelect,
  });

  return (
    <div
      {...(isUploading || isUploadDone ? {} : getRootProps())}
      className={`text-gray-500 flex flex-col items-center justify-center
        ${render ? '' : 'border-2 border-gray-300 pointer-cursor p-12 '}`}
    >
      <input {...getInputProps()} />
      {(() => {
        if (isUploading) return <Spinner size="tiny" />;
        if (error) return <FaTimes className="mb-2 text-red-500 text-4xl" />;
        if (render) return null;
        if (isUploadDone) return <FaCheck className="mb-2 text-green-500 text-4xl" />;
        return <FaUpload className="mb-2 text-4xl" />;
      })()}
      {(() => {
        if (isUploading)
          return (
            <div className="h-4 w-full mt-2">
              <div className="h-full bg-brand rounded-lg" style={{ width: `${uploadProgress}%` }} />
            </div>
          );
        if (error)
          return (
            <p className="flex flex-col items-center justify-center">
              {error}
              <button type="button" onClick={resetFail} className="mt-2 flex items-center">
                <FaRedoAlt className="mr-2" /> Try again
              </button>
            </p>
          );
        if (isUploadDone) return <p>Upload Successful</p>;
        if (isDragActive) return <p>Drop the files here ...</p>;
        if (render) return render;
        return <p>Drag and drop or click to select file</p>;
      })()}
    </div>
  );
}
